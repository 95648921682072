/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

import { useEffect, useState } from "react";

// 3rd party library
import moment from "moment";

// Custom Components
import Author from "components/Table/Author";
import By from "components/Table/By";

export default function FeedbackData(feedbackList) {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (feedbackList) {
      const list = feedbackList.map((item, index) => {
        const temp = {
          srNo: <Author name={index + 1} />,
          type: <Author name={item?.type.replace("_", " ")} />,
          subject: <Author name={item?.subject} />,
          description: <Author name={item?.description} />,
          submittedBy: (
            <By
              name={`${
                item?.createdBy?.callingName
                  ? item?.createdBy?.callingName
                  : item?.createdBy?.firstName
              } ${item?.createdBy?.lastName}`}
              when={moment(item?.createdAt).format("DD-MM-YYYY hh:mm A")}
            />
          ),
        };
        return temp;
      });
      setRows([...list]);
    }
  }, [feedbackList]);

  return {
    columns: [
      { Header: "No.", accessor: "srNo", width: "3%" },
      { Header: "Type", accessor: "type", align: "left" },
      { Header: "Subjects", accessor: "subject", align: "left" },
      { Header: "Description", accessor: "description", align: "left" },
      { Header: "Submitted By", accessor: "submittedBy", width: "13%", align: "left" },
    ],
    rows,
  };
}
