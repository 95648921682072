import React, { useEffect, useState } from "react";

import MDBox from "components/MDBox";
import { Card, Grid, FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import pxToRem from "assets/theme/functions/pxToRem";
import MDTypography from "components/MDTypography";

// Custom Components
import FullScreenImageComponent from "components/ViewFullImage/ViewImage";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import BasicButton from "examples/NewDesign/CustomButton/BasicButton";
import DeleteModal from "examples/modal/deleteModal/deleteModal";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PageTitle from "examples/NewDesign/PageTitle";
import FontComponent from "components/Responsive/fonts";
import CardBreakPoint from "components/Responsive/BreakPoints";
import FTextField from "components/Form/FTextField";
import MDButton from "components/MDButton";
import BasicModal from "examples/modal/BasicModal/BasicModal";
import ImageUpload from "components/ImageUpload/imageUpload";
import CustomButton from "examples/NewDesign/CustomButton";
import ConfirmationModal from "examples/modal/Confirmation/Confirmation";

// Constant
import Constants, {
  Colors,
  FeatureTags,
  Icons,
  ButtonTitles,
  ModalContent,
  Common,
} from "utils/Constants";

// Table Data
import DataTable from "examples/Tables/DataTable";
import ReportStatusData from "layouts/report/data/userReportStatusData";
import ReportOverViewData from "layouts/report/data/reportOverviewData";
import ReportUploadDocumentData from "layouts/report/data/reportUploadDocumentData";

// Redux Component
import { Feature } from "flagged";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbar } from "redux/Slice/Notification";
import { updateReportUserStatus, resetReportDetailsState, storeReportId } from "redux/Slice/Report";
import {
  getReportById,
  updateReportStatus,
  deleteUserReport,
  createReportDocument,
  deleteReportDocument,
  getReportDocument,
  updateReportDocument,
  exportReportPdf,
} from "redux/Thunks/Report";

// 3rd party library
import PropTypes from "prop-types";
import { PDFDocument } from "pdf-lib";

// Utils
import Validators from "utils/Validations";

export default function ReportCardLayout({ children, handleReload }) {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(true);
  const [fullScreenImage, setFullScreenImage] = useState(null);
  const [exportLoading, setExportLoading] = useState(false);
  const [userReportDelete, setUserReportDelete] = useState({
    open: false,
    id: "",
  });
  const [deleteText, setDeleteText] = useState("");
  const { reportDetails, reportDetailsLoading } = useSelector((state) => state.report);
  const ConfigData = useSelector((state) => state.config);
  const permission = ConfigData?.screens?.[6]?.screensInfo?.agreement;
  const customFontSize = FontComponent({ sizes: CardBreakPoint.baseTitleBreakPoint });
  const defaultFontSize = FontComponent({ sizes: {} });
  const [openUploadDocument, setOpenUploadDocument] = useState(false);
  const [documentValue, setDocumentValue] = useState({
    name: "",
    document: "",
    isMerge: false,
  });
  const [documentError, setDocumentError] = useState({
    name: "",
    document: "",
  });
  const [documentLoader, setDocumentLoader] = useState(false);
  const [dummyDocumentData, setDummyDocumentData] = useState([]);
  const [reportDocumentDelete, setReportDocumentDelete] = useState({
    open: false,
    id: "",
  });
  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState({
    editModal: false,
    isMergeModal: false,
  });
  const [imageType, setImageType] = useState(Common.SMALL_IMAGE);
  const [uploadDocumentTitle, setUploadDocumentTitle] = useState(
    ModalContent.UPLOAD_REPORT_DOCUMENT
  );

  useEffect(() => {
    if (params.id) {
      (async () => {
        const res = await dispatch(getReportById(params.id));
        if (res.payload.status === 200) {
          const reportStatus =
            res.payload?.data?.data?.[0]?.[Constants.MONGOOSE_ID]?.userReportStatus;
          await dispatch(storeReportId(reportStatus));
          const userProjectReportId =
            res.payload?.data?.data?.[0]?.[Constants.MONGOOSE_ID]?.userProjectReport;
          const getReportDocumentData = await dispatch(getReportDocument(userProjectReportId));
          setDummyDocumentData(getReportDocumentData?.payload?.data?.data);
        }
      })();
    } else {
      navigate("/client/report");
    }
    return dispatch(resetReportDetailsState());
  }, [params.id, location]);

  const handleReportStatusUpdate = async (event) => {
    const { value, userReportsID } = event.target;
    const reportId = userReportsID?.map((item) => item?.userReportId);
    const body = {
      userReports: reportId,
      status: value,
    };
    const res = await dispatch(updateReportStatus(body));
    if (res?.payload.status === 200) {
      await dispatch(updateReportUserStatus({ status: value, type: "main" }));
      dispatch(
        openSnackbar({
          message: Constants.REPORT_STATUS_SUCCESS,
          notificationType: Constants.NOTIFICATION_SUCCESS,
        })
      );
    } else {
      dispatch(
        openSnackbar({
          message: Constants.REPORT_STATUS_ERROR,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
    }
  };

  const determineStatus = () => {
    const userReports = reportDetails?.[0]?.[Constants.MONGOOSE_ID]?.userReportStatus;
    if (userReports?.some((report) => report.status === "closed")) {
      return "closed";
    }
    if (userReports?.some((report) => report.status === "checked")) return "Checked";
    if (userReports?.some((report) => report.status === "in-discussion")) return "In Discussion";
    if (userReports?.some((report) => report.status === "submitted")) {
      return "submitted";
    }
    return "open";
  };

  const newStateStatus = determineStatus();

  const handleSignatureView = (url) => {
    setImageType(Common.SMALL_SIGNATURE);
    setFullScreenImage(url);
  };

  const handleCloseFullView = () => {
    setFullScreenImage(null);
  };

  const handleOpenReportDelete = (id) => {
    setDeleteText("");
    setUserReportDelete({
      open: true,
      id,
    });
  };

  const handleUserReportDelete = async () => {
    const res = await dispatch(deleteUserReport(userReportDelete.id));
    if (res.payload.status === 200) {
      await dispatch(
        openSnackbar({ message: Constants.USER_REPORT_DELETE_SUCCESS, notificationType: "success" })
      );
      setUserReportDelete({ open: false, id: "" });
      if (reportDetails?.[0]?.[Constants.MONGOOSE_ID]?.userReportStatus?.length === 1) {
        navigate("/client/report");
      } else {
        handleReload();
      }
    } else {
      await dispatch(
        openSnackbar({ message: Constants.REPORT_DELETE_ERROR, notificationType: "error" })
      );
      setUserReportDelete({ open: false, id: "" });
    }
  };

  const viewUploadDocument = () => {
    setOpenUploadDocument(true);
    setUploadDocumentTitle(ModalContent.UPLOAD_REPORT_DOCUMENT);
  };

  const handleDocumentEdit = (data) => {
    setUploadDocumentTitle(ModalContent.UPDATE_REPORT_DOCUMENT);
    setIsDocumentModalOpen((prev) => ({
      ...prev,
      editModal: true,
    }));
    setDocumentValue((prev) => ({
      ...prev,
      name: data?.name || "",
      isMerge: data?.isMerge,
      document: data?.document,
      [Constants.MONGOOSE_ID]: data?.[Constants.MONGOOSE_ID],
    }));
    setOpenUploadDocument(true);
  };

  const handleOpenDocumentDelete = (id) => {
    setReportDocumentDelete({
      open: true,
      id,
    });
  };

  const handleDocumentDelete = async () => {
    try {
      const res = await dispatch(deleteReportDocument(reportDocumentDelete.id));
      if (res.payload.status === 200) {
        const newData = dummyDocumentData.filter(
          (item) => item?.[Constants.MONGOOSE_ID] !== reportDocumentDelete?.id
        );
        setDummyDocumentData(newData);
      }
    } catch (error) {
      dispatch(
        openSnackbar({ message: Constants.SOMETHING_WENT_WRONG, notificationType: "error" })
      );
    }
    setReportDocumentDelete({ open: false, id: "" });
  };

  const handleDocumentIsMerge = (item) => {
    setIsDocumentModalOpen((prev) => ({
      ...prev,
      isMergeModal: true,
    }));
    setDocumentValue(item);
  };

  const { columns, rows } = ReportStatusData(
    reportDetails?.[0]?.[Constants.MONGOOSE_ID]?.userReportStatus,
    handleSignatureView,
    permission,
    handleOpenReportDelete
  );
  const { overViewCoumns, overViewRows } = ReportOverViewData(
    reportDetails?.[0]?.[Constants.MONGOOSE_ID],
    handleReportStatusUpdate,
    newStateStatus,
    permission
  );

  const { documentColumns, documentRows } = ReportUploadDocumentData(
    dummyDocumentData,
    handleDocumentEdit,
    handleOpenDocumentDelete,
    handleDocumentIsMerge
  );

  useEffect(() => {
    setTimeout(() => {
      setShowLoader(false);
    }, 1000);
  });

  async function mergePDFDocuments(documents) {
    const mergedPdf = await PDFDocument.create();

    // Load all PDF documents in parallel
    const loadedDocuments = await Promise.all(documents.map((doc) => PDFDocument.load(doc)));

    // Copy pages for each document and add them to mergedPdf
    await Promise.all(
      loadedDocuments.map(async (document) => {
        const copiedPages = await mergedPdf.copyPages(document, document.getPageIndices());
        copiedPages.forEach((page) => mergedPdf.addPage(page));
      })
    );

    // Save and return the merged PDF as bytes
    return mergedPdf.save();
  }

  const handleExport = async (format) => {
    setExportLoading(true);

    const currentDate = new Date();
    const filename = `Reynard_report_${currentDate.getFullYear()}-${
      currentDate.getMonth() + 1
    }-${currentDate.getDate()}_${currentDate.getHours()}-${currentDate.getMinutes()}-${currentDate.getSeconds()}.${format}`;

    const body = {
      userReportIds: reportDetails[0][Constants.MONGOOSE_ID].userReportStatus.map(
        (item) => item.userReportId
      ),
    };

    // Dispatch action to get the main report PDF
    const res = await dispatch(exportReportPdf(body));
    if (res.error) {
      dispatch(
        openSnackbar({ message: Constants.SOMETHING_WENT_WRONG, notificationType: "error" })
      );
      setExportLoading(false);
      return;
    }

    try {
      // Load the main PDF (from the dispatched response)
      const mainPdfBytes = await res.payload.arrayBuffer();

      const reportDocumentPdfs = dummyDocumentData
        .filter((doc) => doc?.isMerge)
        .map((doc) => doc?.document);

      const reportDocumentPdfBytes = await Promise.all(
        reportDocumentPdfs.map(async (pdfUrl) => {
          const response = await fetch(pdfUrl);
          return response.arrayBuffer();
        })
      );

      // Save the final merged PDF as bytes
      const mergedPdfBytes = await mergePDFDocuments([mainPdfBytes, ...reportDocumentPdfBytes]);

      // Create a Blob and download the merged PDF
      const blob = new Blob([mergedPdfBytes], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      dispatch(
        openSnackbar({ message: Constants.SOMETHING_WENT_WRONG, notificationType: "error" })
      );
    }

    setExportLoading(false);
  };

  const handleModalValueChnage = (e) => {
    const { name, value } = e.target;
    setDocumentValue((prev) => ({
      ...prev,
      [name]: value.trimStart(),
    }));
  };

  const validateField = (fieldName, value, validatorType) => {
    const fieldValue = Validators.validate(
      validatorType,
      value || "",
      null,
      null,
      null,
      fieldName || ""
    );
    setDocumentError((prev) => ({
      ...prev,
      [fieldName]: fieldValue,
    }));
    if (fieldValue === "") return true;
    return false;
  };

  const handleUploadDocumentSubmit = async () => {
    const isNameValid = validateField("name", documentValue?.name, "basic");
    const isDocumentValid = validateField("document", documentValue?.document || "", "basic");
    if (isNameValid && isDocumentValid) {
      setDocumentLoader(true);
      if (isDocumentModalOpen.editModal) {
        // edit document Api call
        const payload = {
          userProjectReportId: documentValue?.[Constants.MONGOOSE_ID],
          body: {
            document: documentValue?.document,
            isMerge: documentValue?.isMerge,
          },
        };
        try {
          const res = await dispatch(updateReportDocument(payload));
          if (res.payload.status === 200) {
            const updatedDocument = res.payload?.data?.data;
            const updatedData = dummyDocumentData.map((item) =>
              item?.[Constants.MONGOOSE_ID] === updatedDocument?.[Constants.MONGOOSE_ID]
                ? { ...item, ...updatedDocument }
                : item
            );
            setDummyDocumentData(updatedData);
            setOpenUploadDocument(false);
            setDocumentError({
              name: "",
              document: "",
            });
            setDocumentValue({
              name: "",
              document: "",
              isMerge: false,
            });
          }
        } catch (error) {
          dispatch(
            openSnackbar({ message: Constants.SOMETHING_WENT_WRONG, notificationType: "error" })
          );
        }
      } else {
        // craete document api call
        const payload = {
          name: documentValue?.name,
          document: documentValue?.document,
          isMerge: documentValue?.isMerge,
          userProjectReport: reportDetails?.[0]?.[Constants.MONGOOSE_ID]?.userProjectReport,
        };
        try {
          const res = await dispatch(createReportDocument(payload));
          if (res.payload.status === 200) {
            const newDocument = res.payload?.data?.data;
            const newData = [...dummyDocumentData, newDocument];
            setDummyDocumentData(newData);
            setOpenUploadDocument(false);
            setDocumentError({
              name: "",
              document: "",
            });
            setDocumentValue({
              name: "",
              document: "",
              isMerge: false,
            });
          } else if (res.payload.status === 400) {
            setDocumentError((prev) => ({
              ...prev,
              name: Constants.REPORT_DOCUMENT_ALREADY_EXIST,
            }));
          }
        } catch (error) {
          dispatch(
            openSnackbar({ message: Constants.SOMETHING_WENT_WRONG, notificationType: "error" })
          );
        }
      }
      setDocumentLoader(false);
    }
  };

  const handleUpdateIsMerge = async () => {
    setDocumentLoader(true);
    // Edit IsMerge api call
    const payload = {
      userProjectReportId: documentValue?.[Constants.MONGOOSE_ID],
      body: {
        isMerge: !documentValue?.isMerge,
      },
    };
    const res = await dispatch(updateReportDocument(payload));
    if (res.payload.status === 200) {
      const updatedDocument = res.payload?.data?.data;
      const updatedData = dummyDocumentData.map((item) =>
        item?.[Constants.MONGOOSE_ID] === updatedDocument?.[Constants.MONGOOSE_ID]
          ? { ...item, ...updatedDocument }
          : item
      );
      setDummyDocumentData(updatedData);
    }
    setIsDocumentModalOpen((prev) => ({ ...prev, isMergeModal: false }));
    setDocumentValue({
      name: "",
      document: "",
      isMerge: false,
    });
    setDocumentLoader(false);
  };

  const handleReportDocumentReload = async () => {
    const userProjectReportId = reportDetails?.[0]?.[Constants.MONGOOSE_ID]?.userProjectReport;
    const getReportDocumentData = await dispatch(getReportDocument(userProjectReportId));
    setDummyDocumentData(getReportDocumentData?.payload?.data?.data);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox display="flex" justifyContent="space-between">
        <PageTitle title="Report Details" />
        {location?.pathname.includes("/client/report/report-details") &&
          (() => (
            <MDBox display="flex">
              <MDButton
                variant="contained"
                color="info"
                onClick={() =>
                  navigate(`${location?.pathname}/web-report`, {
                    state: {
                      reportId: location?.state?.reportId,
                      fetchData: true,
                      type: "reportDetail",
                    },
                  })
                }
                style={{ textTransform: "none", boxShadow: "none" }}
              >
                <span
                  style={{
                    fontSize: FontComponent({ sizes: {} }),
                  }}
                >
                  {ButtonTitles.WEBREPORT}
                </span>
              </MDButton>
              <CustomButton
                title={ButtonTitles.UPLOAD_DOCUMENT}
                icon={Icons.NEW}
                background={Colors.PRIMARY}
                color={Colors.WHITE}
                openModal={() => {
                  viewUploadDocument();
                }}
              />
              <BasicButton
                title={exportLoading ? ButtonTitles.EXPORTING : ButtonTitles.EXPORT}
                icon={Icons.EXPORT}
                background={Colors.WHITE}
                border
                color={Colors.BLACK}
                disabled={exportLoading}
                action={() => handleExport("pdf")}
              />
              <BasicButton
                icon={Icons.RELOAD}
                background={Colors.WHITE}
                border
                color={Colors.BLACK}
                action={() => {
                  handleReload();
                  handleReportDocumentReload();
                }}
              />
            </MDBox>
          ))()}
      </MDBox>
      <Feature name={FeatureTags.SETUP_REPORT}>
        {!showLoader ? (
          <MDBox>
            <Card id="delete-account" sx={{ mt: 3, px: 2 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <MDBox>
                    <MDBox p={2} display="flex" justifyContent="space-between" alignItems="center">
                      <MDTypography
                        p={pxToRem(7)}
                        variant="h6"
                        fontWeight="medium"
                        sx={{ fontSize: customFontSize }}
                      >
                        Report
                      </MDTypography>
                    </MDBox>
                    <DataTable
                      table={{ columns: overViewCoumns, rows: overViewRows }}
                      isSorted={false}
                      entriesPerPage={{ defaultValue: 4 }}
                      showTotalEntries={false}
                      noEndBorder
                      loading={reportDetailsLoading}
                      licenseRequired
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={12}>
                  <MDBox>
                    <MDBox p={2} display="flex" justifyContent="space-between" alignItems="center">
                      <MDTypography
                        p={pxToRem(7)}
                        variant="h6"
                        fontWeight="medium"
                        sx={{ fontSize: customFontSize }}
                      >
                        Report Document
                      </MDTypography>
                    </MDBox>
                    <DataTable
                      table={{ columns: documentColumns, rows: documentRows }}
                      isSorted={false}
                      entriesPerPage={{ defaultValue: 4 }}
                      showTotalEntries={false}
                      noEndBorder
                      loading={reportDetailsLoading}
                      licenseRequired
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={12}>
                  {/* Users Reports Status table */}
                  <MDBox p={2} display="flex" justifyContent="space-between" alignItems="center">
                    <MDTypography
                      variant="h6"
                      fontWeight="medium"
                      sx={{ fontSize: customFontSize }}
                    >
                      User Report Status
                    </MDTypography>
                  </MDBox>
                  <MDBox sx={{ marginBottom: pxToRem(16) }}>
                    <Grid item xs={12}>
                      <MDBox>
                        <DataTable
                          table={{ columns, rows }}
                          isSorted={false}
                          entriesPerPage={{ defaultValue: 4 }}
                          showTotalEntries={false}
                          noEndBorder
                          loading={reportDetailsLoading}
                          licenseRequired
                        />
                      </MDBox>
                    </Grid>
                  </MDBox>
                </Grid>
              </Grid>
            </Card>
            {children}
          </MDBox>
        ) : (
          <MDBox
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {Icons.LOADING2}
          </MDBox>
        )}
        <FullScreenImageComponent
          fullScreenImage={fullScreenImage}
          handleCloseFullView={handleCloseFullView}
          image={fullScreenImage}
          src={fullScreenImage}
          type={imageType}
        />
        {userReportDelete.open && (
          <DeleteModal
            open={userReportDelete}
            title={ModalContent.USER_REPORT_DELETE_TITLE}
            message={ModalContent.USER_REPORT_DELETE_MESSAGE}
            handleClose={() => setUserReportDelete({ open: false, id: "" })}
            handleDelete={handleUserReportDelete}
            confirmText="DELETE"
            confirmInput={deleteText}
          >
            <FTextField
              placeholder="Enter Message"
              name="delete"
              id="delete"
              type="text"
              value={deleteText}
              handleChange={(e) => setDeleteText(e.target.value)}
            />
          </DeleteModal>
        )}
        {/* Document Modal */}
        {openUploadDocument && (
          <BasicModal
            title={uploadDocumentTitle}
            open={openUploadDocument}
            handleClose={() => {
              setOpenUploadDocument(false);
              setDocumentLoader(false);
              setIsDocumentModalOpen((prev) => ({
                ...prev,
                editModal: false,
              }));
              setDocumentValue({
                name: "",
                document: "",
                isMerge: false,
              });
              setDocumentError({
                name: "",
                document: "",
              });
            }}
            handleAction={handleUploadDocumentSubmit}
            disabled={documentLoader}
            actionButton={documentLoader ? ButtonTitles.LOADING : ButtonTitles.SUBMIT}
            py={0}
          >
            <FormControl sx={{ my: 1 }}>
              <FTextField
                label="Document Name*"
                placeholder="Enter Name"
                name="name"
                id="name"
                type="text"
                error={Boolean(documentError?.name)}
                helperText={documentError?.name}
                value={documentValue.name}
                handleChange={handleModalValueChnage}
                disabled={isDocumentModalOpen.editModal}
              />
              <MDBox sx={{ marginTop: pxToRem(16) }}>
                <ImageUpload
                  label={isDocumentModalOpen.editModal ? "Update Document*" : "Upload Document*"}
                  name="document"
                  onImageUpload={(imageValues) =>
                    setDocumentValue((prev) => ({
                      ...prev,
                      document: imageValues[0]?.url,
                    }))
                  }
                  onImageCancel={() =>
                    setDocumentValue((prev) => ({
                      ...prev,
                      document: "",
                    }))
                  }
                  {...(isDocumentModalOpen.editModal && {
                    data: documentValue?.document
                      ? [
                          {
                            name: "",
                            url: documentValue.document,
                            size: 0,
                          },
                        ]
                      : [],
                  })}
                  type="upload_document"
                  formats={["application/pdf"]}
                  acceptType=""
                  error={documentError?.document}
                  helperText={documentError?.document}
                  maxImageCount={1}
                  imageTypeError={Constants.PDF_FILE_ALLOWED}
                  sizeUpdate
                />
              </MDBox>
              <MDBox>
                <MDTypography
                  variant="caption"
                  mb={1}
                  sx={{ fontSize: defaultFontSize, color: "#344054" }}
                >
                  Do you want to merge this document with the Report PDF?
                </MDTypography>
                <RadioGroup
                  name="isMerge"
                  value={documentValue.isMerge}
                  onChange={handleModalValueChnage}
                  sx={{ display: "flex", flexDirection: "row" }}
                >
                  <FormControlLabel label="Yes" value="true" control={<Radio />} />
                  <FormControlLabel
                    sx={{ marginLeft: "8px" }}
                    label="No"
                    value="false"
                    control={<Radio />}
                  />
                </RadioGroup>
              </MDBox>
            </FormControl>
          </BasicModal>
        )}
        {/* Delete Modal for report document */}
        {reportDocumentDelete.open && (
          <DeleteModal
            open={reportDocumentDelete}
            title={ModalContent.REPORT_DOCUMENT_DELETE_TITLE}
            message={ModalContent.REPORT_DOCUMENT_DELETE}
            handleClose={() => setReportDocumentDelete({ open: false, id: "" })}
            handleDelete={handleDocumentDelete}
          />
        )}
        {/* Confirmation Modal for report document Merge */}
        {isDocumentModalOpen.isMergeModal && (
          <ConfirmationModal
            open={isDocumentModalOpen.isMergeModal}
            title={ModalContent.REPORT_DOCUMENT_MERGE_TITLE}
            message={
              !documentValue.isMerge
                ? ModalContent.REPORT_DOCUMENT_MERGE_MESSAGE
                : ModalContent.REPORT_DOCUMENT_NOT_MERGE_MESSAGE
            }
            handleClose={() => {
              setIsDocumentModalOpen((prev) => ({ ...prev, isMergeModal: false }));
              setDocumentValue({
                name: "",
                document: "",
                isMerge: false,
              });
            }}
            handleAction={handleUpdateIsMerge}
            disabled={documentLoader}
          />
        )}
      </Feature>
    </DashboardLayout>
  );
}

ReportCardLayout.propTypes = {
  children: PropTypes.node.isRequired,
  handleReload: PropTypes.func,
};

ReportCardLayout.defaultProps = {
  handleReload: () => {},
};
