import React, { useState } from "react";

// Custom Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import pxToRem from "assets/theme/functions/pxToRem";
import CustomImage from "components/Table/GroupImage";
import CustomDrawer from "components/Drawer/CustomDrawer";
import FullScreenImageComponent from "components/ViewFullImage/ViewImage";
import FontComponent from "components/Responsive/fonts";
import { DrawerBreakPoint, TitleBreakPoint } from "components/Responsive/BreakPoints";

// Materia Components
import { Box, Divider, Grid, Icon, IconButton } from "@mui/material";

// Utils
import Constants, { Icons, Colors, defaultData } from "utils/Constants";

// 3rd party libraries
import moment from "moment";
import PropTypes from "prop-types";

function ToolBoxTalk({ openToolboxAnchor, closeToolboxDrawer, toolBoxViewData, loading }) {
  const [fullScreenImage, setFullScreenImage] = useState(null);
  const [fullScreenImageIndex, setFullScreenImageIndex] = useState(0);
  const titleBreakPoint = FontComponent({ sizes: DrawerBreakPoint.baseTitleBreakPoint });
  const labelBreakPoint = FontComponent({ sizes: TitleBreakPoint.smallTitleBreakPoint });
  const valueBreakPoint = FontComponent({ sizes: TitleBreakPoint.baseTitleBreakPoint });

  const handleImageFullView = (imageUrl, i) => {
    setFullScreenImageIndex(i);
    setFullScreenImage(imageUrl);
  };

  const handleCloseFullView = () => {
    setFullScreenImage(null);
  };

  const handleNextImage = () => {
    setFullScreenImageIndex((prevIndex) => (prevIndex + 1) % toolBoxViewData.photos.length);
  };

  const handlePreviousImage = () => {
    setFullScreenImageIndex(
      (prevIndex) => (prevIndex - 1 + toolBoxViewData.photos.length) % toolBoxViewData.photos.length
    );
  };

  const tooboxData = (key, value, lg = 3) => (
    <Grid item xs={12} sm={6} md={4} lg={lg} key={key}>
      <MDBox display="flex" flexDirection="column" justifyContent="space-between" flexWrap="wrap">
        <MDTypography
          sx={{
            mt: 1,
            color: "#475467",
            fontFamily: "Inter",
            fontSize: labelBreakPoint,
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: pxToRem(20),
          }}
        >
          {key}
        </MDTypography>
        <MDTypography
          sx={{
            mt: 1,
            color: "var(--default-tier-brand-1, #191A51)",
            fontFamily: "Inter",
            fontSize: valueBreakPoint,
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: pxToRem(20),
            whiteSpace: "pre-line",
            textAlign: "justify",
          }}
        >
          {value}
        </MDTypography>
      </MDBox>
    </Grid>
  );
  return (
    <>
      <CustomDrawer
        defaultAnchor={openToolboxAnchor}
        width="75%"
        onDrawerClose={closeToolboxDrawer}
      >
        {!loading ? (
          <>
            <MDBox
              sx={{ backgroundColor: Colors.PRIMARY }}
              px={pxToRem(24)}
              p={pxToRem(10)}
              display="flex"
              justifyContent="start"
              alignItems="center"
            >
              <IconButton mr={2} aria-label="fingerprint" color="info" onClick={closeToolboxDrawer}>
                {Icons.CROSS}
              </IconButton>
              <MDTypography
                sx={{
                  color: Colors.WHITE,
                  fontFamily: "Inter",
                  fontSize: titleBreakPoint,
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: pxToRem(28),
                  textAlign: "center",
                }}
              >
                Toolbox Talk
              </MDTypography>
            </MDBox>
            <Divider />
            <MDBox ml={{ xs: pxToRem(18), md: pxToRem(28) }}>
              <MDBox>
                <MDTypography
                  sx={{
                    color: "var(--gray-900, #101828)",
                    fontFamily: "Inter",
                    fontSize: titleBreakPoint,
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: pxToRem(28),
                    marginTop: pxToRem(20),
                  }}
                >
                  Details
                </MDTypography>
                <Grid container spacing={1} mt={pxToRem(20)}>
                  {tooboxData("Project", toolBoxViewData?.project?.title || Constants.NA)}
                  {tooboxData("Location", toolBoxViewData?.location?.title || Constants.NA)}
                  {tooboxData("Team", toolBoxViewData?.team?.teamsWfmName || Constants.NA)}
                  {tooboxData(
                    "Submitted Date",
                    moment(toolBoxViewData?.createdAt).format(defaultData.WEB_24_HOURS_FORMAT)
                  )}
                  {tooboxData("Note", toolBoxViewData?.note || Constants.NA, 11)}
                </Grid>
              </MDBox>
              <MDBox
                display="flex"
                flexDirection="row"
                position="relative"
                sx={{
                  rowGap: pxToRem(10),
                  marginTop: pxToRem(24),
                }}
              >
                {toolBoxViewData?.photos?.map((img, i) => (
                  <Box
                    key={img}
                    position="relative"
                    sx={{
                      "&:hover .overlay": {
                        display: "flex",
                        borderRadius: "8px",
                      },
                    }}
                  >
                    <CustomImage item={img} />
                    <Box
                      display="none"
                      position="absolute"
                      top={0}
                      right={0}
                      bottom={0}
                      left={0}
                      alignItems="center"
                      justifyContent="center"
                      bgcolor="rgba(0, 0, 0, 0.5)"
                      className="overlay"
                      width={50}
                      height={50}
                    >
                      <Icon
                        sx={{ color: "white", width: 30, height: 30, cursor: "pointer" }}
                        onClick={() => handleImageFullView(img, i)}
                      >
                        {Icons.VIEW2}
                      </Icon>
                    </Box>
                  </Box>
                ))}
              </MDBox>
              <MDTypography
                sx={{
                  color: "var(--gray-900, #101828)",
                  fontFamily: "Inter",
                  fontSize: valueBreakPoint,
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: pxToRem(28),
                  marginTop: pxToRem(10),
                }}
              >
                {`Host Signature (${
                  toolBoxViewData?.createdBy?.callingName
                    ? toolBoxViewData?.createdBy?.callingName
                    : toolBoxViewData?.createdBy?.firstName
                } ${toolBoxViewData?.createdBy?.lastName})`}
              </MDTypography>
              <MDBox
                display="flex"
                alignItems="center"
                justifyContent="center"
                height={pxToRem(60)}
                width={pxToRem(172)}
                sx={{
                  backgroundColor: Colors.GREY1,
                  marginRight: pxToRem(10),
                  border: "1px solid #D0D5DD",
                  borderRadius: pxToRem(8),
                }}
              >
                <img
                  src={toolBoxViewData.createdBySignature}
                  alt={toolBoxViewData.createdBySignature}
                  key={toolBoxViewData.createdBySignature[Constants.MONGOOSE_ID]}
                  style={{
                    width: pxToRem(50),
                    height: pxToRem(50),
                    marginRight: pxToRem(10),
                    borderRadius: "8px",
                  }}
                />
              </MDBox>
              <MDBox marginTop={pxToRem(20)}>
                <Divider />
                <MDBox marginTop={pxToRem(25)}>
                  <MDTypography
                    sx={{
                      color: "var(--gray-900, #101828)",
                      fontFamily: "Inter",
                      fontSize: titleBreakPoint,
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: pxToRem(28),
                    }}
                  >
                    Attendance Details
                  </MDTypography>
                  <MDTypography
                    sx={{
                      color: "var(--gray-900, #101828)",
                      fontFamily: "Inter",
                      fontSize: valueBreakPoint,
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: pxToRem(28),
                      marginTop: pxToRem(10),
                    }}
                  >
                    Signatures
                  </MDTypography>
                  <MDBox display="flex" flexWrap="wrap">
                    {toolBoxViewData?.memberSignature?.length > 0 ? (
                      toolBoxViewData?.memberSignature?.map((sign) => (
                        <MDBox
                          key={toolBoxViewData[Constants.MONGOOSE_ID]}
                          display="flex"
                          flexDirection="column"
                          marginBottom={pxToRem(24)}
                        >
                          <MDTypography
                            sx={{
                              color: "var(--gray-900, #101828)",
                              fontFamily: "Inter",
                              fontSize: valueBreakPoint,
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: pxToRem(28),
                            }}
                          >
                            {`${
                              sign?.user?.callingName
                                ? sign?.user?.callingName
                                : sign?.user?.firstName
                            } ${sign?.user?.lastName}`}
                          </MDTypography>
                          <MDBox
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            height={pxToRem(60)}
                            width={pxToRem(172)}
                            sx={{
                              backgroundColor: Colors.GREY1,
                              marginRight: pxToRem(10),
                              border: "1px solid #D0D5DD",
                              borderRadius: pxToRem(8),
                            }}
                          >
                            <img
                              src={sign.signature}
                              alt={sign?.signature}
                              key={sign[Constants.MONGOOSE_ID]}
                              style={{
                                width: pxToRem(50),
                                height: pxToRem(50),
                                marginRight: pxToRem(10),
                                borderRadius: "8px",
                              }}
                            />
                          </MDBox>
                        </MDBox>
                      ))
                    ) : (
                      <MDTypography
                        sx={{
                          color: "grey",
                          fontFamily: "Inter",
                          fontSize: valueBreakPoint,
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: pxToRem(28),
                          marginTop: pxToRem(10),
                        }}
                      >
                        No Signature found
                      </MDTypography>
                    )}
                  </MDBox>
                </MDBox>
              </MDBox>
            </MDBox>
          </>
        ) : (
          <MDBox
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {Icons.LOADING2}
          </MDBox>
        )}
      </CustomDrawer>
      {fullScreenImage && (
        <FullScreenImageComponent
          fullScreenImage={fullScreenImage}
          handleCloseFullView={handleCloseFullView}
          handlePreviousImage={handlePreviousImage}
          handleNextImage={handleNextImage}
          image={toolBoxViewData.photos}
          src={toolBoxViewData.photos[fullScreenImageIndex]}
        />
      )}
    </>
  );
}

// Setting default values for the props
ToolBoxTalk.defaultProps = {
  openToolboxAnchor: false,
};

// Typechecking props for the ToolBoxTalk
ToolBoxTalk.propTypes = {
  openToolboxAnchor: PropTypes.objectOf(PropTypes.any),
  closeToolboxDrawer: PropTypes.func.isRequired,
  toolBoxViewData: PropTypes.objectOf(PropTypes.any).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ToolBoxTalk;
