import React, { useEffect, useState } from "react";

// Custom Components
import EditEquipmentQuantity from "examples/modal/EditEquipmentQuantity/editEquipmentQuantity";
import ConfirmationModal from "examples/modal/Confirmation/Confirmation";
import equipmentLayerData from "layouts/Approver/data/EquipmentLayerData";
import projectLayerData from "layouts/Approver/data/ProjectLayerData";
import DeleteModal from "examples/modal/deleteModal/deleteModal";
import userLayerData from "layouts/Approver/data/UserLayerData";
import BasicModal from "examples/modal/BasicModal/BasicModal";
import DataTable from "examples/Tables/DataTable/NestedTable";
import FormTextField from "components/Form/FTextField";
import pxToRem from "assets/theme/functions/pxToRem";
import MDBox from "components/MDBox";

// Constant
import Constants, {
  ModalContent,
  FeatureTags,
  defaultData,
  Colors,
  ButtonTitles,
} from "utils/Constants";

// Redux component
import { useDispatch, useSelector } from "react-redux";
import { openSnackbar } from "redux/Slice/Notification";
import AllEquipmentRequestListThunk, {
  equipmentPermissionThunk,
  addToQueueEquipment,
  orderEquipment,
  updateApproverCommentThunk,
} from "redux/Thunks/EquipmentRequest";
import {
  loadProjectLayerData,
  loadEquipmentTypeLayerData,
  updateEquipmentOrderQuantity,
  reloadData,
  getApproverComments,
  storeCurrentStatus,
} from "redux/Slice/EquipmentRequest";
import { removeEquipment } from "redux/Slice/Warehouse";
import { equipmentDeleteThunk } from "redux/Thunks/Equipment";

// 3rd Party lib
import { Feature } from "flagged";
import CheckInCheckOutComments from "examples/modal/CheckInCheckOutComments/CheckInCheckOutComments";
import { paramCreater } from "utils/methods/methods";
import ReactDatePicker from "react-datepicker";
import DateTime from "components/DateTime/DateTime";
import moment from "moment";
import PropTypes from "prop-types";

const intialSelectedDate = {
  startDate: null,
  endDate: null,
};

export default function EquipmentRequest({ status }) {
  const [equipmentId, setEquipmentId] = useState("");
  const [orderRemark, setOrderRemark] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(intialSelectedDate);
  const [projectPermission, setProjectPermission] = useState({
    projectType: "",
    projectId: "",
    equipData: {},
    reason: "",
  });
  const [openProjectApprove, setOpenProjectApprove] = useState(false);
  const [openProjectReject, setOpenProjectReject] = useState(false);
  const handleProjectApproveClose = () => {
    setOpenProjectApprove(false);
    setSelectedDate(intialSelectedDate);
    setOrderRemark("");
  };
  const handleProjectRejectClose = () => setOpenProjectReject(false);
  const [equipPermission, setEquipPermission] = useState({
    equipType: "",
    equipId: "",
    reason: "",
  });
  const [openEquipmentApprove, setOpenEquipmentApprove] = useState(false);
  const [openEquipmentReject, setOpenEquipmentReject] = useState(false);
  const handleApproveClose = () => setOpenEquipmentApprove(false);
  const handleRejectClose = () => setOpenEquipmentReject(false);
  const [userPermission, setUserPermission] = useState({
    userType: "",
    userId: "",
    reason: "",
  });
  const [openUserApprove, setOpenUserApprove] = useState(false);
  const [openUserReject, setOpenUserReject] = useState(false);
  const handleUserApproveClose = () => setOpenUserApprove(false);
  const handleUserRejectClose = () => setOpenUserReject(false);
  const [openEditEquipmentQuantity, setOpenEditEquipmentQuantity] = useState(false);
  const handleCloseEditEquipmentQuantity = () => setOpenEditEquipmentQuantity(false);
  const [editEquipmentData, setEditEquipmentData] = useState({});
  const [approverComments, setApproverComments] = useState(false);
  const [approverCommentsData, setApproverCommentsData] = useState({
    type: "",
    comments: [],
    id: "",
    commentId: "",
    equipStatus: "",
  });
  const [userComments, setUserComments] = useState(false);
  const [userCommentsData, setUserCommentsData] = useState({
    type: "",
    comments: [],
  });
  const [tablePagination, setTablePagination] = useState({
    page: 0,
    perPage: defaultData.DATE_ON_SINGLE_API_CALL,
  });
  const {
    list = [],
    currentProjectLayerId = "",
    currentEquipmentTypeLayerId = "",
    loading,
  } = useSelector((state) => state.equipmentRequest);

  const [next, setNext] = useState(0);
  const ConfigData = useSelector((state) => state.config);
  const permission = ConfigData?.screens?.[10]?.screensInfo?.agreement;
  const dispatch = useDispatch();
  const currentDate = new Date();
  const checkPriceType =
    Array.isArray(projectPermission?.equipData) &&
    projectPermission?.equipData?.some((check) => check?.quantityType?.priceType === "rental");

  const handleFilter = async () => {
    setTablePagination({ ...tablePagination, page: 0 });
    setNext(0);
    const paramData = {
      status,
    };
    Object.keys(paramData).forEach((key) => {
      if (paramData[key] === "") {
        delete paramData[key];
      }
    });
    const data = new URLSearchParams(paramData);
    await dispatch(reloadData());
    await dispatch(storeCurrentStatus(status));
    await dispatch(AllEquipmentRequestListThunk(data));
  };

  const handleChange = (value) => {
    setSelectedDate({
      ...selectedDate,
      startDate: checkPriceType ? value[0] : value,
      endDate: value[1],
    });
  };

  const clearDate = () => {
    setSelectedDate(intialSelectedDate);
  };

  const openApproverCommentModal = (type, comment, id, commentId, equipStatus) => {
    setApproverComments(true);
    setApproverCommentsData((prevData) => ({
      ...prevData,
      type,
      comments: comment,
      id,
      commentId,
      equipStatus,
    }));
  };

  const openEngineerCommentModal = (type, comments) => {
    setUserComments(true);
    setUserCommentsData((prevData) => ({ ...prevData, type, comments }));
  };

  const handleApproverCommentsClose = () => {
    setApproverComments(false);
    setApproverCommentsData({
      type: "",
      comments: "",
    });
  };

  const handleUserCommentsClose = () => setUserComments(false);

  const handleAddApproverComments = async (body) => {
    if (status === "pending") {
      await dispatch(
        getApproverComments({
          comments: body,
          projectId: currentProjectLayerId.projectId,
          equipmentId: approverCommentsData.id,
        })
      );
      setApproverComments(false);
      setApproverCommentsData((prevData) => ({
        ...prevData,
        comments: body,
      }));
    } else {
      const approverComment = {
        pmComments: body,
      };
      const dataPayload = {
        id: approverCommentsData.commentId,
        body: approverComment,
      };
      setButtonLoading(true);
      const res = await dispatch(updateApproverCommentThunk(dataPayload));
      setButtonLoading(false);
      if (res.payload.status === 200) {
        handleFilter();
        setApproverComments(false);
        dispatch(
          openSnackbar({
            message: Constants.COMMENT_UPDATED_SUCCESS,
            notificationType: Constants.NOTIFICATION_SUCCESS,
          })
        );
      } else {
        dispatch(
          openSnackbar({
            message: Constants.SOMETHING_WENT_WRONG,
            notificationType: Constants.NOTIFICATION_ERROR,
          })
        );
      }
    }
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
    setEquipmentId("");
  };

  const handleEditEquipmentQuantity = async (item) => {
    await dispatch(
      updateEquipmentOrderQuantity({
        ...item,
        projectId: currentProjectLayerId.projectId,
        equipmentId: currentEquipmentTypeLayerId.equipmentId,
      })
    );
    handleCloseEditEquipmentQuantity();
  };

  const openConfirmationBox = (element, type) => {
    setEquipPermission({ equipType: type, equipId: element });
    if (type === "approved") {
      setOpenEquipmentApprove(true);
    } else {
      setOpenEquipmentReject(true);
    }
  };

  const openProjectConfirmationBox = (element, type, equipments) => {
    setProjectPermission({ projectType: type, projectId: element, equipData: equipments });
    if (type === "placeOrder") {
      setOpenProjectApprove(true);
    } else {
      setOpenProjectReject(true);
    }
  };

  const openUserConfirmationBox = (element, type) => {
    setUserPermission({ userType: type, userId: element });
    if (type === "approved") {
      setOpenUserApprove(true);
    } else {
      setOpenUserReject(true);
    }
  };

  const openEditQuantity = (item, appQty) => {
    const temp = {
      id: item.equipmentRequestId,
      equipmentImage: currentEquipmentTypeLayerId?.equipmentData?.equipmentTypeImage?.url,
      imageName: currentEquipmentTypeLayerId?.equipmentData?.equipmentTypeImage?.name,
      equipmentName: currentEquipmentTypeLayerId?.equipmentData?.type,
      requestedBy: `${item?.callingName ? item?.callingName : item?.firstName} ${item?.lastName}`,
      totalRequestedQuantity: item?.engineerRequestedQuantity,
      price: currentEquipmentTypeLayerId.equipmentData.price,
      currencySymbol: currentEquipmentTypeLayerId?.equipmentData?.currencyUnit?.abbreviation,
      approvedQuantity: appQty,
    };
    setOpenEditEquipmentQuantity(true);
    setEditEquipmentData(temp);
  };
  const handlePendingAction = async (type) => {
    if (type === "Project") {
      const rejectId = {
        project: projectPermission?.projectId,
      };
      const paramData = {
        id: paramCreater(rejectId),
        status: {
          status: projectPermission.projectType,
          existingStatus: status,
          remark: [
            {
              user: ConfigData.config[0]?.id,
              time: moment(currentDate).format(defaultData.DATABASE_24_HOURS_FORMAT),
              status,
              comment: projectPermission.reason,
            },
          ],
        },
      };
      const res = await dispatch(equipmentPermissionThunk(paramData));
      if (res.payload.status === 200) {
        handleFilter();
        setOpenProjectApprove(false);
        setOpenProjectReject(false);
        dispatch(
          openSnackbar({
            message:
              projectPermission.projectType === "approved"
                ? Constants.PROJECT_APPROVE_SUCCESS_MESSAGE
                : Constants.PROJECT_REJECT_SUCCESS_MESSAGE,
            notificationType: Constants.NOTIFICATION_SUCCESS,
          })
        );
      } else {
        setOpenProjectApprove(false);
        setOpenProjectReject(false);
        dispatch(
          openSnackbar({
            message: Constants.SOMETHING_WENT_WRONG,
            notificationType: Constants.NOTIFICATION_ERROR,
          })
        );
      }
    } else if (type === "Equipment") {
      const rejectId = {
        project: currentProjectLayerId?.projectId,
        equipmentType: equipPermission?.equipId,
      };
      const paramData = {
        id: paramCreater(rejectId),
        status: {
          status: equipPermission.equipType,
          existingStatus: status,
          remark: [
            {
              user: ConfigData.config[0]?.id,
              time: moment(currentDate).format(defaultData.DATABASE_24_HOURS_FORMAT),
              status,
              comment: equipPermission.reason,
            },
          ],
        },
      };
      const res = await dispatch(equipmentPermissionThunk(paramData));
      if (res.payload.status === 200) {
        handleFilter();
        setOpenEquipmentApprove(false);
        setOpenEquipmentReject(false);
        dispatch(
          openSnackbar({
            message:
              equipPermission.equipType === "approved"
                ? Constants.EQUIPMENT_APPROVE_SUCCESS_MESSAGE
                : Constants.EQUIPMENT_REJECT_SUCCESS_MESSAGE,
            notificationType: Constants.NOTIFICATION_SUCCESS,
          })
        );
      } else {
        setOpenEquipmentApprove(false);
        setOpenEquipmentReject(false);
        dispatch(
          openSnackbar({
            message: Constants.SOMETHING_WENT_WRONG,
            notificationType: Constants.NOTIFICATION_ERROR,
          })
        );
      }
    } else if (type === "User") {
      const rejectId = {
        equipmentRequestId: userPermission.userId,
      };

      const paramData = {
        id: paramCreater(rejectId),
        status: {
          status: userPermission.userType,
          remark: [
            {
              user: ConfigData.config[0]?.id,
              time: moment(currentDate).format(defaultData.DATABASE_24_HOURS_FORMAT),
              status,
              comment: userPermission.reason,
            },
          ],
        },
      };

      const res = await dispatch(equipmentPermissionThunk(paramData));
      if (res.payload.status === 200) {
        handleFilter();
        setOpenUserApprove(false);
        setOpenUserReject(false);
        dispatch(
          openSnackbar({
            message:
              userPermission.userType === "approved"
                ? Constants.USER_APPROVE_SUCCESS_MESSAGE
                : Constants.USER_REJECT_SUCCESS_MESSAGE,
            notificationType: Constants.NOTIFICATION_SUCCESS,
          })
        );
      } else {
        setOpenUserApprove(false);
        setOpenUserReject(false);
        dispatch(
          openSnackbar({
            message: Constants.SOMETHING_WENT_WRONG,
            notificationType: Constants.NOTIFICATION_ERROR,
          })
        );
      }
    } else if (type === "OrderProject") {
      if (selectedDate.startDate === null && selectedDate.endDate === null) {
        setSelectedDate({
          ...selectedDate,
          errorMessage: "Please select date",
        });
        return;
      }
      const databody = {
        fromDate: moment(selectedDate.startDate).format(defaultData.DATABSE_DATE_FORMAT).toString(),
        toDate: checkPriceType
          ? moment(selectedDate.endDate).format(defaultData.DATABSE_DATE_FORMAT).toString()
          : null,
        comments: [
          {
            user: ConfigData.config[0]?.id,
            time: moment(currentDate).format(defaultData.DATABASE_24_HOURS_FORMAT),
            status,
            comment: orderRemark,
          },
        ],
        pmOrderManageData: projectPermission?.equipData?.map((element) => ({
          id: element?.pmOrderManageDetails[Constants.MONGOOSE_ID],
          comments: element?.pmOrderManageDetails?.comments?.pmComments?.filter(
            (comment) => comment !== ""
          ),
          fromPeriod: moment(selectedDate.startDate)
            .format(defaultData.DATABSE_DATE_FORMAT)
            .toString(),
          toPeriod:
            element?.quantityType?.priceType === "rental"
              ? moment(selectedDate.endDate).format(defaultData.DATABSE_DATE_FORMAT).toString()
              : null,
        })),
      };
      const body = {
        id: projectPermission?.projectId,
        body: databody,
      };
      setButtonLoading(true);
      const res = await dispatch(orderEquipment(body));
      if (res.payload.status === 200) {
        handleFilter();
        setOpenProjectApprove(false);
        dispatch(
          openSnackbar({
            message: Constants.ORDER_PLACED_SUCCESS_MESSAGE,
            notificationType: Constants.NOTIFICATION_SUCCESS,
          })
        );
      } else {
        setOpenProjectApprove(false);
        dispatch(
          openSnackbar({
            message: Constants.SOMETHING_WENT_WRONG,
            notificationType: Constants.NOTIFICATION_ERROR,
          })
        );
      }
      setSelectedDate(intialSelectedDate);
      setOrderRemark("");
      setButtonLoading(false);
    }
  };

  const addToQueue = async (projectId, equipData) => {
    const queueProject = {
      project: projectId,
      equipmentType: equipData?.map((etype) => ({
        typeId: etype[Constants.MONGOOSE_ID],
        users: etype?.users?.map((user) => ({
          requestId: user?.equipmentRequestId,
          pmApprovedQuantity: user?.approvedQuantity.toString(),
        })),
        pmRequestedQuantity: etype?.users
          ?.reduce((total, user) => total + parseInt(user?.approvedQuantity, 10), 0)
          .toString(),
        pmComments: etype.pmComments.filter((comment) => comment !== ""),
      })),
    };
    setButtonLoading(true);
    const res = await dispatch(addToQueueEquipment(queueProject));
    if (res.payload.status === 200) {
      handleFilter();
      dispatch(
        openSnackbar({
          message: Constants.PROJECT_QUEUE_SUCCESS_MESSAGE,
          notificationType: Constants.NOTIFICATION_SUCCESS,
        })
      );
    } else {
      dispatch(
        openSnackbar({
          message: Constants.SOMETHING_WENT_WRONG,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
    }
    setButtonLoading(false);
  };

  const addToQueueEquipmnt = async (equipId, typeData) => {
    const quantity = list?.flatMap((proj) =>
      proj.equipmentTypes.flatMap((equip) =>
        equip.users.map((user) => {
          if (
            proj[Constants.MONGOOSE_ID] === currentProjectLayerId?.projectId &&
            equip[Constants.MONGOOSE_ID] === equipId &&
            typeData?.users?.some((user1) => user1.equipmentRequestId === user?.equipmentRequestId)
          ) {
            return user?.approvedQuantity || "0"; // Return '0' if approvedQuantity is undefined or null
          }
          return "0";
        })
      )
    );

    const totalQuantity = quantity.reduce((acc, curr) => acc + parseInt(curr, 10), 0).toString();
    const comments = list
      ?.flatMap((proj) =>
        proj.equipmentTypes.flatMap((equip) => {
          if (
            proj[Constants.MONGOOSE_ID] === currentProjectLayerId?.projectId &&
            equip[Constants.MONGOOSE_ID] === equipId
          ) {
            return equip?.pmComments || ""; // Return '0' if approvedQuantity is undefined or null
          }
          return "";
        })
      )
      .filter((comment) => comment !== "");
    const queueEquipment = {
      project: currentProjectLayerId?.projectId,
      equipmentType: [
        {
          typeId: equipId,
          users: typeData?.users?.map((user) => ({
            requestId: user?.equipmentRequestId,
            pmApprovedQuantity: list
              ?.flatMap((proj) =>
                proj.equipmentTypes.flatMap((equip) =>
                  equip.users.map((user1) => {
                    if (
                      proj[Constants.MONGOOSE_ID] === currentProjectLayerId?.projectId &&
                      equip[Constants.MONGOOSE_ID] === equipId &&
                      user1?.equipmentRequestId === user?.equipmentRequestId
                    ) {
                      return user1?.approvedQuantity?.toString();
                    }
                    return null;
                  })
                )
              )
              .flat()
              .find((item) => item !== null),
          })),
          pmRequestedQuantity: totalQuantity,
          pmComments: comments,
        },
      ],
    };
    setButtonLoading(true);
    const res = await dispatch(addToQueueEquipment(queueEquipment));
    if (res.payload.status === 200) {
      handleFilter();
      dispatch(
        openSnackbar({
          message: Constants.EQUIPMENT_QUEUE_SUCCESS_MESSAGE,
          notificationType: Constants.NOTIFICATION_SUCCESS,
        })
      );
    } else {
      dispatch(
        openSnackbar({
          message: Constants.SOMETHING_WENT_WRONG,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
    }
    setButtonLoading(false);
  };

  const { projectColumns, projectrows } = projectLayerData(
    list,
    status,
    openProjectConfirmationBox,
    addToQueue,
    permission,
    buttonLoading
  );

  const { equipmentTypeColumns, equipmentTypeRows } = equipmentLayerData(
    currentProjectLayerId,
    status,
    openConfirmationBox,
    addToQueueEquipmnt,
    list,
    openApproverCommentModal,
    currentProjectLayerId.projectId,
    currentEquipmentTypeLayerId.equipmentId,
    permission,
    buttonLoading
  );

  const { equipmentColumns, equipmentRows } = userLayerData(
    currentEquipmentTypeLayerId,
    status,
    openUserConfirmationBox,
    openEditQuantity,
    list,
    currentProjectLayerId.projectId,
    currentEquipmentTypeLayerId.equipmentId,
    openEngineerCommentModal,
    permission
  );

  useEffect(() => {
    (async () => {
      handleFilter();
    })();
  }, [status]);

  const handleDeleteEquipment = async () => {
    const res = await dispatch(equipmentDeleteThunk(equipmentId));
    if (res.payload.status === 200) {
      await dispatch(removeEquipment(equipmentId));
      await dispatch(
        openSnackbar({
          message: Constants.EQUIPMENT_DELETE_SUCCESS,
          notificationType: Constants.NOTIFICATION_SUCCESS,
        })
      );
    } else if (res.payload.status === 400) {
      await dispatch(
        openSnackbar({
          message: Constants.EQUIPMENT_DELETE_ERROR,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
    }
    setEquipmentId("");
    setDeleteModalOpen(false);
  };

  const handleTablePagination = async () => {
    const paramData = {
      page: next + 1,
      perPage: tablePagination.perPage,
    };
    const data = new URLSearchParams(paramData);
    const res = await dispatch(AllEquipmentRequestListThunk(data));
    if (res.payload.status === 200) setNext(res.payload.data.length > 0 ? next + 1 : next);
  };
  return (
    <Feature name={FeatureTags.WAREHOUSE}>
      <MDBox mt={3} mb={3}>
        <DataTable
          table={{ columns: projectColumns, rows: projectrows }}
          isSorted={false}
          entriesPerPage={{ defaultValue: defaultData.PER_PAGE }}
          showTotalEntries={false}
          noEndBorder
          loadLayerId={loadProjectLayerData}
          loading={loading}
          currentPage={tablePagination.page}
          handleTablePagination={handleTablePagination}
          handleCurrentPage={(page) => setTablePagination({ ...tablePagination, page })}
          childTable={[
            {
              columns: equipmentTypeColumns,
              rows: equipmentTypeRows,
              backgroundColor: Colors.LIGHT_GRAY,
              textColor: Colors.PRIMARY,
              isSorted: false,
              loadLayerId: loadEquipmentTypeLayerData,
              entriesPerPage: { defaultValue: defaultData.PER_PAGE },
              showTotalEntries: false,
              noEndBorder: true,
              loading: Constants.FULFILLED,
            },
            {
              columns: equipmentColumns,
              rows: equipmentRows,
              backgroundColor: Colors.LIGHT_GRAY,
              textColor: Colors.PRIMARY,
              isSorted: false,
              entriesPerPage: { defaultValue: defaultData.PER_PAGE },
              showTotalEntries: false,
              noEndBorder: true,
              loading: Constants.FULFILLED,
            },
          ]}
        />
      </MDBox>

      {/* Delete Modal for Report type */}
      <DeleteModal
        open={deleteModalOpen}
        title={ModalContent.DELETE_EQUIPMENT_TITLE}
        message={ModalContent.DELETE_EQUIPMENT_MESSAGE}
        handleClose={handleCloseDeleteModal}
        handleDelete={handleDeleteEquipment}
      />

      <BasicModal
        title={Constants.ORDER_PROJECT_TITLE}
        open={openProjectApprove}
        handleClose={handleProjectApproveClose}
        handleAction={() => handlePendingAction("OrderProject")}
        disabled={buttonLoading}
        actionButton={buttonLoading === false ? ButtonTitles.ORDER : ButtonTitles.LOADING}
        py={0}
      >
        <ReactDatePicker
          {...(checkPriceType
            ? {
                selectsRange: true,
                startDate: selectedDate.startDate,
                endDate: selectedDate.endDate,
              }
            : {
                selected: selectedDate.startDate,
              })}
          onChange={handleChange}
          customInput={
            <DateTime
              item={{ hint: checkPriceType ? "From Date - To Date" : "From Date" }}
              label={checkPriceType ? "Request Period (for rented items)*" : "Select from date*"}
              onClear={clearDate}
              errors={selectedDate.errorMessage}
              helperText={selectedDate.errorMessage}
            />
          }
          placeholderText="mm/dd/yyyy"
          dateFormat={defaultData.REACTDATETIMEPICKER_DATE_FORMAT}
          errors={Boolean(selectedDate.errorMessage)}
          helperText={selectedDate.errorMessage}
        />
        <FormTextField
          type="textarea"
          marginTop={3}
          marginBottom={3}
          title="Remark"
          value={orderRemark}
          name="remark"
          label="Remark"
          placeholder="Add remark here"
          handleChange={(e) => setOrderRemark(e.target.value)}
        />
      </BasicModal>

      <ConfirmationModal
        title={Constants.REJECT_PROJECT_TITLE}
        message={Constants.REJECT_PROJECT_MESSAGE}
        open={openProjectReject}
        handleClose={handleProjectRejectClose}
        handleAction={() => handlePendingAction("Project")}
        positiveButton={ButtonTitles.REJECT}
        width={pxToRem(85)}
      >
        <FormTextField
          type="textarea"
          marginTop={3}
          title="Reason"
          value={projectPermission.reason}
          name="remark"
          label="Reason"
          placeholder="Add reason here"
          handleChange={(e) =>
            setProjectPermission({ ...projectPermission, reason: e.target.value })
          }
        />
      </ConfirmationModal>

      <ConfirmationModal
        title={Constants.APPROVE_EQUIPMENT_TITLE}
        message={Constants.APPROVE_EQUIPMENT_MESSAGE}
        open={openEquipmentApprove}
        handleClose={handleApproveClose}
        handleAction={() => handlePendingAction("Equipment")}
        positiveButton={ButtonTitles.ADD_TO_QUEUE}
        width={pxToRem(85)}
      />

      <ConfirmationModal
        title={Constants.REJECT_EQUIPMENT_TITLE}
        message={Constants.REJECT_EQUIPMENT_MESSAGE}
        open={openEquipmentReject}
        handleClose={handleRejectClose}
        handleAction={() => handlePendingAction("Equipment")}
        positiveButton={ButtonTitles.REJECT}
        width={pxToRem(85)}
      >
        <FormTextField
          type="textarea"
          marginTop={3}
          title="Reason"
          value={equipPermission.reason}
          name="remark"
          label="Reason"
          placeholder="Add reason here"
          handleChange={(e) => setEquipPermission({ ...equipPermission, reason: e.target.value })}
        />
      </ConfirmationModal>

      <ConfirmationModal
        title={Constants.APPROVE_USER_TITLE}
        message={Constants.APPROVE_USER_MESSAGE}
        open={openUserApprove}
        handleClose={handleUserApproveClose}
        handleAction={() => handlePendingAction("User")}
        positiveButton={ButtonTitles.ADD_TO_QUEUE}
        width={pxToRem(85)}
      />

      <ConfirmationModal
        title={Constants.REJECT_USER_TITLE}
        message={Constants.REJECT_USER_MESSAGE}
        open={openUserReject}
        handleClose={handleUserRejectClose}
        handleAction={() => handlePendingAction("User")}
        positiveButton={ButtonTitles.REJECT}
        width={pxToRem(85)}
      >
        <FormTextField
          type="textarea"
          marginTop={3}
          title="Reason"
          value={userPermission.reason}
          name="remark"
          label="Reason"
          placeholder="Add reason here"
          handleChange={(e) => setUserPermission({ ...userPermission, reason: e.target.value })}
        />
      </ConfirmationModal>

      {/* Edit Equipment Quantity */}
      <EditEquipmentQuantity
        minWidth={600}
        open={openEditEquipmentQuantity}
        handleClose={handleCloseEditEquipmentQuantity}
        editEquipmentData={editEquipmentData}
        handleAction={handleEditEquipmentQuantity}
      />

      {/* Check in check out Comments for Approver */}
      {approverComments && (
        <CheckInCheckOutComments
          open={approverComments}
          type={approverCommentsData.type}
          intialComments={approverCommentsData.comments}
          equipStatus={approverCommentsData.equipStatus}
          handleClose={handleApproverCommentsClose}
          handleAction={handleAddApproverComments}
          buttonLoading={buttonLoading}
          minWidth={600}
        />
      )}

      {/* Check in check out Comments for User */}
      {userComments && (
        <CheckInCheckOutComments
          open={userComments}
          type={userCommentsData.type}
          intialComments={userCommentsData.comments}
          handleClose={handleUserCommentsClose}
          handleAction={handleUserCommentsClose}
          minWidth={600}
        />
      )}
    </Feature>
  );
}

EquipmentRequest.propTypes = {
  status: PropTypes.string.isRequired,
};
