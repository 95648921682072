import React, { useEffect, useState } from "react";

// Common Components
import pxToRem from "assets/theme/functions/pxToRem";
import Author from "components/Table/Author";
import Status from "components/Table/Status";

// Material Common Components
import MDBox from "components/MDBox";

// Utils
import Constants from "utils/Constants";

export default function ApproverOrderEquipmentDetailsData(approverEquipmentList) {
  const [approverEquipmentrows, setApproverEquipmentRows] = useState([]);

  useEffect(() => {
    if (approverEquipmentList) {
      const tempRows = approverEquipmentList?.approverEquipmentdata?.map((element, index) => {
        const temp = {
          srNo: <Author name={index + 1} />,
          equipmentName: (
            <MDBox display="flex" justifyContent="center" alignItems="center">
              <img
                src={
                  element?.equipment?.equipmentImage?.length > 0
                    ? element?.equipment?.equipmentImage?.[0]?.url
                    : process.env.REACT_APP_IMAGE_NOT_FOUND
                }
                alt={element?.equipment?.name}
                style={{
                  width: pxToRem(50),
                  height: pxToRem(50),
                  marginRight: pxToRem(10),
                  borderRadius: "8px",
                }}
              />
              <Author name={element?.equipment?.name} />
            </MDBox>
          ),
          serialNumber: (
            <Author
              name={
                element?.equipment?.serialNumber ? element?.equipment?.serialNumber : Constants.NA
              }
            />
          ),
          status: <Status title={`${element?.status.replace("-", " ")}`} />,
          dispatchedQuantity: <Author name={element?.wmDispatchQuantity} />,
        };
        return temp;
      });
      setApproverEquipmentRows(tempRows);
    }
  }, [approverEquipmentList]);

  return {
    approverEquipmentColumns: [
      { Header: "No.", accessor: "srNo", width: "5%" },
      { Header: "Equipment Name", accessor: "equipmentName" },
      { Header: "Serial Number", accessor: "serialNumber" },
      { Header: "Status", accessor: "status", align: "center" },
      { Header: "Linked Quantity", accessor: "dispatchedQuantity", align: "center" },
    ],

    approverEquipmentrows,
  };
}
