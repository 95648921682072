import React, { useEffect, useState } from "react";

// Common Components
import ReportCardLayout from "layouts/report/reportCardLayout";

// Material Components
import DynamicTypeData from "components/Table/DynamicTypeData";
import pxToRem from "assets/theme/functions/pxToRem";
import MDTypography from "components/MDTypography";
import { Card, Divider } from "@mui/material";
import MDBox from "components/MDBox";

// Routing
import { useLocation } from "react-router-dom";

// Utils
import Constants from "utils/Constants";
import { getReportById } from "redux/Thunks/Report";
import { useDispatch } from "react-redux";

function ReportAnswerDetails() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [reportAnswer, setReportAnswer] = useState({
    question: "",
    questionNo: "",
    answer: [],
    supportedContent: [],
  });

  useEffect(() => {
    setReportAnswer({
      question: location?.state?.reportAnswerDetails?.title,
      questionNo: location?.state?.questionNo,
      answer: location?.state?.reportAnswerDetails?.answerTypes,
      supportedContent: location?.state?.reportAnswerDetails?.supportedContent,
    });
    (async () => {
      const body = {
        userReports: location?.state?.reportId,
        // userReports: reportId,
      };
      await dispatch(getReportById(body));
    })();
  }, []);

  return (
    <ReportCardLayout>
      <MDBox mt={4} sx={{ paddingBottom: pxToRem(20) }}>
        <Card
          sx={{
            padding: pxToRem(55),
            height: "100%",
            minHeight: pxToRem(773),
          }}
        >
          <MDBox>
            <MDTypography
              sx={{
                fontWeight: 600,
                fontSize: pxToRem(24),
                lineHeight: pxToRem(16),
                height: pxToRem(24),
                color: "#475467",
              }}
            >
              Question
            </MDTypography>
            <MDBox
              sx={{
                paddingY: pxToRem(14),
              }}
            >
              <MDTypography
                sx={{
                  fontWeight: 600,
                  color: "#191A51",
                  height: pxToRem(24),
                  textTransform: "capitalize",
                }}
              >
                {`${reportAnswer.questionNo}. ${reportAnswer.question}`}
              </MDTypography>
            </MDBox>
          </MDBox>
          {reportAnswer?.supportedContent?.length > 0 && (
            <MDBox
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              flexWrap="wrap"
              gap={2}
            >
              {reportAnswer?.supportedContent?.map((supportData) => (
                <MDBox display="flex" flexDirection="column" alignItems="flex-start" mt={5}>
                  <img
                    src={supportData?.path}
                    alt={supportData?.subText}
                    style={{
                      width: pxToRem(104),
                      height: pxToRem(86),
                      marginRight: pxToRem(10),
                      borderRadius: "8px",
                    }}
                  />
                  <MDTypography
                    sx={{
                      fontSize: pxToRem(12),
                      fontWeight: "medium",
                      lineHeight: pxToRem(16),
                      marginTop: pxToRem(10),
                      color: "#B3B9C3",
                      width: pxToRem(124),
                    }}
                  >
                    {supportData?.subText}
                  </MDTypography>
                </MDBox>
              ))}
            </MDBox>
          )}

          <MDBox
            sx={{
              marginTop: pxToRem(40),
            }}
          >
            <MDTypography
              sx={{
                fontWeight: 600,
                fontSize: pxToRem(24),
                lineHeight: pxToRem(36),
                height: pxToRem(24),
                color: "#475467",
              }}
            >
              Answers
            </MDTypography>
            <MDBox
              sx={{
                paddingX: pxToRem(24),
                paddingY: pxToRem(16),
              }}
            >
              {(() => {
                // Check if there are any answers
                const hasAnswers = reportAnswer.answer?.some(
                  (element) => element.userAnswers?.length > 0
                );

                if (!hasAnswers) {
                  return (
                    <MDTypography variant="h5" color="secondary">
                      No answers found
                    </MDTypography>
                  );
                }

                return reportAnswer.answer.flatMap((element, typeIdx) =>
                  element.userAnswers?.length > 0
                    ? element.userAnswers.flatMap((item2) => {
                        const usersMap = new Map(); // Map to keep track of users

                        return item2.answers.map((answer, ansIdx) => {
                          const correspondingTitle = element.title.find(
                            (title) => title[Constants.MONGOOSE_ID] === answer.answerTitleId
                          );

                          // Check if the user has been encountered before
                          const userKey = `${
                            item2.createdBy[0].callingName
                              ? item2.createdBy[0].callingName
                              : item2.createdBy[0].firstName
                          } ${item2.createdBy[0].lastName}`;
                          const isFirstOccurrence = !usersMap.has(userKey);

                          // If it's the first occurrence, add the user to the map
                          if (isFirstOccurrence) {
                            usersMap.set(userKey, true);
                          }

                          return (
                            <MDBox key={answer.answerTitleId}>
                              {/* Render user's name only if it's the first occurrence */}
                              {isFirstOccurrence && (
                                <MDTypography
                                  sx={{
                                    fontWeight: 600,
                                    fontSize: pxToRem(16),
                                    lineHeight: pxToRem(36),
                                    height: pxToRem(24),
                                    color: "#475467",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  Answer by{" "}
                                  {`${
                                    item2.createdBy[0].callingName
                                      ? item2.createdBy[0].callingName
                                      : item2.createdBy[0].firstName
                                  } ${item2.createdBy[0].lastName}`}
                                </MDTypography>
                              )}
                              <>
                                <MDTypography
                                  sx={{
                                    marginTop: pxToRem(24),
                                    fontWeight: 500,
                                    fontSize: pxToRem(16),
                                    color: "#475467",
                                  }}
                                >
                                  {`${reportAnswer.questionNo}.${typeIdx + 1}.${ansIdx + 1}. ${
                                    correspondingTitle?.value
                                  }`}
                                </MDTypography>
                                <DynamicTypeData
                                  type={element?.parameterType?.uniqueKey}
                                  data={answer.answer}
                                />
                                <Divider />
                              </>
                            </MDBox>
                          );
                        });
                      })
                    : null
                );
              })()}
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
    </ReportCardLayout>
  );
}

export default ReportAnswerDetails;
