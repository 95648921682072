import React, { useEffect, useState } from "react";

// MUI components
import { Button, Grid, Icon, Modal } from "@mui/material";

// Custom components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Styles
import style from "assets/style/Modal";

// Functions
import pxToRem from "assets/theme/functions/pxToRem";

// Constants
import { Icons, ButtonTitles } from "utils/Constants";

// 3rd party library
import PropTypes from "prop-types";

function DeleteModal({
  open,
  title,
  message,
  handleClose,
  handleDelete,
  children,
  confirmInput,
  confirmText,
  actionButton,
}) {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, [open]);
  const handleDeleteAction = () => {
    setLoading(true);
    handleDelete();
  };
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <MDBox sx={style}>
        <MDBox
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          borderRadius="lg"
          sx={{
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
            height: pxToRem(72),
            padding: "16px 16px 8px 16px",
            marginBottom: "8px",
          }}
        >
          <MDTypography sx={{ fontSize: pxToRem(20), color: "#191A51", fontWeight: "700" }}>
            {title}
          </MDTypography>
          <Icon sx={{ cursor: "pointer", color: "beige" }} fontSize="medium" onClick={handleClose}>
            {Icons.CROSS}
          </Icon>
        </MDBox>
        <MDBox
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          sx={{ maxHeight: 500, paddingX: "16px" }}
        >
          <MDTypography fontSize="16px" lineHeight="20px" sx={{ color: "#475467" }}>
            {message}
          </MDTypography>
        </MDBox>
        <MDBox
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          sx={{ paddingX: "16px" }}
        >
          {children}
        </MDBox>

        <MDBox px={3} mt={2} mb={3}>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <Button
              variant="outlined"
              disableRipple
              sx={{
                backgroundColor: "#fff",

                marginRight: pxToRem(14),
                borderRadius: pxToRem(8),
                height: pxToRem(40),
                width: "51px",

                border: "1px solid #d0d5dd",
              }}
              onClick={handleClose}
            >
              <MDTypography
                fontSize={pxToRem(14)}
                sx={{ fontWeight: 500, fontSize: pxToRem(16), lineHeight: pxToRem(20) }}
              >
                {ButtonTitles.NEGATIVE_BUTTON}
              </MDTypography>
            </Button>

            <Button
              variant="contained"
              style={{ boxShadow: "none", textTransform: "none" }}
              disableRipple
              disabled={loading || confirmInput !== confirmText}
              sx={{
                backgroundColor: "#191a51",
                borderRadius: pxToRem(8),
                height: pxToRem(40),
                width: loading ? "100px" : "57px",
                border: "1px solid #d0d5dd",
                boxShadow: "none",
              }}
              onClick={handleDeleteAction}
            >
              {actionButton ? (
                <MDTypography
                  fontSize={pxToRem(14)}
                  sx={{
                    color: "#fff",
                    fontWeight: 500,
                    fontSize: pxToRem(14),
                    lineHeight: pxToRem(20),
                  }}
                >
                  {loading ? ButtonTitles.LOADING : ButtonTitles.POSITIVE_BUTTON}
                </MDTypography>
              ) : (
                <MDTypography
                  fontSize={pxToRem(14)}
                  sx={{
                    color: "#fff",
                    fontWeight: 500,
                    fontSize: pxToRem(14),
                    lineHeight: pxToRem(20),
                  }}
                >
                  {loading ? ButtonTitles.DELETING : ButtonTitles.POSITIVE_BUTTON}
                </MDTypography>
              )}
            </Button>
          </Grid>
        </MDBox>
      </MDBox>
    </Modal>
  );
}

DeleteModal.defaultProps = {
  children: null,
  confirmInput: "",
  confirmText: "",
  actionButton: false,
};

DeleteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  children: PropTypes.node,
  confirmText: PropTypes.string,
  confirmInput: PropTypes.string,
  actionButton: PropTypes.bool,
};

export default DeleteModal;
