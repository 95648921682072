//  Messages or Hard Coded Data
const TextMessage = {
  MONGOOSE_ID: "_id",
  COMMING_SOON: "Comming Soon...",
  ERROR_TEXT: "Error Occured.Try again",
  EMAIL_FIELD_REQUIRED: "Enter the email",
  PASSWORD_FIELD_REQUIRED: "Enter the password",
  EMAIL_NOT_VALID: "Enter Valid Email Address",
  LATITUDE_NOT_VALID: "Latitude must be a number between -90 and 90.",
  LONGITUDE_NOT_VALID: "Longitude must be a number between -180 and 180.",
  EMAIL_EXIST: "The email already exists. Please provide a different email address.",
  Company_EXIST: "The company already exists. Please choose a different company name.",
  Card_EXIST: "The card already exists. Please select a different card name.",
  VALUE_TOO_SHORT: "Given value is too short",
  VALUE_TOO_LONG: "Given value is too long",
  INVALID_CREDENTIAL: "Invalid credential, please try again.",
  FORGET_PASSWORD_MESSAGE:
    "Confirm your registered email and we will send reset link and the instructions.",
  FORGET_PASSWORD_EMAIL_SUCCESS: "Reset Password link has been sent. Please check your mail.",
  FORGET_PASSWORD_EMAIL_Failure: "User is not registered.",
  PASSWORD_NOT_MATCHED: "Confirm passwords do not match",
  PASSWORD_RESET_SUCCESSFULLY: "Password reset successfully.",
  PASSWORD_LINK_EXPIRED: "Reset password link is expired.",
  INVALID_URL: "Please enter valid URL.",
  INVALID_SPACE: "Empty Space is not allowed",
  PASSWORD_LENGTH_VALIDATION: "Password must be between 8-16 character",
  PASSWORD_UPPERCASE_VALIDATION: "Password must contain at least one uppercase letter",
  PASSWORD_LOWERCASE_VALIDATION: "Password must contain at least one lowercase letter",
  PASSWORD_SYMBOL_VALIDATION: "Password must contain at least one symbol",
  PASSWORD_DIGIT_VALIDATION: "Password must contain at least one digit",
  STATUS_UPDATE_SUCCESS: "Status updated successfully.",
  MEMBER_ADD_SUCCESS: "Member added successfully.",
  MEMBER_UPDATE_SUCCESS: "Member updated successfully.",
  MEMBER_DELETE_SUCCESS: "Member deleted successfully.",
  PHONE_LENGTH_VALIDATION: "Phone number must be of atleast 10 numbers",
  PHONE_VALIDATION: "Invalid phone number",
  SHIFT_CREATE_SUCCESS: "Shift created successfully.",
  SHIFT_UPDATE_SUCCESS: "Shift updated successfully.",
  SHIFT_DELETE_SUCCESS: "Shift deleted successfully.",
  INVALID_SHIFT: "Shift does not exist.",
  CATEGORY_CREATE_SUCCESSFULLY: "Category created succesfully.",
  CATEGORY_UPDATE_SUCCESSFULLY: "Category updated succesfully.",
  CATEGORY_DELETE_SUCCESSFULLY: "Category deleted succesfully.",
  PROFILE_UPDATED_SUCCESS: "Profile updated Successfully",
  ACTIVITY_ADD_SUCCESS: "Activity Added Successfully.",
  ACTIVITY_UPDATE_SUCCESS: "Activity Updated Successfully.",
  ACTIVITY_DELETE_SUCCESS: "Activity Deleted Successfully.",
  NO_DATA_FOUND: "No data found.",
  REDIRECTING_TO_PARENT: "No Data Found, Redirecting to Parent Page",
  SOMETHING_WENT_WRONG: "Something went wrong.",
  REQUIRED: "Required.",
  INVALID_VALUEL: "Value cannot start with a number.",
  INVALID_RANGE: "Enter valid range.",
  ACTIVITY_WARNING: "Please create a Scope before creating an Activity.",
  FUNCTION_WARNING: "Please create a function before linking it with certificate type.",
  PROJECT_STRING_WARNING: "Please create a Location before creating a Project string.",
  ASSET_WARNING: "Please create a Project string and location before creating an Asset.",
  MEMBER_WARNING: "Please register a user before creating an MEMBER.",
  SLIDER_RANGE_VALUE_VALIDATION: "Please add max greater than min",
  SLIDER_NEGATIVE_VALUE_VALIDATION: "Value cannot be less than 0.",
  INVALID_VALUE: "Invalid value",

  CERTIFICATE_UPDATE: "Certificate status updated successfully",
  LICENSE_PROVISION_SUCCESS: "License provisioned successfully",
  LICENSE_REMOVED_SUCCESS: "License removed successfully",
  PROFILE_IMAGE_UPDATE: "Profile image updated successfully",
  FEEDBACK_DELETE_SUCCESS: "Feedback deleted successfully",
  FEEDBACK_DELETE_ERROR: "Feedback cannot be deleted",
  SYNC_UP_TIME_SUCCESS: "Sync up time updated successfully",
  SYNC_UP_TIME_ERROR: "Sync up time cannot be updated",
  SAFETY_CARD_CREATE_SUCCESS: "Safety card created successfully",
  SAFETY_CARD_UPDATE_SUCCESS: "Safety card updated successfully",
  SAFETY_CARD_DELETE_SUCCESS: "Safety card deleted successfully",
  SAFETY_CARD_DELETE_ERROR: "Safety card cannot be deleted",
  MEDICAL_QUESTIONS_CREATE_SUCCESS: "Medical Question created successfully.",
  CERTIFICATE_CREATE_SUCCESS: "Certificate created successfully.",
  MEDICAL_QUESTIONS_CREATE_ERROR: "Medical Question already exist.",
  CERTIFICATE_CREATE_ERROR: "Certificate already exist.",
  MEDICAL_QUESTIONS_UPDATE_SUCCESS: "Medical Question updated successfully.",
  CERTIFICATE_UPDATE_SUCCESS: "Certificate updated successfully.",
  MEDICAL_QUESTIONS_UPDATE_ERROR: "Medical Question already exist.",
  CERTIFICATE_UPDATE_ERROR: "Certificate already exist.",
  MEDICAL_QUESTIONS_DELETE_SUCCESS: "Medical Question deleted successfully.",
  CERTIFICATE_DELETE_SUCCESS: "Certificate deleted successfully.",
  MEDICAL_QUESTIONS_DELETE_ERROR: "Medical Question cannot be deleted.",
  CERTIFICATE_DELETE_ERROR: "Certificate cannot be deleted.",
  MEDICAL_QUESTIONS_CONFIRMATION_SUCCESS: "Medical Question published successfully.",
  CERTIFICATE_CONFIRMATION_SUCCESS: "Certificate validity date updated successfully.",
  ROLE_CREATED_SUCCESSFULLY: "Role created successfully",
  ROLE_UPDATED_SUCCESSFULLY: "Role updated successfully",
  ROLE_DEACTIVATED_SUCCESSFULLY: "Role deactivated successfully",
  ROLE_ASSIGNED_SUCCESSFULLY: "Role assigned successfully",
  USER_LOGOUT_TITTLE: "Logout",
  SWITCH_ACCOUNT_TITTLE: "Switch Account",
  LOGOUT_MESSAGE: "Are you sure you want to logout?",
  SWITCH_SUPERADMIN_ACCOUNT_TO_ADMIN_MESSAGE:
    "Are you sure you want to switch to super admin account?",

  // Authentication Validations
  PASSWORD_REQUIRED: "Password is required",

  // Warehouse
  WAREHOUSE_CREATE_SUCCESS: "Warehouse created successfully.",
  WAREHOUSE_UPDATE_SUCCESS: "Warehouse updated successfully.",
  WAREHOUSE_DELETE_SUCCESS: "Warehouse deleted successfully.",
  WAREHOUSE_DELETE_ERROR: "Warehouse cannot be deleted.",
  WAREHOUSE_ALREADY_EXIST: "Warehouse already exist.",
  WAREHOUSE_NOT_EXIST: "Warehouse does not exist.",
  FILE_TYPE_NOT_ALLOWED: "File type not allowed.",

  // Setup Equipment
  EQUIPMENT_TYPE_CREATE_SUCCESS: "Equipment type created successfully.",
  EQUIPMENT_TYPE_UPDATE_SUCCESS: "Equipment type updated successfully.",
  EQUIPMENT_TYPE_DELETE_SUCCESS: "Equipment type deleted successfully.",
  EQUIPMENT_TYPE_LINKED_TO_DELETE:
    "Unable to delete the equipment type. Please delete all associated equipment before proceeding.",

  EQUIPMENT_CATEGORY_CREATE_SUCCESS: "Equipment category created successfully.",
  EQUIPMENT_CATEGORY_UPDATE_SUCCESS: "Equipment category updated successfully.",
  EQUIPMENT_CATEGORY_DELETE_SUCCESS: "Equipment category deleted successfully.",

  CURRENCY_UNIT_CREATE_SUCCESS: "Currency unit created successfully.",
  CURRENCY_UNIT_UPDATE_SUCCESS: "Currency unit updated successfully.",
  CURRENCY_UNIT_DELETE_SUCCESS: "Currency unit deleted successfully.",

  WEIGHT_FORM_CREATE_SUCCESS: "Weight form created successfully.",
  WEIGHT_FORM_UPDATE_SUCCESS: "Weight form updated successfully.",
  WEIGHT_FORM_DELETE_SUCCESS: "Weight form deleted successfully.",

  QUANTITY_TYPE_CREATE_SUCCESS: "Quantity type created successfully.",
  QUANTITY_TYPE_UPDATE_SUCCESS: "Quantity type updated successfully.",
  QUANTITY_TYPE_DELETE_SUCCESS: "Quantity type deleted successfully.",

  CERTIFICATE_TYPE_CREATE_SUCCESS: "Certificate type created successfully.",
  CERTIFICATE_TYPE_UPDATE_SUCCESS: "Certificate type updated successfully.",
  CERTIFICATE_TYPE_DELETE_SUCCESS: "Certificate type deleted successfully.",

  HSCODE_CREATE_SUCCESS: "HS Code created successfully.",
  HSCODE_UPDATE_SUCCESS: "HS Code updated successfully.",
  HSCODE_DELETE_SUCCESS: "HS Code deleted successfully.",

  // Certificate Management
  CERTIFICATE_REMOVED_SUCCESS: "Certificate Removed successfully.",
  CERTIFICATE_NOT_EXIST_ERROR: "User Certificate is not exist.",

  // Equipment
  EQUIPMENT_CREATE_SUCCESS: "Equipment created successfully.",
  EQUIPMENT_UPDATE_SUCCESS: "Equipment updated successfully.",
  EQUIPMENT_DELETE_SUCCESS: "Equipment deleted successfully.",
  EQUIPMENT_DELETE_ERROR: "Equipment cannot be deleted.",
  INVALID_WEIGHT: "Weight should be greater than 0",
  TOTAL_VALUE_REQUIRED: "Total value is required",
  SERIAL_NUMBER_REQUIRED: "Serial number is required",
  INVALID_TOTAL_VALUE: "Total value should be greater than 0",
  FILL_ALL_REQUIRED_FIELDS: "Please fill in all required fields before proceeding.",
  CERTIFICATE_REQUIRED: "Please Upload Certificate",
  START_DATE_REQUIRED: "Start date is required",
  END_DATE_REQUIRED: "End date is required",

  // Equipment Request
  ORDER_PROJECT_TITLE: "Order all equipments for this project",
  ORDER_PROJECT_MESSAGE: "Are you sure you want to order all equipments?",
  REJECT_PROJECT_TITLE: "Reject all equipment for this project",
  REJECT_PROJECT_MESSAGE: "Are you sure you want to reject all equipment?",

  // Certificate Approve Request
  CERTIFICATE_APPROVAL_TITLE: "Approve Certificate",
  CERTIFICATE_APPROVAL_MESSAGE: "Are you sure you want to approve this certificate?",
  REJECT_CERTIFICATE_TITLE: "Reject Certificate",
  REJECT_CERTIFICATE_MESSAGE: "Are you sure you want to reject  this certificate?",

  APPROVE_EQUIPMENT_TITLE: "Approve all users request fot this equipment",
  APPROVE_EQUIPMENT_MESSAGE: "Are you sure you want to approve this equipment?",
  REJECT_EQUIPMENT_TITLE: "Reject Equipment",
  REJECT_EQUIPMENT_MESSAGE: "Are you sure you want to reject this equipment?",

  APPROVE_USER_TITLE: "Approve equipment for this user",
  APPROVE_USER_MESSAGE: "Are you sure you want to approve equipment for this user?",
  REJECT_USER_TITLE: "Reject equipment for this user",
  REJECT_USER_MESSAGE: "Are you sure you want to reject this user?",

  PROJECT_APPROVE_SUCCESS_MESSAGE:
    "Request approved successfully of all equipments for this project",
  PROJECT_REJECT_SUCCESS_MESSAGE:
    "Request rejected successfully of all equipments for this project",
  EQUIPMENT_APPROVE_SUCCESS_MESSAGE:
    "Request approved successfully of all users request fot this equipment",
  EQUIPMENT_REJECT_SUCCESS_MESSAGE:
    "Request rejected successfully of all users request fot this equipment",
  USER_APPROVE_SUCCESS_MESSAGE: "Request approved successfully of equipment for this user",
  USER_REJECT_SUCCESS_MESSAGE: "Request rejected successfully of equipment for this user",
  PROJECT_QUEUE_SUCCESS_MESSAGE: "All equipments for this project added to queue successfully",
  EQUIPMENT_QUEUE_SUCCESS_MESSAGE: "Equipment added to queue successfully",
  ORDER_PLACED_SUCCESS_MESSAGE: "Order placed successfully",
  EQUIPMENT_ASSIGN_SUCCESS_MESSAGE: "Equipment assigned successfully",
  DISTRIBUTE_EQUIPMENT_QUANTITY_ERROR:
    "Assigned quantity should not be less than or greater than approved quantity",
  // Redux constants
  NOTIFICATION_SUCCESS: "success",
  NOTIFICATION_ERROR: "error",
  PENDING: "pending",
  FULFILLED: "fullfilled",
  FULFILLED2: "fulfilled",
  DEFAULT: "default",
  REJECTED: "rejected",
  IDLE: "idle",
  NO_DATA: "no_data",

  // Project Certificate
  CERTIFICATE_ADDED_SUCCESS: "Certificate Added successfully.",

  // Image File Type Error
  NO_FILE_CHOOSEN: "No files choosen",
  EQUIPMENT_FILE_TYPE_NOT_ALLOWED: "Only JPG, JPEG,PNG and PDF formats are allowed.",
  IMAGE_FILE_TYPE_NOT_ALLOWED: "Only JPG, JPEG,PNG and SVG formats are allowed.",
  PDF_FILE_ALLOWED: "Only PDF formats are allowed.",

  // Status Constants
  OPEN: "open",
  SUBMITTED: "submitted",
  APPROVED: "approved",
  CLOSED: "closed",
  CANCELLED: "cancelled",
  STATUS_REJECTED: "rejected",
  STATUS_PENDING: "pending",
  REQUESTED: "requested",
  IN_TRANSIT: "in transit",
  PARTIAL_CHECK_IN: "partially check-in",
  CHECK_IN: "check in",
  CHECK_OUT: "check out",
  IN_DISCUSSION: "in discussion",
  IN_ACTIVE: "in active",
  IN_STOCK: "in stock",
  PARTIAL_PRE_TRANSIT: "partially pre-transit",
  PRE_TRANSIT: "pre transit",
  LINKED: "linked",
  CERTIFICATE_APPROVAL_SUCCESS_MESSAGE: "Certificate approved successfully",
  CERTIFICATE_REJECT_SUCCESS_MESSAGE: "Certificate rejected successfully",
  PRE_RETURN: "pre return",
  RETURN: "return",
  ON_SITE: "on site",
  PARTIALLY_IN_TRANSIT: "partially in-transit",
  PARTIALLY_RETURNED: "partially returned",
  QUEUE: "queue",
  SUBMITTED_CLIENT: "submitted(client)",
  ORDER_REQUEST: "Order Request",
  SHOPPING_CART: "Shopping Cart",
  PACKED_ORDERS: "Packed Orders",
  SHIPPED_ORDERS: "Shipped Order",
  REQUEST: "Request",
  CHECKED: "checked",

  // Forms Constants
  NA: "N/A",

  // Profile Functions
  PROFILE_FUNCTIONS_CREATE_SUCCESS: "Profile function created successfully.",
  PROFILE_FUNCTIONS_UPDATE_SUCCESS: "Profile functions updated successfully.",
  PROFILE_FUNCTIONS_DELETE_SUCCESS: "Profile function deleted successfully.",
  PROFILE_FUNCTIONS_STATUS_SUCCESS: "Profile function status updated successfully.",

  // Setup Project Create & Update Success Messages
  PROJECT_CREATE_SUCCESS: "Project created successfully.",
  PROJECT_UPDATE_SUCCESS: "Project updated successfully.",
  LOCATION_CREATE_SUCCESS: "Location created successfully.",
  LOCATION_UPDATE_SUCCESS: "Location updated successfully.",
  PROJECTSTRING_CREATE_SUCCESS: "Project string created successfully.",
  PROJECTSTRING_UPDATE_SUCCESS: "Project string updated successfully.",
  ASSET_CREATE_SUCCESS: "Asset created successfully.",
  ASSET_UPDATE_SUCCESS: "Asset updated successfully.",
  TEAM_CREATE_SUCCESS: "Team created successfully.",
  TEAM_UPDATE_SUCCESS: "Team updated successfully.",
  SCOPE_CREATE_SUCCESS: "Scope created successfully.",
  SCOPE_UPDATE_SUCCESS: "Scope updated successfully.",
  ACTIVITY_CREATE_SUCCESS: "Activity created successfully.",
  ACTIVITY_UPDATE_SUCCESS2: "Activity updated successfully.",
  FUNCTION_CREATE_SUCCESS: "Function created successfully.",
  FUNCTION_UPDATE_SUCCESS: "Function updated successfully.",
  PROJECT_DOCUMENT_CREATE_SUCCESS: "Project document created successfully.",
  PROJECT_DOCUMENT_UPDATE_SUCCESS: "Project document updated successfully.",
  PROJECT_DOCUMENT_DELETE_SUCCESS: "Project document deleted successfully.",
  // Setup Project Duplicate Error Messages
  PROJECT_DUPLICATE_ERROR: "Project name already exists.",
  LOCATION_DUPLICATE_ERROR: "Location name already exists.",
  PROJECTSTRING_DUPLICATE_ERROR: "Project string name already exists.",
  ASSET_DUPLICATE_ERROR: "Cable name already exists.",
  TEAM_DUPLICATE_ERROR: "Team name already exists.",
  SCOPE_DUPLICATE_ERROR: "Scope name already exists.",
  ACTIVITY_DUPLICATE_ERROR: "Activity name already exists.",
  FUNCTION_DUPLICATE_ERROR: "Function name already exists.",

  // Equipment Request Queue Tab
  COMMENT_UPDATED_SUCCESS: "Comment updated successfully.",

  // Wm Orders
  ORDER_APPROVE_SUCCESS: "Order approved successfully.",
  ORDER_REJECT_SUCCESS: "Order rejected successfully.",
  ORDER_APPROVED_FAILED: "Order cannot be approved.",
  ORDER_REJECTED_FAILED: "Order cannot be rejected.",
  WM_COMMETNS_UPDATE_SUCCESS: "Comments updated successfully.",
  WM_COMMETNS_UPDATE_FAILED: "Comments cannot be updated.",
  ORDER_INTRANSIT_SUCCESS: "Order in-transit successfully.",
  IN_TRANSIT_CONFIRMATION_MESSAGE: "Are you sure you want to in transist this order?",
  EQUIPMENT_TYPE_REJECT_SUCCESS: "Equipment type rejected successfully.",
  EQUIPMENT_TYPE_REJECT_FAILED: "Equipment type cannot be rejected.",
  REJECT_ORDER: "Reject order",
  REJECT_PARTIAL_ORDER: "Reject partial order",
  LINK_EQUIPMENT: "Link all approved equipment",

  // Project Equipment
  ADD_RETURN_CART_SUCCESS: "Equipment added to return cart successfully.",
  EQUIPMENT_RETURN_SUCCESS: "Equipments returned successfully.",
  EQUIPMENT_REMOVE_CART_SUCCESS: "Equipment removed from cart successfully.",

  // Return orders
  RETURN_EQUIPEMENT_REJECTED_SUCCESSFULLY: "Return equipment rejected successfully.",
  RETURN_EQUIPEMENT_REJECTED_FAILED: "Return equipment cannot be rejected.",

  // Update Stocks
  STOCKS_UPDATE_SUCCESS: "Stocks updated successfully.",
  STOCK_VALIDATION: "Stock quantity cannot be 0",

  // Report
  SETUP_REPORT_CREATE_SUCCESS: "Setup report created successfully.",
  SETUP_REPORT_CREATE_ERROR: "Setup report already exist.",
  SETUP_REPORT_UPDATE_SUCCESS: "Setup report updated successfully.",
  SETUP_REPORT_UPDATE_ERROR: "Setup report already exist.",
  SETUP_REPORT_DELETE_SUCCESS: "Setup report deleted successfully.",
  SETUP_REPORT_DELETE_ERROR: "Setup report cannot be deleted.",
  Question_CREATE_SUCCESS: "Question created successfully.",
  Question_UPDATE_SUCCESS: "Question updated successfully.",
  Question_DELETE_SUCCESS: "Question deleted successfully.",
  Question_DELETE_ERROR: "Question cannot be deleted.",
  Question_ALREADY_EXIST: "Question already exist.",
  Question_NOT_EXIST: "Question does not exist.",
  SETUP_REPORT_PUBLISH_SUCCESS: "Setup Report published successfully.",
  SETUP_REPORT_UNPUBLISH_SUCCESS: "Setup Report unpublished successfully.",
  SETUP_REPORT_PUBLISH_ERROR: "Setup Report cannot be published.",
  SETUP_REPORT_UNPUBLISH_ERROR: "Setup Report cannot be unpublished.",
  TOTAL_WEIGHTAGE_NOT_100: "Total weightage should be 100 to publish report.",
  USER_STATUS_UPDATE_SUCCESS: "User status update successfully",
  REPORT_DELETE_SUCCESS: "Report deleted successfully.",
  USER_REPORT_DELETE_SUCCESS: "User report deleted successfully.",
  REPORT_DELETE_ERROR: "Report cannot be deleted.",
  REPORT_ALREADY_EXIST: "Report already exist.",
  REPORT_STATUS: "Report status",
  REPORT_STATUS_SUCCESS: "Report Status Updated Successfully",
  REPORT_STATUS_ERROR: "Report Status Updated Failed",
  REPORT_ANSWER_UPDATE_SUCCESS: "Report answer updated successfully.",
  REPORT_DOCUMENT_ALREADY_EXIST:
    "Report document name must be unique. Please choose a different one.",
  REPORT_ANSWER_UNASSIGNED_SUCCESS: "Report answer unassigned successfully.",
  REPORT_UNASWER_ERROR: "Report answer cannot be unassigned.",
  REPORT_ANSWER_ASSIGNED_SUCCESS: "Report answer assigned successfully.",
  REPORT_ANSWER_ALREADY_EXISTS: "Report answer already exists.",

  // ToolBox Talk
  TOOLBOX_TALK_DELETE_SUCCESS: "ToolBox Talk deleted successfully.",

  // QHSE image format
  Image_W: "Image w",

  // Web Report Signature Required
  SIGNATURE_REQUIRED: "Signature is required.",
  WEB_REPORT_CREATE_SUCCESS: "Web Report created successfully.",

  USER_MODAL_DETAILS_TITLE: "Personal and Contractual Profile List",
  EXPORT_EXCEL_SUCCESS: "Training Matrix Excel exported successfully",
  ADDRESS: "address",

  // User Profile reviews
  POOR: "Poor",
  GOOD: "Good",
  EXCELLENT: "Excellent",
  SATISFACTORY: "Satisfactory",
  UNSATISFACTORY: "Unsatisfactory",
  BLACKLIST: "Blacklist, not to be used anymore",
  BLACKLIST_2: "Blacklist",
  REVIEW_CREATE_SUCCESS: "Review created successfully.",
  REVIEW_DELETE_SUCCESS: "Review deleted successfully.",

  CERTIFICATE_TYPE_NOT_SELECTED: "Please Select Type",
};

export default TextMessage;
