import React, { useEffect } from "react";

// MUI Components
import { Icon } from "@mui/material";

// Custom Components
import MDBox from "components/MDBox";

// Constants
import { Icons, defaultData, Colors, Common } from "utils/Constants";

// 3rd party library
import PropTypes from "prop-types";
import pxToRem from "assets/theme/functions/pxToRem";
import moment from "moment";

export default function FullScreenImageComponent({
  fullScreenImage,
  handleCloseFullView,
  handlePreviousImage,
  handleNextImage,
  image,
  src,
  width,
  height,
  certificateData,
  type,
}) {
  const isPdf = typeof src === "string" && src.includes(".pdf");

  const images = Array.isArray(image) ? image : [image];

  useEffect(() => {
    if (isPdf && fullScreenImage) {
      window.open(src, "_blank");
      handleCloseFullView();
    }
  }, [isPdf, fullScreenImage, src, handleCloseFullView]);

  return (
    <MDBox>
      {fullScreenImage && !isPdf && (
        <MDBox
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 9999,
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Icon
            style={{
              position: "fixed",
              top: "1rem",
              right: "1rem",
              width: "30px",
              height: "30px",
              display: "flex",
              justifyContent: "flex-end",
            }}
            onClick={handleCloseFullView}
          >
            {Icons.REJECT}
          </Icon>
          {images && handlePreviousImage && images.length > 1 ? (
            <Icon
              style={{
                position: "absolute",
                top: "50%",
                left: "1rem",
                transform: "translateY(-50%)",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={handlePreviousImage}
              fontSize="large"
            >
              {Icons.PREVIOUS}
            </Icon>
          ) : null}
          <img
            src={src}
            alt="Full Screen"
            style={{
              position: "relative",
              width,
              height,
              objectFit: "contain",
              marginBottom: "1rem",
              ...(type === Common.SMALL_SIGNATURE && {
                backgroundColor: Colors.WHITE,
              }),
            }}
          />

          <MDBox display="flex" alignItems="center">
            {certificateData.name && (
              <MDBox
                style={{
                  color: "#f0f0f0",
                  fontSize: pxToRem(18),
                  fontWeight: "bold",
                  marginBottom: "0.25rem",
                  marginRight: "0.5rem",
                }}
              >
                {certificateData.name}
              </MDBox>
            )}
            {certificateData.uploader && (
              <MDBox
                style={{
                  color: "#e0e0e0",
                  fontSize: pxToRem(16),
                  fontStyle: "italic",
                  marginBottom: "0.25rem",
                  textTransform: "capitalize",
                }}
              >
                Uploaded by {certificateData.uploader}
              </MDBox>
            )}
          </MDBox>

          {(certificateData.startDate || certificateData.endDate) && (
            <MDBox
              style={{
                color: "#f0f0f0",
                fontSize: pxToRem(16),
              }}
            >
              {(() => {
                if (certificateData.startDate && certificateData.endDate) {
                  return `From: ${moment(certificateData.startDate).format(
                    defaultData.WEB_DATE_FORMAT
                  )} To: ${moment(certificateData.endDate).format(defaultData.WEB_DATE_FORMAT)}`;
                }
                if (certificateData.startDate) {
                  return `Start Date: ${moment(certificateData.startDate).format(
                    defaultData.WEB_DATE_FORMAT
                  )}`;
                }
                if (certificateData.endDate) {
                  return `End Date: ${moment(certificateData.endDate).format(
                    defaultData.WEB_DATE_FORMAT
                  )}`;
                }
                return null;
              })()}
            </MDBox>
          )}

          {images && handleNextImage && images.length > 1 ? (
            <Icon
              style={{
                position: "absolute",
                top: "50%",
                right: "1rem",
                transform: "translateY(-50%)",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={handleNextImage}
              fontSize="large"
            >
              {Icons.NEXT}
            </Icon>
          ) : null}
        </MDBox>
      )}
    </MDBox>
  );
}

FullScreenImageComponent.propTypes = {
  fullScreenImage: PropTypes.bool,
  handleCloseFullView: PropTypes.func,
  handlePreviousImage: PropTypes.func,
  handleNextImage: PropTypes.func,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.object)]),
  src: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  certificateData: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]),
  type: PropTypes.string,
};

FullScreenImageComponent.defaultProps = {
  fullScreenImage: false,
  handleCloseFullView: () => {},
  handlePreviousImage: () => {},
  handleNextImage: () => {},
  image: [],
  src: "",
  width: pxToRem(1113),
  height: pxToRem(542),
  certificateData: {},
  type: Common.SMALL_IMAGE,
};
