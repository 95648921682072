import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  reportFilters: [],
  shift: {},
  qhse: {
    dynamicFilters: [],
    staticFilters: [],
  },
  trainingMatrix: {},
  projectTracker: {},
  reportSetup: {},
  userManagementFilters: [],
  toolboxTalkFilters: [],
};

const filtersSlice = createSlice({
  name: "filtersSlice",
  initialState,
  reducers: {
    setStoreFilters: (state, action) => {
      const { module, filters } = action.payload;
      state[module] = filters;
    },

    setReportFilters: (state, action) => {
      state.reportFilters = action.payload;
    },

    resetReportFilters: (state) => {
      state.reportFilters = [];
    },

    setUserManagementFilters: (state, action) => {
      state.userManagementFilters = action.payload;
    },

    resetUserManagementFilters: (state) => {
      state.userManagementFilters = [];
    },

    setToolBoxTalkFilters: (state, action) => {
      state.toolboxTalkFilters = action.payload;
    },

    resetToolBoxTalkFilters: (state) => {
      state.toolboxTalkFilters = [];
    },

    qhseStoreFilters: (state, action) => {
      const { module, filters, filterType } = action.payload;
      if (module === "qhse") {
        if (filterType === "dynamic") {
          state.qhse.dynamicFilters = filters;
        } else if (filterType === "static") {
          state.qhse.staticFilters = filters;
        }
      }
    },

    resetFilters: (state, action) => {
      state[action.payload.module] = {};
    },

    resetAllFilters: () => initialState,
  },
});

// Export actions
export const {
  setStoreFilters,
  qhseStoreFilters,
  resetFilters,
  resetAllFilters,
  setReportFilters,
  resetReportFilters,
  setUserManagementFilters,
  resetUserManagementFilters,
  setToolBoxTalkFilters,
  resetToolBoxTalkFilters,
} = filtersSlice.actions;

// Export the reducer
export default filtersSlice.reducer;
