import { createSlice } from "@reduxjs/toolkit";
import { resetStateThunk } from "redux/Thunks/Authentication";
import getAllfeedbacks from "redux/Thunks/feedback";
import Constants from "utils/Constants";
import feedbackRowData from "utils/Types/FeedbackDummyData";

const initialState = {
  loading: Constants.IDLE,
  list: [],
  feedbackListingPagiantion: {
    totalRecords: 0,
    completed: [],
  },
};

export const feedbackSlice = createSlice({
  name: "feedbackSlice",
  initialState,
  reducers: {
    reloadData(state) {
      state.loading = Constants.PENDING;
    },
  },

  extraReducers: {
    [getAllfeedbacks.pending]: (state) => {
      if (state.list.length === 0) state.loading = Constants.PENDING;
    },
    [getAllfeedbacks.fulfilled]: (state, { payload, meta }) => {
      state.loading = Constants.FULFILLED;
      // Destructure data for easier access
      const { feedbackData, allRecordsCount } = payload.data.data;

      // Get pagination info from the meta argument
      const params = new URLSearchParams(meta.arg);
      const page = parseInt(params.get("page"), 10);
      const perPage = parseInt(params.get("perPage"), 10);

      // If list is empty, initialize it with the required format
      const feedbackList =
        page === 0
          ? Array.from({ length: allRecordsCount }, () => ({ ...feedbackRowData }))
          : [...state.list]; // shallow copy to avoid direct state mutation
      // Calculate start index for pagination and replace elements in the array
      const startIndex = page * perPage;
      feedbackList.splice(startIndex, perPage, ...feedbackData);

      // Update state with new data
      state.list = feedbackList;
      state.feedbackListingPagiantion.totalRecords = allRecordsCount;
      state.feedbackListingPagiantion.completed =
        page === 0 ? [page] : [...state.feedbackListingPagiantion.completed, page];
    },
    [getAllfeedbacks.rejected]: (state) => {
      state.loading = Constants.REJECTED;
    },
    [resetStateThunk.fulfilled]: (state) => {
      state.loading = Constants.IDLE;
      state.list = [];
    },
  },
});

export const { reloadData } = feedbackSlice.actions;
export default feedbackSlice.reducer;
