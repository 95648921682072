import React, { useEffect, useState } from "react";

// MUI Components
import { Button, Card } from "@mui/material";

// Custom Components
import ConfirmationModal from "examples/modal/Confirmation/Confirmation";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PageTitle from "examples/NewDesign/PageTitle";
import ReturnCartData from "layouts/returnCart/data/returnCartData";
import DataTable from "examples/Tables/DataTable";
import BasicButton from "examples/NewDesign/CustomButton/BasicButton";
import FilterDropdown from "components/Dropdown/FilterDropdown";
import DeleteModal from "examples/modal/deleteModal/deleteModal";
import FormTextField from "components/Form/FTextField";
import MDBox from "components/MDBox";

// Constant
import Constants, {
  ButtonTitles,
  Icons,
  defaultData,
  Colors,
  PageTitles,
  ModalContent,
} from "utils/Constants";
import pxToRem from "assets/theme/functions/pxToRem";

// Redux component
import { useDispatch, useSelector } from "react-redux";
import {
  returnCartList,
  projectApproverList,
  returnEquipment,
  deleteCartEquipment,
} from "redux/Thunks/ReturnEquipment";
import { reloadData, updateQuantity } from "redux/Slice/OrderEquipment";
import { openSnackbar } from "redux/Slice/Notification";
import MDTypography from "components/MDTypography";
import moment from "moment";

function ReturnCart() {
  const [filters, setFilters] = useState([
    {
      inputLabel: "Project",
      list: [{ [Constants.MONGOOSE_ID]: "select", title: "Select" }],
      selectedValue: "select",
    },
  ]);

  const [tablePagination, setTablePagination] = useState({
    page: 0,
    perPage: defaultData.DATE_ON_SINGLE_API_CALL,
  });
  const [next, setNext] = useState(0);
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    id: "",
  });
  const [returnModal, setReturnModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [returnRemark, setReturnRemark] = useState("");
  const { cartList, cartLoading } = useSelector((state) => state.returnEquipment);
  const dispatch = useDispatch();
  const currentDate = new Date();
  const ConfigData = useSelector((state) => state.config);

  const handleFilter = async () => {
    setTablePagination({ ...tablePagination, page: 0 });
    setNext(0);
    const paramData = {
      projectId: filters[0].selectedValue,
    };
    if (filters[0]?.selectedValue !== "select") {
      await dispatch(reloadData());
      await dispatch(returnCartList(paramData));
    }
  };

  useEffect(() => {
    handleFilter();
    (async () => {
      const tempProjectList = await dispatch(projectApproverList());
      const temp = [...filters];

      temp[0].list = [
        { [Constants.MONGOOSE_ID]: "select", title: "Select" },
        ...tempProjectList.payload.data.data,
      ];

      setFilters(temp);
    })();
  }, []);

  const handleFilterChange = (e) => {
    const temp = [...filters];
    const { value } = e.target;
    const indexs = filters.findIndex((filter) => filter.inputLabel === e.target.name);
    temp[indexs].selectedValue = value;
    setFilters(temp);
    handleFilter();
  };

  const handleResetFilter = () => {
    const temp = filters.map((filter) => ({
      ...filter,
      selectedValue: filter.list[0][Constants.MONGOOSE_ID] || filter.list[0],
    }));
    setFilters(temp);
    handleFilter();
  };

  const handleTablePagination = async () => {
    const res = await dispatch(returnCartList());
    if (res.payload.status === 200) {
      setNext(res.payload.data.data.length > 0 ? next + 1 : next);
    }
  };

  const handleDeleteOpen = (id) => {
    setDeleteModal({ open: true, id });
  };

  const handleReload = async () => {
    await dispatch(reloadData());
    await handleFilter();
  };

  const handleReturn = async () => {
    const returnBody = [
      {
        remark: [
          {
            user: ConfigData.config[0]?.id,
            time: moment(currentDate).format(defaultData.DATABASE_24_HOURS_FORMAT),
            status: "return",
            comment: returnRemark,
          },
        ],
        returnOrder: cartList?.[0]?.return?.returnOrder,
        returnEquipmentData: cartList?.map((item) => ({
          id: item[Constants.MONGOOSE_ID],
          pmDispatchQuantity: Number(item?.returnQty, 10),
        })),
      },
    ];
    setLoading(true);
    const res = await dispatch(returnEquipment(returnBody));
    if (res.payload.status === 200) {
      handleFilter();
      dispatch(
        openSnackbar({
          message: Constants.EQUIPMENT_RETURN_SUCCESS,
          notificationType: Constants.NOTIFICATION_SUCCESS,
        })
      );
      setReturnModal(false);
    } else {
      dispatch(
        openSnackbar({
          message: Constants.SOMETHING_WENT_WRONG,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
      setReturnModal(false);
    }
    setLoading(false);
  };

  const handleDelete = async () => {
    const res = await dispatch(deleteCartEquipment(deleteModal.id));
    if (res.payload.status === 200) {
      await handleFilter();
      setDeleteModal({ open: false, id: "" });

      await dispatch(
        openSnackbar({
          message: Constants.EQUIPMENT_REMOVE_CART_SUCCESS,
          notificationType: Constants.NOTIFICATION_SUCCESS,
        })
      );
    } else {
      setDeleteModal({ open: false, id: "" });
      await dispatch(
        openSnackbar({
          message: Constants.SOMETHING_WENT_WRONG,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
    }
  };

  const handleChange = async (name, e, indexId, quantity) => {
    await dispatch(
      updateQuantity({ name, target: e.target.value, returnIndex: indexId, quantity })
    );
  };
  const { columns, rows } = ReturnCartData(cartList, handleDeleteOpen, handleChange);

  return (
    <DashboardLayout module={defaultData.APPROVER_SCREEN_ID}>
      <DashboardNavbar />
      <MDBox display="flex" justifyContent="space-between">
        <PageTitle title={PageTitles.RETURN_CART} />
        <BasicButton
          icon={Icons.RELOAD}
          background={Colors.WHITE}
          border
          color={Colors.BLACK}
          action={handleReload}
        />
      </MDBox>
      <MDBox display="flex" justifyContent="space-between" mx={0}>
        <MDBox display="flex wrap" flexWrap="wrap" justifyContent="start" mt={2} mx={0}>
          {filters?.map((val) => (
            <FilterDropdown
              label={val.inputLabel}
              name={val.inputLabel}
              defaultValue={val?.selectedValue}
              value={val?.selectedValue || ""}
              handleChange={handleFilterChange}
              menu={val.list}
              key={val.inputLabel}
              maxContent={defaultData.MEDIUM_CONTENT_LENGTH}
            />
          ))}
          <Button
            sx={{
              mt: pxToRem(45),
              mr: 1,
              backgroundColor: "#fff",
              "&:hover": {
                backgroundColor: "#fff",
              },
              fontSize: pxToRem(14),
              textTransform: "capitalize",
            }}
            variant="outlined"
            color="info"
            startIcon={Icons.RESET_FILTER}
            onClick={handleResetFilter}
            disabled={filters[0].selectedValue === "select"}
          >
            {ButtonTitles.RESET_FILTER}
          </Button>
        </MDBox>
        <MDBox mt={8}>
          <BasicButton
            title={ButtonTitles.RETURN}
            icon={Icons.RETURN}
            disabled={filters[0]?.selectedValue === "select" || cartList?.length === 0}
            background={Colors.ERROR}
            border
            color={Colors.WHITE}
            action={() => setReturnModal(true)}
          />
        </MDBox>
      </MDBox>
      <MDBox mt={2}>
        {filters[0]?.selectedValue !== "select" ? (
          <DataTable
            table={{ columns, rows }}
            isSorted={false}
            entriesPerPage={{ defaultValue: defaultData.PER_PAGE }}
            showTotalEntries={false}
            noEndBorder
            loading={cartLoading}
            currentPage={tablePagination.page}
            handleTablePagination={handleTablePagination}
            handleCurrentPage={(page) => setTablePagination({ ...tablePagination, page })}
          />
        ) : (
          <Card sx={{ height: "70vh", marginTop: 3 }}>
            <MDBox display="flex" justifyContent="center" alignItems="center" marginTop="15%">
              <MDTypography sx={{ fontSize: "20px", fontWeight: "semibold", opacity: "0.8" }}>
                Please Select Project
              </MDTypography>
            </MDBox>
          </Card>
        )}
      </MDBox>
      <DeleteModal
        open={deleteModal.open}
        title={ModalContent.DELETE_EQUIPMENT_CART_TITLE}
        message={ModalContent.DELETE_EQUIPMENT_CART_MESSAGE}
        handleClose={() => setDeleteModal({ open: false })}
        handleDelete={handleDelete}
      />
      <ConfirmationModal
        open={returnModal}
        handleClose={() => {
          setReturnModal(false);
          setReturnRemark("");
        }}
        title={ModalContent.RETURN_EQUIPMENT_TITLE}
        disabled={loading}
        handleAction={handleReturn}
      >
        <FormTextField
          type="textarea"
          marginTop={3}
          title="Reason"
          value={returnRemark}
          name="remark"
          label="Reason"
          placeholder="Add reason here"
          handleChange={(e) => setReturnRemark(e.target.value)}
        />
      </ConfirmationModal>
    </DashboardLayout>
  );
}

export default ReturnCart;
