// React
import { useEffect, useState } from "react";

// Material Components
import { Icon, IconButton } from "@mui/material";

// Common Components
import MDBox from "components/MDBox";
import Author from "components/Table/Author";
import MDTypography from "components/MDTypography";
import pxToRem from "assets/theme/functions/pxToRem";
import FontComponent from "components/Responsive/fonts";
import DynamicTypeData from "components/Table/DynamicTypeData";
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox";

// Redux
import { useDispatch } from "react-redux";
import { updateIsPrintable } from "redux/Thunks/Report";
import { openSnackbar } from "redux/Slice/Notification";
import { setQuestionTree, updateIsPrint } from "redux/Slice/Report";

// Utils
import pattern from "utils/Patterns";
import Constants, { Icons, defaultData } from "utils/Constants";

// 3rd party libraries
import moment from "moment";

class TreeNode {
  constructor(value) {
    this.value = value;
    this.children = [];
    this.expanded = true; // Default to expanded
  }
}

const determineAnswer = (element, answer) => {
  const answerText = typeof answer?.answer === "string" ? answer.answer : "";
  if (
    element?.parameterType?.uniqueKey === "dateTime" &&
    answer.answer !== "" &&
    answer.answer !== null
  ) {
    return moment.utc(answer.answer).format(defaultData.WEB_24_HOURS_FORMAT);
  }
  if (element?.parameterType?.uniqueKey === "date") {
    return moment(answer.answer).format(defaultData.WEB_DATE_FORMAT);
  }
  if (answerText.includes("##")) {
    const parts = answerText
      .split("##")
      .map((part) => part.trim())
      .filter((part) => part !== "");

    return parts.join(", ");
  }
  return answer.answer;
};

export default function ReportDetailsData(
  report,
  handleView,
  handleDelete,
  handleAnswerEdit,
  permission,
  reportCount
) {
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  const fontSize = FontComponent({ sizes: {} });

  const handleChange = async (e, ansId, userProjectReport, reportQuestionAnswerId) => {
    const { checked } = e.target;
    const data = {
      userProjectReport,
      reportQuestionAnswer: reportQuestionAnswerId,
      answerTitleId: ansId,
      isPrintable: checked,
    };
    const res = await dispatch(updateIsPrintable(data));
    if (res?.payload?.status === 200) {
      await dispatch(updateIsPrint({ answerId: ansId, isPrintable: checked }));
    } else {
      dispatch(
        openSnackbar({
          message: Constants.SOMETHING_WENT_WRONG,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
    }
  };

  useEffect(() => {
    if (report) {
      (async () => {
        const questionTree = new TreeNode("Question Tree");
        const sortedReport = [...report].sort((a, b) => a.sortOrder - b.sortOrder);

        const activeImageArray = [];
        const list = sortedReport?.map((item, idx) => {
          // Progress tracker
          const getTitle = item.answerTypes.map(
            (b) => b?.title?.filter((t) => t.isActive && t.isRequired)?.length
          );
          const titleLength = getTitle?.reduce((acc, title) => acc + parseInt(title, 10));
          const processedAnswerTitleIds = new Set();

          item.answerTypes.forEach((element1) => {
            element1.userAnswers?.forEach((item21) => {
              item21.answers.forEach((answer1) => {
                // Check if the answer title exists and is marked as required
                const requiredTitles = element1.title.filter((a) => a.isRequired);
                const hasMatchingRequiredTitleId = requiredTitles.some(
                  (a) => a[Constants.MONGOOSE_ID] === answer1.answerTitleId
                );

                if (
                  hasMatchingRequiredTitleId &&
                  !processedAnswerTitleIds.has(answer1.answerTitleId) &&
                  answer1.isActive
                ) {
                  processedAnswerTitleIds.add(answer1.answerTitleId);
                }
              });
            });
          });

          const questionObj = {
            id: item?.questionId,
            queNo: <Author name={idx + 1} />,
            type: <Author name="" />,
            createdBy: <Author name="" />,
            questionAnswer: (
              <MDBox>
                <MDTypography
                  variant="caption"
                  sx={{
                    overflowWrap: "break-word",
                    wordWrap: "break-word",
                    fontSize,
                  }}
                >
                  {`Q${idx + 1}.  ${item.title}`}
                </MDTypography>
              </MDBox>
            ),
            progress: (
              <Author
                textAlign="center"
                name={`${processedAnswerTitleIds.size} / ${titleLength}`}
              />
            ),
            action: item.answerTypes.length > 0 && (
              <IconButton
                aria-label="view report detail"
                color="info"
                onClick={() => handleView(item, idx + 1)}
              >
                {Icons.VIEW}
              </IconButton>
            ),
            isQuestion: true,
            queId: idx + 1,
          };
          questionTree.children.push(new TreeNode(questionObj));
          let currentTitle = null; // Track the previous title
          let inActiveCount = 0;
          if (item.answerTypes.length > 0) {
            item.answerTypes.forEach((element, typeIdx) => {
              const answerTitleIds = element.title.map((title) => title[Constants.MONGOOSE_ID]);

              answerTitleIds.forEach((answerTitleId) => {
                if (currentTitle !== answerTitleId) {
                  currentTitle = answerTitleId; // Update to the new title
                }
                let answerGiven = false;
                item.answerTypes.forEach((type) => {
                  type.userAnswers?.forEach((userAnswer) => {
                    const reportId = userAnswer?.userReport;
                    const answerIndex = userAnswer?.answers?.find(
                      (answ) => answ?.answerTitleId === answerTitleId
                    );

                    // Initialize map for the user if not already present

                    const matchingAnswers = userAnswer.answers.filter(
                      (answer1) => answer1.answerTitleId === answerTitleId
                    );
                    const answer = matchingAnswers.find((ans) => ans.isActive === true);
                    const correspondingTitle = element.title.find(
                      (title) => title[Constants.MONGOOSE_ID] === answerTitleId
                    );

                    const answerTitleCounts = new Map();

                    type.userAnswers.forEach((user) => {
                      user.answers.forEach((answer4) => {
                        if (answer4.isActive) {
                          const answerTitleIds1 = answer4.answerTitleId;
                          answerTitleCounts.set(
                            answerTitleIds1,
                            (answerTitleCounts.get(answerTitleIds1) || 0) + 1
                          );
                        }
                      });
                    });

                    const answerCount = answerTitleCounts.get(answerTitleId) || 0;
                    const progressIcon = answerCount === 1 ? Icons.APPROVED : Icons.INFO_2;

                    const userName = reportCount?.userReportStatus.find(
                      (reportf) => reportf.userReportId === reportId
                    ).createdBy;

                    if (
                      element?.parameterType?.uniqueKey === "image" ||
                      element?.parameterType?.uniqueKey === "signature"
                    ) {
                      if (answer?.answer && answer?.isActive) {
                        activeImageArray.push(answer.answer);
                      }
                    }
                    if (answer) {
                      answerGiven = true;
                      const answerObj2 = {
                        id: answer[Constants.MONGOOSE_ID],
                        queNo: `${idx + 1}.${typeIdx + 1}.${answerIndex?.answerIndex}`,

                        type: (
                          <Author
                            name={
                              answerIndex?.answerIndex === 1
                                ? element?.parameterType?.uniqueKey
                                : ""
                            }
                          />
                        ),
                        print: (
                          <CustomCheckbox
                            id="isPrintable"
                            name="isPrintable"
                            checked={answer?.isPrintable || false}
                            onChange={(e) =>
                              handleChange(
                                e,
                                answer?.answerTitleId,
                                reportCount?.userProjectReport,
                                element[Constants.MONGOOSE_ID]
                              )
                            }
                          />
                        ),
                        isReuired: (
                          <CustomCheckbox
                            name="check"
                            checked={correspondingTitle?.isRequired || false}
                            disabled
                          />
                        ),
                        createdBy: (
                          <Author
                            name={`${
                              userName?.callingName ? userName?.callingName : userName?.firstName
                            } ${userName?.lastName}`}
                          />
                        ),
                        questionAnswer: (
                          <Author
                            name={`${correspondingTitle?.value}:`}
                            maxContent={defaultData.LARGE_CONTENT_LENGTH}
                            style={{ color: "#7B809A" }}
                          />
                        ),
                        answer: (
                          <MDBox sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                            {element?.parameterType?.uniqueKey === "image" ||
                            element?.parameterType?.uniqueKey === "signature" ? (
                              <MDBox sx={{ paddingBottom: pxToRem(8) }}>
                                <DynamicTypeData
                                  type={element?.parameterType?.uniqueKey}
                                  data={
                                    answer.answer?.length > 0
                                      ? answer.answer
                                      : process.env.REACT_APP_IMAGE_NOT_FOUND
                                  }
                                  compressedData={
                                    answer.answer?.length > 0
                                      ? answer.answer.replace(pattern.LAST_SLASH, "/compressed/$1")
                                      : process.env.REACT_APP_IMAGE_NOT_FOUND
                                  }
                                  isDownload
                                  imageArray={activeImageArray}
                                />
                              </MDBox>
                            ) : (
                              <MDBox>
                                <MDTypography
                                  variant="caption"
                                  sx={{
                                    overflowWrap: "break-word",
                                    wordWrap: "break-word",
                                    fontSize,
                                  }}
                                >
                                  {determineAnswer(element, answer)}
                                </MDTypography>
                              </MDBox>
                            )}
                          </MDBox>
                        ),
                        progress: (
                          <MDBox
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              height: "100%", // Ensure the parent container has a defined height
                            }}
                          >
                            <Icon
                              sx={{
                                width: 30,
                                height: 30,
                              }}
                            >
                              {progressIcon}
                            </Icon>
                          </MDBox>
                        ),
                        action: (
                          <MDBox sx={{ width: "max-content" }}>
                            {permission?.update &&
                              element?.parameterType?.uniqueKey !== "signature" && (
                                <IconButton
                                  aria-label="edit report detail"
                                  color="error"
                                  onClick={() =>
                                    handleAnswerEdit(
                                      item,
                                      element,
                                      answer,
                                      userAnswer,
                                      correspondingTitle?.value
                                    )
                                  }
                                >
                                  {Icons.EDIT}
                                </IconButton>
                              )}
                            {permission?.delete && (
                              <IconButton
                                aria-label="delete report detail"
                                color="error"
                                onClick={() =>
                                  handleDelete(
                                    userAnswer[Constants.MONGOOSE_ID],
                                    answer[Constants.MONGOOSE_ID],
                                    answer?.answerTitleId,
                                    userAnswer?.userReport
                                  )
                                }
                              >
                                {Icons.DASH2}
                              </IconButton>
                            )}
                          </MDBox>
                        ),
                        isQuestion: false,
                        queId: idx + 1,
                        ansId: "",
                        title: "",
                      };

                      questionTree.children[idx].children.push(new TreeNode(answerObj2));
                    }
                  });
                });
                const checkTitle = element.title.find(
                  (title) => title[Constants.MONGOOSE_ID] === answerTitleId
                );
                if (!answerGiven && checkTitle?.isActive) {
                  inActiveCount += 1;
                  const correspondingTitle = element.title.find(
                    (title) => title[Constants.MONGOOSE_ID] === answerTitleId
                  );

                  const placeholderAnswerObj = {
                    id: answerTitleId,
                    queNo: `${idx + 1}.${typeIdx + 1}.${inActiveCount}`,
                    type: <Author name={element?.parameterType?.uniqueKey} />,
                    print: (
                      <CustomCheckbox
                        style={{ textAlign: "center" }}
                        id="isPrintable"
                        name="isPrintable"
                        checked={correspondingTitle?.isPrintable || false}
                        disabled
                      />
                    ),
                    isReuired: (
                      <CustomCheckbox
                        name="check"
                        checked={correspondingTitle?.isRequired || false}
                        disabled
                      />
                    ),
                    createdBy: <Author name="" />,
                    questionAnswer: (
                      <Author
                        name={`${correspondingTitle?.value || "Title"}:`}
                        maxContent={defaultData.LARGE_CONTENT_LENGTH}
                        style={{ color: "#7B809A" }}
                      />
                    ),
                    answer: (
                      <MDBox sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                        <MDTypography
                          variant="caption"
                          sx={{ overflowWrap: "break-word", wordWrap: "break-word", fontSize }}
                        >
                          {" "}
                        </MDTypography>
                      </MDBox>
                    ),
                    progress: (
                      <MDBox
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "100%", // Ensure the parent container has a defined height
                        }}
                      >
                        <Icon
                          sx={{
                            width: 30,
                            height: 30,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {Icons.REJECTED}
                        </Icon>
                      </MDBox>
                    ),
                    action: null,
                    isQuestion: false,
                    queId: idx + 1,
                    ansId: "",
                    title: "",
                  };

                  questionTree.children[idx].children.push(new TreeNode(placeholderAnswerObj));
                }
              });
            });
          }

          const questionRow = {
            queNo: idx + 1,
            type: <Author name="" />,
            createdBy: <Author name="" />,
            questionAnswer: item?.title,
            action: "",
          };
          return questionRow;
        });
        await dispatch(setQuestionTree(questionTree));
        setRows([...list]);
      })();
    }
  }, [report, permission, reportCount]);

  return {
    reportColumns: [
      { Header: "No.", accessor: "queNo", width: "2%" },
      { Header: "Type", accessor: "type", align: "left", width: "10%" },
      { Header: "Submitted By", accessor: "createdBy", align: "left", width: "10%" },
      {
        Header: "Question",
        accessor: "questionAnswer",
        align: "left",
        width: "30%",
      },
      { Header: "Answer", accessor: "answer", align: "left", width: "30%" },
      { Header: "Print", accessor: "print", textAlign: "center", align: "center", width: "10px" },
      { Header: "Is Required", accessor: "isReuired", align: "center", width: "10px" },
      { Header: "Progress", accessor: "progress", align: "center", width: "10px" },
      { Header: "Action", accessor: "action", align: "center", width: "7%" },
    ],
    reportRows: rows,
  };
}
