import { useEffect, useState } from "react";

// Material-UI
import { Button, Grid, Modal } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Common Components
import Status from "components/Table/Status";

// assets
import pxToRem from "assets/theme/functions/pxToRem";

// Constants
import Constants, { ButtonTitles, Colors, defaultData } from "utils/Constants";

// 3rd party library
import moment from "moment";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

function CheckInCheckOutComments({
  open,
  handleClose,
  handleAction,
  type = "approver",
  intialComments = [],
  equipStatus,
  buttonLoading,
}) {
  const currentDate = new Date();
  const ConfigData = useSelector((state) => state.config);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState({
    user: ConfigData.config[0]?.id,
    time: moment(currentDate).format(defaultData.DATABASE_24_HOURS_FORMAT),
    status: equipStatus,
    comment: "",
  });
  const [updateComment, setUpdateComment] = useState({
    updateComment: false,
    editCommentIndex: null,
    editCommentedValue: "",
  });

  useEffect(() => {
    const tempComments = intialComments?.map((comment, index) => ({
      key: index,
      value: comment,
    }));
    setComments(tempComments);
  }, [intialComments]);

  const handleUpdateComment = (edit, index, comment) => {
    setUpdateComment({ updateComment: edit, editCommentIndex: index, editCommentedValue: comment });
  };

  const handleSingleUpdate = () => {
    const body = comments.map((comment, i) => {
      if (i === updateComment.editCommentIndex) {
        return { key: comment.key, value: updateComment.editCommentedValue };
      }
      return comment;
    });
    setComments(body);
    setUpdateComment({ updateComment: false, editCommentIndex: null, editCommentedValue: "" });
  };

  const handleEditComentValue = (e) =>
    setUpdateComment({ ...updateComment, editCommentedValue: e.target.value });

  const handleDeleteComment = (index) => {
    const body = comments.filter((comment, i) => i !== index);
    setComments(body);
  };

  const handleCommentAction = () => {
    const tempComments = comments.map((comment) => comment.value);
    let body = [...tempComments, newComment];
    body = body.filter((comment) => comment !== "");
    handleAction(body);
    setNewComment("");
  };

  const getStatusTitle = (equipmentStatus) => {
    switch (equipmentStatus) {
      case "pending":
        return "Request";
      case "requested":
        return Constants.ORDER_REQUEST;
      case "queue":
        return Constants.SHOPPING_CART;
      case "pre-transit":
        return Constants.PACKED_ORDERS;
      case "in-transit":
        return Constants.SHIPPED_ORDERS;
      default:
        return equipmentStatus.replace("-", " ");
    }
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <MDBox
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: pxToRem(473),
          bgcolor: "background.paper",
          borderRadius: pxToRem(8),
          p: 0,
        }}
      >
        {/* Headers */}
        <MDBox
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          borderRadius="lg"
          sx={{
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
            height: pxToRem(72),
            padding: "16px 16px 8px 16px",
          }}
        >
          <MDTypography sx={{ fontSize: pxToRem(16), color: "#344054", fontWeight: "600" }}>
            {(type === "user" && "View Comments") ||
              (!updateComment.updateComment ? "Add New Comments" : "Update Comments")}{" "}
          </MDTypography>
        </MDBox>

        {/* Modal Content  */}
        <MDBox
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          sx={{ maxHeight: 500, paddingX: "16px" }}
        >
          {/* Add new Comments */}
          {type !== "user" && !updateComment.updateComment && (
            <MDInput
              multiline
              rows={3}
              fullWidth
              placeholder="Add Comments"
              style={{
                backgroundColor: "#f8f7f7",
              }}
              value={newComment.comment}
              onChange={(e) => setNewComment({ ...newComment, comment: e.target.value })}
            />
          )}

          <MDBox mt={type !== "user" ? 2 : 0}>
            <MDBox
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              sx={{
                maxHeight: 200,
                overflowY: "auto",
                "::-webkit-scrollbar": {
                  width: "5px",
                },
                "::-webkit-scrollbar-thumb": {
                  background: "gray",
                },
                scrollbarWidth: "thin",
                scrollbarColor: "gray transparent",
              }}
            >
              {comments
                ?.filter((check) => check?.value?.comment !== "")
                ?.sort((a, b) => b.key - a.key)
                ?.map((comment, index) => (
                  <MDBox
                    key={comment.key}
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    sx={{
                      padding: "0px 8px",
                      borderRadius: "8px",
                      marginBottom: "8px",
                    }}
                  >
                    {updateComment.updateComment && updateComment.editCommentIndex === index ? (
                      <>
                        <MDInput
                          multiline
                          fullWidth
                          placeholder="Comments"
                          style={{
                            backgroundColor: "#f8f7f7",
                          }}
                          value={updateComment.editCommentedValue}
                          onChange={handleEditComentValue}
                        />
                        <MDBox display="flex" justifyContent="flex-end">
                          <Button
                            variant="text"
                            onClick={() => handleUpdateComment(false, index, comment.value)}
                            style={{ fontWeight: 400, fontSize: pxToRem(14), paddingLeft: 0 }}
                          >
                            {ButtonTitles.CANCEL}
                          </Button>
                          <Button
                            variant="text"
                            onClick={handleSingleUpdate}
                            style={{ fontWeight: 400, fontSize: pxToRem(14), paddingLeft: 0 }}
                          >
                            {ButtonTitles.UPDATE}
                          </Button>
                        </MDBox>
                      </>
                    ) : (
                      <>
                        <MDBox marginTop={2}>
                          <MDBox display="flex" justifyContent="space-between">
                            <MDTypography
                              fontSize={pxToRem(16)}
                              fontWeight="bold"
                              sx={{ color: "#191D31", textTransform: "capitalize" }}
                              py={1}
                            >
                              {comment?.value?.user?.firstName || comment?.value?.user?.callingName
                                ? ` ${
                                    comment?.value?.user?.callingName
                                      ? comment?.value?.user?.callingName
                                      : comment?.value?.user?.firstName
                                  } ${comment?.value?.user?.lastName}`
                                : ConfigData.config[0]?.username}
                            </MDTypography>
                            <MDTypography
                              fontSize={pxToRem(14)}
                              fontWeight="medium"
                              sx={{ color: "#667085", textTransform: "capitalize" }}
                              py={1}
                            >
                              {moment
                                .utc(comment.value.time)
                                .format(defaultData.WEB_24_HOURS_FORMAT)}
                            </MDTypography>
                          </MDBox>
                          <MDTypography
                            fontSize={pxToRem(16)}
                            fontWeight="regular"
                            sx={{ color: "#191D31", textTransform: "capitalize" }}
                            py={1}
                          >
                            {index + 1}: {comment.value.comment}
                          </MDTypography>
                          <Status title={getStatusTitle(comment?.value?.status)} />
                        </MDBox>
                        {type !== "user" && (
                          <MDBox display="flex" justifyContent="flex-end">
                            <Button
                              variant="text"
                              onClick={() => handleUpdateComment(true, index, comment.value)}
                              style={{
                                fontWeight: 400,
                                fontSize: pxToRem(14),
                                paddingLeft: 0,
                                display: "none",
                              }}
                            >
                              {ButtonTitles.EDIT}
                            </Button>
                            <Button
                              variant="text"
                              ps={0}
                              style={{
                                fontWeight: 400,
                                fontSize: pxToRem(14),
                                color: Colors.DARK_RED,
                                paddingLeft: 0,
                                display: "none",
                              }}
                              onClick={() => handleDeleteComment(index)}
                            >
                              {ButtonTitles.DELETE}
                            </Button>
                          </MDBox>
                        )}
                      </>
                    )}
                  </MDBox>
                ))}
            </MDBox>
          </MDBox>
        </MDBox>

        {/* Action buttons */}
        <MDBox px={3} mt={2} mb={3}>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            {type !== "user" ? (
              <MDBox>
                <Button
                  variant="outlined"
                  disableRipple
                  sx={{
                    backgroundColor: "#fff",

                    marginRight: pxToRem(14),
                    borderRadius: pxToRem(8),
                    height: pxToRem(40),
                    width: pxToRem(80),

                    border: "1px solid #d0d5dd",
                  }}
                  onClick={handleClose}
                >
                  <MDTypography
                    fontSize={pxToRem(14)}
                    sx={{ fontWeight: 500, fontSize: pxToRem(14), lineHeight: pxToRem(20) }}
                  >
                    {ButtonTitles.CANCEL}
                  </MDTypography>
                </Button>

                <Button
                  variant="contained"
                  style={{ boxShadow: "none", textTransform: "none" }}
                  disableRipple
                  disabled={buttonLoading || newComment?.comment?.trim()?.length === 0}
                  sx={{
                    backgroundColor: "#191a51",
                    borderRadius: pxToRem(8),
                    height: pxToRem(40),
                    width: "auto",
                    border: "1px solid #d0d5dd",
                    boxShadow: "none",
                  }}
                  onClick={handleCommentAction}
                >
                  <MDTypography
                    fontSize={pxToRem(14)}
                    sx={{
                      color: "#fff",
                      fontWeight: 500,
                      fontSize: pxToRem(14),
                      lineHeight: pxToRem(20),
                    }}
                  >
                    {buttonLoading ? ButtonTitles.LOADING : ButtonTitles.SAVE}
                  </MDTypography>
                </Button>
              </MDBox>
            ) : (
              <Button
                variant="outlined"
                disableRipple
                sx={{
                  backgroundColor: "#fff",

                  marginRight: pxToRem(14),
                  borderRadius: pxToRem(8),
                  height: pxToRem(40),
                  width: pxToRem(80),

                  border: "1px solid #d0d5dd",
                }}
                onClick={handleClose}
              >
                <MDTypography
                  fontSize={pxToRem(14)}
                  sx={{ fontWeight: 500, fontSize: pxToRem(16), lineHeight: pxToRem(20) }}
                >
                  Close
                </MDTypography>
              </Button>
            )}
          </Grid>
        </MDBox>
      </MDBox>
    </Modal>
  );
}

// default props for modal
CheckInCheckOutComments.defaultProps = {
  type: "user",
  buttonLoading: false,
  intialComments: [],
};

// prop types for modal
CheckInCheckOutComments.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  equipStatus: PropTypes.string.isRequired,
  type: PropTypes.string,
  buttonLoading: PropTypes.bool,
  handleAction: PropTypes.func.isRequired,
  intialComments: PropTypes.arrayOf(PropTypes.object),
};

export default CheckInCheckOutComments;
