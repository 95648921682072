import { useMemo, useEffect, useState, Fragment } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-table components
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Autocomplete from "@mui/material/Autocomplete";
import { IconButton, Pagination, TableCell } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";

// Constants
import Constants, { Icons, defaultData } from "utils/Constants";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { setQuestionTree } from "redux/Slice/Report";
import Session from "utils/Sessions";

// 3rd party library
import jwtDecode from "jwt-decode";

function WebReportsTable({
  entriesPerPage,
  canSearch,
  showTotalEntries,
  table,
  isSorted,
  loading,
  licenseRequired,
  // currentPage, handleTablePagination, handleCurrentPage are use when need data size is more
  // Use this parameter to retrieve additional data when a user visits the last page
  currentPage,
  handleTablePagination,
  handleCurrentPage,
  backgroundColor, // New prop to specify the background color
  textColor,
  extraContent,
}) {
  const dispatch = useDispatch();

  const ConfigData = useSelector((state) => state.config);
  const reportData = useSelector((state) => state.report);

  const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries.map((el) => el.toString())
    : ["25"];

  const [status, setStatus] = useState(Constants.PENDING);
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: currentPage } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { questionTree } = reportData;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  // update the status on role change, when superadmin login as admin and data changes
  useEffect(() => {
    const token = jwtDecode(Session.userToken);
    const { role } = token;

    const setPending = () => setStatus(Constants.PENDING);
    const setRejected = () => setStatus(Constants.REJECTED);
    const setFulfilled = () => setStatus(Constants.FULFILLED);
    const setNoData = () => setStatus("noData");

    const isSuperAdmin =
      role === defaultData.SUPER_ADMIN_ROLE && !Session.isSuperAdminViewingAdminPanel;
    const isPending = ConfigData.loading === Constants.PENDING || loading === Constants.PENDING;
    const isRejected = ConfigData.loading === Constants.REJECTED || loading === Constants.REJECTED;
    const isFulfilledWithData =
      loading === Constants.FULFILLED && rows.length > 0 && pageOptions.length > 0;
    const isFulfilledNoData =
      loading === Constants.FULFILLED && rows.length === 0 && pageOptions.length === 0;
    const isConfigFulfilled = ConfigData.loading === "fulfilled";

    // for superadmin
    if (isSuperAdmin || !licenseRequired) {
      if (loading === Constants.PENDING) setPending();
      else if (isRejected) setRejected();
      else if (isFulfilledWithData) setFulfilled();
      else if (isFulfilledNoData) setNoData();
    }
    // for admin
    else if (isPending) setPending();
    else if (isRejected) setRejected();
    else if (isConfigFulfilled && isFulfilledWithData) setFulfilled();
    else if (isConfigFulfilled && isFulfilledNoData) setNoData();
  }, [Session.userToken, Session.isSuperAdminViewingAdminPanel, ConfigData.loading, table]);

  // set current page to last page when the current page has no data
  // Works when some data is deleted from the last page
  useEffect(() => {
    if (pageOptions.length > 0 && pageOptions.length <= currentPage) {
      const lastPage = pageOptions.length - 1;
      gotoPage(lastPage);
      handleCurrentPage(lastPage);
    }
  }, [pageOptions]);
  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value) => setPageSize(value);

  // Search input value state
  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  const handlePageChange = (event, value) => {
    if (pageOptions.length === value) handleTablePagination(value);
    if (event.target.innerText === "Previous") {
      previousPage();
    } else if (event.target.innerText === "Next") {
      nextPage();
    } else {
      gotoPage(value - 1);
      handleCurrentPage(value - 1);
    }
  };

  const toggleNode = async (node) => {
    const updatedQuestionTree = { ...questionTree };
    const updatedNode = {
      ...node,
      expanded: !node.expanded,
    };
    updatedQuestionTree.children = updatedQuestionTree.children.map((child) => {
      if (child.value.queNo === updatedNode.value.queNo) {
        return updatedNode;
      }
      return child;
    });
    await dispatch(setQuestionTree(updatedQuestionTree));
  };

  const treeTable = (tree) =>
    tree?.children?.map((node) => {
      const isQuestionRow = node.value.isQuestion;
      const bgColor = node.value.queId % 2 === 0 ? "#f6f7ff" : "transparent";
      const isUserCreator = node.value?.createdBy?.props?.name !== "";
      return (
        <Fragment key={node?.value?.id}>
          {!isQuestionRow && node.value.ansId === 1 && (
            <TableRow sx={{ background: bgColor }}>
              <DataTableBodyCell isChildRow={isQuestionRow} />
              <DataTableBodyCell isChildRow={isQuestionRow} />
              <DataTableBodyCell isChildRow={isQuestionRow} />
              <DataTableBodyCell isChildRow={!isQuestionRow} style={{ borderBottom: "none" }}>
                <MDTypography>Answers</MDTypography>
              </DataTableBodyCell>
            </TableRow>
          )}
          <TableRow sx={{ background: bgColor }}>
            <DataTableBodyCell>{node.value.queNo}</DataTableBodyCell>
            <DataTableBodyCell
              isChildRow={!isQuestionRow}
              style={{ borderTop: isUserCreator ? "1px solid #E0E6F5" : "none" }}
            >
              {node.value.type}
            </DataTableBodyCell>
            <DataTableBodyCell
              isChildRow={!isQuestionRow}
              style={{ borderBottom: "1px solid #E0E6F5" }}
            >
              <MDBox sx={{ display: "flex", alignItems: "flex-start" }}>
                {node.children.length > 0 && (
                  <IconButton sx={{ paddingTop: 0, marginTop: 0 }} onClick={() => toggleNode(node)}>
                    {node.expanded ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                )}
                <MDBox sx={{ display: "flex", alignItems: "center", flex: 1 }}>
                  {node.value.questionAnswer}
                </MDBox>
              </MDBox>
            </DataTableBodyCell>
            <DataTableBodyCell>{node.value.answer}</DataTableBodyCell>
            <DataTableBodyCell>{node.value.isReuired}</DataTableBodyCell>
            <DataTableBodyCell
              isChildRow={isQuestionRow}
              style={{ borderBottom: "1px solid #E0E6F5" }}
            >
              {node.value.progress}
            </DataTableBodyCell>
            <DataTableBodyCell isChildRow={!isQuestionRow} style={{ border: "1px solid #E0E6F5" }}>
              {node.value.action}
            </DataTableBodyCell>
          </TableRow>
          {node.expanded && node.children.length > 0 && treeTable(node)}
        </Fragment>
      );
    });

  return (
    <MDBox
      sx={{
        backgroundColor: "White",
        borderRadius: "10px",
        border: "1px solid #E0E6F5",
      }}
    >
      <TableContainer sx={{ boxShadow: "none" }}>
        {entriesPerPage.entries || canSearch ? (
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
            backgroundColor={backgroundColor}
            color={textColor}
          >
            {entriesPerPage && (
              <MDBox display="flex" alignItems="center">
                <Autocomplete
                  disableClearable
                  value={pageSize.toString()}
                  options={entries}
                  onChange={(event, newValue) => {
                    setEntriesPerPage(parseInt(newValue, 10));
                  }}
                  size="small"
                  sx={{ width: "5rem" }}
                  renderInput={(params) => <MDInput {...params} />}
                />
                <MDTypography variant="caption" color="secondary">
                  &nbsp;&nbsp;entries per page
                </MDTypography>
              </MDBox>
            )}
            {canSearch && (
              <MDBox width="12rem" ml="auto">
                <MDInput
                  placeholder="Search..."
                  value={search}
                  size="small"
                  fullWidth
                  onChange={({ currentTarget }) => {
                    setSearch(search);
                    onSearchChange(currentTarget.value);
                  }}
                />
              </MDBox>
            )}
          </MDBox>
        ) : null}
        <Table {...getTableProps()}>
          <MDBox component="thead">
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <DataTableHeadCell
                    {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                    width={column.width ? column.width : "auto"}
                    align={column.align ? column.align : "left"}
                    sorted={setSortedValue(column)}
                    backgroundColor={backgroundColor}
                    textColor={textColor}
                  >
                    {column.render("Header")}
                  </DataTableHeadCell>
                ))}
              </TableRow>
            ))}
          </MDBox>
          <TableBody {...getTableBodyProps()}>
            {(() => {
              switch (status) {
                case Constants.PENDING:
                  return (
                    <TableRow>
                      <TableCell colSpan={columns.length} align="center">
                        <MDBox py={5} display="flex" justifyContent="center" alignItems="center">
                          {Icons.LOADING2}
                        </MDBox>
                      </TableCell>
                    </TableRow>
                  );

                case Constants.FULFILLED:
                  if (!questionTree.value) {
                    return (
                      <TableRow>
                        <TableCell colSpan={columns.length} align="center">
                          <MDTypography variant="h4" color="secondary">
                            {Constants.SOMETHING_WENT_WRONG}
                          </MDTypography>
                        </TableCell>
                      </TableRow>
                    );
                  }
                  return treeTable(questionTree);

                case "noData":
                  return (
                    <TableRow>
                      <TableCell colSpan={columns.length} align="center">
                        <MDTypography variant="h4" color="secondary">
                          {Constants.NO_DATA_FOUND}
                        </MDTypography>
                      </TableCell>
                    </TableRow>
                  );

                case Constants.REJECTED:
                default:
                  return (
                    <TableRow>
                      <TableCell colSpan={columns.length} align="center">
                        <MDTypography variant="h4" color="secondary">
                          {Constants.SOMETHING_WENT_WRONG}
                        </MDTypography>
                      </TableCell>
                    </TableRow>
                  );
              }
            })()}
          </TableBody>
        </Table>
      </TableContainer>
      {status === Constants.FULFILLED && page.length > 0 && pageOptions.length > 1 && (
        <MDBox
          sx={{ color: "#f6f7ff" }}
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent="center"
          alignItems={{ xs: "flex-start", sm: "center" }}
          p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
        >
          <Pagination
            count={pageOptions.length}
            page={pageIndex + 1}
            onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
            sx={{
              "& .Mui-selected:hover": {
                backgroundColor: "#f6f7ff",
              },
              "& .Mui-selected": {
                backgroundColor: "#e0e1f5",
              },
              ".MuiPaginationItem-root": {
                borderRadius: "50%",
                border: "none",
              },
            }}
          />
        </MDBox>
      )}
      {extraContent}
    </MDBox>
  );
}

// Setting default values for the props of DataTable
WebReportsTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
  currentPage: 0,
  loading: Constants.PENDING,
  licenseRequired: false,
  handleTablePagination: () => {},
  handleCurrentPage: () => {},
  backgroundColor: "", // Add a default value for backgroundColor
  textColor: "",
  extraContent: null,
};

// Typechecking props for the DataTable
WebReportsTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  loading: PropTypes.string,
  licenseRequired: PropTypes.bool,
  handleTablePagination: PropTypes.func,
  currentPage: PropTypes.number,
  handleCurrentPage: PropTypes.func,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  extraContent: PropTypes.node,
};

export default WebReportsTable;
