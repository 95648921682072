import React, { useEffect, useState } from "react";

// Custom Components
import Author from "components/Table/Author";
import By from "components/Table/By";

// Constants
import Constants, { defaultData } from "utils/Constants";

// 3rd party library
import moment from "moment";
import Status from "components/Table/Status";

const StatusUpdateData = (statusUpdateList) => {
  const [rows, setRows] = useState([]);
  useEffect(() => {
    if (statusUpdateList) {
      const dataList = statusUpdateList?.map((item, index) => ({
        no: <Author name={index + 1} />,
        updatedBy: (
          <By
            name={
              `${
                (item?.user?.callingName ? item?.user?.callingName : item?.user?.firstName) ?? ""
              } ${item?.user?.lastName ?? ""}`.trim() || Constants.NA
            }
            when={moment(item?.time).format(defaultData.WEB_24_HOURS_FORMAT)}
          />
        ),
        name: (
          <Author
            name={
              `${
                (item?.user?.callingName ? item?.user?.callingName : item?.user?.firstName) ?? ""
              } ${item?.user?.lastName ?? ""}`.trim() || Constants.NA
            }
          />
        ),
        version: <Author name={item?.version || Constants.NA} />,
        status: <Status title={`${item?.status.replace("_", " ")}`} />,
        reason: <Author name={item?.action} maxContent={defaultData.MEDIUM_CONTENT_LENGTH_2} />,
      }));

      setRows(dataList);
    }
  }, [statusUpdateList]);

  return {
    columns: [
      { Header: "No.", accessor: "no", width: "7%" },
      { Header: "Updated By", accessor: "updatedBy", align: "left" },
      { Header: "Version", accessor: "version", align: "left" },
      { Header: "Status", accessor: "status", align: "left" },
      { Header: "Reason", accessor: "reason", align: "left" },
    ],
    rows,
  };
};

export default StatusUpdateData;
