import React, { useState } from "react";

// Common Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import pxToRem from "assets/theme/functions/pxToRem";
import FDropdown2 from "components/Dropdown/fDropdown2";
import FontComponent from "components/Responsive/fonts";
import ReportCardLayout from "layouts/report/reportCardLayout";
import CardBreakPoint from "components/Responsive/BreakPoints";
import BasicModal from "examples/modal/BasicModal/BasicModal2";
import ReportEditModal from "layouts/report/reportAnswerUpdate";
import ViewReportAnswer from "examples/Drawers/Report/ViewReportAnswer";
import UnAssignReportModal from "examples/modal/deleteModal/deleteModal";

// Table data
import DataTable from "examples/Tables/DataTable";
import reportDatailsData from "layouts/report/data/reportDetailsData";
import ReportDataTable from "examples/Tables/DataTable/ReportTable";
import InActiveReportDataTable from "layouts/report/data/inActiveReportData";

// Redux
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbar } from "redux/Slice/Notification";
import { getReportById, unAssignAnswerThunk, assignAnswerThunk } from "redux/Thunks/Report";
import { reloadReportDetails, unassignReportAnswer, reloadActiveReport } from "redux/Slice/Report";

// Utils
import Constants, { ModalContent, defaultData, ButtonTitles } from "utils/Constants";

const initialAssignReportData = {
  openAssignmodal: false,
  answerTitles: [],
  answerData: {},
  reportQuestionTitle: "",
  questionId: "",
  userReportAnswerId: "",
  oldReportAnswerId: "",
  newReportAnswerTitleId: "",
};

function ReportDetail() {
  const { reportDetails, reportDetailsLoading, inActiveReport, activeReportLoading } = useSelector(
    (state) => state.report
  );
  const ConfigData = useSelector((state) => state.config);
  const permission = ConfigData?.screens?.[6]?.screensInfo?.agreement;
  const customFontSize = FontComponent({ sizes: CardBreakPoint.baseTitleBreakPoint });
  const [unAssignReportAnswer, setUnAssignReportAnswer] = useState({
    open: false,
    id: "",
    answerId: "",
  });
  const [reportEdit, setReportEdit] = useState({
    open: false,
    questionTitle: "",
    answerType: "",
    answerData: {},
  });
  const [viewReportAnswerAnchor, setViewReportAnswerAnchor] = useState({ right: false });
  const [buttonLoading, setButtonLoading] = useState(false);
  const [assignReportData, setAssignReportData] = useState(initialAssignReportData);
  const [reportQuestionAnswer, setReportQuestionAnswer] = useState({
    question: "",
    questionNo: "",
    answer: [],
    supportedContent: [],
  });

  const params = useParams();
  const dispatch = useDispatch();

  const handleView = async (reportAnswer, questionIndex) => {
    setViewReportAnswerAnchor({ right: true });
    setReportQuestionAnswer({
      question: reportAnswer?.title,
      questionNo: questionIndex,
      answer: reportAnswer?.answerTypes,
      supportedContent: reportAnswer?.supportedContent,
    });
  };

  const handleOpenUnAssignModal = (id, ansId, answerTitleId, reportId) => {
    setUnAssignReportAnswer({
      open: true,
      id,
      answerId: ansId,
      answerTitleId,
      reportId,
    });
  };

  const handleUnAssignReportAnswer = async () => {
    const body = {
      id: unAssignReportAnswer.id,
      answerId: unAssignReportAnswer.answerId,
      answerTitleId: unAssignReportAnswer.answerTitleId,
      userReport: unAssignReportAnswer.reportId,
    };
    const res = await dispatch(unAssignAnswerThunk(body));
    if (res.payload.status === 200) {
      await dispatch(
        unassignReportAnswer({
          userId: unAssignReportAnswer.id,
          answerId: unAssignReportAnswer.answerId,
          answerTitleId: unAssignReportAnswer.answerTitleId,
        })
      );
      await dispatch(
        openSnackbar({
          message: Constants.REPORT_ANSWER_UNASSIGNED_SUCCESS,
          notificationType: Constants.NOTIFICATION_SUCCESS,
        })
      );
      setUnAssignReportAnswer({ id: "", answerId: "", answerTitleId: "", open: false });
    } else if (res.payload.status === 400) {
      await dispatch(
        openSnackbar({
          message: Constants.REPORT_UNASWER_ERROR,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
      setUnAssignReportAnswer({ id: "", answerId: "", answerTitleId: "", open: false });
    } else {
      await dispatch(
        openSnackbar({
          message: Constants.SOMETHING_WENT_WRONG,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
      setUnAssignReportAnswer({ id: "", answerId: "", answerTitleId: "", open: false });
    }
  };

  const handleAnswerEdit = (reportAnswer, answerType, answer, user, answerTitle) => {
    setReportEdit({
      open: true,
      questionTitle: reportAnswer.title,
      answerType: answerType?.parameterType?.uniqueKey,
      answerData: {
        answerTitleId: answer?.answerTitleId,
        answerId: user[Constants.MONGOOSE_ID],
        reportAnswer: answer.answer,
        userName: user.createdBy,
        answerTitle,
        reportAnswerData:
          answerType?.parameterType?.uniqueKey === "range" ? answerType.range : answerType.option,
      },
    });
  };

  const handleReload = async () => {
    await dispatch(reloadReportDetails());
    await dispatch(reloadActiveReport());
    await dispatch(getReportById(params?.id));
  };

  const { reportColumns, reportRows } = reportDatailsData(
    reportDetails?.[0]?.reportQuestions,
    handleView,
    handleOpenUnAssignModal,
    handleAnswerEdit,
    permission,
    reportDetails?.[0]?.[Constants.MONGOOSE_ID]
  );

  const handleOpenAssignModal = (
    answerTitles,
    answerData,
    reportQuestionTitle,
    userReportAnswerId,
    questionId
  ) => {
    const activeTitles = answerTitles.reduce((result, a) => {
      if (a.isActive) {
        result.push({
          [Constants.MONGOOSE_ID]: a[Constants.MONGOOSE_ID],
          title: a.value,
        });
      }
      return result;
    }, []);

    const isCurrentAnswerActive = activeTitles.find((a) => a.title === reportQuestionTitle);
    setAssignReportData({
      openAssignmodal: true,
      answerTitles: activeTitles,
      answerData,
      reportQuestionTitle: isCurrentAnswerActive || "",
      questionId,
      userReportAnswerId,
      oldReportAnswerId: answerData?.answerTitleId,
      newReportAnswerTitleId: isCurrentAnswerActive?.[Constants.MONGOOSE_ID] || "",
    });
  };

  const handleAssignModalClose = () => {
    setButtonLoading(false);
    setAssignReportData(initialAssignReportData);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setAssignReportData({ ...assignReportData, [name]: value });
  };

  const handleAssignReportAnswer = async () => {
    setButtonLoading(true);
    const body = {
      userReportAnswerId: assignReportData.userReportAnswerId,
      submittedAnswerTitleId: assignReportData.oldReportAnswerId,
      userAnswerTitleNewId: assignReportData.newReportAnswerTitleId,
      answer: assignReportData?.answerData?.answer,
      answerId: assignReportData?.answerData[Constants.MONGOOSE_ID],
    };
    const res = await dispatch(assignAnswerThunk(body));
    if (res.payload.status === 200) {
      await dispatch(
        openSnackbar({
          message: Constants.REPORT_ANSWER_ASSIGNED_SUCCESS,
          notificationType: Constants.NOTIFICATION_SUCCESS,
        })
      );
      setButtonLoading(false);
      handleAssignModalClose();
      await dispatch(reloadActiveReport());
      await dispatch(getReportById(params?.id));
    } else if (res.payload.status === 400) {
      await dispatch(
        openSnackbar({
          message: Constants.REPORT_ANSWER_ALREADY_EXISTS,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
      setButtonLoading(false);
      handleAssignModalClose();
    } else {
      await dispatch(
        openSnackbar({
          message: Constants.SOMETHING_WENT_WRONG,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
      setButtonLoading(false);
      handleAssignModalClose();
    }
  };
  const { inActiveReportCoumns, inActiveReportRows } = InActiveReportDataTable(
    inActiveReport[0]?.reportQuestions,
    reportDetails?.[0]?.[Constants.MONGOOSE_ID],
    handleOpenAssignModal
  );

  return (
    <>
      <ReportCardLayout handleReload={handleReload}>
        <MDBox mt={4} sx={{ paddingBottom: pxToRem(20) }}>
          <MDTypography
            variant="h6"
            fontWeight="medium"
            sx={{ fontSize: customFontSize, marginBottom: 2, marginLeft: 2 }}
          >
            Active Submission
          </MDTypography>
          <ReportDataTable
            table={{ columns: reportColumns, rows: reportRows }}
            loading={activeReportLoading}
            entriesPerPage={{ defaultValue: defaultData.PER_PAGE_4 }}
            showTotalEntries={false}
            isSorted={false}
            licenseRequired
          />
        </MDBox>
        <MDBox mt={4} sx={{ paddingBottom: pxToRem(20) }}>
          <MDTypography
            variant="h6"
            fontWeight="medium"
            sx={{ fontSize: customFontSize, marginBottom: 2, marginLeft: 2 }}
          >
            InActive Submission
          </MDTypography>
          <DataTable
            table={{ columns: inActiveReportCoumns, rows: inActiveReportRows }}
            isSorted={false}
            entriesPerPage={{ defaultValue: defaultData.PER_PAGE_4 }}
            showTotalEntries={false}
            noEndBorder
            loading={reportDetailsLoading}
            licenseRequired
          />
        </MDBox>
      </ReportCardLayout>

      {/* UnAssign Modal for Report Answer */}
      <UnAssignReportModal
        open={unAssignReportAnswer.open}
        title={ModalContent.UNASSIGN_REPORT_ANSWER}
        message={ModalContent.UNASSIGN_REPORT_ANSWER_MESSAGE}
        handleClose={() => {
          setUnAssignReportAnswer({ ...unAssignReportAnswer, open: false });
        }}
        handleDelete={handleUnAssignReportAnswer}
        actionButton
      />

      {/* Report Assign Modal for Report Answer */}
      <BasicModal
        title="Assign Report Answer"
        open={assignReportData.openAssignmodal}
        handleClose={handleAssignModalClose}
        handleAction={handleAssignReportAnswer}
        disabled={!assignReportData.newReportAnswerTitleId || buttonLoading}
        actionButton={buttonLoading === false ? "Assign" : ButtonTitles.LOADING}
        py={0}
      >
        <FDropdown2
          label="Select Report Answer title*"
          id="newReportAnswerTitleId"
          name="newReportAnswerTitleId"
          value={assignReportData.newReportAnswerTitleId}
          options={assignReportData.answerTitles || []}
          handleChange={handleChange}
          marginBottom={pxToRem(10)}
        />
      </BasicModal>

      {/* Report Edit Modal */}
      <ReportEditModal reportEdit={reportEdit} setReportEdit={setReportEdit} />

      <ViewReportAnswer
        viewReportAnswerAnchor={viewReportAnswerAnchor}
        setViewReportAnswerAnchor={setViewReportAnswerAnchor}
        reportAnswer={reportQuestionAnswer}
      />
    </>
  );
}

export default ReportDetail;
