import React, { useEffect, useState } from "react";

// Material component
import { Card, Divider } from "@mui/material";
import MDTypography from "components/MDTypography";

// Custom Component
import ConfirmationModal from "examples/modal/Confirmation/Confirmation";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import BasicButton from "examples/NewDesign/CustomButton/BasicButton";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FilterDropdown from "components/Dropdown/FilterDropdown";
import ResetFilterButton from "components/Buttons/ResetButton";
import SearchBar from "components/Search/SearchInTable";
import PageTitle from "examples/NewDesign/PageTitle";
import pxToRem from "assets/theme/functions/pxToRem";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";

// Methods
import { paramCreater } from "utils/methods/methods";

// Redux
import getReturnEquipment, {
  addToReturnCart,
  projectApproverList,
} from "redux/Thunks/ReturnEquipment";
import { openSnackbar } from "redux/Slice/Notification";
import { useDispatch, useSelector } from "react-redux";
import { reloadData, updateCheckBox } from "redux/Slice/OrderEquipment";

// Data
import ProjectEquipmentData from "layouts/projectEquipment/data/projectEquipmentData";
import DataTable from "examples/Tables/DataTable";

// Feature Flag
import { Feature } from "flagged";

// Constant
import Constants, {
  Icons,
  PageTitles,
  Colors,
  FeatureTags,
  defaultData,
  ButtonTitles,
  ModalContent,
} from "utils/Constants";

function ProjectEquipment() {
  const [filters, setFilters] = useState([
    {
      inputLabel: "Project",
      list: [{ [Constants.MONGOOSE_ID]: "select", title: "Select" }],
      selectedValue: "select",
    },
    {
      inputLabel: "Search",
      list: [{ [Constants.MONGOOSE_ID]: "All", title: "All" }],
      selectedValue: "All",
      isLoading: false,
    },
    {
      inputLabel: "Price Type",
      list: [
        { [Constants.MONGOOSE_ID]: "select", title: "Select" },
        { [Constants.MONGOOSE_ID]: "all", title: "All" },
        { [Constants.MONGOOSE_ID]: "rental", title: "Rental" },
        { [Constants.MONGOOSE_ID]: "buy", title: "Buy" },
      ],
      selectedValue: "select",
    },
  ]);
  const [tablePagination, setTablePagination] = useState({
    page: 0,
    perPage: defaultData.DATE_ON_SINGLE_API_CALL,
  });
  const [checkAll, setCheckAll] = useState(true);
  const [addToCartModal, setAddToCartModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [next, setNext] = useState(0);
  const dispatch = useDispatch();
  const returnEquipmentData = useSelector((state) => state?.returnEquipment);
  let debounceTimeout;
  const handleFilter = async (temp = filters) => {
    setTablePagination({ ...tablePagination, page: 0 });
    setNext(0);
    const paramData = {
      page: 0,
      perPage: tablePagination.perPage,
      project: temp[0].selectedValue,
      ...(temp[2].selectedValue !== "select" &&
        temp[2].selectedValue !== "all" && { priceType: temp[2].selectedValue }),
    };
    if (temp[1]?.selectedValue !== "All" && temp[1]?.selectedValue !== null) {
      paramData.search = temp[1].selectedValue;
    }
    if (filters[0]?.selectedValue !== "select") {
      await dispatch(reloadData());
      await dispatch(getReturnEquipment(paramCreater(paramData)));
    }
  };

  useEffect(() => {
    handleFilter(filters);
    (async () => {
      const tempProjectList = await dispatch(projectApproverList());

      const temp = [...filters];

      temp[0].list = [
        { [Constants.MONGOOSE_ID]: "select", title: "Select" },
        ...tempProjectList.payload.data.data,
      ];

      setFilters(temp);
    })();
  }, []);

  const handleFilterType = async (e) => {
    const temp = [...filters];
    const { value, name } = e.target;
    if (value) {
      const i = temp.findIndex((item) => item.inputLabel === e.target.name);
      if (name === "Project" && temp[i].selectedValue === "select") {
        const priceTypeIndex = temp.findIndex((item) => item.inputLabel === "Price Type");
        temp[priceTypeIndex].selectedValue = "all";
      }
      temp[i].selectedValue = value;
      setFilters(temp);
      await handleFilter(temp);
    }
  };

  const handleReset = async () => {
    const resetFilters = filters.map((filter) => ({
      ...filter,
      selectedValue: filter.list[0][Constants.MONGOOSE_ID] || filter.list[0],
    }));

    // Remove the search filter from the resetFilters array
    const updatedFilters = resetFilters.map((filter) => {
      if (filter.inputLabel === "Search") {
        return {
          ...filter,
          selectedValue: "",
        };
      }
      return filter;
    });

    setFilters(updatedFilters);
  };

  const handleTablePagination = async () => {
    const data = {
      page: next + 1,
      perPage: tablePagination.perPage,
      project: filters[0].selectedValue,
      ...(filters[2].selectedValue !== "select" &&
        filters[2].selectedValue !== "all" && { priceType: filters[2].selectedValue }),
    };
    const res = await dispatch(getReturnEquipment(paramCreater(data)));
    if (res.payload.status === 200) setNext(res.payload.data.data?.length > 0 ? next + 1 : next);
  };
  const handleReload = async () => {
    await dispatch(reloadData());
    handleFilter();
  };

  const handleSearch = async (searchValue = filters) => {
    setFilters((prev) => {
      const temp = [...prev];
      temp[1].isLoading = true;
      return temp;
    });

    setTablePagination({ ...tablePagination, page: 0 });

    const data = {
      page: 0,
      perPage: tablePagination.perPage,
      project: searchValue[0].selectedValue,
      ...(searchValue[2].selectedValue !== "select" &&
        searchValue[2].selectedValue !== "all" && { priceType: searchValue[2].selectedValue }),
      search: searchValue[1].selectedValue.trim(),
    };
    await dispatch(getReturnEquipment(paramCreater(data)));
    const temp = [...filters];
    temp[1].isLoading = false;
    setFilters(temp);
  };
  const debouncedHandleSearch = (e) => {
    clearTimeout(debounceTimeout);
    setTablePagination({ ...tablePagination, page: 0 });
    setNext(0);
    const temp = [...filters];
    temp[1].selectedValue = e.target.value.trim();
    debounceTimeout = setTimeout(async () => handleSearch(temp), 300);
  };

  const handleChange = async (name, e, indexId) => {
    await dispatch(updateCheckBox({ name, target: e.target.checked, returnIndex: indexId }));
  };

  const handleCheckAll = async () => {
    setCheckAll((prevCheckAll) => !prevCheckAll);
    await dispatch(updateCheckBox({ name: "checkAllRows", target: checkAll }));
  };
  const handleOpenCart = () => {
    setAddToCartModal(true); //
  };
  const handleCloseCart = () => {
    setAddToCartModal(false);
  };
  const handleAddtoCart = async () => {
    const cartBody = returnEquipmentData?.list
      ?.filter((elements) => elements?.check)
      ?.map((item) => ({
        projectId: item[Constants.MONGOOSE_ID]?.projectId,
        equipmentId: item[Constants.MONGOOSE_ID]?.equipmentId,
        equipmentHistoryIds: item?.equipmentHistoryIds,
        pmReceivedQuantity: item?.pmReceivedQuantity,
        pmDispatchQuantity: item?.pmDispatchQuantity,
      }));
    setLoading(true);
    const res = await dispatch(addToReturnCart(cartBody));
    if (res.payload.status === 200) {
      handleFilter();
      dispatch(
        openSnackbar({
          message: Constants.ADD_RETURN_CART_SUCCESS,
          notificationType: Constants.NOTIFICATION_SUCCESS,
        })
      );
      setAddToCartModal(false);
    } else {
      dispatch(
        openSnackbar({
          message: Constants.SOMETHING_WENT_WRONG,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
      setAddToCartModal(false);
    }
    setLoading(false);
  };
  const { columns, rows } = ProjectEquipmentData(returnEquipmentData?.list, handleChange);
  return (
    <DashboardLayout module={defaultData.APPROVER_SCREEN_ID}>
      <DashboardNavbar />
      <MDBox
        display="flex"
        flexDirection={{ md: "row", sm: "column" }}
        justifyContent={{ md: "space-between" }}
        alignItems={{ lg: "space-between", sm: "center" }}
      >
        <PageTitle title={PageTitles.PROJECT_EQUIPMENT} />
        <BasicButton
          icon={Icons.RELOAD}
          background={Colors.WHITE}
          border
          color={Colors.BLACK}
          action={handleReload}
        />
      </MDBox>
      <Divider sx={{ marginTop: pxToRem(22) }} />
      <Feature name={FeatureTags.PROJECT_EQUIPMENT}>
        <MDBox display="flex" flexWrap="wrap" flexDirection="row" mx={0}>
          <MDBox
            display="flex"
            alignItems="start"
            flexWrap="wrap"
            justifyContent="space-between"
            sx={{ flexDirection: "row", mr: 2 }}
            style={{ width: "100%" }}
          >
            <MDBox display="flex">
              <SearchBar
                freeSolos
                options={filters[1]?.list.map((val) => val.title) || []}
                filters={filters}
                placeholder="Search"
                value={filters[1].selectedValue}
                disabled={filters[0].selectedValue === "select"}
                debouncedHandleSearch={debouncedHandleSearch}
                isLoading={filters[1].isLoading}
              />
              {filters
                ?.filter((val) => val.inputLabel !== "Search")
                ?.map((val) => (
                  <FilterDropdown
                    key={val.inputLabel}
                    disabled={
                      filters[0].selectedValue === "select" && val.inputLabel === "Price Type"
                    }
                    label={val.inputLabel}
                    name={val.inputLabel}
                    defaultValue={val?.selectedValue}
                    value={val?.selectedValue}
                    handleChange={handleFilterType}
                    menu={val.list}
                    style={{ marginLeft: pxToRem(1) }}
                    maxContent={defaultData.MEDIUM_CONTENT_LENGTH}
                  />
                ))}
              <ResetFilterButton
                style={{ marginLeft: "1rem" }}
                disabled={filters[0].selectedValue === "select"}
                handleReset={handleReset}
              />
            </MDBox>
            <MDBox display="flex" flexDirection="row" mt={5.5}>
              <MDButton
                color="info"
                disabled={filters[0].selectedValue === "select"}
                style={{ textTransform: "none", boxShadow: "none", marginRight: "10px" }}
                onClick={() => handleCheckAll()}
              >
                Check All
              </MDButton>
              <BasicButton
                title={ButtonTitles.RETURN_CART}
                icon={Icons.CHEKOUT}
                disabled={!returnEquipmentData?.list?.some((item) => item.check)}
                background={Colors.ERROR}
                border
                color={Colors.WHITE}
                action={handleOpenCart}
              />
            </MDBox>
          </MDBox>
        </MDBox>
        <MDBox mt={3} mb={3}>
          {filters[0]?.selectedValue !== "select" ? (
            <DataTable
              table={{ columns, rows }}
              isSorted={false}
              entriesPerPage={{ defaultValue: defaultData.PER_PAGE }}
              showTotalEntries={false}
              noEndBorder
              loading={returnEquipmentData.loading}
              licenseRequired
              currentPage={tablePagination.page}
              handleTablePagination={handleTablePagination}
              handleCurrentPage={(page) => setTablePagination({ ...tablePagination, page })}
            />
          ) : (
            <Card sx={{ height: "70vh", marginTop: 3 }}>
              <MDBox display="flex" justifyContent="center" alignItems="center" marginTop="15%">
                <MDTypography sx={{ fontSize: "25px", fontWeight: "semibold", opacity: "0.8" }}>
                  Please Select Project
                </MDTypography>
              </MDBox>
            </Card>
          )}
        </MDBox>
        <ConfirmationModal
          open={addToCartModal}
          disabled={loading}
          handleClose={handleCloseCart}
          title={ModalContent.RETURN_CART_TITLE}
          handleAction={handleAddtoCart}
        />
      </Feature>
    </DashboardLayout>
  );
}

export default ProjectEquipment;
