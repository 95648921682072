import React, { useEffect, useState } from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import { Badge, IconButton } from "@mui/material";
import Constants, { Icons } from "utils/Constants";

// Custom Components
import Author from "components/Table/Author";
import Status from "components/Table/Status";

// assets
import pxToRem from "assets/theme/functions/pxToRem";

// 3rd party library
import moment from "moment";

export default function WmOrderDetailsData(
  order,
  handleCommentsOpen,
  handleEditOpen,
  handleRejectSingleEquipmentOpen,
  permission
) {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (permission && order.orderDetailsLoading === Constants.FULFILLED) {
      const tempRows = order?.orderDetails?.equipmentTypeData.map((element, index) => {
        const fromDate = moment(element?.fromPeriod);
        const toDate = moment(element?.toPeriod);
        const duration = moment.duration(toDate.diff(fromDate));
        const days = element?.toPeriod ? duration.asDays() + 1 : Constants.NA;

        let totalAmount = 0;
        if (element?.quantityPriceType === "rental") {
          totalAmount = Number(element?.wmApprovedQuantity) * Number(days) * Number(element?.price);
        } else if (element?.quantityPriceType === "buy" && element?.status === "requested") {
          totalAmount = element?.totalPrice;
        } else {
          totalAmount = Number(element?.price) * Number(element?.wmApprovedQuantity);
        }
        const temp = {
          collapseBtn: (
            <Author
              name={{
                pmOrderManageId: element.pmOrderManageId,
                list: element.linkedEquipment,
              }}
            />
          ),
          srNo: <Author name={index + 1} />,
          equipmentType: (
            <MDBox display="flex" justifyContent="center" alignItems="center">
              <img
                src={
                  element?.equipmentTypeImage?.url
                    ? element?.equipmentTypeImage?.url
                    : process.env.REACT_APP_IMAGE_NOT_FOUND
                }
                alt={element?.equipmentTypeImage?.name}
                key={element?.equipmentTypeImage?.name}
                style={{
                  width: pxToRem(50),
                  height: pxToRem(50),
                  marginRight: pxToRem(10),
                  borderRadius: "8px",
                }}
              />{" "}
              <Author name={element?.equipmentType} />
            </MDBox>
          ),
          reqQty: <Author name={element?.pmRequestedQuantity} />,
          appQty: <Author name={element?.wmApprovedQuantity} />,
          equipmentCategory: <Author name={element?.equipmentCategory} />,
          quantityType: <Author name={element?.quantityPriceType} />,
          rentdays: <Author name={days} />,
          price: <Author name={`${element.currencyDetail.symbol} ${element?.price}`} />,
          amount: <Author name={`${element.currencyDetail.symbol} ${totalAmount}`} />,
          status: <Status title={element?.status.replace("-", " ")} />,
          action: (
            <MDBox style={{ width: "max-content" }}>
              {permission?.update && (
                <IconButton
                  aria-label="fingerprint"
                  color="error"
                  onClick={() =>
                    handleCommentsOpen("wm", element.pmOrderManageId, element.comments)
                  }
                >
                  <Badge
                    badgeContent={
                      (element.comments.wmComments?.length || 0) +
                      (element.comments.pmComments?.length || 0)
                    }
                    color="error"
                    max={9}
                    sx={{ "& .MuiBadge-badge": { fontSize: 10, height: 15, minWidth: 15 } }}
                  >
                    {Icons.ADD_COMMENT}
                  </Badge>
                </IconButton>
              )}
              {element.status === "requested" && permission?.update && (
                <IconButton
                  aria-label="fingerprint"
                  color="info"
                  onClick={() => handleEditOpen(element)}
                >
                  {Icons.EDIT2}
                </IconButton>
              )}
              {["requested"].includes(element?.status) && permission?.delete && (
                <IconButton
                  aria-label="fingerprint"
                  color="error"
                  onClick={() => handleRejectSingleEquipmentOpen(element?.pmOrderManageId, element)}
                >
                  {Icons.REJECTED}
                </IconButton>
              )}
              {[
                "approved",
                "partially-pre-transit",
                "partially-in-transit",
                "partially-check-in",
              ].includes(element?.status) &&
                permission?.delete &&
                element.wmApprovedQuantity > element.linkedEquipment?.length && (
                  <IconButton
                    aria-label="fingerprint"
                    color="error"
                    onClick={() =>
                      handleRejectSingleEquipmentOpen(
                        element?.pmOrderManageId,
                        element.linkedEquipment?.length > 0
                      )
                    }
                  >
                    {Icons.REJECTED}
                  </IconButton>
                )}
            </MDBox>
          ),
        };
        return temp;
      });
      setRows([...tempRows]);
    }
  }, [order, permission]);
  return {
    columns: [
      ...(order.orderDetails.status !== "requested"
        ? [{ Header: "", accessor: "collapseBtn", width: "5%" }]
        : []),
      { Header: "No.", accessor: "srNo", width: "5%" },
      {
        Header: "Equipment Type",
        accessor: "equipmentType",
        width: "10%",
        align: "left",
      },
      { Header: "Equipment Category", accessor: "equipmentCategory", align: "left" },
      { Header: "Quantity Type", accessor: "quantityType", align: "left" },
      { Header: "Requested Qty", accessor: "reqQty", align: "center" },
      { Header: "Approved Qty", accessor: "appQty", align: "center" },
      { Header: "Expected Rental days", accessor: "rentdays", align: "center" },
      { Header: "Price Per Equipment", accessor: "price", align: "center" },
      { Header: "Total Amount", accessor: "amount", align: "center" },
      { Header: "Status", accessor: "status", align: "center" },
      {
        Header: "Action",
        accessor: "action",
        width: "10%",
        align: "right",
      },
    ],
    rows,
  };
}
