const ShiftFormat = {
  _id: "",
  startDate: "",
  endDate: "",
  duration: "",
  status: "",
  createdAt: "",
  createdBy: {
    _id: "",
    callingName: "",
    firstName: "",
    lastName: "",
    role: [
      {
        _id: "",
        title: "",
      },
    ],
  },
  updatedBy: {
    _id: "",
    callingName: "",
    firstName: "",
    lastName: "",
    role: [
      {
        _id: "",
        title: "",
      },
    ],
  },
  teammembers: [
    {
      _id: "",
      member: "",
      function: "",
      shift: "",
      members: {
        _id: "",
        project: "",
        user: "",
        users: {
          fullName: "",
        },
        functions: {
          functionName: "",
        },
      },
    },
  ],
  projects: [
    {
      _id: "",
      title: "",
      defaultIdentifier: "",
      isActive: true,
    },
  ],
  defaultProject: null,
  teams: [
    {
      _id: "",
      teamsWfmName: "",
    },
  ],
  locations: [],
  shiftActivitiesCount: 0,
};

export default ShiftFormat;
