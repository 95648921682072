// Functions
import pxToRem from "assets/theme/functions/pxToRem";
import { TitleBreakPoint } from "components/Responsive/BreakPoints";
import FontComponent from "components/Responsive/fonts";

// 3rd party
import PropTypes from "prop-types";

// Utils
import { Colors } from "utils/Constants";

// Custom Components
const { default: MDBox } = require("components/MDBox");
const { default: MDInput } = require("components/MDInput");
const { default: MDTypography } = require("components/MDTypography");

// Form Text field
export default function FTextField({
  label,
  name,
  id,
  placeholder,
  type,
  error,
  helperText,
  value,
  defaultValue,
  handleChange,
  width,
  marginBottom,
  disabled,
  onKeyPress,
  InputProps = {},
  defaultRows = 3,
  isMarginTopRequired = true,
}) {
  const fontSize = FontComponent({ sizes: {} });

  return (
    <MDBox
      sx={{
        mt: isMarginTopRequired && pxToRem(8),
        display: "flex",
        flexDirection: "column",
        width,
      }}
    >
      <MDTypography
        variant="body"
        sx={{ fontSize, fontWeight: 500, color: "#344054", lineHeight: "20px" }}
      >
        {label}
      </MDTypography>
      <MDInput
        sx={{
          backgroundColor: Colors.WHITE,
          mt: isMarginTopRequired && pxToRem(6),
          "& .MuiInputBase-input": {
            fontSize: FontComponent({ sizes: TitleBreakPoint.baseTitleBreakPoint }),
          },
          marginBottom,
        }}
        type={type}
        {...(type === "textarea" && { multiline: true, rows: defaultRows })}
        id={id}
        name={name}
        error={error}
        helperText={helperText}
        FormHelperTextProps={{
          sx: { marginLeft: 0, color: "red" },
        }}
        onKeyPress={onKeyPress}
        {...(value && { value })}
        disabled={disabled}
        defaultValue={defaultValue}
        placeholder={placeholder}
        onChange={handleChange}
        // to allow scroll fucnctionality in textarea when field is disabled.
        onFocus={(e) => {
          if (disabled) {
            e.target.blur();
          }
        }}
        InputProps={InputProps}
      />
    </MDBox>
  );
}

FTextField.defaultProps = {
  type: "text",
  label: "",
  name: "",
  id: "",
  placeholder: "",
  error: false,
  helperText: "",
  value: "",
  defaultValue: "",
  width: "100%",
  marginBottom: 0,
  disabled: false,
  onKeyPress: () => {},
  InputProps: {},
  defaultRows: 3,
  handleChange: () => {},
  isMarginTopRequired: true,
};

FTextField.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.string,
  width: PropTypes.string,
  marginBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  onKeyPress: PropTypes.func,
  InputProps: PropTypes.objectOf(PropTypes.any),
  defaultRows: PropTypes.number,
  handleChange: PropTypes.func,
  isMarginTopRequired: PropTypes.bool,
};
