/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

import React, { useEffect, useState } from "react";

// Material Common Components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import BasicButton from "examples/NewDesign/CustomButton/BasicButton";
import FilterDropdown from "components/Dropdown/FilterDropdown";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import CustomButton from "examples/NewDesign/CustomButton";
import SearchBar from "components/Search/SearchInTable";
import PageTitle from "examples/NewDesign/PageTitle";
import MDBox from "components/MDBox";

// Modal
import NewUser from "examples/modal/NewUser";

// Table
import Usermanagementdata from "layouts/wfmwizard/System/data/userData";
import DataTable from "examples/Tables/DataTable";

// Redux
import UserListThunk from "redux/Thunks/UserManagement";
import { setUserManagementFilters, resetUserManagementFilters } from "redux/Slice/Filter";
import getProfileFunctions from "redux/Thunks/ProfileFunctions";
import { openSnackbar } from "redux/Slice/Notification";
import { useDispatch, useSelector } from "react-redux";

// Material Library Components
import { Divider } from "@mui/material";
import ResetFilterButton from "components/Buttons/ResetButton";

// Utils
import Constants, {
  Icons,
  defaultData,
  Colors,
  countryList,
  PageTitles,
  FiltersModuleName,
  Common,
} from "utils/Constants";
import { paramCreater } from "utils/methods/methods";
import userMgtRowData from "utils/Types/UserManagementDummyData";

// Style
import pxToRem from "assets/theme/functions/pxToRem";

const userManagementFiltersArr = [
  {
    inputLabel: FiltersModuleName.SEARCH,
    list: [FiltersModuleName.USER_MANAGEMENT_FILTERS_TITLE_OBJ],
    selectedValue: FiltersModuleName.ALL_WITH_FIRST_LETTER_CAPITAL,
    isLoading: false,
  },
  {
    inputLabel: FiltersModuleName.COUNTRY,
    list: [FiltersModuleName.ALL_WITH_FIRST_LETTER_CAPITAL, ...countryList],
    selectedValue: FiltersModuleName.ALL_WITH_FIRST_LETTER_CAPITAL,
  },
  {
    inputLabel: FiltersModuleName.STATUS,
    list: [
      { [Constants.MONGOOSE_ID]: "All", title: "All" },
      { [Constants.MONGOOSE_ID]: "true", title: "Active" },
      { [Constants.MONGOOSE_ID]: "false", title: "InActive" },
    ],
    selectedValue: FiltersModuleName.ALL_WITH_FIRST_LETTER_CAPITAL,
  },
  {
    inputLabel: FiltersModuleName.PROFILE_FUNCTION,
    list: [FiltersModuleName.USER_MANAGEMENT_FILTERS_TITLE_OBJ],
    selectedValue: FiltersModuleName.ALL_WITH_FIRST_LETTER_CAPITAL,
  },
];

function Usermanagement() {
  const dispatch = useDispatch();
  const ConfigDataObj = useSelector((state) => state.config) || {};
  const { config = [] } = ConfigDataObj;
  const permission = ConfigDataObj?.screens[11]?.screensInfo?.agreement;

  const userMgtFilters = useSelector((state) => state.filtersSlice.userManagementFilters) || [];

  // Checking If the store has any previous filters saved.
  const initialFilters = userMgtFilters?.length > 0 ? userMgtFilters : userManagementFiltersArr;

  const [loadingStatus, setLoadingStatus] = useState(Constants.PENDING);
  const [allUserList, setAllUserList] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const [shouldUpdateState, setShouldUpdateState] = useState(false);
  const [searchUpdateKey, setSearchUpdateKey] = useState(0);
  const [paginationThing, setPaginationThing] = useState({ allRecordsCount: 0, completed: [] });
  const [tablePagination, setTablePagination] = useState({
    page: 0,
    perPage: defaultData.PER_PAGE,
  });

  const [open, setOpen] = useState(false);

  let debounceTimeout;
  const { columns, rows } = Usermanagementdata(
    allUserList,
    setShouldUpdateState,
    setLoadingStatus,
    permission
  );

  // Module Functions starts here.
  const getEachFilterValueByIndex = (index) => {
    if (filters[index].selectedValue === FiltersModuleName.ALL_WITH_FIRST_LETTER_CAPITAL) {
      return "";
    }
    return filters[index].selectedValue;
  };

  const handlePrepareFilterParams = () => {
    const params = {
      page: tablePagination.page,
      perPage: tablePagination.perPage,
      nationality: filters[1].selectedValue?.toLowerCase().replace(/ /g, "_"),
      isActive: getEachFilterValueByIndex(2), // Index 2 for isActive.
      profileFunction: getEachFilterValueByIndex(3), // Index 3 for profileFunction.
    };

    if (filters[0].selectedValue !== FiltersModuleName.ALL_WITH_FIRST_LETTER_CAPITAL) {
      params.search = filters[0].selectedValue; // Index 0 for search
    }

    return paramCreater(params);
  };

  const createUserListFunc = (resObj = {}, meta = {}) => {
    const { allRecordsCount = 0, usersData = [] } = resObj;

    // Get pagination info from the meta argument
    const params = new URLSearchParams(meta.arg);
    const page = parseInt(params.get("page"), 10);
    const perPage = parseInt(params.get("perPage"), 10);

    // If allUserList is empty, initialize it with the required format
    const userDataArr =
      page === 0
        ? Array.from({ length: allRecordsCount }, () => ({ ...userMgtRowData }))
        : [...allUserList];

    // Calculate start index for pagination and replace elements in the array
    const startIndex = page * perPage;
    userDataArr.splice(startIndex, perPage, ...usersData);
    const visitedPagesArr = page === 0 ? [page] : [...paginationThing.completed, page];

    setAllUserList(userDataArr);
    setPaginationThing({ allRecordsCount, completed: visitedPagesArr });
  };

  const updateUserFilters = (userArr = []) => {
    if (userArr.length > 0) {
      const updatedUserList = userArr.reduce((acc, user) => {
        if (
          ![defaultData.ADMIN_ROLE, defaultData.SUPER_ADMIN_ROLE].includes(user?.role?.title) &&
          (user.callingName || user.firstName) &&
          user.lastName
        ) {
          acc.push({
            [Constants.MONGOOSE_ID]: user[Constants.MONGOOSE_ID],
            title: `${user.callingName ? user.callingName : user.firstName} ${user.lastName}`,
          });
        }
        return acc;
      }, []);

      setFilters((prev) => {
        const updatedFilters = prev.map((filter) => {
          if (filter.inputLabel === FiltersModuleName.SEARCH) {
            return {
              ...filter,
              list: [FiltersModuleName.USER_MANAGEMENT_FILTERS_TITLE_OBJ, ...updatedUserList],
              isLoading: false,
            };
          }
          return filter;
        });
        dispatch(setUserManagementFilters(updatedFilters));
        return updatedFilters;
      });
    }
  };

  const getAllUsersDataFunc = async (filterParams) => {
    try {
      setLoadingStatus(Constants.PENDING);
      const res = await dispatch(UserListThunk(filterParams));
      if (res?.payload?.status === Common.API_STATUS_200) {
        await createUserListFunc(res?.payload?.data?.data, res.meta);
        await updateUserFilters(res?.payload?.data?.data?.usersData);
      }
    } catch (error) {
      dispatch(
        openSnackbar({
          message: Constants.SOMETHING_WENT_WRONG,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
    }
    setLoadingStatus(Constants.FULFILLED);
  };

  const handleReload = async () => {
    setShouldUpdateState((prev) => !prev);
  };

  const handleResetFilters = async () => {
    setFilters((prev) => {
      const updatedFilters = prev.map((filter) => ({
        ...filter,
        selectedValue: FiltersModuleName.ALL_WITH_FIRST_LETTER_CAPITAL,
      }));
      dispatch(resetUserManagementFilters());
      return updatedFilters;
    });

    setSearchUpdateKey((prev) => prev + 1);
    setTablePagination((prev) => ({ ...prev, page: 0 }));
    setShouldUpdateState((prev) => !prev);
  };

  const handleTablePagination = async (value) => {
    if (paginationThing.completed.includes(value)) return;
    setShouldUpdateState((prev) => !prev);
  };

  const handleFiltersChange = (e) => {
    const { name, value } = e.target;

    setFilters((prev) => {
      const updatedFilters = prev.map((filter) => {
        if (filter.inputLabel === name) {
          return { ...filter, selectedValue: value };
        }
        return filter;
      });
      dispatch(setUserManagementFilters(updatedFilters));
      return updatedFilters;
    });

    setTablePagination((prev) => ({ ...prev, page: 0 }));
    setShouldUpdateState((prev) => !prev);
  };

  const handleSearch = async (searchValue) => {
    if (searchValue === "" || !searchValue) return;

    setFilters((prev) => {
      const updatedFilters = prev.map((filter) => {
        if (filter.inputLabel === FiltersModuleName.SEARCH) {
          return { ...filter, selectedValue: searchValue, isLoading: true };
        }
        return filter;
      });
      return updatedFilters;
    });

    setTablePagination((prev) => ({ ...prev, page: 0 }));
    setShouldUpdateState((prev) => !prev);
  };

  const debouncedHandleSearch = (e) => {
    clearTimeout(debounceTimeout);
    const { value } = e.target;
    const trimmedValue = value?.trim();

    debounceTimeout = setTimeout(async () => handleSearch(trimmedValue), 400);
  };

  // For select dropdown
  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    if (value === "" || !value) return;

    setFilters((prev) => {
      const updatedFilters = prev.map((filter) => {
        if (filter.inputLabel === name) {
          const selectedValue = filter.list.find((item) => item.title === value)?.[
            Constants.MONGOOSE_ID
          ];
          return { ...filter, selectedValue };
        }
        return filter;
      });
      return updatedFilters;
    });

    setTablePagination((prev) => ({ ...prev, page: 0 }));
    setShouldUpdateState((prev) => !prev);
  };

  const getProfileFunctionsListFunc = async () => {
    try {
      const res = await dispatch(getProfileFunctions());
      if (res?.payload?.status === Common.API_STATUS_200) {
        const resData = res?.payload?.data?.data || [];

        const profileFuncArr = resData.filter((item) => item?.isActive === true);
        const profileFuncList = profileFuncArr.map((item) => ({
          [Constants.MONGOOSE_ID]: item?.[Constants.MONGOOSE_ID],
          title: item?.name,
        }));

        setFilters((prev) => {
          const updatedFilters = prev.map((filter) => {
            if (filter.inputLabel === FiltersModuleName.PROFILE_FUNCTION) {
              return {
                ...filter,
                list: [FiltersModuleName.USER_MANAGEMENT_FILTERS_TITLE_OBJ, ...profileFuncList],
              };
            }
            return filter;
          });
          dispatch(setUserManagementFilters(updatedFilters));
          return updatedFilters;
        });
      }
    } catch (error) {
      dispatch(
        openSnackbar({
          message: Constants.SOMETHING_WENT_WRONG,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
    }
  };

  useEffect(() => {
    const filterParams = handlePrepareFilterParams();
    getAllUsersDataFunc(filterParams);
  }, [shouldUpdateState]);

  useEffect(() => {
    getProfileFunctionsListFunc();
  }, [config]);

  return (
    <DashboardLayout module={defaultData.USER_MANAGEMENT_SCREEN_ID}>
      <DashboardNavbar />
      <MDBox display="flex" justifyContent="space-between" alignItems="center">
        {open && (
          <NewUser
            open={open}
            handleClose={() => setOpen(false)}
            setShouldUpdateState={setShouldUpdateState}
          />
        )}
        <PageTitle title={PageTitles.PERSONNEL_MANAGEMENT} />
        <MDBox display="flex" flexDirection="row">
          {permission?.create && (
            <CustomButton
              title="New"
              icon="add_circle_outline"
              background="#191A51"
              color="#ffffff"
              openModal={setOpen}
            />
          )}

          <Divider
            orientation="vertical"
            sx={{
              backgroundColor: "var(--gray-300, #D0D5DD)",
              height: "auto",
              marginLeft: pxToRem(16),
              marginRight: 0,
            }}
          />
          <BasicButton
            icon={Icons.RELOAD}
            background={Colors.WHITE}
            border
            color={Colors.BLACK}
            action={handleReload}
          />
        </MDBox>
      </MDBox>
      <Divider sx={{ marginTop: pxToRem(22) }} />

      {/* Search and filter */}
      <MDBox
        display="flex"
        alignItems="start"
        flexWrap="wrap"
        sx={{ flexDirection: "row", mr: 2, mb: 3 }}
        style={{ width: "100%" }}
      >
        <SearchBar
          key={searchUpdateKey}
          freeSolos
          options={filters[0]?.list.map((val) => val.title) || []}
          filters={filters}
          placeholder="Search"
          value={
            filters[0].list.find((item) => item[Constants.MONGOOSE_ID] === filters[0].selectedValue)
              ?.title ||
            filters[0].selectedValue ||
            "All"
          }
          handleFilterChange={(e, value) => {
            handleSearchChange({
              target: {
                name: filters[0].inputLabel,
                value,
              },
            });
          }}
          debouncedHandleSearch={debouncedHandleSearch}
          isLoading={filters[0].isLoading}
        />

        {filters
          ?.filter((item) => item.inputLabel !== FiltersModuleName.SEARCH)
          ?.map((val) => (
            <FilterDropdown
              key={val.inputLabel}
              label={val.inputLabel}
              name={val.inputLabel}
              defaultValue={val.selectedValue}
              value={val.selectedValue}
              handleChange={handleFiltersChange}
              menu={val.list}
              style={{ marginLeft: pxToRem(1) }}
              maxContent={defaultData.LARGE_CONTENT_LENGTH}
            />
          ))}
        <ResetFilterButton handleReset={handleResetFilters} style={{ marginLeft: "1rem" }} />
      </MDBox>

      <MDBox mt={1}>
        <DataTable
          table={{ columns, rows }}
          isSorted={false}
          entriesPerPage={{ defaultValue: defaultData.PER_PAGE }}
          showTotalEntries={false}
          loading={loadingStatus}
          currentPage={tablePagination.page}
          handleTablePagination={handleTablePagination}
          handleCurrentPage={(page) => setTablePagination({ ...tablePagination, page })}
          isGotoVisisble
        />
      </MDBox>
    </DashboardLayout>
  );
}

export default Usermanagement;
