import { useEffect, useState } from "react";

// Material Components
import MDBox from "components/MDBox";
import Author from "components/Table/Author";
import FDropdown from "components/Dropdown/FDropdown";
import { useSelector } from "react-redux";

export default function ReportOverviewData(
  reportOverviewData,
  handleReportStatusUpdate,
  newStateStatus,
  permission
) {
  const [overViewRows, setOverViewRows] = useState([]);
  const { userReportsID } = useSelector((state) => state.report);
  const data = [
    {
      project: reportOverviewData?.project?.title,
      report: reportOverviewData?.report?.title,
      location: reportOverviewData?.location?.title,
      assets: reportOverviewData?.asset,
    },
  ];
  useEffect(() => {
    if (reportOverviewData) {
      const list = data?.map((item, index) => {
        const temp = {
          srNo: <Author name={index + 1} />,
          project: <Author name={item?.project} style={{ textTransform: "none" }} />,
          report: <Author name={item?.report} style={{ textTransform: "none" }} />,
          location: <Author name={item?.location} style={{ textTransform: "none" }} />,
          status: (
            <MDBox>
              <FDropdown
                variant="standard"
                height={25}
                id="status"
                name="status"
                value={`${newStateStatus.charAt(0).toUpperCase()}${newStateStatus
                  .replace("-", " ")
                  .slice(1)}`}
                disabled={!permission?.update}
                menu={["Open", "Submitted", "Checked", "In Discussion", "Closed"]}
                handleChange={(name, value) =>
                  handleReportStatusUpdate({
                    target: {
                      name,
                      value: value.toLowerCase().replace(" ", "-"),
                      userReportsID,
                    },
                  })
                }
                width="150px"
              />
            </MDBox>
          ),
          assets: (
            <Author
              name={item.assets?.map(
                (elem, i, array) => `${elem.cableName}${i < array.length - 1 ? ", " : ""}`
              )}
              style={{ textTransform: "none" }}
            />
          ),
        };
        return temp;
      });
      setOverViewRows([...list]);
    }
  }, [reportOverviewData, permission, userReportsID]);

  return {
    overViewCoumns: [
      { Header: "Project", accessor: "project", align: "left", width: "35%" },
      { Header: "Report", accessor: "report", align: "left", width: "20%" },
      { Header: "Location", accessor: "location", align: "left", width: "20%" },
      { Header: "Status", accessor: "status", align: "left", width: "10%" },
      { Header: "Assets", accessor: "assets", align: "center", width: "15%" },
    ],

    overViewRows,
  };
}
