import { createSlice } from "@reduxjs/toolkit";
import { resetStateThunk } from "redux/Thunks/Authentication";
import ProfileThunk from "redux/Thunks/Profile";
import { UserListbyIdThunk } from "redux/Thunks/UserManagement";
import Constants from "utils/Constants";

const initialState = {
  loading: Constants.IDLE,
  list: [],
  userDetails: [],
};

export const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    addUserReview(state, action) {
      const { rating, comment, ratedByDetails, ratedAt, ratedBy } = action.payload;

      state.userDetails.rating = [
        ...(state.userDetails.rating || []),
        {
          rating,
          comment,
          ratedByDetails,
          ratedAt: ratedAt || new Date().toISOString(),
          ratedBy,
          isDeleted: false,
        },
      ];
    },

    removeUserRating(state, action) {
      state.userDetails.rating = state.userDetails.rating.filter(
        (rating) => rating[Constants.MONGOOSE_ID] !== action.payload.ratingId
      );
    },

    reloadData(state) {
      state.loading = Constants.PENDING;
    },
  },

  extraReducers: {
    [ProfileThunk.pending]: (state) => {
      if (state.list.length === 0) state.loading = Constants.PENDING;
    },
    [ProfileThunk.fulfilled]: (state, { payload }) => {
      state.loading = Constants.FULFILLED;
      state.userDetails = payload.data;
    },
    [ProfileThunk.rejected]: (state) => {
      state.loading = Constants.REJECTED;
    },

    [UserListbyIdThunk.pending]: (state) => {
      if (state.userDetails.length === 0) state.loading = Constants.PENDING;
    },
    [UserListbyIdThunk.fulfilled]: (state, { payload }) => {
      state.loading = Constants.FULFILLED;
      state.userDetails = payload.data.data;
    },
    [UserListbyIdThunk.rejected]: (state) => {
      state.loading = Constants.REJECTED;
    },

    [resetStateThunk.fulfilled]: (state) => {
      state.loading = Constants.IDLE;
      state.list = [];
    },
  },
});

export const { reloadData, addUserReview, removeUserRating } = userSlice.actions;
export default userSlice.reducer;
