const userDetails = [
  {
    label: "Personal Details",
    name: "personalDetails",
    details: [
      {
        title: "Passport Name",
        value: "callingName",
      },
      {
        title: "Phone",
        value: "contactNumber",
      },
      {
        title: "Email",
        value: "email",
      },
      {
        title: "Address",
        value: "address",
      },
      {
        title: "Main Language",
        value: "motherLanguage",
      },
      {
        title: "Preferred Airport of Departure",
        value: "prefAirportDeprt",
      },
      {
        title: "Travel Time to Airport  (in hrs)",
        value: "travelTimeToAirport",
      },
      {
        title: "Driving License",
        value: "drivingLicence",
      },
      {
        title: "Shoe Size",
        value: "shoeSize",
      },
      {
        title: "WINDA ID",
        value: "windaId",
      },
      {
        title: "Clothes Size",
        value: "clothesSize",
      },
      {
        title: "Next of Kin",
        value: "nextofKin",
      },
    ],
  },
  {
    label: "Constractual Details",
    name: "contractualDetails",
    details: [
      {
        title: "Passport/ID",
        value: "passport",
      },
      {
        title: "National Identification Number (BSN/NINO/SSN etc.)",
        value: "nationalIdentificationNumber",
      },
      {
        title: "Date of Birth",
        value: "birthDate",
      },
      {
        title: "Passport Place of Birth",
        value: "birthPlace",
      },
      {
        title: "Employment Type",
        value: "employmentType",
      },
      {
        title: "Company Name",
        value: "companyName",
      },
      {
        title: "Company Registration Nr.",
        value: "companyRegistrationNumber",
      },
      {
        title: "Company VAT Nr.",
        value: "companyVATNumber",
      },
      {
        title: "Company Address",
        value: "companyAddress",
      },
      {
        title: "Bank Name",
        value: "bankName",
      },
      {
        title: "Account Name Holder",
        value: "accountHolderName",
      },
      {
        title: "Bank Account Nr (IBAN)",
        value: "bankAccount",
      },
      {
        title: "BIC/SWIFT",
        value: "bicSwift",
      },
    ],
  },
];

export default userDetails;
