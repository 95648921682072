import React, { useEffect, useState } from "react";

// MUI Components
import MDBox from "components/MDBox";

// Custom Components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import BasicButton from "examples/NewDesign/CustomButton/BasicButton";
import FilterDropdown from "components/Dropdown/FilterDropdown";
import ResetFilterButton from "components/Buttons/ResetButton";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PageTitle from "examples/NewDesign/PageTitle";
import Report from "layouts/report/data/reportData";
import DataTable from "examples/Tables/DataTable";

// Constant
import Constants, {
  Icons,
  PageTitles,
  FeatureTags,
  defaultData,
  Colors,
  FiltersModuleName,
} from "utils/Constants";
import pxToRem from "assets/theme/functions/pxToRem";

// Redux component
import { useDispatch, useSelector } from "react-redux";
import { getAllReportTypes, getAllReports, exportReportThunk } from "redux/Thunks/Report";
import { projectListThunk, locationListByIdThunk } from "redux/Thunks/FieldsData";
import { Feature } from "flagged";
import { setReportFilters, resetReportFilters } from "redux/Slice/Filter";
import { paramCreater } from "utils/methods/methods";
import { openSnackbar } from "redux/Slice/Notification";

const reportFiltersArr = [
  {
    inputLabel: FiltersModuleName.PROJECT,
    list: [FiltersModuleName.REPORT_FILTERS_TITLE_OBJ],
    selectedValue: FiltersModuleName.ALL_IN_SMALL_CASE,
  },
  {
    inputLabel: FiltersModuleName.TITLE,
    list: [FiltersModuleName.REPORT_FILTERS_TITLE_OBJ],
    selectedValue: FiltersModuleName.ALL_IN_SMALL_CASE,
  },
  {
    inputLabel: FiltersModuleName.LOCATION,
    list: [FiltersModuleName.REPORT_FILTERS_TITLE_OBJ],
    selectedValue: FiltersModuleName.ALL_IN_SMALL_CASE,
  },
  {
    inputLabel: FiltersModuleName.STATUS,
    list: ["All", "Open", "Submitted", "checked", "in-discussion", "Closed"],
    selectedValue: FiltersModuleName.ALL_WITH_FIRST_LETTER_CAPITAL,
  },
];

function Reports() {
  const mongooseId = "_id";
  const dispatch = useDispatch();

  const ConfigData = useSelector((state) => state.config);
  const permission = ConfigData?.screens?.[6]?.screensInfo?.agreement;

  // Reports Related Store Data.
  const { reportList = [], reportListpagination } = useSelector((state) => state?.report) || {};
  const reportFiltersList = useSelector((state) => state.filtersSlice.reportFilters) || [];

  // Checking If the store has any previous filters saved.
  const initialFilters = reportFiltersList?.length > 0 ? reportFiltersList : reportFiltersArr;

  // Report Filters State Variables.
  const [loading, setLoading] = useState(Constants.PENDING);
  const [filters, setFilters] = useState(initialFilters);
  const [shouldUpdateState, setShouldUpdateState] = useState(false);
  const [tablePagination, setTablePagination] = useState({
    page: 0,
    perPage: defaultData.PER_PAGE,
  });

  const handleDownloadReport = async (reportId, format = "pdf") => {
    const currentDate = new Date();
    const filename = `reynard_report_${currentDate.getFullYear()}-${
      currentDate.getMonth() + 1
    }-${currentDate.getDate()}_${currentDate.getHours()}-${currentDate.getMinutes()}-${currentDate.getSeconds()}.${format}`;

    const res = await dispatch(exportReportThunk(reportId));
    const url = window.URL.createObjectURL(res.payload);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  // Columns and Rows Data for Report Table.
  const { columns, rows } = Report(reportList, permission, handleDownloadReport);

  // Data Related API function starts from here

  const handlePrepareFilterParams = () => {
    const params = {
      page: tablePagination.page,
      perPage: tablePagination.perPage,
      project: filters[0].selectedValue,
      report: filters[1].selectedValue,
      location: filters[2].selectedValue.toLowerCase().replace(/ /g, "_"),
      status: filters[3].selectedValue.toLowerCase().replace(/ /g, "_"),
    };

    return paramCreater(params);
  };

  const getAllReportsDataFunc = async (filterParams) => {
    try {
      setLoading(Constants.PENDING);
      await dispatch(getAllReports(filterParams));
    } catch (error) {
      dispatch(
        openSnackbar({
          message: Constants.SOMETHING_WENT_WRONG,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
    }
    setLoading(Constants.FULFILLED);
  };

  // Get Project List Func for dropdown.
  const getProjectListFunc = async () => {
    try {
      const projectList = await dispatch(projectListThunk());
      if (projectList.payload.status) {
        setFilters((prev) => {
          const updatedFilters = prev.map((filter) => {
            if (filter.inputLabel === FiltersModuleName.PROJECT) {
              return {
                ...filter,
                list: [FiltersModuleName.REPORT_FILTERS_TITLE_OBJ, ...projectList.payload.data],
              };
            }
            return filter;
          });

          // Updating the Store also with the latest Project List.
          dispatch(setReportFilters(updatedFilters));
          return updatedFilters;
        });
      }
    } catch (error) {
      dispatch(
        openSnackbar({
          message: Constants.SOMETHING_WENT_WRONG,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
    }
  };

  const updateFilterList = (reportTypeList, locationList) => {
    const createListWithDefault = (list = []) => [
      {
        [Constants.MONGOOSE_ID]: FiltersModuleName.ALL_IN_SMALL_CASE,
        title:
          list.length > 0
            ? FiltersModuleName.ALL_WITH_FIRST_LETTER_CAPITAL
            : FiltersModuleName.NO_DATA_FOUND,
      },
      ...list,
    ];

    setFilters((prevFilters) => {
      const updatedFilters = prevFilters.map((filter) => {
        if (filter.inputLabel === FiltersModuleName.TITLE) {
          return {
            ...filter,
            selectedValue: FiltersModuleName.ALL_IN_SMALL_CASE,
            list: createListWithDefault(reportTypeList),
          };
        }
        if (filter.inputLabel === FiltersModuleName.LOCATION) {
          return {
            ...filter,
            selectedValue: FiltersModuleName.ALL_IN_SMALL_CASE,
            list: createListWithDefault(locationList),
          };
        }
        return filter;
      });

      // Updating the Store also with the latest Project List.
      dispatch(setReportFilters(updatedFilters));
      return updatedFilters;
    });
  };

  const fetchReportAndLocationFunc = async (key) => {
    const paramData = {
      page: 0,
      perPage: 100,
      project: key,
    };
    const updatedParams = paramCreater(paramData);
    try {
      const [reportTypeList, newLocationList] = await Promise.all([
        dispatch(getAllReportTypes({ param: updatedParams, byPassSlice: false })),
        dispatch(locationListByIdThunk(key)),
      ]);
      const reportTypesArr = reportTypeList.payload?.data?.data || [];
      const newLocationArr = newLocationList?.payload?.data || [];
      updateFilterList(reportTypesArr, newLocationArr);
    } catch (error) {
      dispatch(
        openSnackbar({
          message: Constants.SOMETHING_WENT_WRONG,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
    }
  };

  const handleFilterChange = async (e) => {
    const { value, name } = e.target;
    setLoading(Constants.PENDING);

    setFilters((prevFilters) => {
      const updatedFilters = prevFilters.map((filter) => {
        if (filter.inputLabel === name) {
          return { ...filter, selectedValue: value };
        }
        if (
          name === FiltersModuleName.PROJECT &&
          value === FiltersModuleName.ALL_IN_SMALL_CASE &&
          filter.inputLabel !== FiltersModuleName.STATUS
        ) {
          return { ...filter, selectedValue: FiltersModuleName.ALL_IN_SMALL_CASE };
        }
        return filter;
      });
      dispatch(setReportFilters(updatedFilters));
      return updatedFilters;
    });

    if (name === FiltersModuleName.PROJECT && value !== FiltersModuleName.ALL_IN_SMALL_CASE) {
      await fetchReportAndLocationFunc(value);
    }

    setTablePagination((prev) => ({ ...prev, page: 0 }));
    setShouldUpdateState((prev) => !prev);
  };

  const handleResetFilter = () => {
    setFilters((prevFilters) => {
      const updatedFilters = prevFilters.map((filter) => ({
        ...filter,
        selectedValue: filter.list[0][mongooseId] || filter.list[0],
      }));
      return updatedFilters;
    });

    setTablePagination((prev) => ({ ...prev, page: 0 }));
    dispatch(resetReportFilters());
    setShouldUpdateState((prev) => !prev);
  };

  const handleTablePagination = async (value) => {
    if (reportListpagination.completed.includes(value)) return;
    setShouldUpdateState((prev) => !prev);
  };

  const handleReload = async () => {
    setShouldUpdateState((prev) => !prev);
  };

  useEffect(() => {
    getProjectListFunc();
  }, []);

  useEffect(() => {
    const filterParams = handlePrepareFilterParams();
    getAllReportsDataFunc(filterParams);
  }, [shouldUpdateState]);

  return (
    <DashboardLayout module={defaultData.REPORT_SCREEN_ID}>
      <DashboardNavbar />
      <MDBox display="flex" justifyContent="space-between">
        <PageTitle title={PageTitles.REPORT} />
        <BasicButton
          icon={Icons.RELOAD}
          background={Colors.WHITE}
          border
          color={Colors.BLACK}
          action={handleReload}
        />
      </MDBox>
      <Feature name={FeatureTags.SETUP_REPORT}>
        <>
          <MDBox display="flex" justifyContent="space-between" mt={2} mx={0}>
            <MDBox
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "start",
                alignItems: "flex-end",
              }}
              mt={2}
              mx={0}
            >
              {filters.map((val) => (
                <FilterDropdown
                  key={val.inputLabel}
                  label={val.inputLabel}
                  name={val.inputLabel}
                  defaultValue={val.selectedValue}
                  value={val.selectedValue}
                  handleChange={handleFilterChange}
                  menu={val.list}
                  style={{ marginLeft: pxToRem(1) }}
                  disabled={
                    (val?.inputLabel === "Location" && filters[0]?.selectedValue === "all") ||
                    (val?.inputLabel === "Title" && filters[0]?.selectedValue === "all") ||
                    (val?.inputLabel === "Location" &&
                      filters[2]?.list[0].title === "No data found") ||
                    (val?.inputLabel === "Title" && filters[1]?.list[0].title === "No data found")
                  }
                  maxContent={defaultData.MEDIUM_CONTENT_LENGTH}
                  textTransform={val.textTransform}
                />
              ))}
              <ResetFilterButton handleReset={handleResetFilter} style={{ marginLeft: "1rem" }} />
            </MDBox>
          </MDBox>
          <MDBox mt={3} mb={3}>
            <DataTable
              table={{ columns, rows }}
              isSorted={false}
              entriesPerPage={{ defaultValue: defaultData.PER_PAGE }}
              showTotalEntries={false}
              noEndBorder
              loading={loading}
              licenseRequired
              currentPage={tablePagination.page}
              handleTablePagination={handleTablePagination}
              handleCurrentPage={(page) => setTablePagination({ ...tablePagination, page })}
              isGotoVisisble
            />
          </MDBox>
        </>
      </Feature>
    </DashboardLayout>
  );
}

export default Reports;
