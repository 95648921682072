import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for MDInput
import MDInputRoot from "components/MDInput/MDInputRoot";

const MDInput = forwardRef(
  ({ error, success, disabled, FormHelperTextProps, type, ...rest }, ref) => {
    // Add the onWheel handler conditionally for number input types
    const handleWheel = (e) => {
      if (type === "number") {
        e.target.blur(); // Disable scrolling for number inputs
      }
    };

    return (
      <MDInputRoot
        {...rest}
        ref={ref}
        ownerState={{ error, success, disabled }}
        FormHelperTextProps={FormHelperTextProps}
        type={type}
        onWheel={handleWheel}
      />
    );
  }
);

// Setting default values for the props of MDInput
MDInput.defaultProps = {
  error: false,
  success: false,
  disabled: false,
  FormHelperTextProps: {},
  type: "text",
};

// Typechecking props for the MDInput
MDInput.propTypes = {
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
  FormHelperTextProps: PropTypes.objectOf(PropTypes.any),
  type: PropTypes.string,
};

export default MDInput;
