import { createSlice } from "@reduxjs/toolkit";
import { resetStateThunk } from "redux/Thunks/Authentication";
import {
  getAllReports,
  getAllReportTypes,
  getAllQuestionTypes,
  getReportById,
} from "redux/Thunks/Report";
import Constants from "utils/Constants";
import reportsRowData from "utils/Types/ReportsDummyData";

const initialState = {
  reportTypeLoading: Constants.IDLE,
  reportType: [],
  reportLoading: Constants.IDLE,
  reportList: [],
  reportListpagination: {
    allRecordsCount: 0,
    completed: [],
  },
  questionTree: {},
  questionsTypeLoading: Constants.IDLE,
  questionsType: [],
  reportDetails: [],
  reportDetailsLoading: Constants.IDLE,
  activeReportLoading: Constants.IDLE,
  reportId: [],
  userReportsID: [],
  inActiveReport: [],
};

export const reportSlice = createSlice({
  name: "reportSlice",
  initialState,
  reducers: {
    unassignReportAnswer(state, action) {
      const stateData = JSON.parse(JSON.stringify(state));

      // Iterate over reportDetails
      const updatedReportDetails = stateData.reportDetails.map((report) => ({
        ...report,
        reportQuestions: report.reportQuestions.map((question) => ({
          ...question,
          answerTypes: question.answerTypes.map((answerType) => ({
            ...answerType,
            userAnswers: answerType.userAnswers.map((userAnswer) => ({
              ...userAnswer,
              answers: userAnswer.answers.map((answer) => {
                if (answer[Constants.MONGOOSE_ID] === action.payload.answerId) {
                  return {
                    ...answer,
                    isActive: false,
                  };
                }
                return answer;
              }),
            })),
          })),
        })),
      }));
      const inActiveReportDetails = stateData.reportDetails.map((report) => ({
        reportQuestions: report.reportQuestions.map((question) => ({
          answerTypes: question.answerTypes.map((answerType) => ({
            ...answerType,
            userAnswers: answerType.userAnswers.map((userAnswer) => ({
              ...userAnswer,
              answers: userAnswer.answers?.map((answer) => {
                if (action.payload.answerId === answer[Constants.MONGOOSE_ID]) {
                  return {
                    ...answer,
                    isActive: false,
                  };
                }
                return answer;
              }),
            })),
          })),
        })),
      }));

      // Update the state with modified reportDetails
      return {
        ...state,
        reportDetails: updatedReportDetails,
        inActiveReport: inActiveReportDetails,
      };
    },

    updateReport(state, action) {
      const stateData = JSON.parse(JSON.stringify(state));
      // Iterate over reportDetails
      const updatedReportDetails = stateData.reportDetails.map((report) => ({
        ...report,
        reportQuestions: report.reportQuestions.map((question) => ({
          ...question,
          answerTypes: question.answerTypes.map((answerType) => ({
            ...answerType,
            userAnswers: answerType.userAnswers.map((userAnswer) => ({
              ...userAnswer,
              answers: userAnswer.answers.map((answer) => {
                if (
                  answer.answerTitleId === action.payload.data.answerTitleId &&
                  userAnswer[Constants.MONGOOSE_ID] === action.payload.answerId
                ) {
                  return {
                    ...answer,
                    answer: action.payload.data.answer,
                  };
                }
                return answer;
              }),
            })),
          })),
        })),
      }));

      // Update the state with modified reportDetails
      return {
        ...state,
        reportDetails: updatedReportDetails,
      };
    },

    // Update the user report status based on the type
    updateReportUserStatus: (state, action) => {
      const stateData = JSON.parse(JSON.stringify(state));
      const updatedReportDetails = stateData.reportDetails.map((report) => ({
        ...report,
        [Constants.MONGOOSE_ID]: {
          ...report[Constants.MONGOOSE_ID],
          userReportStatus: report[Constants.MONGOOSE_ID].userReportStatus.map((reportStatus) => {
            if (action.payload.type === "main") {
              return {
                ...reportStatus,
                status: action.payload.status,
              };
            }
            if (
              action.payload.type === "sub" &&
              reportStatus.userReportId === action.payload.reportId
            ) {
              return {
                ...reportStatus,
                status: action.payload.status,
              };
            }
            return reportStatus;
          }),
        },
        reportQuestions: report.reportQuestions,
      }));
      return {
        ...state,
        reportDetails: updatedReportDetails,
      };
    },

    updateIsPrint: (state, action) => {
      const { answerId, isPrintable } = action.payload;

      // Create updatedReportDetails with targeted updates
      const updatedReportDetails = state.reportDetails.map((report) => ({
        ...report,
        reportQuestions: report.reportQuestions.map((question) => ({
          ...question,
          answerTypes: question.answerTypes.map((answerType) => ({
            ...answerType,
            userAnswers: answerType.userAnswers.map((userAnswer) => ({
              ...userAnswer,
              answers: userAnswer.answers.map((answer) => {
                if (answer?.answerTitleId === answerId) {
                  return {
                    ...answer,
                    isPrintable,
                  };
                }
                return answer;
              }),
            })),
          })),
        })),
      }));

      // Return updated state with modified reportDetails
      return {
        ...state,
        reportDetails: updatedReportDetails,
      };
    },

    removeReportType(state, action) {
      state.reportType = state.reportType.filter(
        (item) => item?.[Constants.MONGOOSE_ID] !== action.payload
      );
    },

    reloadReportTypeData(state) {
      state.reportTypeLoading = Constants.PENDING;
    },

    updateReportTypeReducer(state, action) {
      const { editIndex, updatedData } = action.payload;
      const tempReportTypeList = [...state.reportType];
      tempReportTypeList[editIndex] = updatedData;
      state.reportType = tempReportTypeList;
    },

    setQuestionTree(state, action) {
      state.questionTree = action.payload;
    },

    reloadReport(state) {
      state.reportLoading = Constants.PENDING;
    },
    resetReportState(state) {
      state.reportLoading = Constants.PENDING;
      state.reportList = [];
      state.reportListpagination = {
        allRecordsCount: 0,
        completed: [],
      };
    },

    reloadReportDetails(state) {
      state.reportDetailsLoading = Constants.PENDING;
    },
    reloadActiveReport(state) {
      state.activeReportLoading = Constants.PENDING;
    },
    resetReportDetailsState(state) {
      state.reportDetailsLoading = Constants.PENDING;
      state.activeReportLoading = Constants.PENDING;
      state.reportDetails = [];
    },

    storeReportId(state, action) {
      state.reportId = action.payload;
      state.userReportsID = action.payload;
    },
  },

  extraReducers: {
    [getAllReportTypes.pending]: (state) => {
      if (state.reportType.length === 0) state.reportTypeLoading = Constants.PENDING;
    },
    [getAllReportTypes.fulfilled]: (state, { payload }) => {
      state.reportTypeLoading = Constants.FULFILLED;
      // Use `byPassSlice: true` to avoid loading data into the slice.
      // For example, this is useful when calling an API to retrieve data for filters.
      if (!payload.byPassSlice && payload.type === "add") state.reportType = payload.data.data;
      else if (!payload.byPassSlice) state.reportType.push(...payload.data.data);
    },
    [getAllReportTypes.rejected]: (state) => {
      state.reportTypeLoading = Constants.REJECTED;
    },

    [getAllReports.pending]: (state) => {
      if (state.reportList.length === 0) state.reportLoading = Constants.PENDING;
    },

    [getAllReports.fulfilled]: (state, { payload, meta }) => {
      const { reportData, allRecordsCount } = payload.data.data;
      state.reportLoading = Constants.FULFILLED;

      // Get pagination info from the meta argument
      const params = new URLSearchParams(meta.arg);
      const page = parseInt(params.get("page"), 10);
      const perPage = parseInt(params.get("perPage"), 10);

      // If reportList is empty, initialize it with the required format
      const reportListArr =
        page === 0
          ? Array.from({ length: allRecordsCount }, () => ({ ...reportsRowData }))
          : [...state.reportList];

      const startIndex = page * perPage;
      reportListArr.splice(startIndex, perPage, ...reportData);

      // Update state with new data
      state.reportList = reportListArr;
      state.reportListpagination = {
        allRecordsCount,
        completed: page === 0 ? [page] : [...state.reportListpagination.completed, page],
      };
    },

    [getAllReports.rejected]: (state) => {
      state.reportLoading = Constants.REJECTED;
    },
    [getAllQuestionTypes.pending]: (state) => {
      if (state.questionsType.length === 0) state.questionsTypeLoading = Constants.PENDING;
    },
    [getAllQuestionTypes.fulfilled]: (state, { payload }) => {
      state.questionsTypeLoading = Constants.FULFILLED;
      state.questionsType = payload.data.data;
    },
    [getAllQuestionTypes.rejected]: (state) => {
      state.questionsTypeLoading = Constants.REJECTED;
    },

    [getReportById.pending]: (state) => {
      if (state.reportDetails.length === 0) {
        state.reportDetailsLoading = Constants.PENDING;
        state.activeReportLoading = Constants.PENDING;
      }
    },
    [getReportById.fulfilled]: (state, { payload }) => {
      state.reportDetailsLoading = Constants.FULFILLED;
      state.activeReportLoading = Constants.FULFILLED;
      if (state.currentStatus !== payload.currentStatus) return;

      const modifiedReportDetails = payload.data.data;
      // Function to add index properties to nested items
      const addIndexToItems = (items, key) =>
        items.map((item, idx) => ({
          ...item,
          [key]: idx + 1,
        }));

      // Main mapping function for report details
      const updatedReportDetails = modifiedReportDetails.map((report) => ({
        ...report,
        reportQuestions: report.reportQuestions.map((question) => ({
          ...question,
          answerTypes: question.answerTypes.map((answerType) => ({
            ...answerType,
            title: addIndexToItems(answerType.title, "typeIndex"),
            userAnswers: answerType.userAnswers.map((userAnswer) => ({
              ...userAnswer,
              answers: userAnswer.answers.map((answer, answerIdx) => ({
                ...answer,
                answerIndex: answerIdx + 1,
              })),
            })),
          })),
        })),
      }));

      // Create a map for tracking report counts by user ID
      const reportDetails = updatedReportDetails[0][Constants.MONGOOSE_ID];
      const reportCountMap = new Map();

      // Count occurrences of each report by user
      reportDetails.userReportStatus.forEach((report) => {
        const userId = report.createdBy[Constants.MONGOOSE_ID];
        reportCountMap.set(userId, (reportCountMap.get(userId) || 0) + 1);
      });

      // Update userReportStatus with indexed last names if needed
      const updatedUserReportStatus = reportDetails.userReportStatus.reverse().map((report) => {
        const userId = report.createdBy[Constants.MONGOOSE_ID];
        const reportCount = reportCountMap.get(userId);

        // If multiple reports exist for the same user, append the count to the lastName
        if (reportCount > 1) {
          reportCountMap.set(userId, reportCount - 1); // Decrease count for reverse mapping
          return {
            ...report,
            createdBy: {
              ...report.createdBy,
              lastName: `${report.createdBy.lastName} (${reportCount})`,
            },
          };
        }
        return report;
      });

      // Construct the final object with updated user report status
      const tempId = {
        ...reportDetails,
        userReportStatus: updatedUserReportStatus.reverse(),
      };

      // Update the state with the new report details
      updatedReportDetails[0][Constants.MONGOOSE_ID] = tempId;

      const inActiveReportDetails = modifiedReportDetails.map((report) => ({
        reportQuestions: report.reportQuestions.map((question) => ({
          answerTypes: question.answerTypes.map((answerType) => ({
            ...answerType,
            title: answerType.title?.map((title, titleIdx) => ({
              ...title,
              typeIndex: titleIdx + 1,
            })),
            userAnswers: answerType.userAnswers.map((userAnswer) => ({
              ...userAnswer,
              answers: userAnswer.answers?.map((answer, answerIdx) => ({
                ...answer,
                answerIndex: answerIdx + 1,
              })),
            })),
          })),
        })),
      }));

      state.inActiveReport = inActiveReportDetails;
      state.reportDetails = updatedReportDetails;
    },

    [getReportById.rejected]: (state) => {
      state.reportDetailsLoading = Constants.REJECTED;
      state.activeReportLoading = Constants.REJECTED;
    },
    [resetStateThunk.fulfilled]: (state) => {
      state.reportLoading = Constants.IDLE;
      state.reportList = [];
      state.reportType = [];
      state.reportDetails = [];
    },
  },
});

export const {
  unassignReportAnswer,
  removeReportType,
  updateReportTypeReducer,
  reloadReportTypeData,
  setQuestionTree,
  reloadReport,
  resetReportState,
  updateReport,
  reloadReportDetails,
  reloadActiveReport,
  resetReportDetailsState,
  storeReportId,
  updateReportUserStatus,
  updateIsPrint,
} = reportSlice.actions;
export default reportSlice.reducer;
