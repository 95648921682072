// color constants
const Common = {
  SMALL_SIGNATURE: "signature",
  SMALL_IMAGE: "image",
  ALL: "all",

  // File Constants
  IMAGE_JPEG: "image/jpeg",
  IMAGE_JPG: "image/jpg",
  IMAGE_PNG: "image/png",
  IMAGE_SVG: "image/svg+xml",
  PDF_FILE: "application/pdf",

  // Label Constants - Certificate
  CERTIFICATE_UPLOAD_LABEL: "Certificate (PDF, Image)",
  CERTIFICATE_TYPE_LABEL: "Certificate Type",
  CERTIFICATE_START_END_DATE_LABEL: "Certificate Start and End Date",

  START_DATE_LABEL: "Start Date",
  END_DATE_LABEL: "End Date",

  // API Network Status Code
  API_STATUS_200: 200,
  API_STATUS_401: 401,
  API_STATUS_422: 422,

  // Keyboard Keys Name
  ARROW_DOWN_KEY: "ArrowDown",
  ENTER_KEY: "Enter",

  // Start And End for Dates
  START: "start",
  START_DATE: "startDate",
  END: "end",
  END_DATE: "endDate",
  CURRENT_TIME_ZONE: Intl.DateTimeFormat().resolvedOptions().timeZone,
  DEFAULT_TIME_ZONE: "Europe/London",
};

export default Common;
