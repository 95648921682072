// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FullScreenImageComponent from "components/ViewFullImage/ViewImage";

// Material Dashboard 2 React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import pxToRem from "assets/theme/functions/pxToRem";

// Custom components
import ProfileAvatar from "examples/ProfileAvatar/profileAvatar";
import { IconButton } from "@mui/material";
import Constants, { Icons } from "utils/Constants";
import { useEffect, useState } from "react";

function ProfileInfoCard({
  title,
  tabChangeValue,
  description,
  info,
  social,
  shadow,
  logo,
  website,
}) {
  const [labels, setLabels] = useState([]);
  const [values, setValues] = useState([]);
  const [fullScreenImage, setFullScreenImage] = useState(null);
  const { socialMediaColors } = colors;
  const { size } = typography;

  const handleImageDisplay = (imagePreview) => {
    setFullScreenImage(imagePreview);
  };

  const handleCloseFullView = () => {
    setFullScreenImage(null);
  };

  const renderItems = labels.map((label, key) => {
    const isSpecialTitle = title === "Medical" || title === "GDPR";
    const labelWidth = isSpecialTitle ? "55%" : "35%";
    const valueWidth = isSpecialTitle ? "45%" : "65%";

    return (
      <MDBox
        key={label}
        display="flex"
        flexWrap="wrap"
        py={2}
        pr={2}
        sx={{ borderBottom: "1px solid #E0E6F5" }}
      >
        {/* Label Section */}
        <MDBox width={labelWidth} sx={{ textAlign: "left", paddingRight: "16px" }}>
          <MDTypography
            sx={{
              fontSize: pxToRem(16),
              fontWeight: "500",
              color: "#667085",
              textTransform: "capitalize",
              whiteSpace: "normal",
              wordBreak: "break-word",
            }}
          >
            {label}
          </MDTypography>
        </MDBox>

        {/* Value Section */}
        <MDBox width={valueWidth} sx={{ textAlign: "left" }}>
          {typeof values[key] !== "object" ? (
            <MDTypography
              sx={{
                fontSize: pxToRem(16),
                fontWeight: "600",
                color: "#191D31",
                whiteSpace: "normal",
                wordBreak: "break-word",
              }}
            >
              {values[key]}
            </MDTypography>
          ) : (
            <MDBox display="flex" alignItems="center" flexWrap="wrap">
              <MDTypography
                sx={{
                  fontSize: pxToRem(16),
                  fontWeight: "600",
                  color: "#191D31",
                  whiteSpace: "normal",
                  wordBreak: "break-word",
                }}
              >
                {values[key].name}
              </MDTypography>

              {/* Optional Icon for Viewing */}
              {values[key].name !== Constants.NA && (
                <IconButton color="info" onClick={() => handleImageDisplay(values[key].url)}>
                  {Icons.VIEW}
                </IconButton>
              )}

              {/* Full Screen Image Component */}
              <FullScreenImageComponent
                fullScreenImage={fullScreenImage}
                handleCloseFullView={handleCloseFullView}
                src={fullScreenImage}
              />
            </MDBox>
          )}
        </MDBox>
      </MDBox>
    );
  });

  const renderLogo = logo && (
    <MDBox key={logo} display="flex" py={1} pr={2}>
      <ProfileAvatar profileUrl={logo} name="Organization Profile" width={50} height={50} />
    </MDBox>
  );

  const renderUrl = website && (
    <MDBox key={website} display="flex" py={1} pr={2}>
      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
        Website: &nbsp;
      </MDTypography>
      <Link href={website} fontSize="small" underline="none" variant="string" color="text">
        {website}
      </Link>
    </MDBox>
  );

  // Render the card social media icons
  const renderSocial = social.map(({ link, icon, color }) => (
    <MDBox
      key={color}
      component="a"
      href={link}
      target="_blank"
      rel="noreferrer"
      fontSize={size.lg}
      color={socialMediaColors[color].main}
      pr={1}
      pl={0.5}
      lineHeight={1}
    >
      {icon}
    </MDBox>
  ));

  useEffect(() => {
    // Convert this form `objectKey` of the object key in to this `object key`
    const lableArr = Object.keys(info)?.map((el) => {
      if (el.match(/[A-Z\s]+/)) {
        const uppercaseLetter = Array.from(el).find((i) => i.match(/[A-Z]+/));
        return el.replace(uppercaseLetter, ` ${uppercaseLetter.toLowerCase()}`);
      }
      return el;
    });
    setLabels(lableArr);

    // Push the object values into the values array
    const valuesArr = [...Object.values(info)];
    setValues(valuesArr);
  }, [tabChangeValue, info]);

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MDBox
        display="flex"
        justifyContent="start"
        alignItems="center"
        flexDirection="row"
        pt={2}
        px={2}
      >
        <MDTypography
          variant="h5"
          fontWeight="medium"
          sx={{ color: "#667085" }}
          textTransform="capitalize"
        >
          {title}
        </MDTypography>

        {renderLogo}
      </MDBox>

      <MDBox p={2}>
        <MDBox mb={2} lineHeight={1}>
          <MDTypography variant="button" color="text" fontWeight="light">
            {description}
          </MDTypography>
        </MDBox>

        <MDBox opacity={0.3}>
          <Divider />
        </MDBox>

        <MDBox>
          {renderItems}
          {renderUrl}
          <MDBox display="flex" py={1} pr={2}>
            {renderSocial.length > 0 && (
              <MDBox>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  social: &nbsp;
                </MDTypography>
                {renderSocial}
              </MDBox>
            )}
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default props for the ProfileInfoCard
ProfileInfoCard.defaultProps = {
  shadow: true,
  social: [],
  logo: "",
  website: "",
  description: "",
  tabChangeValue: 0,
};

// Typechecking props for the ProfileInfoCard
ProfileInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  info: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])).isRequired,
  social: PropTypes.arrayOf(PropTypes.object),
  shadow: PropTypes.bool,
  logo: PropTypes.string,
  website: PropTypes.string,
  tabChangeValue: PropTypes.number,
};

export default ProfileInfoCard;
