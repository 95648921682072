/* eslint-disable react/function-component-definition */

import React, { useEffect, useState, useRef } from "react";

// 3rd party library
import { Feature } from "flagged";
import html2canvas from "html2canvas";
import { jsPDF as JSPDF } from "jspdf";
import moment from "moment";

// MUI Components
import { Card, Divider, Menu, MenuItem, Button } from "@mui/material";
import pxToRem from "assets/theme/functions/pxToRem";

// Custom Componentsu
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PageTitle from "examples/NewDesign/PageTitle";
import BasicButton from "examples/NewDesign/CustomButton/BasicButton";
import MDBox from "components/MDBox";
import ProjectTrackerTable from "examples/Tables/DataTable/projectTrackerTable";
import FilterDropdown from "components/Dropdown/FilterDropdown";
import LocationCalculation from "examples/Drawers/Report/locationCalculation/index";
import MDTypography from "components/MDTypography";
import ResetFilterButton from "components/Buttons/ResetButton";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { openSnackbar } from "redux/Slice/Notification";
import { projectListThunk, exportProjectTrackerExcel } from "redux/Thunks/Filter";
import getProjectTrackerData from "redux/Thunks/ProjectTracker";
import { setStoreFilters, resetFilters } from "redux/Slice/Filter";

// Constants
import Constants, {
  PageTitles,
  Icons,
  Colors,
  FeatureTags,
  ButtonTitles,
  defaultData,
} from "utils/Constants";
import reportCalculationData from "./data/reportCalculationData";

const ReportCalculation = () => {
  const dispatch = useDispatch();
  const projectTrackerStoredFilters = useSelector((state) => state.filtersSlice.projectTracker);
  const [loading, setLoading] = useState(true);
  const [locationData, setLocationData] = useState({
    id: "",
    name: "",
  });
  const [filters, setFilters] = useState([
    {
      inputLabel: "Project",
      list: [{ [Constants.MONGOOSE_ID]: "select", title: "Select" }],
      selectedValue: "select",
      textTransform: "none",
    },
  ]);
  const [viewLocationProgressAnchor, setViewLocationProgressAnchor] = useState({ right: false });
  const [isscopeReportDataExist, setIsscopeReportDataExist] = useState(false);
  const [reportHeaderFooterData, setReportHeaderFooterData] = useState(null);
  const [reportRowsData, setReportRowsData] = useState(null);
  const [totalCompletion, setTotalCompletion] = useState(0);
  const [pdfLoading, setPdfLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const projectTrackerRef = useRef();

  const handleLocationClick = (locId, locName) => {
    setViewLocationProgressAnchor({ right: true });
    setLocationData({
      id: locId,
      name: locName,
    });
  };

  const fetchFilters = async () => {
    const projectList = await dispatch(projectListThunk());
    if (projectList.payload.status) {
      const temp = [...filters];

      temp[0].list = [
        { [Constants.MONGOOSE_ID]: "select", title: "Select" },
        ...projectList.payload.data,
      ];
      if (projectTrackerStoredFilters?.length > 0) {
        setFilters((prevFilters) => {
          const updatedFilters = [...prevFilters];
          updatedFilters[0] = {
            ...updatedFilters[0],
            selectedValue: projectTrackerStoredFilters[0]?.selectedValue,
            list: temp[0].list,
          };
          return updatedFilters;
        });
      } else {
        setFilters(temp);
      }
    }
  };

  const fetchProjectReportProgress = async (id) => {
    const res = await dispatch(getProjectTrackerData(id));
    if (res.payload.status === 200) {
      const projectTrackerData = res?.payload?.data?.data?.projectTrackerData;
      const scopeData = res?.payload?.data?.data?.scopeData;
      const totalCompletions = res?.payload?.data?.data?.totalCompletions || 0;
      setReportHeaderFooterData(scopeData);
      setReportRowsData(projectTrackerData);
      setTotalCompletion(totalCompletions.toFixed(2));

      if (
        scopeData?.length === 0 ||
        (scopeData?.length === 1 && scopeData[0]?.reports?.length === 0)
      ) {
        setIsscopeReportDataExist(false);
      } else {
        setIsscopeReportDataExist(true);
      }
    }
  };

  // Effect to load data on component mount
  useEffect(() => {
    (async () => {
      setLoading(true);
      if (projectTrackerStoredFilters?.length > 0) {
        await fetchFilters(projectTrackerStoredFilters);
        await fetchProjectReportProgress(projectTrackerStoredFilters[0]?.selectedValue);
      } else {
        await fetchFilters();
      }
      setLoading(false);
    })();
  }, []);

  const handleReload = async () => {
    setLoading(true);
    await fetchProjectReportProgress(filters[0].selectedValue);
    setLoading(false);
  };

  const handleFilterChange = async (e) => {
    setLoading(true);
    const temp = [...filters];
    const { value } = e.target;
    await fetchProjectReportProgress(value);
    temp[0] = {
      ...temp[0],
      selectedValue: e.target.value,
    };
    setFilters(temp);
    dispatch(setStoreFilters({ module: "projectTracker", filters: temp }));
    setLoading(false);
  };

  const handleResetFilter = () => {
    const temp = filters.map((filter) => ({
      ...filter,
      selectedValue: filter.list[0][Constants.MONGOOSE_ID] || filter.list[0],
    }));
    setFilters(temp);
    dispatch(resetFilters({ module: "projectTracker" }));
  };

  // Helper function to load images
  const loadImage = (url) =>
    new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = url;
    });

  const handleDownloadPdf = async (fileName) => {
    try {
      const input = document.getElementById("pdfTable");

      if (!input) {
        await dispatch(
          openSnackbar({ message: Constants.SOMETHING_WENT_WRONG, notificationType: "error" })
        );
        return;
      }

      const pdf = new JSPDF("landscape", "pt", "a4");
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const margin = 20;
      const headerHeight = 40;
      const footerHeight = 30;
      const usableWidth = pageWidth - margin * 2;
      const usableHeight = pageHeight - margin * 2 - headerHeight - footerHeight;
      const rowHeight = 30;

      const rows = Array.from(input.querySelectorAll("tr"));
      const totalColumns = input.querySelectorAll("th").length;

      const setTableStyles = (table) => {
        const tableCopy = table;
        tableCopy.style.width = `${usableWidth}px`;
        tableCopy.style.borderCollapse = "separate";
        tableCopy.style.borderSpacing = "0";
        tableCopy.style.borderRadius = "4px";
        tableCopy.style.overflow = "hidden";

        // Hide all elements with the 'eye-icon-button' class
        Array.from(tableCopy.querySelectorAll(".eye-icon-button")).forEach((icon) => {
          const item = icon;
          item.style.display = "none";
        });

        Array.from(tableCopy.querySelectorAll("th, td")).forEach((cell) => {
          const cellCopy = cell;
          cellCopy.style.border = "1px solid #E0E6F5";
          cellCopy.style.padding = "5px";
          cellCopy.style.boxSizing = "border-box";
          cellCopy.style.wordWrap = "break-word";
          cellCopy.style.overflow = "hidden";
          cellCopy.style.height = `${rowHeight}px`;
          cellCopy.style.width = `${usableWidth / totalColumns}px`;
        });
      };

      const generatePdfChunk = async (chunkHtml, pageNumber, totalPages) => {
        const chunkDiv = document.createElement("div");
        chunkDiv.innerHTML = chunkHtml;
        chunkDiv.style.position = "absolute";
        chunkDiv.style.top = "-9999px";
        chunkDiv.style.left = "-9999px";
        document.body.appendChild(chunkDiv);

        try {
          const canvas = await html2canvas(chunkDiv, { scale: 1, useCORS: true });
          const imgData = canvas.toDataURL("image/png");
          const imgWidth = usableWidth;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;

          // Add header image
          const headerImgUrl =
            "https://reynard-dev-82a6381.sfo3.cdn.digitaloceanspaces.com/images/Mail_Images/reynard_logo_pdf.png";
          const headerImg = await loadImage(headerImgUrl);

          const headerImgHeight = 35;
          const headerImgWidth = (headerImgHeight / 20) * 80;

          pdf.addImage(headerImg, "PNG", margin, margin, headerImgWidth, headerImgHeight);

          // Add title and date on the first page only
          if (pageNumber === 1) {
            const title = "Project Tracker";
            const todayDate = new Date().toLocaleDateString();

            pdf.setFontSize(16);
            pdf.setTextColor("#191A51");
            pdf.text(title, margin, headerHeight + margin + 20, { align: "left" });

            pdf.setFontSize(12);
            pdf.setTextColor("#000000");
            pdf.text(
              `(${todayDate})`,
              margin + pdf.getStringUnitWidth(title) * 14 + 18,
              headerHeight + margin + 18,
              { align: "left" }
            );
          }

          // Add the table chunk as an image
          pdf.addImage(imgData, "PNG", margin, headerHeight + margin + 35, imgWidth, imgHeight);

          // Add footer on each page
          const footerImgUrl =
            "https://reynard-dev-82a6381.sfo3.cdn.digitaloceanspaces.com/images/Mail_Images/reynard_logo_pdf.png";
          const footerImg = await loadImage(footerImgUrl);

          // Add "Powered by:" text and logo image
          pdf.setTextColor(84, 83, 83);
          pdf.setFontSize(12);
          pdf.text("Powered by:", margin, pageHeight - footerHeight + 15);

          pdf.addImage(footerImg, "PNG", margin + 70, pageHeight - footerHeight + 2, 80, 20);

          // Add page number
          pdf.text(
            `Page ${pageNumber} of ${totalPages}`,
            pageWidth - margin,
            pageHeight - footerHeight + 15,
            { align: "right" }
          );
        } catch (error) {
          await dispatch(
            openSnackbar({ message: Constants.SOMETHING_WENT_WRONG, notificationType: "error" })
          );
        } finally {
          document.body.removeChild(chunkDiv); // Clean up
        }
      };

      const processChunks = async () => {
        let startIndex = 0;
        const totalRows = rows.length;
        let pageNumber = 1;
        const totalPages = Math.ceil(totalRows / (usableHeight / rowHeight));

        // eslint-disable-next-line no-undef
        while (startIndex < totalRows) {
          const chunk = document.createElement("table");
          const thead = input.querySelector("thead").cloneNode(true);
          const tbody = document.createElement("tbody");

          if (startIndex !== 0) {
            chunk.appendChild(thead);
          }
          let accumulatedHeight = 0;

          // Add rows until the accumulated height reaches the usable height
          while (startIndex < totalRows && accumulatedHeight + rowHeight <= usableHeight) {
            const row = rows[startIndex].cloneNode(true);
            tbody.appendChild(row);
            accumulatedHeight += rowHeight;
            startIndex += 1;
          }

          chunk.appendChild(tbody);
          setTableStyles(chunk);

          // Capture the chunk as an image and add to the PDF
          // eslint-disable-next-line
          await generatePdfChunk(chunk.outerHTML, pageNumber, totalPages);

          if (startIndex < totalRows) {
            pdf.addPage();
            pageNumber += 1;
          }
        }

        // Save PDF
        pdf.save(fileName);
      };

      try {
        await processChunks();
      } catch (error) {
        await dispatch(
          openSnackbar({ message: Constants.SOMETHING_WENT_WRONG, notificationType: "error" })
        );
      }
    } catch (err) {
      await dispatch(
        openSnackbar({ message: Constants.SOMETHING_WENT_WRONG, notificationType: "error" })
      );
    }
  };

  const handleExportProjectTrackerPdf = async (format) => {
    setAnchorEl(null);
    setPdfLoading(true);
    const fileName = `Reynard_Project_Tracker(${
      filters[0].list.find(
        (item) => item[Constants.MONGOOSE_ID] === filters[0]?.selectedValue.replace(/ /g, "_")
      )?.title || ""
    }).${format}`;
    if (format === "pdf") {
      await handleDownloadPdf(fileName);
    } else {
      try {
        const browserDateTime = moment().format(defaultData.WEB_24_HOURS_FORMAT);
        const res = await dispatch(
          exportProjectTrackerExcel({ projectId: filters[0].selectedValue, browserDateTime })
        );
        const url = window.URL.createObjectURL(res.payload);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (err) {
        await dispatch(
          openSnackbar({ message: Constants.SOMETHING_WENT_WRONG, notificationType: "error" })
        );
      }
    }
    setPdfLoading(false);
  };

  const handleOpenExportMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseExportMenu = () => {
    setAnchorEl(null);
  };

  const { columns, rows, extraHeadersList, footerList } = reportCalculationData(
    reportHeaderFooterData,
    reportRowsData,
    totalCompletion,
    handleLocationClick
  );

  const renderContent = () => {
    if (loading) {
      return (
        <MDBox
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {Icons.LOADING2}
        </MDBox>
      );
    }
    if (filters[0]?.selectedValue === "select" || !isscopeReportDataExist) {
      return (
        <Card sx={{ height: "60vh", marginTop: 3 }}>
          <MDBox display="flex" justifyContent="center" alignItems="center" height="100%">
            <MDTypography sx={{ fontSize: "25px", fontWeight: "semibold", opacity: "0.8" }}>
              {filters[0]?.selectedValue === "select"
                ? "Please Select Project"
                : "Nothing to track for selected project"}
            </MDTypography>
          </MDBox>
        </Card>
      );
    }
    return (
      <MDBox
        sx={{
          paddingTop: pxToRem(24),
          paddingBottom: pxToRem(32),
        }}
        ref={projectTrackerRef}
        id="pdfTable"
      >
        <ProjectTrackerTable
          table={{ columns, rows }}
          isSorted={false}
          loading={Constants.FULFILLED}
          extraHeaders={extraHeadersList}
          footerList={footerList}
        />
      </MDBox>
    );
  };

  return (
    <>
      <DashboardLayout module={defaultData.REPORT_SCREEN_ID}>
        <DashboardNavbar />
        <MDBox display="flex" justifyContent="space-between">
          <PageTitle title={PageTitles.REPORT_CALCULATION} />
          <BasicButton
            icon={Icons.RELOAD}
            background={Colors.WHITE}
            border
            color={Colors.BLACK}
            action={handleReload}
            disabled={filters[0]?.selectedValue === "select"}
          />
        </MDBox>
        <Divider sx={{ marginTop: 2 }} />
        <Feature name={FeatureTags.PROJECT_TRACKER}>
          <>
            <MDBox display="flex" justifyContent="space-between" mt={1} mx={0} width="100%">
              <MDBox
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "start",
                  alignItems: "flex-end",
                }}
                mt={0}
                mx={0}
                width="100%"
              >
                {filters?.map((val) => (
                  <FilterDropdown
                    key={val.inputLabel}
                    label={val.inputLabel}
                    name={val.inputLabel}
                    defaultValue={val?.selectedValue}
                    value={val?.selectedValue}
                    handleChange={handleFilterChange}
                    menu={val.list}
                    maxContent={defaultData.MEDIUM_CONTENT_LENGTH}
                    textTransform={val.textTransform}
                  />
                ))}
                <ResetFilterButton handleReset={handleResetFilter} style={{ marginLeft: "1rem" }} />
              </MDBox>
              <MDBox
                sx={{
                  display: "flex",
                  alignItems: "end",
                  flexWrap: "wrap",
                }}
              >
                <Button
                  sx={{
                    m: 2,
                    mt: pxToRem(45),
                    ml: 0,
                    mb: 0,
                    backgroundColor: "#fff",
                    "&:hover": {
                      backgroundColor: "#fff",
                    },
                    fontSize: pxToRem(14),
                    textTransform: "capitalize",
                    width: pxToRem(130),
                  }}
                  variant="outlined"
                  color="info"
                  onClick={handleOpenExportMenu}
                  startIcon={Icons.EXPORT}
                  disabled={
                    filters[0]?.selectedValue === "select" || pdfLoading || !isscopeReportDataExist
                  }
                >
                  {pdfLoading ? ButtonTitles.EXPORTING : ButtonTitles.EXPORT}
                </Button>
              </MDBox>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseExportMenu}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={() => handleExportProjectTrackerPdf("xlsx")}>CSV</MenuItem>
                <MenuItem onClick={() => handleExportProjectTrackerPdf("pdf")}>PDF</MenuItem>
              </Menu>
            </MDBox>
            {renderContent()}
          </>
        </Feature>
      </DashboardLayout>
      {viewLocationProgressAnchor.right && (
        <LocationCalculation
          viewLocationProgressAnchor={viewLocationProgressAnchor}
          setViewLocationProgressAnchor={setViewLocationProgressAnchor}
          locationData={locationData}
          setLocationData={setLocationData}
        />
      )}
    </>
  );
};

export default ReportCalculation;
