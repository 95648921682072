import React, { useState } from "react";

// MUI components
import { Icon } from "@mui/material";

// custom components
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import FontComponent from "components/Responsive/fonts";
import DynamicTypeData from "components/Table/DynamicTypeData";
import TextField from "@mui/material/TextField";
import MDTypography from "components/MDTypography";

// utils
import Constants, { ButtonTitles, Icons, Colors } from "utils/Constants";
import TextMessage from "utils/Constants/Messages.constants";

// 3rd party libs
import SignaturePad from "react-signature-canvas";
import PropTypes from "prop-types";

// redux
import { useDispatch } from "react-redux";
import uploadImageThunk from "redux/Thunks/ImageUpload";
import { openSnackbar } from "redux/Slice/Notification";

function SignaturePadComponent({ keyName, handleSignaturePad, folderName, displayError }) {
  const [isBtnClick, setIsBtnClick] = useState(false);
  const [signaturePad, setSignaturePad] = useState(null);
  const [imageData, setImageData] = useState(null);
  const [loading, setLoading] = useState(false);
  const fontSize = FontComponent({ sizes: {} });
  const dispatch = useDispatch();

  const openSignaturePad = () => {
    setIsBtnClick(true);
  };

  const closeSignaturePad = () => {
    setIsBtnClick(false);
  };

  const handleSubmit = async () => {
    if (signaturePad) {
      setLoading(true);
      const dataUrl = signaturePad.toDataURL("image/png"); // Capture the signature as a base64 data URL

      // Function to convert a base64 URL to a binary File object
      const dataUrlToFile = (Url, fileName) => {
        const byteString = atob(Url.split(",")[1]); // Decode base64
        const mimeString = Url.split(",")[0].split(":")[1].split(";")[0]; // Get MIME type
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i += 1) {
          ia[i] = byteString.charCodeAt(i);
        }
        return new File([ab], fileName, { type: mimeString });
      };

      // Convert the base64 signature to a binary File object
      const signatureFile = dataUrlToFile(dataUrl, "signature.png");

      try {
        // Send the binary File object to your API
        const response = await dispatch(
          uploadImageThunk({ file: signatureFile, type: folderName })
        );
        const imageObj = {
          name: response.payload.data.fileName,
          size: response.payload.data.sizeOfFile,
          url: response.payload.data.iconUrl,
        };
        setImageData(imageObj);
        handleSignaturePad(keyName, imageObj);
        closeSignaturePad();
      } catch (error) {
        dispatch(
          openSnackbar({
            message: Constants.SOMETHING_WENT_WRONG,
            notificationType: TextMessage.NOTIFICATION_ERROR,
          })
        );
      }
      setLoading(false);
    }
  };

  const clearSignature = () => {
    if (signaturePad) {
      signaturePad.clear(); // Clear the signature pad
    }
  };

  return (
    <>
      <MDBox
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "start",
        }}
      >
        <MDBox sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <TextField
            onClick={openSignaturePad}
            defaultValue="Sign Here"
            variant="standard"
            InputProps={{
              readOnly: true, // Make it read-only so it can't be edited
              style: {
                color: displayError && !imageData ? "#FF2E2E" : "inherit", // Apply red color to text when there's an error
              },
            }}
            sx={{
              width: "150px",
              borderBottom: displayError && !imageData && "1px solid red",
            }}
          />
          {displayError && !imageData && (
            <MDTypography variant="button" fontWeight="light" color="error">
              Signature is required
            </MDTypography>
          )}
        </MDBox>
        {imageData && imageData.url && (
          <DynamicTypeData type="signature" data={imageData.url} imageArray={[imageData.url]} />
        )}
      </MDBox>

      {isBtnClick && (
        <MDBox
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 9999,
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {/* Close Icon */}
          <Icon
            sx={{
              position: "absolute",
              top: "2%",
              right: "2%",
              cursor: "pointer",
              zIndex: 10000,
              height: "5%",
              width: "5%",
              color: "#fff",
            }}
            onClick={closeSignaturePad}
          >
            {Icons.REJECT}
          </Icon>

          {/* Signature Pad */}
          <MDBox
            style={{
              backgroundColor: "#fff",
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.3)",
              width: "50%",
              maxWidth: "50%",
              height: "70%",
              position: "relative",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <SignaturePad
              ref={(ref) => setSignaturePad(ref)}
              penColor={Colors.PRIMARY}
              canvasProps={{
                style: {
                  border: "1px solid #000",
                  width: "100%",
                  height: "100%",
                  borderRadius: "10px",
                },
              }}
            />

            <MDBox sx={{ display: "flex", justifyContent: "flex-end", gap: 2, marginTop: "20px" }}>
              {/* Clear Button */}
              <MDButton
                variant="outlined"
                color="primary"
                onClick={clearSignature}
                disabled={loading}
                style={{
                  textTransform: "none",
                }}
              >
                <span
                  style={{
                    fontSize,
                  }}
                >
                  Clear
                </span>
              </MDButton>

              {/* Submit Button */}
              <MDButton
                variant="contained"
                color="info"
                onClick={handleSubmit}
                disabled={loading}
                style={{
                  textTransform: "none",
                }}
              >
                <span
                  style={{
                    fontSize,
                  }}
                >
                  {!loading ? ButtonTitles.SIGNATURE : ButtonTitles.LOADING}
                </span>
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      )}
    </>
  );
}

export default SignaturePadComponent;

SignaturePadComponent.defaultProps = {
  keyName: "",
  handleSignaturePad: () => {},
  folderName: "",
  displayError: false,
};

SignaturePadComponent.propTypes = {
  keyName: PropTypes.string,
  handleSignaturePad: PropTypes.func,
  folderName: PropTypes.string,
  displayError: PropTypes.bool,
};
