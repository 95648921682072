import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Custom Components
import MDBox from "components/MDBox";
import Author from "components/Table/Author";

// MUI Components
import { IconButton, Popover, Switch, Tooltip } from "@mui/material";

// Redux
import { updateAdminProfileThunk } from "redux/Thunks/SuperAdmin";
import { openSnackbar } from "redux/Slice/Notification";
import { useDispatch } from "react-redux";

// Constants
import Constants, { Icons, defaultData, Common } from "utils/Constants";

// 3rd party library
import PropTypes from "prop-types";
import pxToRem from "assets/theme/functions/pxToRem";
import MDTypography from "components/MDTypography";

export function UserProjects({ projectList }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <MDBox display="flex" alignItems="center">
      {projectList[0]?.title?.length > 18 ? (
        <Tooltip title={projectList[0]?.title}>
          <MDTypography sx={{ fontSize: pxToRem(14) }}>{`${projectList[0]?.title.slice(
            0,
            18
          )}...`}</MDTypography>
        </Tooltip>
      ) : (
        <MDTypography sx={{ fontSize: pxToRem(14) }}>{projectList[0]?.title}</MDTypography>
      )}
      {projectList.length > 1 && (
        <IconButton aria-label="report-info-status" color="info" onClick={handleClick}>
          {Icons.INFO}
        </IconButton>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{ height: "50%" }}
      >
        <MDBox sx={{ p: 1, backgroundColor: "#f5f5f5" }}>
          {projectList.map((project) =>
            project?.title?.length > defaultData.SMALLER_CONTENT_LENGTH ? (
              <Tooltip title={project?.title}>
                <MDTypography
                  sx={{ p: 1, backgroundColor: "#f5f5f5", fontSize: pxToRem(14) }}
                >{`${project?.title?.slice(
                  0,
                  defaultData.SMALLER_CONTENT_LENGTH
                )}...`}</MDTypography>
              </Tooltip>
            ) : (
              <MDTypography sx={{ p: 1, backgroundColor: "#f5f5f5", fontSize: pxToRem(14) }}>
                {project?.title}
              </MDTypography>
            )
          )}
        </MDBox>
      </Popover>
    </MDBox>
  );
}

UserProjects.propTypes = {
  projectList: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default function User(userData, setShouldUpdateState, setLoadingStatus, permission) {
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();

  const handleUserStatus = async (id, value) => {
    const b = {
      body: { isActive: value, id },
      id,
    };
    setLoadingStatus(Constants.PENDING);
    const res = await dispatch(updateAdminProfileThunk(b));
    if (res.payload.status === Common.API_STATUS_200) {
      setShouldUpdateState((prev) => !prev);
      await dispatch(
        openSnackbar({ message: Constants.USER_STATUS_UPDATE_SUCCESS, notificationType: "success" })
      );
    }
    setLoadingStatus(Constants.FULFILLED);
  };

  useEffect(() => {
    if (userData) {
      const tempRows = userData
        .filter(
          (element) =>
            element.role?.title.toLowerCase() !== defaultData.ADMIN_ROLE &&
            element.role?.title !== defaultData.SUPER_ADMIN_ROLE
        )
        .map((element, index) => {
          const temp = {
            srNo: <Author name={index + 1} />,
            name: (
              <Link to={`/client/personnel/profile/${element?.[Constants.MONGOOSE_ID]}`}>
                <Author
                  name={`${element?.callingName ? element?.callingName : element?.firstName} ${
                    element?.lastName
                  }`}
                />
              </Link>
            ),
            email: <Author name={element?.email} style={{ textTransform: "inherit" }} />,
            contactnumber: (
              <Author
                name={
                  element?.contactNumber?.number
                    ? `${element?.contactNumber?.in}${element?.contactNumber?.number}`
                    : Constants.NA
                }
              />
            ),
            role: <Author name={element?.role?.title} />,
            rating: (
              <Author name={element?.rating?.length > 0 ? element?.rating[0]?.rating : null} />
            ),
            profileFunction: <Author name={element?.profileFunction?.name} />,
            assignedProjects:
              element?.projectDetails.length > 0 ? (
                <UserProjects projectList={element?.projectDetails} />
              ) : (
                <Author name={" "} />
              ),
            accessType: <Author name={element?.role?.accessType} />,
            isactive: (
              <Switch
                checked={element?.isActive}
                onChange={(e) =>
                  handleUserStatus(element?.[Constants.MONGOOSE_ID], e.target.checked)
                }
                disabled={!permission?.update}
              />
            ),
            action: (
              <MDBox>
                <Link to={`/client/personnel/profile/${element?.[Constants.MONGOOSE_ID]}`}>
                  <IconButton color="secondary" fontSize="medium" sx={{ cursor: "pointer" }}>
                    {Icons.VIEW}
                  </IconButton>
                </Link>
              </MDBox>
            ),
          };
          return temp;
        });
      setRows([...tempRows]);
    }
  }, [userData, permission]);

  return {
    columns: [
      { Header: "No.", accessor: "srNo", width: "7%" },
      {
        Header: "Name",
        accessor: "name",
        width: "20%",
        align: "left",
      },
      { Header: "Email", accessor: "email", width: "20%", align: "left" },
      { Header: "Contact", accessor: "contactnumber", width: "20%", align: "left" },
      { Header: "Role", accessor: "role", width: "10%", align: "left" },
      { Header: "Rating", accessor: "rating", width: "5%", align: "left" },
      { Header: "Profile Function", accessor: "profileFunction", width: "10%", align: "left" },
      { Header: "Assigned Projects", accessor: "assignedProjects", width: "10%", align: "left" },
      { Header: "Access Type", accessor: "accessType", width: "10%", align: "left" },
      { Header: "Is Active", accessor: "isactive", width: "10%", align: "left" },
      { Header: "Action", accessor: "action", width: "8%", align: "left" },
    ],
    rows,
  };
}
