const userMgtRowData = {
  _id: "",
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  role: {
    _id: "",
    title: "",
    isActive: false,
    accessType: "",
  },
  contactNumber: {
    number: "",
    code: "",
    in: "",
  },
  emergencyContactNumber: {
    number: "",
    code: "",
    in: "",
  },
  isActive: false,
  account: "",
  isDeleted: false,
  resetExpiresIn: "",
  resetToken: "",
  secondaryEmail: null,
  nationality: null,
  motherLanguage: null,
  placeOfBirth: null,
  birthdate: null,
  maritalStatus: null,
  ssn: null,
  bloodGroup: null,
  allergies: null,
  companyName: null,
  companyLogo: null,
  profileImage: null,
  callingName: null,
  address: null,
  gender: null,
  street: null,
  zipCode: null,
  city: null,
  state: null,
  area: null,
  country: null,
  prefAirportDeprt: null,
  travelTimeToAirport: null,
  secondaryPrefAirportDeprt: null,
  travelTimeToSecondAirport: null,
  certificate: [],
  contractualDetailId: null,
  certificateId: null,
  businessPurpose: false,
  emailCommunication: false,
  clothesSize: "",
  shoeSize: "",
  windaId: null,
  profileFunction: {
    _id: "",
    name: "",
    isActive: false,
  },
  createdBy: "",
  updateBy: null,
  drivingLicence: [],
  curriculumVitae: [],
  seamansBook: [],
  medical: [],
  gdpr: [],
  createdAt: "",
  updatedAt: "",
  __v: 0,
  projectDetails: [],
};

export default userMgtRowData;
