import { useState } from "react";

// MUI Components
import { Box, FormControl, IconButton, MenuItem, Select, Tooltip } from "@mui/material";

// Custom Components
import FormControlErrorStyles from "assets/style/Component";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FontComponent from "components/Responsive/fonts";

// Functions
import pxToRem from "assets/theme/functions/pxToRem";

// Constants
import Cosntants, { Icons, defaultData, Colors } from "utils/Constants";

// 3rd party
import PropTypes from "prop-types";

export default function FilterDropdown({
  label,
  name,
  defaultValue,
  value,
  handleChange,
  menu,
  disabled = false,
  style,
  maxContent = defaultData.FILTER_MINIMUM_LENGTH,
  textTransform,
}) {
  const [open, setOpen] = useState(false);
  const fontSize = FontComponent({ sizes: {} });
  const labelFontSize = FontComponent({
    sizes: {},
  });

  return (
    <FormControl
      sx={{
        mr: 2,
        mt: pxToRem(20),
        ml: 0,
        mb: 0,
        minWidth: 160,
        maxHeight: 400,
        ...FormControlErrorStyles,
        ...style,
      }}
      size="small"
    >
      <MDTypography
        variant="caption"
        mb={1}
        sx={{
          fontSize: labelFontSize,
          fontWeight: 600,
          color: "#344054",
        }}
      >
        {label}
      </MDTypography>
      <Select
        displayEmpty
        labelId="demo-select-small"
        id="demo-select-small"
        name={name}
        defaultValue={defaultValue}
        disabled={disabled}
        value={value}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        sx={{
          height: 40,
          color: "black",
          fontWeight: 600,
          paddingY: "0.65rem",
          paddingRight: "0.55rem",
          maxHeight: 100,
          cursor: "pointer",
          backgroundColor: disabled ? Colors.DISABLED : "#fff",
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 29,
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          PaperProps: {
            style: {
              maxHeight: 200,
              opacity: 1,
              transform: "none",
              top: 183,
              left: 442,
            },
          },
        }}
        onChange={handleChange}
        renderValue={(selected) => {
          const val = menu.filter(
            (opt) => opt?.[Cosntants.MONGOOSE_ID] === selected || opt === selected
          )[0];
          return (
            <MDBox sx={{ display: "flex", alignItems: "center" }}>
              {val?.color && val?.color !== "" ? (
                <Box
                  component="span"
                  sx={{
                    backgroundColor: `#${val?.color}`,
                    borderRadius: "50%",
                    width: "10px",
                    height: "10px",
                    mr: 1,
                  }}
                />
              ) : null}
              <MDTypography variant="caption" sx={{ textTransform, fontSize }}>
                {val && val?.title?.length > maxContent ? (
                  <Tooltip title={val?.title}>
                    <span>{`${val?.title.slice(0, maxContent)}...`}</span>
                  </Tooltip>
                ) : (
                  val?.title || val
                )}
              </MDTypography>
            </MDBox>
          );
        }}
      >
        {menu.map((item) => (
          <MenuItem
            value={item[Cosntants.MONGOOSE_ID] || item}
            sx={{
              maxHeight: 400,
              fontSize: pxToRem(14),
              fontWeight: 400,
              marginTop: "4px",
              color: "#344054",
            }}
            disabled={item[Cosntants.MONGOOSE_ID] === "select" || item === "select"}
            key={item[Cosntants.MONGOOSE_ID] || item.replace(/\s/g, "")}
          >
            {item?.color && item?.color !== "" && (
              <Box
                component="span"
                sx={{
                  backgroundColor: `#${item?.color}`,
                  borderRadius: "50%",
                  width: "10px",
                  height: "10px",
                  mr: 1,
                }}
              />
            )}
            <MDTypography
              variant="caption"
              sx={{
                textTransform,
                fontSize,
                fontWeight: 400,
                color: "#667085",
                display: "flex",
              }}
            >
              {item && item?.title?.length > maxContent ? (
                <Tooltip title={item.title}>
                  <span>{`${item.title.slice(0, maxContent)}...`}</span>
                </Tooltip>
              ) : (
                item.title || item
              )}
            </MDTypography>
          </MenuItem>
        ))}
      </Select>
      <IconButton
        onClick={() => setOpen(!open)}
        disabled={disabled}
        aria-label="fingerprint"
        color="info"
        sx={{
          position: "absolute",
          top: "70%",
          right: 0,
          transform: "translateY(-50%)",
          zIndex: 1,
        }}
      >
        {Icons.ARROW_DOWN}
      </IconButton>
    </FormControl>
  );
}

FilterDropdown.defaultProps = {
  label: "",
  name: "",
  defaultValue: "",
  disabled: false,
  value: "",
  style: {},
  menu: [],
  maxContent: defaultData.FILTER_MINIMUM_LENGTH,
  handleChange: () => {},
  textTransform: "capitalize",
};

FilterDropdown.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.any),
  menu: PropTypes.arrayOf(PropTypes.object),
  maxContent: PropTypes.number,
  handleChange: PropTypes.func,
  textTransform: PropTypes.string,
};
