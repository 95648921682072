import { Divider, FormControl, Grid, IconButton, MenuItem, Select } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import PageTitle from "examples/NewDesign/PageTitle";
import Constants, { PageTitles, Icons } from "utils/Constants";
import { useDispatch, useSelector } from "react-redux";
import pxToRem from "assets/theme/functions/pxToRem";
import { updateSyncupTime } from "redux/Thunks/UserManagement";
import configThunk from "redux/Thunks/Config";
import { openSnackbar } from "redux/Slice/Notification";

function Setting() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { config } = useSelector((state) => state.config);
  const [syncUptime, setSyncUptime] = useState({
    currentSyncUpTime: 0,
    syncUpTime: Array.from({ length: 24 }, (_hour, hours) =>
      Array.from({ length: 4 }, (_min, index) => ({
        hours: index === 3 ? hours + 1 : hours,
        min: index === 3 ? 0 : (index + 1) * 15,
        id: `${hours}${index}`,
      }))
    ).flat(),
  });
  const { cards = [] } = useSelector((state) => state.setting);

  // UseEffect for sync up time storing & logic
  useEffect(() => {
    (async () => {
      if (config?.[0]?.syncUpTime) {
        const index = syncUptime.syncUpTime.findIndex(
          (val) =>
            val.hours === parseInt(config?.[0]?.syncUpTime?.hours, 10) &&
            val.min === parseInt(config?.[0]?.syncUpTime?.min, 10)
        );
        setSyncUptime((prev) => {
          const temp = { ...prev };
          temp.currentSyncUpTime = index;
          return temp;
        });
      }
    })();
  }, [config]);

  // Function to update Sync up time
  const handleSyncUptime = async (e) => {
    const index = syncUptime.syncUpTime.findIndex((val) => val.id === e.target.value);
    const paramAndBody = {
      body: {
        syncUpTime: {
          hours: syncUptime.syncUpTime[index].hours,
          min: syncUptime.syncUpTime[index].min,
        },
      },
      accountId: config?.[0]?.account,
    };
    const res = await dispatch(updateSyncupTime(paramAndBody));
    if (res.payload.status === 200) {
      await dispatch(configThunk());
      await dispatch(
        openSnackbar({
          message: Constants.SYNC_UP_TIME_SUCCESS,
          notificationType: Constants.NOTIFICATION_SUCCESS,
        })
      );
    } else {
      dispatch(
        openSnackbar({
          message: Constants.SYNC_UP_TIME_ERROR,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
    }
  };

  // Function to open dropdown menu
  const handleOpenMenu = () => {
    setOpen(!open);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <PageTitle title={PageTitles.SETTINGS} color="#101828" />
      <Divider sx={{ marginTop: 3 }} />
      <MDBox py={3} mt={3}>
        <Grid container spacing={3} sx={{ display: "flex" }}>
          {cards
            .filter((val) => val.isVisible)
            .map((val) => (
              <Grid item xs={12} sm={6} md={6} lg={4} sx={{ display: "flex" }} key={val.index}>
                <MDBox mb={1.5} sx={{ flex: "1 1 auto" }}>
                  <Card
                    sx={{
                      boxShadow: "none",
                      border: "1px solid #E0E6F5",
                      minHeight: "280px",
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                    }}
                  >
                    <MDBox display="flex" pt={1} px={2}>
                      <MDBox
                        bgColor={val.logoBgr}
                        borderRadius="xl"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width="4rem"
                        height="4rem"
                        p="1rem"
                        mt={-3}
                      >
                        <img src={val.logo} alt="complex" />
                      </MDBox>
                      <MDBox flexGrow={1} textAlign="right" lineHeight={1.25} p={1}>
                        <MDTypography variant="h4" fontWeight="bold" sx={{ color: "#191D31" }}>
                          {val.cardTitle}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <MDBox mt={2} mb={2} flexGrow={1}>
                      <List>
                        {val.menu.length > 0 ? (
                          val.menu
                            .filter((item) => item.isVisible)
                            .map((item) =>
                              item.key === "roles-permission" ? (
                                <MDBox
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <MDTypography
                                    sx={{
                                      fontSize: pxToRem(16),
                                      fontWeight: 500,
                                      color: "#667085",
                                      marginLeft: pxToRem(15),
                                    }}
                                  >
                                    Sync up time
                                  </MDTypography>
                                  <FormControl
                                    onClick={handleOpenMenu}
                                    variant="standard"
                                    sx={{
                                      marginRight: pxToRem(10),
                                      width: "45%",
                                      maxHeight: 400,
                                    }}
                                    size="small"
                                  >
                                    <Select
                                      open={open}
                                      onClose={() => setOpen(false)}
                                      onOpen={() => setOpen(true)}
                                      value={
                                        syncUptime.syncUpTime[syncUptime.currentSyncUpTime]?.id
                                      }
                                      sx={{
                                        minWidth: "100%",
                                        "& .MuiInputBase-input": {
                                          fontSize: pxToRem(16),
                                          fontWeight: 400,
                                          color: "#667085",
                                        },
                                        textTransform: "capitalize",
                                        backgroundColor: "black",
                                        paddingY: "0.65rem",
                                        paddingRight: "0.55rem",
                                        maxHeight: 100,
                                        cursor: "pointer",
                                      }}
                                      MenuProps={{
                                        anchorOrigin: {
                                          vertical: 39,
                                          horizontal: "left",
                                        },
                                        transformOrigin: {
                                          vertical: "top",
                                          horizontal: "left",
                                        },
                                        PaperProps: {
                                          style: {
                                            maxHeight: 200,
                                            opacity: 1,
                                            transform: "none",
                                            border: "1px solid #D0D5DD",
                                          },
                                        },
                                      }}
                                      onChange={handleSyncUptime}
                                      IconComponent={Icons.DROPDOWN}
                                    >
                                      {syncUptime.syncUpTime.map((element) => (
                                        <MenuItem
                                          value={element.id}
                                          sx={{
                                            textTransform: "capitalize",
                                            maxHeight: 400,
                                            fontSize: pxToRem(16),
                                            fontWeight: 400,
                                            color: "#344054",
                                          }}
                                          key={element.id}
                                        >
                                          {(element.hours === 0 &&
                                            element.min !== 0 &&
                                            `${element.min}min`) ||
                                            (element.hours !== 0 &&
                                              element.min === 0 &&
                                              `${element.hours}hr`) ||
                                            (element.hours !== 0 &&
                                              element.min !== 0 &&
                                              `${element.hours}hr ${element.min}min`)}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </MDBox>
                              ) : (
                                <ListItem key={item.key} disablePadding>
                                  <ListItemButton component={Link} to={item.link}>
                                    <ListItemText>
                                      <MDTypography
                                        variant="body2"
                                        gutterBottom
                                        sx={{ color: "#667085" }}
                                      >
                                        {item.menuTitle}
                                      </MDTypography>
                                    </ListItemText>
                                  </ListItemButton>
                                  <IconButton
                                    edge="end"
                                    aria-label="comments"
                                    sx={{ marginRight: 2 }}
                                  >
                                    {item.icon}
                                  </IconButton>
                                </ListItem>
                              )
                            )
                        ) : (
                          <ListItem disablePadding>
                            <ListItemButton>
                              <ListItemText>
                                <MDTypography variant="body2" gutterBottom>
                                  Coming Soon....
                                </MDTypography>
                              </ListItemText>
                            </ListItemButton>
                          </ListItem>
                        )}
                      </List>
                    </MDBox>
                  </Card>
                </MDBox>
              </Grid>
            ))}
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
export default Setting;
