const reportsRowData = {
  id: ["", "", ""],
  project: {
    _id: "",
    title: "",
  },
  users: [
    {
      _id: "",
      callingName: "",
      firstName: "",
      lastName: "",
      email: "",
    },
    {
      _id: "",
      callingName: "",
      firstName: "",
      lastName: "",
      email: "",
    },
    {
      _id: "",
      callingName: "",
      firstName: "",
      lastName: "",
      email: "",
    },
  ],
  createdAt: "",
  reportQuestions: [
    {
      _id: "",
      title: "",
      deletedAt: null,
    },
    {
      _id: "",
      title: "",
      deletedAt: null,
    },
    {
      _id: "",
      title: "",
      deletedAt: null,
    },
    {
      _id: "",
      title: "",
      deletedAt: null,
    },
    {
      _id: "",
      title: "",
      deletedAt: null,
    },
    {
      _id: "",
      title: "",
      deletedAt: null,
    },
  ],
  userReportAnswers: [
    {
      _id: {
        reportQuestion: "",
      },
      groupedAnswers: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
    },
    {
      _id: {
        reportQuestion: "",
      },
      groupedAnswers: [""],
    },
  ],
  reportQuestionAnswers: [
    {
      _id: {
        reportQuestion: "",
      },
      groupedTitles: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
    },
    {
      _id: {
        reportQuestion: "",
      },
      groupedTitles: ["", ""],
    },
    {
      _id: {
        reportQuestion: "",
      },
      groupedTitles: ["", ""],
    },
    {
      _id: {
        reportQuestion: "",
      },
      groupedTitles: [""],
    },
    {
      _id: {
        reportQuestion: "",
      },
      groupedTitles: [""],
    },
    {
      _id: {
        reportQuestion: "",
      },
      groupedTitles: [""],
    },
  ],
  totalQuestions: 0,
  totalAnsweredQuestions: 0,
  location: {
    _id: "",
    title: "",
  },
  report: {
    _id: "",
    title: "",
  },
  asset: [
    {
      _id: "",
      cableName: "",
    },
    {
      _id: "",
      cableName: "",
    },
  ],
  status: ["", "", ""],
  userProjectReport: "",
};

export default reportsRowData;
