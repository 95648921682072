import React from "react";

// MUI Components
import { Checkbox } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

// 3rd party library
import PropTypes from "prop-types";
import MDBox from "components/MDBox";

export default function CustomCheckbox({ name, checked, onChange, disabled }) {
  return (
    <MDBox
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Checkbox
        name={name}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        style={{
          border: disabled ? "2px solid #BDBDBD" : "2px solid #191A51",
          borderRadius: "2px",
          padding: "0",
          height: "18px",
          width: "18px",
          opacity: disabled ? 0.5 : 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        icon={
          <CheckCircleOutlineIcon
            style={{
              border: "0px solid #191A51",
              borderRadius: "0px",
              padding: "0",
              margin: 0,
              display: "block",
            }}
          />
        }
        checkedIcon={
          <CheckCircleIcon
            style={{
              border: "0px solid #191A51",
              borderRadius: "0px",
              padding: "0",
              margin: 0,
              display: "block",
            }}
          />
        }
      />
    </MDBox>
  );
}

CustomCheckbox.defaultProps = {
  name: "",
  checked: false,
  onChange: () => {},
  disabled: false,
};

CustomCheckbox.propTypes = {
  name: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};
