// Button titles
const ButtonTitles = {
  LOGIN_BUTTON: "Login",
  FORGET_PASSWORD_BUTTON: "Forgot Password",
  BACK_TO_LOGIN_BUTTON: "Back to Login",
  RESET_PASSWORD_BUTTON: "Reset Password",
  NEW_SAFE: "Safe Card",
  NEW_UNSAFE: "Unsafe Card",
  NEW_NCR: "NCR Card",
  NEW_INCIDENT: "Incident Card",
  NEW_SHIFT: "Shift Card",
  SHIFT_STATUS: "Shift Status",
  NEW_MEMBER: "Member",
  NEW_ACTIVITY: "Activity",
  NEW_CATEGORY: "Category",
  NEW_REPORT: "Report",
  NEW_DPR: "DPR",
  NEW_PROJECT: "Project",
  NEW_LOCATION: "Location",
  NEW_PROJECT_STRING: "Project String",
  NEW_ASSET: "Asset",
  NEW_FUNCTION: "Function",
  SETUP_REPORT: "Setup Report",
  NEW_REPORT_PARAMETER_FIGURE: "Parameter & Figure",
  RESET_FILTER: "Reset Filter",
  EXPORT: "Export",
  EXPORTING: "Exporting...",
  PRINT: "Print",
  PRINTING: "Printing...",
  LOADING: "Loading...",
  SUBMIT: "Submit",
  SUBMIT_LOADING: "Submitting...",
  UPDATE: "Update",
  UPDATE_LOADING: "Updating...",
  CREATE_DROPDOWN: "Create Dropdown Field",
  CREATE_CHECKBOX: "Create Checkbox Field",
  CREATE_SHORTANSWER: "Create Short Field",
  CREATE_DATE: "Create Date Field",
  CREATE_DATETIME: "Create Datetime Field",
  CREATE_NUMBER: "Create Number Field",
  CREATE_SLIDER: "Create Slider Field",
  CREATE_BOOLEAN: "Create Boolean Field",
  APPROVE_LICENSE: "Approve",
  REJECT_LICENSE: "Reject",
  NEW_FIELD: "Add a Field",
  STATUS: "Status",
  CREATE_FIELD: "Create Field",
  CREATE_FIELD_LOADING: "Creating Field...",
  UPDATE_FIELD: "Update Field",
  UPDATE_FIELD_LOADING: "Updating Field...",
  ADD_OPTIONS: "Add Options",
  APPROVE_CERTIFICATE: "Approve",
  REJECT_CERTIFICATE: "Reject",
  NEW_ROLE: "Role",
  NEGATIVE_BUTTON: "No",
  POSITIVE_BUTTON: "Yes",
  POSITIVE_LOADING_BUTTON: "Loading...",
  SWITCH_TO_SUPERADMIN: "Switch to Super Admin",
  NEW_WAREHOUSE: "Warehouse",
  UPDATE_AND_CONTINUE: "Update and Continue",
  SAVE_AND_CONTINUE: "Save and Continue",
  ADD_PRODUCT: "Add Product",
  EDIT_DETAILS: "Edit Details",
  SAVE: "Save",
  CANCEL: "Cancel",
  DELTE: "Delete",
  EDIT: "Edit",
  BACK: "Back",
  DOWNLOAD_IMPORT_SAMPLE: "Download Import Sample",
  NEW_REQUEST: "Request",
  PENDING: "Pending",
  APPROVED: "Approved",
  REJECTED: "Rejected",
  PLACE_ORDER: "Place Order",
  APPROVE: "Approve",
  ADD_TO_QUEUE: "Add",
  REJECT: "Reject",
  MARK_AS_READ: "Mark as read",
  MARK_ALL_AS_READ: "Mark all as read",
  PROFILE_FUNCTION: "Profile Function",
  DELETE: "Delete",
  RETURN: "Return",
  RETURN_CART: "Add to Return cart",
  ORDER: "Order",
  EDIT_STOCKS: "Edit Stocks",
  ADD_STOCKS: "Add Stocks",
  VIEW_list: "View List",
  ADD: "Add",
  DELETING: "Deleting",

  // Report
  ADD_NEW_QUESTIONS: "Add New Questions",
  ADD_SUPPORTED_CONTENT: "Add Supported Image & Text",
  ADD_ANSWERS: "Add More Answers Types",
  GENERATE_ANSWER_TITLE: "Generate Answer Title",
  CREATE_QUESTION: "Create Question",
  CREATE_QUESTION_LOADING: "Creating Question...",
  UPDATE_QUESTION: "Update Question",
  UPDATE_QUESTION_LOADING: "Updating Question...",
  PUBLISH: "Publish",
  PUBLISH_LOADING: "Publishing...",
  UNPUBLISH: "Unpublish",
  UNPUBLISH_LOADING: "Unpublishing...",
  WEBREPORT: "Web Report",
  UPLOAD_DOCUMENT: "Upload Document",
  SUBMIT_REPORT: "Submit Report",
  SIGNATURE: "Sign",
  DONE: "Done",

  // User Profile
  UPLOAD_CERTIFICATES: "Upload Certificates",
  UPLOAD: "Upload",
  ADD_ANOTHER: "Add Another",
};

export default ButtonTitles;
