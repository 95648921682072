import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import { Icons, defaultData } from "utils/Constants";
import Author from "components/Table/Author";
import moment from "moment";

export default function Member(
  memberList,
  handleOpenNewModal,
  setModalType,
  editLists,
  setEditLists,
  handleDelete,
  handleMemberSort,
  sorted
) {
  const [rows, setRows] = useState([]);
  const mongooseId = "_id";

  const handleEdit = (item) => {
    setModalType("Update");
    setEditLists({ ...editLists, member: item });
    handleOpenNewModal("Member");
  };
  useEffect(() => {
    if (memberList) {
      const list = memberList.map((item) => {
        const temp = {
          candidateName: (
            <Author
              name={`${item?.user?.callingName ? item?.user?.callingName : item?.user?.firstName} ${
                item.user?.lastName
              }`}
            />
          ),
          function: <Author name={item?.function?.functionName} />,
          from: (
            <Author
              name={
                item.createdAt ? moment(item?.createdAt).format(defaultData.WEB_DATE_FORMAT) : ""
              }
            />
          ),
          isApprover: <Author name={item?.isApprover ? "Yes" : "No"} />,
          action: (
            <MDBox>
              <Icon
                color="secondary"
                fontSize="medium"
                onClick={() => handleEdit(item)}
                sx={{ cursor: "pointer" }}
              >
                {Icons.EDIT}
              </Icon>{" "}
              &nbsp;
              <Icon
                color="secondary"
                fontSize="medium"
                onClick={() => handleDelete("Member", item[mongooseId])}
              >
                {Icons.DELETE}
              </Icon>
            </MDBox>
          ),
        };
        return temp;
      });
      setRows([...list]);
    }
  }, [memberList]);
  return {
    membersColumns: [
      {
        Header: () => (
          <div
            onClick={handleMemberSort}
            role="button"
            tabIndex={0}
            onKeyDown={(e) => e.key === "Enter" && handleMemberSort()}
            style={{ cursor: "pointer" }}
          >
            Name
            <MDBox
              position="absolute"
              top={-3}
              left="30%"
              color={sorted === "asc" ? "text" : "secondary"}
              opacity={sorted === "asc" ? 1 : 0.5}
            >
              <Icon fontSize="medium">arrow_drop_up</Icon>
            </MDBox>
            <MDBox
              position="absolute"
              top={3}
              left="30%"
              color={sorted === "desc" ? "text" : "secondary"}
              opacity={sorted === "desc" ? 1 : 0.5}
            >
              <Icon fontSize="medium">arrow_drop_down</Icon>
            </MDBox>
          </div>
        ),
        accessor: "candidateName",
        width: "35%",
        align: "left",
      },
      { Header: "Function", accessor: "function", width: "30%", align: "left" },
      { Header: "Active From", accessor: "from", width: "15%", align: "left" },
      { Header: "Is Approver", accessor: "isApprover", width: "10%", align: "left" },
      { Header: "Action", accessor: "action", width: "10%", align: "right" },
    ],
    membersRows: rows,
  };
}
