import React, { useState } from "react";

// Material Components
import { Grid, Icon } from "@mui/material";
import Rating from "@mui/material/Rating";

// Custom Components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import FormTextArea from "components/Form/FTextArea";
import pxToRem from "assets/theme/functions/pxToRem";
import RatingDeleteModal from "examples/modal/deleteModal/deleteModal";

// Redux
import { addUserReview, removeUserRating } from "redux/Slice/User";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbar } from "redux/Slice/Notification";
import { createReview, deleteUserRatings, UserListbyIdThunk } from "redux/Thunks/UserManagement";

// Utils
import Constants, { ButtonTitles, Colors, defaultData, Icons, ModalContent } from "utils/Constants";

// 3rd party libraries
import moment from "moment";

const ratingDescription = [
  {
    rating: "5.",
    description: Constants.EXCELLENT,
  },
  {
    rating: "4.",
    description: Constants.GOOD,
  },
  {
    rating: "3.",
    description: Constants.SATISFACTORY,
  },
  {
    rating: "2.",
    description: Constants.UNSATISFACTORY,
  },
  {
    rating: "1.",
    description: Constants.POOR,
  },
  {
    rating: "0.",
    description: Constants.BLACKLIST,
  },
];

function userRatings({ userId, permission }) {
  const dispatch = useDispatch();
  const [hover, setHover] = useState(-1);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [deleteUserRating, setDeleteUserRating] = useState({
    open: false,
    ratingId: "",
  });
  const { config } = useSelector((state) => state.config);
  const { userDetails } = useSelector((state) => state.users);

  // Function to get rating attributes
  const getRatingAttributes = (value) => {
    let color;
    let description;
    switch (value) {
      case 1:
        color = Colors.RATING_1;
        description = Constants.POOR;
        break;
      case 2:
        color = Colors.RATING_2;
        description = Constants.UNSATISFACTORY;
        break;
      case 3:
        color = Colors.RATING_3;
        description = Constants.SATISFACTORY;
        break;
      case 4:
        color = Colors.RATING_4;
        description = Constants.GOOD;
        break;
      case 5:
        color = Colors.RATING_5;
        description = Constants.EXCELLENT;
        break;
      default:
        color = Colors.RATING_0;
        description = Constants.BLACKLIST_2;
    }
    return { color, description };
  };

  // Create a new review
  const handleReview = async () => {
    setLoading(true);
    const apiPayload = {
      rating,
      comment: comment.trimStart(),
    };

    // Data to update the Redux state locally
    const localReview = {
      rating,
      comment: comment.trimStart(),
      ratedByDetails: {
        firstName: config[0]?.username,
        lastName: "",
        profileImage: config[0]?.profileImage,
      },
      ratedBy: config[0]?.id,
      ratedAt: new Date().toISOString(),
    };

    const res = await dispatch(createReview({ data: apiPayload, userId }));
    if (res.payload.status === 200) {
      await dispatch(addUserReview(localReview));
      await dispatch(UserListbyIdThunk(userId));
      await dispatch(
        openSnackbar({
          message: Constants.REVIEW_CREATE_SUCCESS,
          notificationType: Constants.NOTIFICATION_SUCCESS,
        })
      );
      setComment("");
      setRating(0);
      setLoading(false);
    } else {
      await dispatch(
        openSnackbar({
          message: Constants.SOMETHING_WENT_WRONG,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
      setLoading(false);
    }
  };

  // Open delete modal
  const handleOpenDeleteModal = (ratingId) => {
    setDeleteUserRating({
      open: true,
      ratingId,
      userId,
    });
  };

  // Function to delete user ratings
  const handleDeleteUserRating = async () => {
    const res = await dispatch(deleteUserRatings({ ratingId: deleteUserRating.ratingId, userId }));

    if (res.payload.status === 200) {
      await dispatch(removeUserRating({ ratingId: deleteUserRating.ratingId }));
      await dispatch(
        openSnackbar({
          message: Constants.REVIEW_DELETE_SUCCESS,
          notificationType: Constants.NOTIFICATION_SUCCESS,
        })
      );
      setDeleteUserRating({ ...deleteUserRating, open: false, ratingId: "" });
    } else {
      await dispatch(
        openSnackbar({
          message: Constants.SOMETHING_WENT_WRONG,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
      setDeleteUserRating({ ...deleteUserRating, open: false });
    }
  };
  return (
    <>
      <MDBox ml={2}>
        <MDTypography variant="h5" fontWeight="medium" color="text" textTransform="capitalize">
          {config[0]?.id !== userId && config[0]?.id !== userDetails[Constants.MONGOOSE_ID]
            ? "Rating"
            : "Ratings"}
        </MDTypography>
        {permission?.create &&
          config[0]?.id !== userId &&
          config[0]?.id !== userDetails[Constants.MONGOOSE_ID] && (
            <MDBox>
              <MDBox sx={{ marginTop: pxToRem(20) }}>
                <MDTypography
                  sx={{
                    fontSize: pxToRem(16),
                    fontWeight: 500,
                    marginBottom: pxToRem(10),
                    color: Colors.BLACK,
                  }}
                >
                  Star Rating
                </MDTypography>
                <Rating
                  name="simple-controlled"
                  value={rating}
                  onChange={(event, newValue) => {
                    setRating(newValue);
                  }}
                  onChangeActive={(event, newHoverValue) => {
                    setHover(newHoverValue);
                  }}
                  sx={{
                    "& .MuiRating-iconFilled": {
                      color: getRatingAttributes(hover !== -1 ? hover : rating).color,
                    },
                  }}
                  size="large"
                />
                <MDBox
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "flex-start",
                    width: "100%",
                  }}
                >
                  {ratingDescription.map((rate) => (
                    <MDBox sx={{ display: "flex" }}>
                      <MDTypography
                        sx={{
                          fontSize: pxToRem(14),
                          color: Colors.TITLE_1,
                          fontWeight: 600,
                          marginRight: pxToRem(5),
                        }}
                      >
                        {rate.rating}
                      </MDTypography>
                      <MDTypography
                        sx={{
                          fontSize: pxToRem(14),
                          color: Colors.TITLE_1,
                          fontWeight: 400,
                          marginRight: pxToRem(10),
                        }}
                      >
                        {rate.description}
                      </MDTypography>
                    </MDBox>
                  ))}
                </MDBox>
              </MDBox>
              <Grid mt={2} item xs={10} xl={8}>
                <MDTypography
                  sx={{
                    fontSize: pxToRem(16),
                    color: Colors.BLACK,
                    fontWeight: 500,
                  }}
                >
                  Comments
                </MDTypography>
                <FormTextArea
                  name="comment"
                  placeholder="Add Comment here"
                  value={comment}
                  handleChange={(e) => setComment(e.target.value)}
                  backgroundColor={Colors.INPUT_BG}
                />
                <MDBox sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <MDButton
                    onClick={handleReview}
                    variant="contained"
                    color="info"
                    disabled={loading}
                    style={{
                      textTransform: "none",
                      boxShadow: "none",
                    }}
                  >
                    {loading ? ButtonTitles.LOADING : ButtonTitles.SAVE}
                  </MDButton>
                </MDBox>
              </Grid>
            </MDBox>
          )}
        <MDTypography
          variant="h5"
          fontWeight="medium"
          color="text"
          textTransform="capitalize"
          mt={2}
        >
          {config[0]?.id !== userId && config[0]?.id !== userDetails[Constants.MONGOOSE_ID]
            ? "Previous Reviews"
            : ""}
        </MDTypography>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 1, md: 3 }}
          mt={5}
          height={
            permission?.create &&
            config[0]?.id !== userId &&
            config[0]?.id !== userDetails[Constants.MONGOOSE_ID] &&
            "40vh"
          }
          sx={{
            overflow: "auto",
            padding: 2,
            "::-webkit-scrollbar": {
              width: "5px",
            },
            "::-webkit-scrollbar-thumb": {
              background: Colors.LIGHT_GRAY,
            },
            scrollbarWidth: "thin",
            scrollbarColor: "gray transparent",
          }}
        >
          {userDetails?.rating?.length > 0 ? (
            userDetails?.rating
              ?.filter((review) => review?.isDeleted === false)
              ?.map((rev) => (
                <Grid item xs={10} lg={8}>
                  <MDBox
                    display="flex"
                    border={`1px solid ${Colors.LIGHT_GRAY}`}
                    borderRadius="8px"
                    p={2}
                    position="relative"
                    mb={5}
                    sx={{ backgroundColor: Colors.BOX_BG }}
                  >
                    <MDBox sx={{ position: "relative" }}>
                      {rev?.ratedByDetails?.profileImage !== "" &&
                      rev?.ratedByDetails?.profileImage !== null ? (
                        <img
                          src={rev?.ratedByDetails?.profileImage}
                          alt={rev?.ratedByDetails.firstName}
                          style={{
                            width: pxToRem(50),
                            height: pxToRem(50),
                            borderRadius: "50%",
                            objectFit: "cover",
                          }}
                        />
                      ) : (
                        <MDBox
                          sx={{
                            backgroundColor: Colors.PRIMARY,
                            borderRadius: "50%",
                            width: pxToRem(50),
                            height: pxToRem(50),
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <MDTypography
                            sx={{
                              color: Colors.WHITE,
                              fontSize: pxToRem(16),
                              fontWeight: "500",
                              backgroundColor: Colors.PRIMARY,
                            }}
                          >
                            {rev?.ratedByDetails?.firstName?.charAt(0)?.toUpperCase()}
                          </MDTypography>
                        </MDBox>
                      )}
                    </MDBox>
                    <MDBox display="flex" flexDirection="column">
                      <MDBox ml={2} mt={0}>
                        <MDTypography
                          display="block"
                          variant="caption"
                          sx={{ textTransform: "capitalize", fontWeight: "600" }}
                        >
                          {`${
                            rev?.ratedByDetails?.callingName
                              ? rev?.ratedByDetails?.callingName
                              : rev?.ratedByDetails?.firstName
                          } ${rev?.ratedByDetails?.lastName}`}
                        </MDTypography>

                        <MDTypography display="block" variant="caption" color="text">
                          {moment(rev?.ratedAt).format(defaultData.WEB_DATE_FORMAT)}
                        </MDTypography>
                      </MDBox>
                      <MDBox mt={4}>
                        <MDTypography
                          display="block"
                          variant="caption"
                          fontWeight="400"
                          sx={{
                            color: Colors.TEXT_1,
                            whiteSpace: "pre-line",
                            textAlign: "justify",
                          }}
                        >
                          {rev?.comment}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <MDBox position="absolute" right={5} zIndex={1}>
                      <Rating
                        name="simple-controlled"
                        value={rev?.rating}
                        sx={{
                          "& .MuiRating-iconFilled": {
                            color: getRatingAttributes(rev?.rating).color,
                          },
                        }}
                        readOnly
                      />
                      <MDTypography
                        display="block"
                        variant="caption"
                        color="text"
                        sx={{
                          textAlign: "end",
                          marginRight: pxToRem(5),
                        }}
                      >
                        {getRatingAttributes(rev?.rating)?.description}
                      </MDTypography>
                    </MDBox>

                    {config[0]?.id === rev?.ratedBy && permission?.delete && (
                      <MDBox position="absolute" top={-10} right={-10} zIndex={2}>
                        <Icon
                          sx={{
                            color: "white",
                            width: 30,
                            height: 30,
                            cursor: "pointer",
                            zIndex: 1,
                          }}
                          onClick={() => {
                            handleOpenDeleteModal(rev[Constants.MONGOOSE_ID]);
                          }}
                        >
                          {Icons.CROSS2}
                        </Icon>
                      </MDBox>
                    )}
                  </MDBox>
                </Grid>
              ))
          ) : (
            <MDTypography
              mt="10%"
              ml="30%"
              variant="h6"
              fontWeight="medium"
              color="text"
              textTransform="capitalize"
            >
              No Reviews found
            </MDTypography>
          )}
        </Grid>
      </MDBox>
      <RatingDeleteModal
        open={deleteUserRating.open}
        title={ModalContent.DELETE_RATING}
        message={ModalContent.DELETE_RATING_MESSAGE}
        handleClose={() => {
          setDeleteUserRating({ ...deleteUserRating, open: false, ratingId: "" });
        }}
        handleDelete={handleDeleteUserRating}
        actionButton
      />
    </>
  );
}

export default userRatings;
