import { Grid, Icon, Modal } from "@mui/material";
import PropTypes from "prop-types";
import style from "assets/style/Modal";
import MDBox from "components/MDBox";
import ModalTitle from "examples/NewDesign/ModalTitle";
import pxToRem from "assets/theme/functions/pxToRem";
import { Icons, Colors } from "utils/Constants";
import BasicButton from "examples/NewDesign/CustomButton/BasicButton";

function basicModal({
  open,
  handleClose,
  title,
  children,
  actionButton,
  btnIcon,
  handleAction,
  disabled,
  minWidth = 700,
  py = 2,

  // props only if addional button is needed
  isAdditionalBtnRequired,
  additionalBtnTitle,
  additionalBtnIcon,
  additionalBtnAction,
  additionalBtnDisabled,
}) {
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <MDBox sx={{ ...style, minWidth }}>
        <MDBox
          bgColor="info"
          p={3}
          mb={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          borderRadius="lg"
          sx={{ borderBottomRightRadius: 0, borderBottomLeftRadius: 0, height: pxToRem(72) }}
        >
          <ModalTitle title={title} color="white" />

          <Icon sx={{ cursor: "pointer", color: "beige" }} fontSize="medium" onClick={handleClose}>
            {Icons.CROSS}
          </Icon>
        </MDBox>
        <MDBox
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          px={3}
          py={py}
          sx={{
            maxHeight: 500,
            overflowY: "scroll",
            "::-webkit-scrollbar": { display: "none" },
            scrollbarWidth: "none",
          }}
        >
          {children}
        </MDBox>
        <MDBox px={2} mb={2} mr={1}>
          <Grid container justifyContent="flex-end" alignItems="center">
            {/* Additional Button. (Refercence: Used in User Profile Certificate Upload Modal) */}
            {isAdditionalBtnRequired && (
              <Grid item xs={0}>
                <BasicButton
                  title={additionalBtnTitle}
                  icon={additionalBtnIcon}
                  background={Colors.WHITE}
                  color={Colors.PRIMARY}
                  action={additionalBtnAction}
                  disabled={additionalBtnDisabled}
                  border
                  borderColor={Colors.PRIMARY}
                  modalFontSize
                  style={{ textTransform: "none", boxShadow: "none" }}
                />
              </Grid>
            )}

            {/* Primary Button */}
            <Grid item xs={0}>
              <BasicButton
                title={actionButton}
                icon={btnIcon}
                background={Colors.PRIMARY}
                color={Colors.WHITE}
                action={handleAction}
                disabled={disabled}
                modalFontSize
                style={{ textTransform: "none", boxShadow: "none" }}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Modal>
  );
}

basicModal.defaultProps = {
  disabled: false,
  minWidth: 700,
  py: 2,
  btnIcon: "",
  isAdditionalBtnRequired: false,
  additionalBtnTitle: "",
  additionalBtnIcon: "",
  additionalBtnAction: () => {},
  additionalBtnDisabled: false,
};

basicModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  actionButton: PropTypes.string.isRequired,
  handleAction: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  minWidth: PropTypes.number,
  btnIcon: PropTypes.string,
  py: PropTypes.number,
  isAdditionalBtnRequired: PropTypes.bool,
  additionalBtnTitle: PropTypes.string,
  additionalBtnIcon: PropTypes.string,
  additionalBtnAction: PropTypes.func,
  additionalBtnDisabled: PropTypes.bool,
};
export default basicModal;
