import React, { useState } from "react";

// Material Components
import MDTypography from "components/MDTypography";
import { Box, Icon } from "@mui/material";
import MDBox from "components/MDBox";

// Common Components
import FullScreenImageComponent from "components/ViewFullImage/ViewImage";
import CustomImage from "components/Table/GroupImage";
import pxToRem from "assets/theme/functions/pxToRem";
import Author from "components/Table/Author";
import { downloadFile } from "utils/methods/methods";

// Utils
import { defaultData, Icons } from "utils/Constants";

// Libraries
import moment from "moment";

// 3rd party libraries
import PropTypes from "prop-types";

export default function DynamicTypeData({ type, data, compressedData, isDownload, imageArray }) {
  const [fullScreenImage, setFullScreenImage] = useState(null);
  const [fullScreenImageIndex, setFullScreenImageIndex] = useState(0);
  const handleImageFullView = (imagePreview) => {
    // Find the index of the clicked image in the array
    const normalizedImagePreview = imagePreview.trim().toLowerCase();
    const index = imageArray.findIndex(
      (img) => img.trim().toLowerCase() === normalizedImagePreview
    );

    // Check if the image exists in the array
    if (index !== -1) {
      // Set the full-screen image index and array
      setFullScreenImageIndex(index);
      setFullScreenImage(imageArray);
    }
  };

  const handleCloseFullView = () => {
    setFullScreenImage(null);
  };

  const handleNextImage = () => {
    setFullScreenImageIndex((prevIndex) => (prevIndex + 1) % imageArray.length);
  };

  const handlePreviousImage = () => {
    setFullScreenImageIndex((prevIndex) => (prevIndex - 1 + imageArray.length) % imageArray.length);
  };

  return (
    <MDBox sx={{ paddingX: pxToRem(8) }}>
      {(() => {
        switch (type) {
          case "shortAnswer":
          case "dropdown":
          case "number":
          case "dateTime":
          case "boolean":
          case "range":
          case "checkbox":
            return (
              <MDBox>
                <MDBox
                  display="flex"
                  flexDirection="row"
                  key={data}
                  alignItems="center"
                  gap={1}
                  mt={1}
                >
                  <MDTypography
                    sx={{
                      width: "100%",
                      fontWeight: 500,
                      fontSize: pxToRem(16),
                      wordWrap: "break-word",
                      whiteSpace: "normal",
                    }}
                    key={data}
                  >
                    {type === "dateTime"
                      ? moment(data).format(defaultData.WEB_24_HOURS_FORMAT)
                      : `${data.replace(/##/g, ", ")}`}
                  </MDTypography>
                </MDBox>
              </MDBox>
            );
          case "date":
            return (
              <MDBox>
                <MDBox
                  display="flex"
                  flexDirection="row"
                  key={data}
                  alignItems="center"
                  gap={1}
                  mt={1}
                >
                  <MDTypography
                    sx={{
                      width: "100%",
                      textTransform: "capitalize",
                      fontWeight: 500,
                      fontSize: pxToRem(16),
                      wordWrap: "break-word",
                      whiteSpace: "normal",
                    }}
                    key={data}
                  >
                    {moment(data).format(defaultData.WEB_DATE_FORMAT)}
                  </MDTypography>
                </MDBox>
              </MDBox>
            );
          case "image":
          case "signature":
            return data || compressedData ? (
              <MDBox
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  flexWrap: "nowrap",
                }}
              >
                <MDBox
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  position="relative"
                  sx={{
                    rowGap: pxToRem(10),
                    "&:hover .overlay": {
                      display: "flex",
                      borderRadius: "8px",
                    },
                  }}
                >
                  <CustomImage item={compressedData || data} />
                  <Box
                    display="none"
                    position="absolute"
                    top={0}
                    right={0}
                    bottom={0}
                    left={0}
                    alignItems="center"
                    justifyContent="center"
                    bgcolor="rgba(0, 0, 0, 0.5)"
                    className="overlay"
                    width={50}
                    height={50}
                  >
                    <Icon
                      sx={{ color: "white", width: 30, height: 30, cursor: "pointer" }}
                      onClick={() => handleImageFullView(data)}
                    >
                      {Icons.VIEW2}
                    </Icon>
                  </Box>
                </MDBox>
                {isDownload && (
                  <Icon
                    fontSize="medium"
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.1)", // Adjust the RGBA value for transparency
                        borderRadius: "4px", // Optional: add border radius for smoother corners
                      },
                    }}
                    onClick={(event) => downloadFile(data, event)}
                  >
                    {Icons.DOWNLOAD}
                  </Icon>
                )}
              </MDBox>
            ) : (
              <MDBox
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  flexWrap: "nowrap",
                }}
              >
                <CustomImage item={process.env.REACT_APP_IMAGE_NOT_FOUND} />
              </MDBox>
            );
          default:
            return <Author name={data} />;
        }
      })()}
      <FullScreenImageComponent
        fullScreenImage={fullScreenImage}
        handleCloseFullView={handleCloseFullView}
        handlePreviousImage={handlePreviousImage}
        handleNextImage={handleNextImage}
        image={imageArray}
        src={imageArray[fullScreenImageIndex]}
        type={type}
      />
    </MDBox>
  );
}

DynamicTypeData.propTypes = {
  type: PropTypes.string,
  data: PropTypes.string,
  compressedData: PropTypes.string,
  isDownload: PropTypes.bool,
  imageArray: PropTypes.arrayOf(PropTypes.object),
};

DynamicTypeData.defaultProps = {
  type: "",
  data: "",
  compressedData: "",
  isDownload: false,
  imageArray: [],
};
