/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Components
import Author from "components/Table/Author";
import By from "components/Table/By";
import CustomImage from "components/Table/GroupImage";

// Material ui Components
import MDBox from "components/MDBox";
import { IconButton } from "@mui/material";

// Utils
import Constants, { Icons, defaultData } from "utils/Constants";

// 3rd party libraries
import moment from "moment";

export default function WmOrderLayerData(
  { warehouseOrderList, warehouseOrderLoading },
  currentFilter,
  handleIntransist,
  permission
) {
  const [orderRow, setOrderRow] = useState([]);
  const navigate = useNavigate();

  const handleView = (id) => {
    navigate(`/client/orders/order-details/${id}`);
  };

  useEffect(() => {
    if (
      warehouseOrderLoading === Constants.FULFILLED &&
      !["return", "in-stock", "return-rejected"].includes(currentFilter)
    ) {
      const tempRows = warehouseOrderList?.map((element, index) => {
        const temp = {
          srNo: <Author name={index + 1} />,
          project: (
            <Author name={element?.project?.title} maxContent={defaultData.MEDIUM_CONTENT_LENGTH} />
          ),
          orderId: <Author name={element?.orderNumber} />,
          raisedBy: (
            <By
              name={`${
                element?.orderBy?.callingName
                  ? element?.orderBy?.callingName
                  : element?.orderBy?.firstName
              } ${element?.orderBy?.lastName}`}
              when={moment(element?.createdAt).format(defaultData.WEB_DATE_FORMAT)}
            />
          ),
          requestedEquipment: (
            <MDBox display="flex" flexDirection="row">
              {element?.equipmentTypeData?.length > 5
                ? element?.equipmentTypeData.slice(0, 5).map((item, imgIndex) => (
                    <React.Fragment key={item?.equipmentTypeImage?.[Constants.MONGOOSE_ID]}>
                      <CustomImage
                        item={item?.equipmentTypeImage?.url}
                        index={imgIndex}
                        requestedQuantity={
                          currentFilter === "requested"
                            ? item?.pmRequestedQuantity
                            : item?.wmApprovedQuantity
                        }
                        remainingLength={element.equipmentTypeData.length - 5 || 0}
                      />
                    </React.Fragment>
                  ))
                : element?.equipmentTypeData.map((item, imgIndex) => (
                    <React.Fragment key={item?.equipmentTypeImage?.[Constants.MONGOOSE_ID]}>
                      <CustomImage
                        item={item?.equipmentTypeImage?.url}
                        requestedQuantity={
                          currentFilter === "requested"
                            ? item?.pmRequestedQuantity
                            : item?.wmApprovedQuantity
                        }
                        index={imgIndex}
                      />
                    </React.Fragment>
                  ))}
            </MDBox>
          ),
          reqItems: <Author name={element?.totalItems} />,
          quantity: (
            <Author
              style={{ width: "max-content" }}
              name={
                currentFilter === "requested"
                  ? element?.totalRequestedQuantity
                  : element?.equipmentTypeData.reduce(
                      (acc, total) => acc + parseInt(total?.wmApprovedQuantity, 10),
                      0
                    )
              }
            />
          ),
          action: (
            <MDBox>
              <IconButton
                aria-label="fingerprint"
                color="info"
                onClick={() => {
                  handleView(element?.[Constants.MONGOOSE_ID]);
                }}
              >
                {Icons.VIEW}
              </IconButton>
              {currentFilter === "pre-transit" && permission?.update && (
                <IconButton
                  aria-label="fingerprint"
                  color="info"
                  onClick={() => handleIntransist(element)}
                >
                  {Icons.IN_TRANSIST}
                </IconButton>
              )}
            </MDBox>
          ),
        };
        return temp;
      });
      setOrderRow(tempRows);
    }
  }, [warehouseOrderLoading]);

  return {
    orderColumns: [
      { Header: "No.", accessor: "srNo", width: "5%" },
      { Header: "Project", accessor: "project", align: "left", width: "25%" },
      { Header: "Order #", accessor: "orderId", align: "left" },
      { Header: "Order By", accessor: "raisedBy", align: "left" },
      { Header: "Equipment Type", accessor: "requestedEquipment", align: "left" },
      { Header: "Items", accessor: "reqItems", align: "center" },
      { Header: "Quantity", accessor: "quantity", align: "center" },
      { Header: "Action", accessor: "action", align: "center" },
    ],
    orderRow,
  };
}
