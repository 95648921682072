import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Material Components
import { Box, Divider, Grid, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import styled from "@emotion/styled";
import MDTypography from "components/MDTypography";

// Custom Components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PageTitle from "examples/NewDesign/PageTitle";
import DataTable from "examples/Tables/DataTable/NestedTable";
import EditEquipmentQuantity from "examples/modal/EditEquipmentQuantity/editEquipmentQuantity";
import BasicButton from "examples/NewDesign/CustomButton/BasicButton";
import WmOrderDetailsData from "layouts/wmOrdersManagement/data/WmOrderDetailsData";
import EquipmentLayerData from "layouts/wmOrdersManagement/data/EquipmentLayerData";
import ConfirmationModal from "examples/modal/Confirmation/Confirmation";
import pxToRem from "assets/theme/functions/pxToRem";
import CheckInCheckOutComments from "examples/modal/CheckInCheckOutComments/CheckInCheckOutComments";
import Status from "components/Table/Status";

// Redux component
import { useDispatch, useSelector } from "react-redux";
import {
  warehouseOrderDetailsThunk,
  warehouseBUlkApproveRejectOrderEquipmentThunk,
  updateCommentThunk,
  rejectSingleEqiuipmentTypeRequestThunk,
  rejectPartialOrderThunk,
} from "redux/Thunks/Warehouse";
import {
  reloadWarehouse,
  updateEquipmentOrderQuantity,
  resetOrderDetail,
  loadWmOrderDetailsEquipmentLayerData,
  updateWmComments,
  reloadWarehouseOrderRequest,
  updateListOnReject,
} from "redux/Slice/Warehouse";
import { openSnackbar } from "redux/Slice/Notification";

// 3rd Party lib
import { Feature } from "flagged";
import PropTypes from "prop-types";

// Utils
import moment from "moment";
import Constants, {
  Icons,
  PageTitles,
  FeatureTags,
  defaultData,
  Colors,
  ButtonTitles,
} from "utils/Constants";

import { paramCreater } from "utils/methods/methods";
import FTextField from "components/Form/FTextField";
import { exportOrderPdf } from "redux/Thunks/Filter";
import ExportHOC from "examples/HigherOrderComponents/ExportHOC";

const StyledMDBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  gap: theme.spacing(3),
  marginTop: theme.spacing(3),

  borderRadius: theme.spacing(1),
  border: "1px solid #E0E6F5",
  background: "var(--base-white, #FFF)",

  /* Shadow/sm */
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)",

  padding: theme.spacing(`${pxToRem(30)} ${pxToRem(30)}`), // Default padding for all breakpoints
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(`${pxToRem(30)} ${pxToRem(30)}`), // Adjust padding for small screens and below
    // flex direction row
    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(`${pxToRem(30)} ${pxToRem(20)}`), // Adjust padding for small screens and below
    // flex direction column
    flexDirection: "column",
  },
  [theme.breakpoints.down("xs")]: {
    padding: theme.spacing(`${pxToRem(30)} ${pxToRem(10)}`), // Adjust padding for extra-small screens
    // flex direction column
    flexDirection: "column",
  },
}));

const intialsComments = {
  open: false,
  type: "pm",
  pmOrderManageId: "",
  intialComments: [],
};

const intialSingleRejectData = {
  open: false,
  id: "",
  remark: "",
  loading: "",
  isPartialReject: false,
  rejectData: {},
};

function WarehouseOrderDetails({ handlePdfExport, exportLoading }) {
  const [printLoading, setPrintLoading] = useState(false);
  const [openApproveRejectModal, setOpenApproveRejectModal] = useState({
    open: false,
    status: "",
  });
  const [editEquipment, setEditEquipment] = useState({});
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [singleRejectData, setSingleRejectData] = useState(intialSingleRejectData);

  const [tablePagination, setTablePagination] = useState({
    page: 0,
    perPage: defaultData.DATE_ON_SINGLE_API_CALL,
  });
  const [next, setNext] = useState(0);
  const [comments, setComments] = useState(intialsComments);
  const { id } = useParams();
  const order = useSelector((state) => state.Warehouse);
  const ConfigData = useSelector((state) => state.config);
  const permission = ConfigData?.screens?.[9]?.screensInfo?.agreement;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentDate = new Date();
  const handleApproveRejectModalClose = () => setOpenApproveRejectModal(false);
  const handleSingleRejectClose = () => setSingleRejectData(intialSingleRejectData);

  const handleEditOpen = (item) => {
    const temp = {
      id: item.pmOrderManageId,
      equipmentImage: item?.equipmentTypeImage?.url,
      imageName: item?.equipmentType,
      equipmentName: item.equipmentType,
      requestedBy: `${
        order.orderDetails?.orderBy?.callingName
          ? order.orderDetails?.orderBy?.callingName
          : order.orderDetails?.orderBy?.firstName
      } ${order.orderDetails?.orderBy?.lastName}`,
      requestedAt: order.createdAt,
      totalRequestedQuantity: item.pmRequestedQuantity,
      price: item.price,
      currencySymbol: item.currencyDetail.symbol,
      approvedQuantity: item.wmApprovedQuantity,
    };
    setEditEquipment(temp);
    setEditModalOpen(true);
  };

  // Reject Single Equipment type Request from order
  const handleRejectSingleEquipmentOpen = async (rejectId, rejectData, isPartialReject = false) =>
    setSingleRejectData({ open: true, id: rejectId, loading: false, isPartialReject, rejectData });

  const handleEditClose = () => {
    setEditModalOpen(false);
    setEditEquipment({});
  };

  const handleCommentsOpen = (type, pmOrderManageId, intialComments) => {
    setComments({
      open: true,
      type,
      pmOrderManageId,
      intialComments: [
        ...(intialComments.pmComments?.map((item) => ({ ...item, type: "pm" })) || []),
        ...(intialComments.wmComments?.map((item) => ({ ...item, type: "wm" })) || []),
      ],
    });
  };
  const handleCommentsClose = () => setComments(intialsComments);

  const handleFilter = async () => {
    setTablePagination({ ...tablePagination, page: 0 });
    setNext(0);
    const paramData = {
      page: 0,
      perPage: tablePagination.perPage,
    };

    await dispatch(warehouseOrderDetailsThunk({ id, param: paramCreater(paramData) }));
  };

  useEffect(() => {
    (async () => {
      handleFilter();
    })();

    return () => {
      dispatch(resetOrderDetail());
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (
        order.orderDetailsLoading === Constants.NO_DATA ||
        order.orderDetailsLoading === Constants.REJECTED
      ) {
        dispatch(
          openSnackbar({
            message: Constants.REDIRECTING_TO_PARENT,
            notificationType: Constants.NOTIFICATION_ERROR,
          })
        );
        await dispatch(reloadWarehouseOrderRequest());
        navigate("/client/orders");
      }
    })();
  }, [order.orderDetailsLoading]);

  const handleTablePagination = async () => {
    const paramData = {
      page: next + 1,
      perPage: tablePagination.perPage,
    };
    const res = await dispatch(warehouseOrderDetailsThunk({ id, param: paramCreater(paramData) }));
    if (res.payload.status === 200) setNext(res.payload.data.length > 0 ? next + 1 : next);
  };

  const handleReload = async () => {
    await dispatch(reloadWarehouse());
    handleFilter();
  };

  const orderinfo = (title, value = "", subValue = "") => (
    <MDBox display="flex" flexDirection="column" alignItems="start" justifyContent="space-between">
      <MDTypography
        mb={pxToRem(8)}
        sx={{
          color: "var(--gray-600, #475467)",
          fontFamily: "Inter",
          fontSize: pxToRem(14),
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: pxToRem(16),
        }}
      >
        {title}
      </MDTypography>
      {title === "Status" ? (
        <Status title={value} />
      ) : (
        <MDTypography
          fontWeight="medium"
          sx={{
            mb: 1,
            color: Colors.PRIMARY,
            fontFamily: "Inter",
            fontSize: pxToRem(16),
            fontStyle: "normal",
            lineHeight: pxToRem(16),
            textTransform: "capitalize",
          }}
        >
          {value}
        </MDTypography>
      )}
      <Tooltip title={subValue}>
        <MDTypography
          variant="caption"
          sx={{
            textTransform: "capitalize",
            whiteSpace: "normal",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "100%",
          }}
        >
          {subValue}
        </MDTypography>
      </Tooltip>
    </MDBox>
  );

  const handleEditAction = async (item) => {
    await dispatch(updateEquipmentOrderQuantity(item));
    handleEditClose();
  };

  const handleApproveReject = async (status) => {
    setOpenApproveRejectModal({ open: true, status: "Loading..." });

    const filterdData = order.orderDetails?.equipmentTypeData.filter(
      (val) => val.status === "requested"
    );
    const totalApprovedQuantity = order?.orderDetails?.equipmentTypeData?.reduce((acc, ord) => {
      const quantityToAdd =
        ord?.wmApprovedQuantity > 0 ? ord?.wmApprovedQuantity : ord?.pmRequestedQuantity || 0;
      return acc + parseInt(quantityToAdd, 10);
    }, 0);

    const totalRequestedQuantity = order?.orderDetails?.equipmentTypeData?.reduce(
      (acc, req) => acc + parseInt(req?.pmRequestedQuantity, 10),
      0
    );
    const data = {
      id: order.orderDetails?.[Constants.MONGOOSE_ID],
      body: {
        status,
        pmOrderManage: filterdData?.map((val) => ({
          id: val.pmOrderManageId,
          wmApprovedQuantity: val.wmApprovedQuantity,
          wmComments: val.wmComments,
        })),
        remark:
          order?.orderDetails?.pmComment?.[0]?.comment?.length > 0 &&
          order?.orderDetails?.pmComment?.[0]?.comment !== null
            ? order?.orderDetails?.pmComment?.[0]?.comment
            : "",
        project: order.orderDetails?.project?.title,
        pmRequestedQuantity: totalRequestedQuantity.toString(),
        wmApprovedQuantity: totalApprovedQuantity.toString(),
        createdByEmail: order.orderDetails?.orderBy?.email,
        orderNumber: order.orderDetails?.orderNumber,
        companyName: ConfigData.config[0]?.companyName,
        username: `${
          order.orderDetails?.orderBy?.callingName
            ? order.orderDetails?.orderBy?.callingName
            : order.orderDetails?.orderBy?.firstName
        } ${order.orderDetails?.orderBy?.lastName}`,
      },
    };

    const res = await dispatch(warehouseBUlkApproveRejectOrderEquipmentThunk(data));
    if (res.payload.status === 200) {
      await dispatch(
        openSnackbar({
          message:
            status === "approved"
              ? Constants.ORDER_APPROVE_SUCCESS
              : Constants.ORDER_REJECT_SUCCESS,
          notificationType: Constants.NOTIFICATION_SUCCESS,
        })
      );
      navigate("/client/orders");
    } else {
      await dispatch(
        openSnackbar({
          message:
            status === "approved"
              ? Constants.ORDER_APPROVED_FAILED
              : Constants.ORDER_REJECTED_FAILED,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
    }
    setOpenApproveRejectModal({ open: false, status: "" });
  };

  // Single Reject
  const handleApproveRejectSingle = async () => {
    setSingleRejectData({ ...singleRejectData, loading: true });
    const data = {
      id: singleRejectData.id,
      body: {
        remark: [
          {
            user: ConfigData.config[0]?.id,
            time: moment(currentDate).format(defaultData.DATABASE_24_HOURS_FORMAT),
            status: order.orderDetails?.status,
            comment: singleRejectData.remark,
          },
        ],
        wmApprovedQuantity: singleRejectData.rejectData.wmApprovedQuantity,
        equipmentType: singleRejectData.rejectData.equipmentType,
        orderNumber: order.orderDetails?.orderNumber,
        status: "Rejected",
        companyName: ConfigData.config[0]?.companyName,
        createdByEmail: order.orderDetails?.orderBy?.email,
        username: ConfigData.config[0]?.username,
      },
    };

    const thunkAction = singleRejectData.isPartialReject
      ? rejectPartialOrderThunk
      : rejectSingleEqiuipmentTypeRequestThunk;

    const res = await dispatch(thunkAction(data));
    if (res.payload.status === 200) {
      await dispatch(
        openSnackbar({
          message: Constants.EQUIPMENT_TYPE_REJECT_SUCCESS,
          notificationType: Constants.NOTIFICATION_SUCCESS,
        })
      );
      handleSingleRejectClose();
      if (singleRejectData.isPartialReject) {
        handleFilter();
      } else {
        await dispatch(updateListOnReject(singleRejectData.id));
      }
    } else {
      handleSingleRejectClose();
      await dispatch(
        openSnackbar({
          message: Constants.EQUIPMENT_TYPE_REJECT_FAILED,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
    }
  };

  // Edit warehouse comments
  const handleEditCommentAction = async (updatedComments) => {
    const data = {
      id: comments.pmOrderManageId,
      body: {
        wmComments: updatedComments?.filter((item) => item.type !== "pm"),
      },
    };
    setButtonLoading(true);
    const res = await dispatch(updateCommentThunk(data));
    setButtonLoading(false);
    if (res.payload.status === 200) {
      await dispatch(
        openSnackbar({
          message: Constants.WM_COMMETNS_UPDATE_SUCCESS,
          notificationType: Constants.NOTIFICATION_SUCCESS,
        })
      );
      dispatch(
        updateWmComments({
          id: comments.pmOrderManageId,
          comments: updatedComments?.filter((item) => item.type !== "pm"),
        })
      );
      handleCommentsClose();
    } else {
      await dispatch(
        openSnackbar({
          message: Constants.WM_COMMETNS_UPDATE_FAILED,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
    }
  };

  const { columns, rows } = WmOrderDetailsData(
    order,
    handleCommentsOpen,
    handleEditOpen,
    handleRejectSingleEquipmentOpen,
    permission
  );

  const { equipmentLayerColumns, equipmentLayerOrderRow } = EquipmentLayerData(
    order,
    handleCommentsOpen
  );

  const rentalDateUI = (forBill = false) => {
    const fromDate = moment(order?.orderDetails?.fromDate);
    const toDate = moment(order?.orderDetails?.toDate);
    const duration = moment.duration(toDate.diff(fromDate));
    const days = duration.asDays() + 1;
    if (forBill)
      return (
        <MDBox width="100%" display="flex" justifyContent="space-between" alignItems="center">
          <MDTypography variant="caption" fontWeight="regular">
            {`Total days :
             (${moment(order?.orderDetails?.fromDate).format(
               defaultData.WEB_DATE_FORMAT
             )} - ${moment(order?.orderDetails?.toDate).format(defaultData.WEB_DATE_FORMAT)})`}
          </MDTypography>
          <MDTypography fontWeight="medium" fontSize={pxToRem(16)}>
            {days}
          </MDTypography>
        </MDBox>
      );

    return (
      <Grid item xs={12} sm={2}>
        <MDBox
          display="flex"
          flexDirection="column"
          alignItems="start"
          justifyContent="space-between"
          px={2}
        >
          <MDTypography
            sx={{
              mb: 1,
              color: Colors.PRIMARY,
              fontFamily: "Inter",
              fontSize: pxToRem(16),
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: pxToRem(16),
              textTransform: "capitalize",
              width: "max-content",
            }}
          >
            <MDBox display="flex" flexDirection="column" width={pxToRem(130)}>
              <MDBox display="flex" flexDirection="column" justifyContent="space-between">
                <MDTypography variant="caption" fontWeight="regular">
                  From
                </MDTypography>
                <MDTypography fontWeight="medium" fontSize={pxToRem(16)}>
                  {moment(order.orderDetails?.fromDate).format(defaultData.WEB_DATE_FORMAT)}
                </MDTypography>
              </MDBox>
              {order?.orderDetails?.toDate && (
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  marginTop={1}
                >
                  <MDTypography variant="caption" fontWeight="regular">
                    To
                  </MDTypography>
                  <MDTypography fontWeight="medium" fontSize={pxToRem(16)}>
                    {moment(order.orderDetails?.toDate).format(defaultData.WEB_DATE_FORMAT)}
                  </MDTypography>
                </MDBox>
              )}
            </MDBox>
          </MDTypography>
        </MDBox>
      </Grid>
    );
  };

  const billingComponent = (
    <MDBox
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="end"
    >
      {order.orderDetails?.equipmentTypeData &&
        Object.keys(order.orderDetails?.equipmentTypeData).length > 0 && (
          <MDBox width={{ xs: "100%", md: "50%", xl: "30%" }} px={3} pb={3}>
            <MDBox width="100%" display="flex" justifyContent="space-between" alignItems="center">
              <MDTypography variant="caption" fontWeight="regular">
                Total Items
              </MDTypography>
              <MDTypography fontWeight="medium" fontSize={pxToRem(16)}>
                {order.orderDetails?.equipmentTypeData?.length || ""}
              </MDTypography>
            </MDBox>
            <MDBox width="100%" display="flex" justifyContent="space-between" alignItems="center">
              <MDTypography variant="caption" fontWeight="regular">
                Total quantity
              </MDTypography>
              <MDTypography fontWeight="medium" fontSize={pxToRem(16)}>
                {order.orderDetails?.equipmentTypeData?.reduce(
                  (acc, curr) => acc + parseInt(curr.wmApprovedQuantity || 0, 10),
                  0
                )}
              </MDTypography>
            </MDBox>
            {order?.orderDetails?.toDate && rentalDateUI(true)}
            <MDBox width="100%" display="flex" justifyContent="space-between" alignItems="center">
              <MDTypography variant="caption" fontWeight="medium">
                Total amount
              </MDTypography>
              <MDTypography
                fontWeight="medium"
                sx={{ color: Colors.DARK_ORANGE }}
                fontSize={pxToRem(16)}
              >
                {order.orderDetails?.equipmentTypeData?.[0].currencyUnit}{" "}
                {order.orderDetails?.equipmentTypeData
                  ?.filter((item) => item.status !== "rejected")
                  ?.reduce((acc, curr) => {
                    const fromDate = moment(order?.orderDetails?.fromDate);
                    const toDate = moment(order?.orderDetails?.toDate);
                    const duration = moment.duration(toDate.diff(fromDate));
                    const days = duration.asDays() + 1;

                    let totalAmount = 0;
                    if (curr?.quantityPriceType === "rental") {
                      totalAmount =
                        Number(curr?.wmApprovedQuantity) * Number(days) * Number(curr?.price);
                    } else if (curr?.quantityPriceType === "buy" && curr?.status === "requested") {
                      totalAmount = curr?.totalPrice;
                    } else {
                      totalAmount = Number(curr?.price) * Number(curr?.wmApprovedQuantity);
                    }
                    return acc + totalAmount;
                  }, 0)}
              </MDTypography>
            </MDBox>
          </MDBox>
        )}
    </MDBox>
  );

  const handleExport = async () => {
    const res = await handlePdfExport(
      `Reynard_Order_#${order.orderDetails?.orderNumber}.pdf`,
      exportOrderPdf(id),
      false
    );
    if (res.error)
      dispatch(
        openSnackbar({
          message: Constants.LINK_EQUIPMENT,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
  };

  const handlePrint = async () => {
    try {
      setPrintLoading(true);
      // Dispatch the thunk to fetch the PDF Blob
      const pdfBlob = await dispatch(exportOrderPdf(id)).unwrap();

      // Create a URL from the Blob
      const blobURL = URL.createObjectURL(pdfBlob);

      // Create a new window with the PDF URL
      const printWindow = window.open(blobURL, "_blank");

      // Check if the print popup is blocked
      if (printWindow.closed) {
        // If blocked, prompt the user for permission
        alert(
          "The print popup was blocked. Please enable pop-ups in your browser settings and try again."
        );
      } else {
        // If not blocked, trigger the print dialog
        printWindow.focus(); // Ensure the new window is focused
        printWindow.print();
      }
      setPrintLoading(false);
    } catch (error) {
      setPrintLoading(false);
      dispatch(
        openSnackbar({
          message: Constants.LINK_EQUIPMENT,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Feature name={FeatureTags.WAREHOUSE}>
        <MDBox display="flex" justifyContent="space-between">
          <PageTitle title={`${PageTitles.ORDER_DETAIL}${order.orderDetails?.orderNumber || ""}`} />
          <MDBox display="flex" justifyContent="center" alignItems="start" sx={{ mr: 1 }}>
            {order.orderDetails.status === "requested" && permission?.update && (
              <>
                <BasicButton
                  title={ButtonTitles.REJECT}
                  icon={Icons.REJECTED}
                  background={Colors.WHITE}
                  border
                  color={Colors.BLACK}
                  action={() => setOpenApproveRejectModal({ open: true, status: "rejected" })}
                />

                <BasicButton
                  title={ButtonTitles.APPROVE}
                  icon={Icons.APPROVED}
                  background={Colors.WHITE}
                  border
                  color={Colors.BLACK}
                  action={() => setOpenApproveRejectModal({ open: true, status: "approved" })}
                />
              </>
            )}
            <BasicButton
              title={printLoading ? ButtonTitles.PRINTING : ButtonTitles.PRINT}
              icon={Icons.PRINT}
              background={Colors.WHITE}
              border
              color={Colors.BLACK}
              disabled={
                ![
                  "approved",
                  "rejected",
                  "pre-transit",
                  "partially-pre-transit",
                  "transit",
                  "in-transit",
                  "partially-check-in",
                  "check-in",
                ].includes(order.orderDetails?.status)
              }
              action={handlePrint}
            />
            <BasicButton
              title={exportLoading ? ButtonTitles.EXPORTING : ButtonTitles.EXPORT}
              icon={Icons.EXPORT}
              background={Colors.WHITE}
              border
              disabled={
                ![
                  "approved",
                  "rejected",
                  "pre-transit",
                  "partially-pre-transit",
                  "transit",
                  "in-transit",
                  "partially-check-in",
                  "check-in",
                ].includes(order.orderDetails?.status)
              }
              color={Colors.BLACK}
              action={handleExport}
            />
            <BasicButton
              icon={Icons.RELOAD}
              background={Colors.WHITE}
              border
              color={Colors.BLACK}
              action={handleReload}
            />
          </MDBox>
        </MDBox>

        <Divider sx={{ marginTop: 2 }} />

        {/* Order detail card */}
        <StyledMDBox>
          {permission && order.orderDetailsLoading === Constants.FULFILLED ? (
            <MDBox width="100%">
              <MDBox
                width="100%"
                display="flex"
                flexDirection={{ md: "row", sm: "column" }}
                justifyContent={{ md: "space-between", sm: "column" }}
                alignItems={{ lg: "flex-start", sm: "flex-start" }}
                flexWrap="wrap"
              >
                <MDBox
                  width={{ md: "15%", sm: "100%" }}
                  height={pxToRem(100)}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <MDTypography
                    variant="h4"
                    fontWeight="medium"
                    mt={3}
                    justifyContent="center"
                    alignItems="center"
                    width={{ xs: "100%", sm: "auto" }}
                    height={{ xs: "100%", sm: "auto" }}
                    textAlign={{ xs: "center", sm: "left" }}
                    textTransform="capitalize"
                  >
                    {order.orderDetails?.project?.title?.length >
                    defaultData.MEDIUM_CONTENT_LENGTH ? (
                      <Tooltip title={order.orderDetails?.project?.title}>
                        <span>{`${order.orderDetails?.project?.title.slice(
                          0,
                          defaultData.MEDIUM_CONTENT_LENGTH
                        )}...`}</span>
                      </Tooltip>
                    ) : (
                      order.orderDetails?.project?.title
                    )}
                  </MDTypography>
                </MDBox>
                <MDBox>
                  <MDBox mb={1} display="flex" flexDirection="column" alignItems="start">
                    <MDBox display="flex" flexDirection="row" alignItems="flex-start">
                      {order.orderDetails?.orderBy?.profileImage !== null &&
                      order.orderDetails?.orderBy?.profileImage !== "" ? (
                        <img
                          src={order.orderDetails?.orderBy?.profileImage}
                          alt={
                            order.orderDetails?.orderBy?.callingName
                              ? order.orderDetails?.orderBy?.callingName
                              : order.orderDetails?.orderBy?.firstName
                          }
                          style={{
                            width: pxToRem(134),
                            height: pxToRem(134),
                            borderRadius: pxToRem(4),
                            objectFit: "cover",
                          }}
                        />
                      ) : (
                        <MDBox
                          sx={{
                            backgroundColor: Colors.PRIMARY,
                            borderRadius: pxToRem(4),
                            width: pxToRem(134),
                            height: pxToRem(134),
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <MDTypography
                            sx={{
                              marginTop: pxToRem(5),
                              marginRight: pxToRem(3),
                              color: Colors.WHITE,
                              fontSize: pxToRem(50),
                              fontWeight: "bold",
                              backgroundColor: Colors.PRIMARY,
                            }}
                          >
                            {order.orderDetails?.orderBy?.charAt(0).toUpperCase()}
                          </MDTypography>
                        </MDBox>
                      )}
                      <MDBox ml={2} display="flex" flexDirection="column" alignItems="flex-start">
                        <MDTypography
                          mb={pxToRem(8)}
                          variant="caption"
                          fontWeight="regular"
                          fontSize={pxToRem(14)}
                          width={{ xs: "100%", sm: "auto" }}
                          textAlign={{ xs: "center", sm: "left" }}
                          textTransform="capitalize"
                        >
                          Requested By
                        </MDTypography>
                        <MDTypography
                          fontWeight="medium"
                          width={{ xs: "100%", sm: "auto" }}
                          textAlign={{ xs: "center", sm: "left" }}
                          textTransform="capitalize"
                          fontSize={pxToRem(16)}
                        >
                          {order.orderDetails?.orderBy?.callingName
                            ? order.orderDetails?.orderBy?.callingName
                            : order.orderDetails?.orderBy?.firstName}{" "}
                          {order.orderDetails?.orderBy?.lastName}
                        </MDTypography>
                        <MDTypography
                          variant="caption"
                          fontWeight="regular"
                          width={{ xs: "100%", sm: "auto" }}
                          marginTop={2}
                        >
                          On{" "}
                          {moment(order.orderDetails?.createdAt).format(
                            defaultData.WEB_DATE_FORMAT
                          )}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>
                <MDBox>
                  {orderinfo("Requested Items", order.orderDetails?.totalItems || 0)}
                  {orderinfo(
                    "Requested Quantity",
                    `${order.orderDetails?.totalRequestedQuantity} Qty`
                  )}
                </MDBox>
                <MDBox>
                  {orderinfo("Status", `${order.orderDetails?.status.replace("-", " ")}`)}
                </MDBox>
                {rentalDateUI(false)}
              </MDBox>
              {order?.orderDetails?.pmComment?.[0]?.comment?.length > 0 &&
                order?.orderDetails?.pmComment?.[0]?.comment !== null && (
                  <MDBox
                    display="flex"
                    flexDirection="row"
                    width={{ xs: "100%", sm: "auto" }}
                    marginLeft={{ sm: "35%" }}
                    marginTop={-5}
                  >
                    <MDTypography
                      fontSize={pxToRem(14)}
                      fontWeight="medium"
                      width={{ xs: "100%", sm: "auto" }}
                      textAlign={{ xs: "center", sm: "left" }}
                      marginTop={2}
                    >
                      Remark
                    </MDTypography>
                    <MDTypography
                      fontWeight="regular"
                      width={{ xs: "100%", sm: "auto" }}
                      textAlign={{ xs: "center", sm: "left" }}
                      textTransform="capitalize"
                      fontSize={pxToRem(14)}
                      mt={pxToRem(17)}
                      ml={1}
                    >
                      {order?.orderDetails?.pmComment?.[0]?.comment}
                    </MDTypography>
                  </MDBox>
                )}
            </MDBox>
          ) : (
            <MDBox
              py={5}
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              {Icons.LOADING2}
            </MDBox>
          )}
        </StyledMDBox>

        {/* Order Table */}
        <MDBox mt={3} mb={3}>
          <DataTable
            table={{ columns, rows }}
            isSorted={false}
            entriesPerPage={{ defaultValue: defaultData.PER_PAGE }}
            showTotalEntries={false}
            noEndBorder
            loading={(permission && order.orderDetailsLoading) || Constants.PENDING}
            loadLayerId={loadWmOrderDetailsEquipmentLayerData}
            currentPage={tablePagination.page}
            handleTablePagination={handleTablePagination}
            handleCurrentPage={(page) => setTablePagination({ ...tablePagination, page })}
            extraContent={billingComponent}
            childTable={[
              {
                columns: equipmentLayerColumns,
                rows: equipmentLayerOrderRow,
                backgroundColor: Colors.LIGHT_GRAY,
                textColor: Colors.PRIMARY,
                isSorted: false,
                entriesPerPage: { defaultValue: defaultData.PER_PAGE },
                showTotalEntries: false,
                noEndBorder: true,
                loading: Constants.FULFILLED,
              },
            ]}
          />
        </MDBox>

        {/* Edit Equipment Quantity */}
        {editModalOpen && (
          <EditEquipmentQuantity
            open={editModalOpen}
            handleClose={handleEditClose}
            editEquipmentData={editEquipment}
            handleAction={handleEditAction}
            minWidth={600}
          />
        )}

        {/* Approve and Reject confirmation modal */}
        {openApproveRejectModal.open && (
          <ConfirmationModal
            open={openApproveRejectModal.open}
            disabled={openApproveRejectModal.status.includes("Loading")}
            handleClose={handleApproveRejectModalClose}
            title={`Are you sure you want to ${openApproveRejectModal.status} this order?`}
            actionButton={openApproveRejectModal.status}
            handleAction={() => handleApproveReject(openApproveRejectModal.status)}
          />
        )}

        {/* Single Reject confirmation modal */}
        {singleRejectData.open && (
          <ConfirmationModal
            open={singleRejectData.open}
            handleClose={handleSingleRejectClose}
            title={
              singleRejectData.isPartialReject
                ? Constants.REJECT_PARTIAL_ORDER
                : Constants.REJECT_ORDER
            }
            handleAction={handleApproveRejectSingle}
          >
            <FTextField
              label="Remark"
              name="remark"
              id="remark"
              placeholder="Enter your remark"
              type="text"
              value={singleRejectData.remark}
              handleChange={(e) =>
                setSingleRejectData({ ...singleRejectData, remark: e.target.value })
              }
              width="100%"
              marginBottom={pxToRem(10)}
            />
          </ConfirmationModal>
        )}

        {/* Approver Comments */}
        {comments.open && (
          <CheckInCheckOutComments
            open={comments.open}
            type={comments.type}
            intialComments={comments.intialComments}
            equipStatus={order.orderDetails?.status}
            handleClose={handleCommentsClose}
            handleAction={handleEditCommentAction}
            buttonLoading={buttonLoading}
            minWidth={600}
          />
        )}
      </Feature>
    </DashboardLayout>
  );
}

WarehouseOrderDetails.propTypes = {
  handlePdfExport: PropTypes.func.isRequired,
  exportLoading: PropTypes.bool.isRequired,
};

export default ExportHOC(WarehouseOrderDetails);
