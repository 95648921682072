import { createSlice } from "@reduxjs/toolkit";
import { resetStateThunk } from "redux/Thunks/Authentication";
import shiftListThunk, { shiftByIdThunk } from "redux/Thunks/DalShift";
import { dalShitFiltersThunk } from "redux/Thunks/Filter";
import Constants from "utils/Constants";
import ShiftFormat from "utils/Types/ShiftTypes";

const initialState = {
  loading: Constants.IDLE,
  shiftList: [],
  shiftListingPagiantion: {
    totalRecords: 0,
    completed: [],
  },
};

export const dalShiftSlice = createSlice({
  name: "dalShift",
  initialState,
  reducers: {
    loadshiftData(state, action) {
      state.shiftList.push(...action.payload.data);
    },
    removeShift(state, action) {
      const index = state.shiftList.findIndex(
        (item) => item?.[Constants.MONGOOSE_ID] === action.payload
      );
      state.shiftList.splice(index, 1);
      state.shiftListingPagiantion.totalRecords -= 1;
    },
    reloadData(state) {
      state.loading = Constants.PENDING;
    },
    updateCompleted(state, action) {
      state.shiftListingPagiantion.completed = [...new Set(action.payload)];
    },
  },

  extraReducers: {
    [shiftListThunk.pending]: (state) => {
      state.loading = Constants.PENDING;
    },
    [shiftListThunk.fulfilled]: (state, { payload }) => {
      state.loading = Constants.FULFILLED;
      state.shiftList = payload.data;
    },
    [shiftListThunk.rejected]: (state) => {
      state.loading = Constants.REJECTED;
    },
    [dalShitFiltersThunk.pending]: (state) => {
      state.loading = Constants.PENDING;
    },
    [dalShitFiltersThunk.fulfilled]: (state, { payload, meta }) => {
      state.loading = Constants.FULFILLED;

      // Destructure data for easier access
      const { shiftData, allRecordsCount } = payload.data.data;

      // Sort shifts by start date in descending order
      const sortedShifts = shiftData.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));

      // Get pagination info from the meta argument
      const params = new URLSearchParams(meta.arg);
      const page = parseInt(params.get("page"), 10);
      const perPage = parseInt(params.get("perPage"), 10);

      // If shiftList is empty, initialize it with the required format
      const shiftList =
        page === 0
          ? Array.from({ length: allRecordsCount }, () => ({ ...ShiftFormat }))
          : [...state.shiftList]; // shallow copy to avoid direct state mutation

      // Calculate start index for pagination and replace elements in the array
      const startIndex = page * perPage;
      shiftList.splice(startIndex, perPage, ...sortedShifts);

      // Update state with new data
      state.shiftList = shiftList;
      state.shiftListingPagiantion.totalRecords = allRecordsCount;
      state.shiftListingPagiantion.completed =
        page === 0 ? [page] : [...state.shiftListingPagiantion.completed, page];
    },
    [dalShitFiltersThunk.rejected]: (state) => {
      state.loading = Constants.REJECTED;
    },
    [shiftByIdThunk.pending]: (state) => {
      if (state.shiftList.length === 0) state.loading = Constants.PENDING;
    },
    [shiftByIdThunk.fulfilled]: (state, { payload }) => {
      state.loading = Constants.FULFILLED;
      state.shiftList = [payload.data.data];
    },
    [shiftByIdThunk.rejected]: (state) => {
      state.loading = Constants.REJECTED;
    },
    [resetStateThunk.fulfilled]: (state) => {
      state.loading = Constants.IDLE;
      state.shiftList = [];
    },
  },
});

export const { loadshiftData, removeShift, reloadData, updateCompleted } = dalShiftSlice.actions;
export default dalShiftSlice.reducer;
