import React, { useEffect, useState } from "react";

// MUI Components
import { Divider } from "@mui/material";
import pxToRem from "assets/theme/functions/pxToRem";

// Custom Component
import MDBox from "components/MDBox";
import PageTitle from "examples/NewDesign/PageTitle";
import ViewToolBoxDrawer from "examples/Drawers/ToolboxTalk";
import ResetFilterButton from "components/Buttons/ResetButton";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FilterDropdown from "components/Dropdown/FilterDropdown";
import DeleteModal from "examples/modal/deleteModal/deleteModal";
import BasicButton from "examples/NewDesign/CustomButton/BasicButton";
import ToolboxTalkData from "layouts/toolboxTalk/data/toolboxTalkData";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ExportHOC from "examples/HigherOrderComponents/ExportHOC";

// Data tables
import DataTable from "examples/Tables/DataTable";

// Redux
import { useDispatch, useSelector } from "react-redux";
import getToolBoxTalk, {
  toolBoxTalkByIdThunk,
  deleteToolBoxTalkThunk,
} from "redux/Thunks/toolBoxTalk";
import { setToolBoxTalkFilters, resetToolBoxTalkFilters } from "redux/Slice/Filter";
import { openSnackbar } from "redux/Slice/Notification";

// Constants
import Constants, {
  PageTitles,
  Icons,
  Colors,
  defaultData,
  FeatureTags,
  ModalContent,
  FiltersModuleName,
  Common,
} from "utils/Constants";
import { paramCreater, findPageNumberAfterRecordDelete } from "utils/methods/methods";
import { projectListThunk, locationListByIdThunk, teamThunk } from "redux/Thunks/FieldsData";
import toolBoxTalkDataFormat from "utils/Types/ToolboxTalk";

// 3rd party library
import { Feature } from "flagged";

const toolboxFiltersArr = [
  {
    inputLabel: FiltersModuleName.PROJECT,
    list: [FiltersModuleName.TOOLBOX_TALK_FILTERS_TITLE_OBJ],
    selectedValue: FiltersModuleName.ALL_IN_SMALL_CASE,
  },
  {
    inputLabel: FiltersModuleName.LOCATION,
    list: [FiltersModuleName.TOOLBOX_TALK_FILTERS_TITLE_OBJ],
    selectedValue: FiltersModuleName.ALL_IN_SMALL_CASE,
  },
  {
    inputLabel: FiltersModuleName.TEAM,
    list: [FiltersModuleName.TOOLBOX_TALK_FILTERS_TITLE_OBJ],
    selectedValue: FiltersModuleName.ALL_IN_SMALL_CASE,
  },
];

function ToolboxTalk() {
  const dispatch = useDispatch();

  const toolboxFiltersList = useSelector((state) => state.filtersSlice.toolboxTalkFilters) || [];

  // Checking If the store has any previous filters saved.
  const initialFilters = toolboxFiltersList?.length > 0 ? toolboxFiltersList : toolboxFiltersArr;

  const [tablePagination, setTablePagination] = useState({
    page: 0,
    perPage: defaultData.PER_PAGE,
  });
  const [shouldUpdateState, setShouldUpdateState] = useState(false);
  const [paginationThing, setPaginationThing] = useState({ allRecordsCount: 0, completed: [] });
  const [loading, setLoading] = useState(Constants.PENDING);
  const [toolboxTalkDataArr, setToolboxTalkDataArr] = useState(null);
  const [toolBoxViewData, setToolBoxViewData] = useState({
    data: {},
    loading: true,
  });
  const [deleteToolboxTalkData, setDeleteToolboxTalkData] = useState({
    id: "",
    open: false,
  });
  const [filters, setFilters] = useState(initialFilters);
  const [openToolboxAnchor, setOpenToolboxAnchor] = useState({ right: false });

  const createTookBoxTalkListFunc = (resObj = {}, meta = {}) => {
    const { allRecordsCount = 0, toolboxTalkData = [] } = resObj;

    // Get pagination info from the meta argument
    const params = new URLSearchParams(meta.arg);
    const page = parseInt(params.get("page"), 10);
    const perPage = parseInt(params.get("perPage"), 10);

    // If toolboxTalkDataArr is empty, initialize it with the required format
    const toolBoxTalkList =
      page === 0
        ? Array.from({ length: allRecordsCount }, () => ({ ...toolBoxTalkDataFormat }))
        : [...toolboxTalkDataArr];

    // Calculate start index for pagination and replace elements in the array
    const startIndex = page * perPage;
    toolBoxTalkList.splice(startIndex, perPage, ...toolboxTalkData);

    const visitedPagesArr = page === 0 ? [page] : [...paginationThing.completed, page];

    setToolboxTalkDataArr(toolBoxTalkList);
    setPaginationThing({ allRecordsCount, completed: visitedPagesArr });
  };

  const deleteToolBoxRecordInternally = (id) => {
    const updatedToolBoxArr = toolboxTalkDataArr.filter(
      (item) => item?.[Constants.MONGOOSE_ID] !== id
    );
    const updatedRecordsCount = paginationThing.allRecordsCount - 1;

    setToolboxTalkDataArr(updatedToolBoxArr);
    setPaginationThing((prev) => ({ ...prev, allRecordsCount: updatedRecordsCount }));
  };

  const updateToolBoxListAfterDeleteInternally = () => {
    const completedPages = [...paginationThing.completed];

    for (let i = completedPages.length - 1; i >= 0; i -= 1) {
      if (completedPages[i] > tablePagination.page) {
        completedPages.splice(i, 1);
      }
    }
    setPaginationThing((prev) => ({ ...prev, completed: [...new Set(completedPages)] }));
  };

  // All the component functions starts from here.
  const getToolBoxTalkData = async (filterParams) => {
    const updatedFilterParams = paramCreater(filterParams);
    setLoading(Constants.PENDING);
    try {
      const res = await dispatch(getToolBoxTalk(updatedFilterParams));
      if (res.payload.status === Common.API_STATUS_200) {
        createTookBoxTalkListFunc(res?.payload?.data?.data, res.meta);
      }
    } catch (error) {
      dispatch(
        openSnackbar({
          message: Constants.SOMETHING_WENT_WRONG,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
    }
    setLoading(Constants.FULFILLED);
  };

  const handleDeleteToolboxTalkData = async () => {
    try {
      const res = await dispatch(deleteToolBoxTalkThunk(deleteToolboxTalkData.id));
      if (res?.payload?.status === Common.API_STATUS_200) {
        deleteToolBoxRecordInternally(deleteToolboxTalkData.id);

        const { page, perPage } = tablePagination;
        const { allRecordsCount } = paginationThing;
        const currPage = findPageNumberAfterRecordDelete(page, perPage, allRecordsCount);

        const filterParams = new URLSearchParams({
          page: currPage,
          perPage: tablePagination.perPage,
          project: filters[0].selectedValue,
          location: filters[1].selectedValue.toLowerCase().replace(/ /g, "_"),
          team: filters[2].selectedValue,
        });
        // setTablePagination((prev) => ({ ...prev, page: currPage }));
        const toolboxTalkRes = await dispatch(getToolBoxTalk(filterParams));
        if (toolboxTalkRes.payload.status === Common.API_STATUS_200) {
          updateToolBoxListAfterDeleteInternally();
        }
        await dispatch(
          openSnackbar({
            message: Constants.TOOLBOX_TALK_DELETE_SUCCESS,
            notificationType: Constants.NOTIFICATION_SUCCESS,
          })
        );
        setDeleteToolboxTalkData({ id: "", open: false });
      } else {
        dispatch(
          openSnackbar({
            message: Constants.SOMETHING_WENT_WRONG,
            notificationType: Constants.NOTIFICATION_ERROR,
          })
        );
        setDeleteToolboxTalkData({ id: "", open: false });
      }
    } catch (error) {
      dispatch(
        openSnackbar({
          message: Constants.SOMETHING_WENT_WRONG,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
    }
  };

  // Get Project List Func for dropdown.
  const getProjectListFunc = async () => {
    try {
      const projectList = await dispatch(projectListThunk());
      if (projectList.payload.status) {
        setFilters((prev) => {
          const updatedFilters = prev.map((filter) => {
            if (filter.inputLabel === FiltersModuleName.PROJECT) {
              return {
                ...filter,
                list: [
                  FiltersModuleName.TOOLBOX_TALK_FILTERS_TITLE_OBJ,
                  ...projectList.payload.data,
                ],
              };
            }
            return filter;
          });

          // Updating the Store also with the latest Project List.
          dispatch(setToolBoxTalkFilters(updatedFilters));
          return updatedFilters;
        });
      }
    } catch (error) {
      dispatch(
        openSnackbar({
          message: Constants.SOMETHING_WENT_WRONG,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
    }
  };

  const handleReload = async () => {
    setShouldUpdateState((prev) => !prev);
  };

  const handleResetFilter = () => {
    setFilters((prev) => {
      const updatedFilters = prev.map((filter) => ({
        ...filter,
        selectedValue: filter.list[0][Constants.MONGOOSE_ID] || filter.list[0],
      }));
      return updatedFilters;
    });
    setTablePagination((prev) => ({ ...prev, page: 0 }));
    dispatch(resetToolBoxTalkFilters());
    setShouldUpdateState((prev) => !prev);
  };

  const handleTablePagination = async (value) => {
    if (paginationThing.completed.includes(value)) return;
    setShouldUpdateState((prev) => !prev);
  };

  // Func realted to filter
  const updateFilterList = (locationData = [], teamData = []) => {
    const createListWithDefault = (list = []) => [
      {
        [Constants.MONGOOSE_ID]: FiltersModuleName.ALL_IN_SMALL_CASE,
        title:
          list.length > 0
            ? FiltersModuleName.ALL_WITH_FIRST_LETTER_CAPITAL
            : FiltersModuleName.NO_DATA_FOUND,
      },
      ...list,
    ];

    setFilters((prevFilters) => {
      const updatedFilters = prevFilters.map((filter) => {
        if (filter.inputLabel === FiltersModuleName.LOCATION) {
          return {
            ...filter,
            selectedValue: FiltersModuleName.ALL_IN_SMALL_CASE,
            list: createListWithDefault(locationData),
          };
        }
        if (filter.inputLabel === FiltersModuleName.TEAM) {
          const teamListArr = teamData.map((item) => ({
            [Constants.MONGOOSE_ID]: item?.[Constants.MONGOOSE_ID] || "",
            title: item?.teamsWfmName || "",
          }));
          return {
            ...filter,
            selectedValue: FiltersModuleName.ALL_IN_SMALL_CASE,
            list: createListWithDefault(teamListArr),
          };
        }
        return filter;
      });

      // Updating the Store also with the latest Project List.
      dispatch(setToolBoxTalkFilters(updatedFilters));
      return updatedFilters;
    });
  };

  const fetchLocationAndTeamList = async (key) => {
    try {
      const [locationList, teamList] = await Promise.all([
        dispatch(locationListByIdThunk(key)),
        dispatch(teamThunk(key)),
      ]);
      const locationArr = locationList?.payload?.data;
      const teamArr = teamList?.payload?.data;
      updateFilterList(locationArr, teamArr);
    } catch (error) {
      dispatch(
        openSnackbar({
          message: Constants.SOMETHING_WENT_WRONG,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
    }
  };

  const handleFilterChange = async (e) => {
    const { value, name } = e.target;
    setLoading(Constants.PENDING);

    setFilters((prevFilters) => {
      const updatedFilters = prevFilters.map((filter) => {
        if (filter.inputLabel === name) {
          return { ...filter, selectedValue: value };
        }
        if (name === FiltersModuleName.PROJECT && value === FiltersModuleName.ALL_IN_SMALL_CASE) {
          return { ...filter, selectedValue: FiltersModuleName.ALL_IN_SMALL_CASE };
        }
        return filter;
      });

      // Updating the Store also with the latest Project List.
      dispatch(setToolBoxTalkFilters(updatedFilters));
      return updatedFilters; // Only update the state here
    });

    if (name === FiltersModuleName.PROJECT && value !== FiltersModuleName.ALL_IN_SMALL_CASE) {
      await fetchLocationAndTeamList(value);
    }
    setTablePagination((prev) => ({ ...prev, page: 0 }));
    setShouldUpdateState((prev) => !prev);
  };

  const getToolBoxTalkDrawerData = async (id) => {
    const res = await dispatch(toolBoxTalkByIdThunk(id));
    if (res.payload.status === Common.API_STATUS_200) {
      setToolBoxViewData({ data: res.payload.data.data, loading: false });
    }
  };

  const handleOpenToolboxDrawer = (id) => {
    setOpenToolboxAnchor({ right: true });
    getToolBoxTalkDrawerData(id);
  };

  const closeToolboxDrawer = () => {
    setOpenToolboxAnchor({ right: false });
    setToolBoxViewData({ data: {}, loading: true });
  };

  const handleOpenDeleteModal = (deleteId) => {
    setDeleteToolboxTalkData({
      id: deleteId,
      open: true,
    });
  };

  const { columns, rows } = ToolboxTalkData(
    toolboxTalkDataArr,
    handleOpenToolboxDrawer,
    handleOpenDeleteModal
  );

  useEffect(() => {
    getProjectListFunc();
  }, []);

  useEffect(() => {
    const filterParams = {
      page: tablePagination.page,
      perPage: tablePagination.perPage,
      project: filters[0].selectedValue,
      location: filters[1].selectedValue.toLowerCase().replace(/ /g, "_"),
      team: filters[2].selectedValue,
    };

    getToolBoxTalkData(filterParams);
  }, [shouldUpdateState]);

  return (
    <DashboardLayout module={defaultData.TOOLBOX_TALK_SCREEN_ID}>
      <DashboardNavbar />
      <MDBox display="flex" justifyContent="space-between">
        <PageTitle title={PageTitles.TOOLBOX_TALK} />
        <Feature name={FeatureTags.TOOLBOX_TALK}>
          <BasicButton
            icon={Icons.RELOAD}
            background={Colors.WHITE}
            border
            color={Colors.BLACK}
            action={handleReload}
          />
        </Feature>
      </MDBox>
      <Divider sx={{ marginTop: 2 }} />
      <Feature name={FeatureTags.TOOLBOX_TALK}>
        <MDBox display="flex" justifyContent="space-between" mx={0}>
          <MDBox
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "start",
              alignItems: "flex-end",
            }}
            mx={0}
          >
            {filters.map((val) => (
              <FilterDropdown
                key={val.inputLabel}
                label={val.inputLabel}
                name={val.inputLabel}
                defaultValue={val.selectedValue}
                value={val.selectedValue}
                handleChange={handleFilterChange}
                menu={val.list}
                style={{ marginLeft: pxToRem(1) }}
                disabled={
                  (val?.inputLabel === "Location" && filters[0]?.selectedValue === "all") ||
                  (val?.inputLabel === "Location" &&
                    filters[1]?.list[0].title === "No data found") ||
                  (val?.inputLabel === "Team" && filters[0]?.selectedValue === "all") ||
                  (val?.inputLabel === "Team" && filters[2]?.list[0].title === "No data found")
                }
                maxContent={defaultData.MEDIUM_CONTENT_LENGTH}
              />
            ))}
            <ResetFilterButton handleReset={handleResetFilter} style={{ marginLeft: "1rem" }} />
          </MDBox>
        </MDBox>
        <MDBox
          sx={{
            marginTop: pxToRem(24),
            marginBottom: pxToRem(16),
          }}
        >
          <DataTable
            table={{ columns, rows }}
            isSorted={false}
            entriesPerPage={{ defaultValue: defaultData.PER_PAGE }}
            showTotalEntries={false}
            noEndBorder
            loading={loading}
            licenseRequired
            currentPage={tablePagination.page}
            handleTablePagination={handleTablePagination}
            handleCurrentPage={(page) => setTablePagination({ ...tablePagination, page })}
            isGotoVisisble
          />
        </MDBox>
        {openToolboxAnchor.right && (
          <ViewToolBoxDrawer
            openToolboxAnchor={openToolboxAnchor}
            closeToolboxDrawer={closeToolboxDrawer}
            toolBoxViewData={toolBoxViewData.data}
            loading={toolBoxViewData.loading}
          />
        )}
        <DeleteModal
          open={deleteToolboxTalkData.open}
          title={ModalContent.DELETE_TOOLBOX_TALK}
          message={ModalContent.DELETE_TOOLBOX_TALK_MESSAGE}
          handleClose={() => {
            setDeleteToolboxTalkData({ ...deleteToolboxTalkData, open: false });
          }}
          handleDelete={handleDeleteToolboxTalkData}
        />
      </Feature>
    </DashboardLayout>
  );
}

export default ExportHOC(ToolboxTalk);
