import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import {
  Box,
  Tab,
  Tabs,
  CircularProgress,
  Icon,
  Card,
  Tooltip,
  IconButton,
  styled,
} from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";
import MDTypography from "components/MDTypography";
import ImageUpload from "components/ImageUpload/imageUpload";
import DateTime from "components/DateTime/DateTime";
import CustomAutoComplete from "components/Dropdown/CustomAutoComeplete";
import FontComponent from "components/Responsive/fonts";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import pxToRem from "assets/theme/functions/pxToRem";
import Personaldetails from "examples/modal/UpdateProfile/PersonalDetails";
import CustomButton from "examples/NewDesign/CustomButton";
import BasicModal from "examples/modal/BasicModal/BasicModal";
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox";

// Overview page components
import FullScreenImageComponent from "components/ViewFullImage/ViewImage";
import Status from "components/Table/Status";

// Images imports from assets
import bgImage from "assets/images/Profilebg.png";
import Pdf from "assets/images/pdf.svg";

// third party libraries
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import ReactDatePicker from "react-datepicker";

// Redux Components imports
import ProfileThunk from "redux/Thunks/Profile";
import { UserListbyIdThunk } from "redux/Thunks/UserManagement";
import uploadImageThunk from "redux/Thunks/ImageUpload";
import { updateAdminProfileThunk } from "redux/Thunks/SuperAdmin";
import { openSnackbar } from "redux/Slice/Notification";
import RoleListThunk from "redux/Thunks/Role";
import getProfileFunctions from "redux/Thunks/ProfileFunctions";
import { createUserCertificate } from "redux/Thunks/certificateApproval";

// Utils Components imports
import Constants, { Icons, defaultData, Colors, ButtonTitles, Common } from "utils/Constants";
import { downloadFile } from "utils/methods/methods";
import getAllCertificateType from "redux/Thunks/certificateQuestion";
import UserRatings from "./userRatings";

// Initial values for certificate upload payload.
const uploadCertificateInitialValues = {
  startDate: null,
  endDate: null,
  certificateType: "",
  name: "",
  link: "",
  fileName: "",
  size: "",
  internal: true,
  disabled: false,
  isValidityDate: true,
};

function Overview({ isUserShow, userId }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mongooseID = "_id";
  const fontSize = FontComponent({ sizes: {} });
  const ConfigData = useSelector((state) => state.config);
  const permission = ConfigData?.screens[11]?.screensInfo?.agreement;
  // State variables
  const [profileData, setProfileData] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  const [fullScreenImage, setFullScreenImage] = useState(null);
  const [profileFunctionOptions, setProfileFunctionOptions] = useState([]);
  const [update, setUpdate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [value, setValue] = useState(0);

  // User Certificate Related State variables
  const [certificateValue, setCertificateValue] = useState(0);
  const [renderImageField, setRenderImageField] = useState(false);
  const [openCertificateUploadModal, setOpenCertificateUploadModal] = useState(false);
  const [certificateTypeOptions, setCertificateTypeOptions] = useState([]);
  const [currentCertificateIndex, setCurrentCertificateIndex] = useState(0);
  const [certificateValidation, setCertificateValidation] = useState({});
  const [uploadedCertificatesArr, setUploadedCertificatesArr] = useState([
    uploadCertificateInitialValues,
  ]);
  const isUserWebAccess = profileData[0]?.role?.accessType === defaultData.WEB_ACCESSTYPE;

  const CustomTabs = styled(Tabs)({
    backgroundColor: "white",
    border: "1px solid #E0E2E7",
  });

  const CustomTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    color: "#667085",
    "&.Mui-selected": {
      color: "#667085",
      fontWeight: 600,
      backgroundColor: "#DEDEFA",
    },
  }));

  // Common user tab styles.
  const commonUserTabStyles = {
    display: "flex",
    justifyContent: "flex-start",
    paddingLeft: "12px",
    fontSize: pxToRem(14),
    borderRadius: 0,
    height: "34px!important",
    textTransform: "capitalize",
  };

  // Function to handle dynamic styles for user view tabs.
  const dynamicUserTabStyles = (tabIndex) => ({
    fontWeight: value === tabIndex ? "600" : null,
    backgroundColor: value === tabIndex ? "#F6F7FF" : "#ffffff",
    borderLeft: `2px solid ${value === tabIndex ? "#191A51" : "#ffffff"}`,
  });

  // API Calls to fetch Data for the profile page.
  const displayErrorMessage = () => {
    dispatch(openSnackbar({ message: Constants.SOMETHING_WENT_WRONG, notificationType: "error" }));
  };

  const fetchAdditionalDataFunc = async () => {
    try {
      const [roleRes, profileFunctionRes] = await Promise.all([
        dispatch(RoleListThunk()),
        dispatch(getProfileFunctions()),
      ]);

      const filteredRoles = roleRes?.payload?.data?.data?.rolesData?.filter(
        (role) => ![defaultData.ADMIN_ROLE, defaultData.SUPER_ADMIN_ROLE].includes(role?.title)
      );

      const activeFunctions = profileFunctionRes?.payload?.data?.data?.filter(
        (func) => func.isActive === true
      );

      setRoleOptions(filteredRoles);
      setProfileFunctionOptions(activeFunctions);
    } catch (error) {
      displayErrorMessage();
    }
  };

  const getuserListByIdFunc = async () => {
    try {
      const res = await dispatch(UserListbyIdThunk(id || userId));

      if (res.payload?.status === 200) {
        const profile = res?.payload?.data?.data;
        setProfileData([profile]);

        const roleTitle = profile?.role?.title?.toLowerCase();
        if (![defaultData.ADMIN_ROLE, defaultData.SUPER_ADMIN_ROLE].includes(roleTitle)) {
          await fetchAdditionalDataFunc();
        }
      } else {
        displayErrorMessage();
        navigate("/client/personnel");
      }
    } catch (error) {
      displayErrorMessage();
      navigate("/client/personnel");
    }
  };

  const fetchAdminDataFunc = async () => {
    try {
      const res = await dispatch(ProfileThunk());
      setProfileData([res?.payload?.data]);
      setRoleOptions([]);
      setProfileFunctionOptions([]);
    } catch (error) {
      displayErrorMessage();
    }
  };

  const getAllCertificateTypeFunc = async () => {
    try {
      const res = await dispatch(getAllCertificateType());
      if (res?.payload?.status === 200) {
        const certificateTypesArr = res?.payload?.data?.data || [];
        const certificateOptionsArr = certificateTypesArr.map((item) => ({
          title: item?.name,
          [mongooseID]: item?.[mongooseID],
          validityDate: item?.validityDate,
          disabled: false,
          hide: false,
        }));

        setCertificateTypeOptions(certificateOptionsArr);
      }
    } catch (error) {
      displayErrorMessage();
    }
  };
  // API Calls to fetch Data for the profile page ends here.

  const handleProfileImageChange = async (event) => {
    const file = event.target.files[0];
    const type = "Profile_Photos";

    const res = await dispatch(uploadImageThunk({ file, type }));
    if (res.error === undefined) {
      const b = {
        body: { profileImage: res.payload.data.iconUrl },
        id: profileData[0]?.[mongooseID],
      };

      const res1 = await dispatch(updateAdminProfileThunk(b));
      if (res1.error === undefined) {
        dispatch(
          openSnackbar({ message: Constants.PROFILE_IMAGE_UPDATE, notificationType: "success" })
        );
        setUpdate((prev) => !prev);
      }
    } else {
      displayErrorMessage();
    }
  };

  const formatAddress = (element, profileDatas) => {
    if (profileDatas[0]?.role?.accessType === defaultData.WEB_ACCESSTYPE) {
      return element?.address || Constants.NA;
    }
    const addressParts = [
      element?.street,
      element?.area,
      element?.zipCode,
      element?.city,
      element?.state,
    ];

    const address = addressParts.filter((part) => part).join(",");

    return address || Constants.NA;
  };

  const formatDrivingLicence = (licenseObj) => {
    const expiryDate = moment(licenseObj?.expiryDate).format(defaultData.WEB_DATE_FORMAT);
    const issueDate = moment(licenseObj?.issueDate).format(defaultData.WEB_DATE_FORMAT);

    if (licenseObj?.licenseNumber) {
      return `${licenseObj?.licenseNumber} (${issueDate} - ${expiryDate})`;
    }
    return "No";
  };

  const handleInsuranceInContractualDetails = (insuranceObj) => {
    const fromDate = moment(insuranceObj?.fromDate).format(defaultData.WEB_DATE_FORMAT);
    const toDate = moment(insuranceObj?.toDate).format(defaultData.WEB_DATE_FORMAT);

    return {
      name: `${insuranceObj?.name} (${fromDate} - ${toDate})`,
      url: insuranceObj?.url,
      [Constants.MONGOOSE_ID]: insuranceObj?.[Constants.MONGOOSE_ID],
    };
  };

  const handleImageFullView = (imagePreview) => {
    setFullScreenImage(imagePreview);
  };

  const handleCloseFullView = () => {
    setFullScreenImage(null);
  };

  const renderCertificates = (certificates) => (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 3 }} mt={2}>
      {certificates.length > 0 ? (
        certificates.map((element) => (
          <Grid key={element?.[Constants.mongooseID]} item xs={10} xl={5}>
            <MDTypography
              display="block"
              variant="caption"
              textTransform="capitalize"
              fontWeight="medium"
              mb={1}
              mt={1}
            >
              {element?.certificateType?.name?.length > defaultData.MEDIUM_CONTENT_LENGTH ? (
                <Tooltip title={element?.certificateType?.name}>
                  <span>
                    {`${element?.certificateType?.name.slice(
                      0,
                      defaultData.MEDIUM_CONTENT_LENGTH
                    )}...`}
                  </span>
                </Tooltip>
              ) : (
                <span>{element?.certificateType?.name}</span>
              )}
            </MDTypography>
            <Box
              display="flex"
              border="1px solid #E0E6F5"
              borderRadius="8px"
              p={1}
              sx={{ cursor: "pointer" }}
              onClick={() => handleImageFullView(element?.link)}
            >
              {!element?.link.includes("pdf") ? (
                <img
                  src={element?.link || process.env.REACT_APP_IMAGE_NOT_FOUND}
                  alt="Preview"
                  height="60px"
                  width="60px"
                  style={{
                    border: "1px solid #D0D5DD",
                    borderRadius: "8px",
                    marginTop: "5px",
                    marginLeft: "4px",
                  }}
                />
              ) : (
                <img
                  src={Pdf}
                  alt="Preview"
                  height="60px"
                  width="60px"
                  style={{
                    marginTop: "5px",
                    marginLeft: "4px",
                  }}
                />
              )}
              <Box ml={2}>
                <MDTypography
                  display="block"
                  variant="caption"
                  sx={{ textTransform: "capitalize" }}
                >
                  {element?.fileName?.length > defaultData.SMALLER_CONTENT_LENGTH ? (
                    <Tooltip title={element?.fileName}>
                      <span>{`${element?.fileName.slice(
                        0,
                        defaultData.SMALLER_CONTENT_LENGTH
                      )}...`}</span>
                    </Tooltip>
                  ) : (
                    <span>{element?.fileName}</span>
                  )}
                </MDTypography>
                {element?.endDate && (
                  <MDTypography display="block" variant="caption" color="text">
                    End date: {moment(element?.endDate).format(defaultData.WEB_DATE_FORMAT)}
                  </MDTypography>
                )}
                <MDTypography display="block" variant="caption" color="text">
                  {element?.size ? `${element?.size} KB` : Constants.NA}
                </MDTypography>
                <Box mt={1}>
                  <Status
                    title={`${
                      certificateValue === 2
                        ? Constants.IN_ACTIVE
                        : element?.status.replace("_", " ")
                    }`}
                  />
                </Box>
              </Box>
              <IconButton
                sx={{ ml: "auto" }}
                onClick={(event) => downloadFile(element?.link, event, element?.fileName)}
              >
                {Icons.DOWNLOAD}
              </IconButton>
            </Box>
          </Grid>
        ))
      ) : (
        <MDTypography
          mt="25%"
          ml="30%"
          variant="h6"
          fontWeight="medium"
          color="text"
          textTransform="capitalize"
        >
          No Certificates Uploaded
        </MDTypography>
      )}
    </Grid>
  );

  const updateSetUploadCertificateArr = (index, updateObj) => {
    setUploadedCertificatesArr((prev) =>
      prev.map((item, i) => (i === index ? { ...item, ...updateObj } : item))
    );
  };

  const handleCertificateTab = (event, certValue) => {
    setCertificateValue(certValue);
  };

  const filterEmptyValues = (obj) =>
    Object.fromEntries(
      Object.entries(obj).filter(([key, certiValue]) => {
        if (certiValue === null || certiValue === "") {
          if (
            obj.isValidityDate === false &&
            (key === Common.START_DATE || key === Common.END_DATE)
          ) {
            return false;
          }
          return true;
        }
        return false;
      })
    );

  // Function to handle certificate dropdown value change
  const handleChangeUploadCertificateType = (e, index) => {
    const { value: certificateTypeId } = e.target;
    const certificateTypeObj = certificateTypeOptions.find(
      (item) => item?.[Constants.MONGOOSE_ID] === certificateTypeId
    );

    if (certificateTypeId) {
      setCertificateValidation((prev) => {
        const { certificateType, ...rest } = prev;
        return rest;
      });
    }

    // Updating the Certificate Type using the index value
    updateSetUploadCertificateArr(index, {
      certificateType: certificateTypeId,
      name: certificateTypeObj?.title,
      isValidityDate: certificateTypeObj?.validityDate,
    });
  };

  // Function to handle certificate upload
  const handleCertificateUploadFunc = async (imageValues, index) => {
    updateSetUploadCertificateArr(index, {
      link: imageValues?.[0]?.url || "",
      fileName: imageValues?.[0]?.name || "",
      size: imageValues?.[0]?.size || "",
    });

    // deleting the empty values by key name
    if (certificateValidation?.link === "") {
      setCertificateValidation((prev) => {
        const { link, ...rest } = prev;
        return rest;
      });
    }
  };

  const handleCertificateDateChange = (val, index, type) => {
    if (type === Common.START && val) {
      setCertificateValidation((prev) => {
        const { startDate, ...rest } = prev;
        return rest;
      });
    } else if (type === Common.END && val) {
      setCertificateValidation((prev) => {
        const { endDate, ...rest } = prev;
        return rest;
      });
    }
    updateSetUploadCertificateArr(index, {
      ...(type === Common.START && { startDate: val }),
      ...(type === Common.END && { endDate: val }),
    });
  };

  const clearDate = (index) => {
    updateSetUploadCertificateArr(index, { startDate: null, endDate: null });
  };

  const handleCertificateInternalCheckChange = (e, index) => {
    updateSetUploadCertificateArr(index, { internal: e.target.checked });
  };

  const handleAddAnotherCertificate = () => {
    const getEmptyFields = filterEmptyValues(uploadedCertificatesArr[currentCertificateIndex]);

    if (Object.entries(getEmptyFields).length) {
      setCertificateValidation({
        ...getEmptyFields,
        index: currentCertificateIndex,
      });
      return;
    }
    const selectedCertificatesId = uploadedCertificatesArr.map((item) => item?.certificateType);

    setCertificateTypeOptions((prev) => {
      const updatedArr = prev.map((item) => {
        if (selectedCertificatesId.includes(item?.[Constants.MONGOOSE_ID])) {
          return { ...item, hide: true };
        }
        return item;
      });
      return updatedArr;
    });

    setCertificateValidation({});
    setCurrentCertificateIndex(currentCertificateIndex + 1);

    updateSetUploadCertificateArr(currentCertificateIndex, { disabled: true });
    setUploadedCertificatesArr((prev) => [...prev, uploadCertificateInitialValues]);
  };

  const handleAllCertificateUpload = async () => {
    const getEmptyFields = filterEmptyValues(uploadedCertificatesArr[currentCertificateIndex]);

    if (Object.entries(getEmptyFields).length) {
      setCertificateValidation({
        ...getEmptyFields,
        index: currentCertificateIndex,
      });
      return;
    }

    setCertificateValidation({});
    const requestBody = {
      user: id,
      files: uploadedCertificatesArr,
    };
    const res = await dispatch(createUserCertificate(requestBody));
    if (res?.payload?.status === 200) {
      setOpenCertificateUploadModal(false);
      setCurrentCertificateIndex(0);
      setUploadedCertificatesArr([uploadCertificateInitialValues]);
      dispatch(
        openSnackbar({
          message: Constants.CERTIFICATE_ADDED_SUCCESS,
          notificationType: Constants.NOTIFICATION_SUCCESS,
        })
      );
      setUpdate((prev) => !prev);
    } else {
      dispatch(
        openSnackbar({
          message: Constants.SOMETHING_WENT_WRONG,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
    }
  };

  const handleCloseCertificateUploadModal = async () => {
    setOpenCertificateUploadModal(false);
    setCurrentCertificateIndex(0);
    setUploadedCertificatesArr([uploadCertificateInitialValues]);
    setCertificateValidation({});
  };

  const handleRemoveAnotherCertificate = (itemObj) => {
    const currentCertiVal = currentCertificateIndex - 1;
    setCurrentCertificateIndex(currentCertiVal); // set current index

    // Removing the deleted item by id and updating the Upoaded Certificate Array
    setUploadedCertificatesArr((prev) => {
      const updatedArr = prev.filter((item) => item?.certificateType !== itemObj?.certificateType);
      return updatedArr;
    });

    // Updating the Certificate Type Options once a certificate is removed
    setCertificateTypeOptions((prev) => {
      const updatedArr = prev.map((item) => {
        if (item?.[Constants.MONGOOSE_ID] === itemObj?.certificateType) {
          return { ...item, hide: false };
        }
        return item;
      });
      return updatedArr;
    });

    // Variable for rendering the ImageUpload Component
    setRenderImageField((prev) => !prev);
  };

  const renderUserView = () => (
    <MDBox>
      {profileData.length > 0 ? (
        <MDBox position="relative">
          {isUserShow && (
            <MDBox
              display="flex"
              alignItems="center"
              position="relative"
              minHeight="14.75rem"
              ml={-4}
              mr={-3.5}
              sx={{
                backgroundImage: ({ functions: { rgba, linearGradient } }) =>
                  `${linearGradient(rgba("#FFC9B0", 0.6), rgba("#FFC9B0", 0.6))}, url(${bgImage})`,
                backgroundSize: "cover",
                backgroundPosition: "50%",
                overflow: "hidden",
              }}
            />
          )}
          <Card
            sx={{
              position: "relative",
              mt: isUserShow ? -24 : 0,
              py: 6,
              height: "100%",
              boxShadow: isUserShow ? 1 : 0,
              mb: "20px",
            }}
          >
            <Grid
              container
              spacing={3}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              alignContent={{ xs: "center", md: "flex-start", lg: "flex-start" }}
            >
              <Grid item xs={12} sm={12} md={6} lg={3}>
                {profileData[0]?.profileImage ? (
                  <MDAvatar
                    src={profileData[0]?.profileImage}
                    size="xxl"
                    shadow="sm"
                    sx={{ marginLeft: "35px" }}
                  />
                ) : (
                  <MDAvatar
                    size="xxl"
                    shadow="sm"
                    sx={{ marginLeft: "35px", backgroundColor: "#191A51" }}
                  >
                    <MDTypography color="light" sx={{ fontSize: pxToRem(50), fontWeight: "700" }}>
                      {profileData[0]?.callingName
                        ? profileData[0]?.callingName.charAt(0)?.toUpperCase()
                        : profileData[0]?.firstName.charAt(0)?.toUpperCase()}
                    </MDTypography>
                  </MDAvatar>
                )}

                {profileData[0]?.role?.title.toLowerCase() === defaultData.ADMIN_ROLE ? (
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item>
                      <Tooltip title="Change profile image">
                        <IconButton
                          aria-label="change-profile-image"
                          component="label"
                          htmlFor="profile-image-input"
                        >
                          <Icon
                            fontSize="medium"
                            display="flex"
                            sx={{
                              ml: 14,
                              mt: -7,
                              cursor: "pointer",
                              backgroundColor: "#f5f5f5",
                              borderRadius: "50%",
                            }}
                          >
                            {Icons.EDIT}
                          </Icon>
                          <input
                            type="file"
                            id="profile-image-input"
                            accept="image/*"
                            hidden
                            onChange={handleProfileImageChange}
                          />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={3}>
                {profileData?.map((element) => (
                  <MDBox
                    key={element?.[Constants.mongooseID]}
                    height="100%"
                    mt={0.5}
                    lineHeight={1}
                  >
                    <MDBox display="flex" justifyContent="center" alignItems="center">
                      <MDTypography sx={{ fontSize, fontWeight: "700", color: "#191D31" }}>
                        {`${element?.callingName ? element?.callingName : element?.firstName} ${
                          element?.lastName
                        }`}
                      </MDTypography>
                    </MDBox>

                    <MDBox display="flex" justifyContent="center" alignItems="center">
                      <MDTypography
                        sx={{
                          fontSize,
                          fontWeight: "500",
                          color: "#191D31",
                          textTransform: "capitalize",
                        }}
                      >
                        {element?.role?.title}
                      </MDTypography>
                    </MDBox>

                    <MDBox display="flex" justifyContent="center" alignItems="center">
                      <Tooltip title={element?.email}>
                        <MDTypography
                          sx={{
                            fontSize,
                            fontWeight: "600",
                            marginLeft: "25px",
                            color: "#191D31",
                            wordBreak: "break-all",
                          }}
                        >
                          {element?.email.length > defaultData.MEDIUM_CONTENT_LENGTH
                            ? `${element?.email.slice(0, defaultData.MEDIUM_CONTENT_LENGTH)}...`
                            : element?.email}
                        </MDTypography>
                      </Tooltip>

                      <Icon
                        fontSize="medium"
                        onClick={() => setOpenEdit(true)}
                        sx={{ ml: 2, cursor: "pointer", display: !permission?.update && "none" }}
                      >
                        {Icons.EDIT}
                      </Icon>
                    </MDBox>
                  </MDBox>
                ))}
              </Grid>
            </Grid>

            <MDBox mt={5} mb={3}>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 1, md: 3 }}
                display="flex"
                flexWrap="wrap"
              >
                <Grid
                  item
                  xs={12}
                  md={6}
                  xl={3}
                  sx={{
                    display: "flex",
                    height:
                      profileData[0]?.role?.accessType === defaultData.MOBILE_ACCESSTYPE ||
                      profileData[0]?.role?.accessType === defaultData.BOTH_ACCESSTYPE
                        ? "380px"
                        : "120px",
                  }}
                >
                  <Tabs
                    value={value}
                    onChange={(e, val) => setValue(val)}
                    orientation="vertical"
                    sx={{
                      backgroundColor: "#ffffff",
                      width: "100%!important",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Tab
                      label="Personal Details"
                      sx={{ ...commonUserTabStyles, ...dynamicUserTabStyles(0) }}
                    />

                    {isUserWebAccess ? null : (
                      <Tab
                        label="Certificates"
                        sx={{ ...commonUserTabStyles, ...dynamicUserTabStyles(1) }}
                      />
                    )}

                    {isUserWebAccess ? null : (
                      <Tab
                        label="Contractual Details"
                        sx={{ ...commonUserTabStyles, ...dynamicUserTabStyles(2) }}
                      />
                    )}

                    {isUserWebAccess ? null : (
                      <Tab
                        label="Medical"
                        sx={{ ...commonUserTabStyles, ...dynamicUserTabStyles(3) }}
                      />
                    )}

                    {isUserWebAccess ? null : (
                      <Tab
                        label="GDPR"
                        sx={{ ...commonUserTabStyles, ...dynamicUserTabStyles(4) }}
                      />
                    )}

                    {isUserWebAccess ? null : (
                      <Tab
                        label="Ratings"
                        sx={{ ...commonUserTabStyles, ...dynamicUserTabStyles(5) }}
                      />
                    )}

                    <Tab
                      label="Organization Profile"
                      sx={{
                        ...commonUserTabStyles,
                        ...dynamicUserTabStyles(
                          profileData[0]?.role?.title.toLowerCase() === defaultData.ADMIN_ROLE
                            ? 1
                            : 6
                        ),
                      }}
                    />
                  </Tabs>
                </Grid>

                <Grid
                  xs
                  mt={{ xs: 0, sm: 0, md: -30, lg: -30 }}
                  sx={{ borderLeft: "1px solid #E0E6F5" }}
                >
                  {value ===
                    (profileData[0]?.role?.title.toLowerCase() === defaultData.ADMIN_ROLE
                      ? 1
                      : 6) && (
                    <Box>
                      {profileData?.map((element) => (
                        <ProfileInfoCard
                          tabChangeValue={value}
                          key={element?.[Constants.mongooseID]}
                          title="Organization Profile"
                          info={{
                            Organization: element?.account?.name
                              ? element?.account?.name
                              : Constants.NA,
                            Status: element.isActive ? "Active" : "Not Active",
                            Country: element?.account.organizationCountry
                              ? element?.account.organizationCountry
                              : Constants.NA,
                            address: element.account.organizationAddress
                              ? element.account.organizationAddress
                              : Constants.NA,
                          }}
                          logo={element?.account?.logo}
                          action={{ route: "", tooltip: "Edit Profile" }}
                          shadow={false}
                        />
                      ))}
                    </Box>
                  )}

                  {value === 0 && (
                    <Box>
                      {profileData?.map((element) => (
                        <ProfileInfoCard
                          tabChangeValue={value}
                          key={element?.[Constants.mongooseID]}
                          title="Personal Details"
                          info={{
                            "Calling Name": element?.callingName || Constants.NA,
                            "First Names (as per passport)": element?.firstName || Constants.NA,
                            "Last Names (as per passport)": element?.lastName || Constants.NA,
                            Phone: element?.contactNumber?.number
                              ? `${element?.contactNumber?.in} ${element?.contactNumber?.number}`
                              : Constants.NA,
                            Country: element?.country || Constants.NA,
                            Address: formatAddress(element, profileData),
                            "Main Language": element?.motherLanguage || Constants.NA,
                            ...(profileData[0]?.role.accessType !== defaultData.WEB_ACCESSTYPE && {
                              "Preferred Airport of Departure":
                                element?.prefAirportDeprt || Constants.NA,
                              "Travel Time to Airport  (in hrs)":
                                element?.travelTimeToAirport ?? Constants.NA,
                              "Second Pref. Airport of Departure":
                                element?.secondaryPrefAirportDeprt
                                  ? element?.secondaryPrefAirportDeprt
                                  : Constants.NA,
                              "Travel Time to 2nd Airport (in hrs)":
                                element?.travelTimeToSecondAirport ?? Constants.NA,
                              "Driving License": element?.drivingLicence
                                ? formatDrivingLicence(element?.drivingLicence?.[0])
                                : "No",
                              "Clothes Size": element?.clothesSize
                                ? element?.clothesSize
                                : Constants.NA,
                              "Shoe Size": element?.shoeSize ? element?.shoeSize : Constants.NA,
                              "WINDA ID": element?.windaId ? element?.windaId : Constants.NA,
                              "Curriculum Vitae (CV)":
                                element?.curriculumVitae?.[0] || Constants.NA,
                              "Seamans Book": element?.seamansBook?.[0] || Constants.NA,
                            }),
                          }}
                          action={{ route: "", tooltip: "Edit Profile" }}
                          shadow={false}
                        />
                      ))}
                      {profileData[0]?.role?.accessType === defaultData.WEB_ACCESSTYPE ? null : (
                        <MDBox>
                          <MDTypography
                            variant="h5"
                            fontWeight="medium"
                            color="text"
                            textTransform="capitalize"
                            mt={2}
                            ml={2}
                          >
                            {profileData[0]?.nextOfKin.length > 0 ? " Next of Kin" : null}
                          </MDTypography>
                          {profileData[0]?.nextOfKin?.map((kin, index) => (
                            <ProfileInfoCard
                              tabChangeValue={value}
                              key={kin?.[Constants.mongooseID]}
                              title={`Kin ${index + 1}`}
                              info={{
                                Name: kin?.kinName || Constants.NA,
                                Relationship: kin?.relationship || Constants.NA,
                                Address: kin
                                  ? `${kin?.kinStreet || ""} ${kin?.kinArea || ""} ${
                                      kin?.kinCity || ""
                                    } ${kin?.kinState || ""} ${kin?.kinCountry || ""} ${
                                      kin?.kinZip || ""
                                    }`
                                  : Constants.NA,
                                Phone: kin?.kinContactNumber
                                  ? `${kin?.kinContactNumber?.in}${kin?.kinContactNumber?.number}`
                                  : Constants.NA,
                              }}
                              action={{ route: "", tooltip: "Edit Profile" }}
                              shadow={false}
                            />
                          ))}
                        </MDBox>
                      )}
                    </Box>
                  )}

                  {profileData[0]?.role.accessType === defaultData.WEB_ACCESSTYPE
                    ? null
                    : value === 1 && (
                        <Box ml={2} sx={{ px: 2 }}>
                          <Box display="flex" justifyContent="space-between">
                            <MDTypography
                              variant="h5"
                              fontWeight="medium"
                              color="text"
                              textTransform="capitalize"
                            >
                              Certificates
                            </MDTypography>

                            {permission?.create && (
                              <CustomButton
                                title={ButtonTitles.UPLOAD_CERTIFICATES}
                                icon={Icons.NEW}
                                background={Colors.PRIMARY}
                                color={Colors.WHITE}
                                openModal={setOpenCertificateUploadModal}
                              />
                            )}
                          </Box>
                          <BasicModal
                            open={openCertificateUploadModal}
                            handleClose={handleCloseCertificateUploadModal}
                            title={ButtonTitles.UPLOAD_CERTIFICATES}
                            actionButton={ButtonTitles.SAVE}
                            btnIcon={Icons.SAVE}
                            handleAction={handleAllCertificateUpload}
                            minWidth={1100}
                            isAdditionalBtnRequired
                            additionalBtnTitle={ButtonTitles.ADD_ANOTHER}
                            additionalBtnIcon={Icons.NEW}
                            additionalBtnAction={handleAddAnotherCertificate}
                          >
                            {uploadedCertificatesArr.map((certificate, index) => (
                              <MDBox id={`certificate_${index}`}>
                                <MDBox
                                  display="flex"
                                  justifyContent="space-between"
                                  flexWrap="wrap"
                                  sx={{ gap: 2 }}
                                >
                                  <MDBox
                                    sx={{ display: "flex", flex: 0.8, flexDirection: "column" }}
                                    mt={pxToRem(8)}
                                  >
                                    <CustomAutoComplete
                                      label={Common.CERTIFICATE_TYPE_LABEL}
                                      id={`certificateType_${index}`}
                                      name="certificateType"
                                      hint="Select Certificate Type"
                                      mt={pxToRem(8)}
                                      disabled={certificate.disabled}
                                      handleChange={(e) =>
                                        handleChangeUploadCertificateType(e, index)
                                      }
                                      menu={certificateTypeOptions}
                                      error={
                                        certificateValidation?.index === index &&
                                        certificateValidation?.certificateType === ""
                                      }
                                      helperText={
                                        certificateValidation?.index === index &&
                                        certificateValidation?.certificateType === ""
                                          ? Constants.CERTIFICATE_TYPE_NOT_SELECTED
                                          : ""
                                      }
                                      getOptionLabel={(option) => option.title || ""}
                                      value={{
                                        title: certificateTypeOptions.find(
                                          (item) =>
                                            item[Constants.MONGOOSE_ID] ===
                                            certificate.certificateType
                                        )?.title,
                                      }}
                                    />
                                  </MDBox>
                                  <MDBox
                                    sx={{
                                      display: "flex",
                                      flex: 0.8,
                                      flexDirection: "column",
                                    }}
                                    mt={pxToRem(8)}
                                  >
                                    <ImageUpload
                                      key={renderImageField}
                                      name="certificate"
                                      label={Common.CERTIFICATE_UPLOAD_LABEL}
                                      onImageUpload={(e) => handleCertificateUploadFunc(e, index)}
                                      data={
                                        certificate?.link
                                          ? [
                                              {
                                                name: certificate?.fileName,
                                                size: certificate?.size,
                                                url: certificate?.link,
                                              },
                                            ]
                                          : []
                                      }
                                      type="uploadCertificate"
                                      formats={[
                                        Common.IMAGE_JPEG,
                                        Common.IMAGE_JPG,
                                        Common.IMAGE_PNG,
                                        Common.IMAGE_SVG,
                                        Common.PDF_FILE,
                                      ]}
                                      acceptType="image/*, application/pdf"
                                      maxImageCount={1}
                                      isBottomMarginRequired={false}
                                      imageTypeError={Constants.IMAGE_FILE_TYPE_NOT_ALLOWED}
                                      direction="row"
                                      disabled={certificate.disabled}
                                      error={
                                        certificateValidation?.index === index &&
                                        certificateValidation?.link === ""
                                      }
                                      helperText={
                                        certificateValidation?.index === index &&
                                        certificateValidation?.link === ""
                                          ? Constants.CERTIFICATE_REQUIRED
                                          : ""
                                      }
                                      sizeUpdate
                                    />
                                  </MDBox>
                                  <MDBox sx={{ display: "flex", flex: 0.8 }}>
                                    <ReactDatePicker
                                      selected={certificate.startDate}
                                      maxDate={moment(certificate?.endDate).toDate()}
                                      popperModifiers={[
                                        {
                                          name: "offset",
                                          options: {
                                            offset: [0, -35], // Adjust the second value to reduce the gap
                                          },
                                        },
                                      ]}
                                      onChange={(e) =>
                                        handleCertificateDateChange(e, index, Common.START)
                                      }
                                      customInput={
                                        <DateTime
                                          item={{
                                            hint: "Select start date",
                                          }}
                                          isMarginBottomRequired={false}
                                          disabled={!certificate?.isValidityDate}
                                          label={Common.START_DATE_LABEL}
                                          onClear={(e) => clearDate(e, index)}
                                          errors={
                                            certificateValidation?.index === index &&
                                            certificateValidation?.startDate === null
                                              ? Constants.START_DATE_REQUIRED
                                              : ""
                                          }
                                        />
                                      }
                                      showMonthDropdown
                                      showYearDropdown
                                      placeholderText={defaultData.REACTDATETIMEPICKER_DATE_FORMAT}
                                      dateFormat={defaultData.REACTDATETIMEPICKER_DATE_FORMAT}
                                      disabled={
                                        certificate?.disabled || !certificate?.isValidityDate
                                      }
                                    />
                                  </MDBox>
                                  <MDBox sx={{ display: "flex", flex: 0.8 }}>
                                    <ReactDatePicker
                                      selected={certificate.endDate}
                                      popperModifiers={[
                                        {
                                          name: "offset",
                                          options: {
                                            offset: [0, -35], // Adjust the second value to reduce the gap
                                          },
                                        },
                                      ]}
                                      onChange={(e) =>
                                        handleCertificateDateChange(e, index, Common.END)
                                      }
                                      customInput={
                                        <DateTime
                                          item={{
                                            hint: "Select end date",
                                          }}
                                          isMarginBottomRequired={false}
                                          label={Common.END_DATE_LABEL}
                                          onClear={(e) => clearDate(e, index)}
                                          errors={
                                            certificateValidation?.index === index &&
                                            certificateValidation?.endDate === null
                                              ? Constants.END_DATE_REQUIRED
                                              : ""
                                          }
                                        />
                                      }
                                      showMonthDropdown
                                      showYearDropdown
                                      placeholderText="Please select end date"
                                      dateFormat={defaultData.REACTDATETIMEPICKER_DATE_FORMAT}
                                      minDate={moment(certificate?.startDate).toDate()}
                                      disabled={
                                        certificate?.disabled || !certificate?.isValidityDate
                                      }
                                    />
                                  </MDBox>
                                  <MDBox
                                    sx={{
                                      display: "flex",
                                      flex: 0.5,
                                      flexDirection: "column",
                                      alignItems: "center",
                                    }}
                                  >
                                    <MDTypography variant="subtitle2">Internal</MDTypography>
                                    <MDBox mt={pxToRem(20)}>
                                      <CustomCheckbox
                                        name="Internal"
                                        checked={certificate.internal}
                                        onChange={(e) =>
                                          handleCertificateInternalCheckChange(e, index)
                                        }
                                        // disabled={certificate.disabled}
                                      />
                                    </MDBox>
                                  </MDBox>

                                  {uploadedCertificatesArr?.length > 1 && (
                                    <MDBox
                                      sx={{
                                        display: "flex",
                                        flex: 0.3,
                                        flexDirection: "column",
                                        alignItems: "center",
                                      }}
                                      mt={pxToRem(34)}
                                    >
                                      <IconButton
                                        id={index}
                                        aria-label="delete options"
                                        color="error"
                                        onClick={() => handleRemoveAnotherCertificate(certificate)}
                                      >
                                        {Icons.DELETE}
                                      </IconButton>
                                    </MDBox>
                                  )}
                                </MDBox>
                              </MDBox>
                            ))}
                          </BasicModal>
                          <MDBox mt={2}>
                            <CustomTabs
                              value={certificateValue}
                              onChange={handleCertificateTab}
                              aria-label="certificate tabs"
                            >
                              <CustomTab label="Approved" />
                              <CustomTab label="Rejected" />
                              <CustomTab label="In Active" />
                              <CustomTab label="All" />
                            </CustomTabs>
                          </MDBox>
                          {certificateValue === 3 &&
                            renderCertificates(profileData[0]?.userCertificate || [])}
                          {certificateValue === 0 &&
                            renderCertificates(
                              profileData[0]?.userCertificate.filter(
                                (cert) => cert.status === "approved" && cert.isActive
                              )
                            )}
                          {certificateValue === 1 &&
                            renderCertificates(
                              profileData[0]?.userCertificate.filter(
                                (cert) => cert.status === "rejected"
                              )
                            )}
                          {certificateValue === 2 &&
                            renderCertificates(
                              profileData[0]?.userCertificate.filter((cert) => !cert.isActive)
                            )}
                        </Box>
                      )}

                  {profileData[0]?.role.accessType === defaultData.WEB_ACCESSTYPE
                    ? null
                    : value === 2 && (
                        <Box>
                          {profileData?.map((element) => (
                            <ProfileInfoCard
                              tabChangeValue={value}
                              key={element?.[Constants.MONGOOSE_ID]}
                              title="Contractual Details"
                              info={{
                                "Passport/ID": element?.contractualDetail?.passport
                                  ? {
                                      name: element?.contractualDetail?.passport,
                                      url: element?.contractualDetail?.identityProof[0]?.url,
                                      [Constants.MONGOOSE_ID]:
                                        element?.contractualDetail?.identityProof[0]?.[
                                          Constants.MONGOOSE_ID
                                        ],
                                    }
                                  : Constants.NA,
                                "National Identification Number (BSN/NINO/SSN etc.)": element
                                  ?.contractualDetail?.nationalIdentificationNumber
                                  ? element?.contractualDetail?.nationalIdentificationNumber
                                  : Constants.NA,
                                "Date of Birth": element?.contractualDetail?.birthDate
                                  ? moment(element?.contractualDetail?.birthDate).format(
                                      "DD-MM-YYYY"
                                    )
                                  : Constants.NA,
                                "Passport Place of Birth": element?.contractualDetail?.birthPlace
                                  ? element?.contractualDetail?.birthPlace
                                  : Constants.NA,
                                "Employment Type": element?.contractualDetail?.employmentType
                                  ? element?.contractualDetail?.employmentType
                                  : Constants.NA,
                                ...(profileData[0]?.contractualDetail?.employmentType ===
                                  "self-employed" && {
                                  "Liability Insurance document":
                                    element?.contractualDetail?.liabilityInsurance?.length > 0
                                      ? handleInsuranceInContractualDetails(
                                          element?.contractualDetail?.liabilityInsurance?.[0]
                                        )
                                      : Constants.NA,
                                  "Health Insurance incl. repatriation document":
                                    element?.contractualDetail?.healthInsurance?.length > 0
                                      ? handleInsuranceInContractualDetails(
                                          element?.contractualDetail?.healthInsurance?.[0]
                                        )
                                      : Constants.NA,
                                }),
                                ...(profileData[0]?.contractualDetail?.employmentType !==
                                  "internal-employee" && {
                                  "Company Name": element?.contractualDetail?.companyName
                                    ? element?.contractualDetail?.companyName
                                    : Constants.NA,
                                  "Company Registration Nr.": element?.contractualDetail
                                    ?.companyRegistrationNumber
                                    ? element?.contractualDetail?.companyRegistrationNumber
                                    : Constants.NA,
                                  "Company VAT Nr.": element?.contractualDetail?.companyVATNumber
                                    ? element?.contractualDetail?.companyVATNumber
                                    : Constants.NA,
                                  "Company Address": element?.contractualDetail?.companyAddress
                                    ? element?.contractualDetail?.companyAddress
                                    : Constants.NA,
                                }),
                                "Bank Name": element?.contractualDetail?.bankName
                                  ? element?.contractualDetail?.bankName
                                  : Constants.NA,
                                "Account Name Holder": element?.contractualDetail?.accountHolderName
                                  ? element?.contractualDetail?.accountHolderName
                                  : Constants.NA,
                                "Bank Account Nr (IBAN)": element?.contractualDetail?.bankAccount
                                  ? element?.contractualDetail?.bankAccount
                                  : Constants.NA,
                                "BIC/SWIFT": element?.contractualDetail?.bicSwift
                                  ? element?.contractualDetail?.bicSwift
                                  : Constants.NA,
                              }}
                              action={{ route: "", tooltip: "Edit Profile" }}
                              shadow={false}
                            />
                          ))}
                        </Box>
                      )}

                  {profileData[0]?.role.accessType === defaultData.WEB_ACCESSTYPE
                    ? null
                    : value === 3 && (
                        <Box>
                          <MDTypography
                            mt={2}
                            ml={2}
                            mb={2}
                            variant="h5"
                            fontWeight="medium"
                            color="text"
                            textTransform="capitalize"
                          >
                            Medical
                          </MDTypography>
                          {profileData[0]?.medical && profileData[0]?.medical.length > 0 ? (
                            profileData[0]?.medical.map((item) => (
                              <MDBox
                                key={item?.questionId}
                                display="flex"
                                alignContent="center"
                                flexDirection="column"
                              >
                                <MDBox
                                  ml={2}
                                  display="flex"
                                  alignContent="center"
                                  py={2}
                                  pr={2}
                                  sx={{ borderBottom: "1px solid #E0E6F5" }}
                                >
                                  <MDBox display="flex" flexDirection="column" width="65%">
                                    <MDTypography
                                      textTransform="capitalize"
                                      sx={{
                                        fontSize: pxToRem(16),
                                        fontWeight: "500",
                                        color: "#667085",
                                        whiteSpace: "pre-line",
                                        textAlign: "justify",
                                      }}
                                    >
                                      {item.title}
                                    </MDTypography>
                                    <MDTypography
                                      textTransform="capitalize"
                                      sx={{
                                        fontSize: pxToRem(14),
                                        fontWeight: "400",
                                        color: "#667085",
                                        whiteSpace: "pre-line",
                                        textAlign: "justify",
                                      }}
                                    >
                                      {item.description}
                                    </MDTypography>
                                  </MDBox>
                                  <MDTypography
                                    sx={{
                                      fontSize: pxToRem(16),
                                      fontWeight: "600",
                                      color: "#191D31",
                                    }}
                                  >
                                    {item.answer ? "Yes" : "No"}
                                  </MDTypography>
                                </MDBox>
                              </MDBox>
                            ))
                          ) : (
                            <MDTypography
                              mt={2}
                              ml={2}
                              variant="h6"
                              fontWeight="medium"
                              color="text"
                              textTransform="capitalize"
                            >
                              No Medical data available
                            </MDTypography>
                          )}
                        </Box>
                      )}

                  {profileData[0]?.role.accessType === defaultData.WEB_ACCESSTYPE
                    ? null
                    : value === 4 && (
                        <Box>
                          {profileData?.map((element) => (
                            <ProfileInfoCard
                              tabChangeValue={value}
                              key={element?.[Constants.MONGOOSE_ID]}
                              title="GDPR"
                              info={{
                                "Grant permission to use information for business purposes": element
                                  ?.gdpr[0]?.answer
                                  ? "Yes"
                                  : "No",
                                "Grant permission to use e-mail for communication": element?.gdpr[1]
                                  ?.answer
                                  ? "Yes"
                                  : "No",
                              }}
                              action={{ route: "", tooltip: "Edit Profile" }}
                              shadow={false}
                            />
                          ))}
                        </Box>
                      )}

                  {profileData[0]?.role.accessType === defaultData.WEB_ACCESSTYPE
                    ? null
                    : value === 5 && <UserRatings userId={userId || id} permission={permission} />}
                </Grid>
              </Grid>
            </MDBox>
          </Card>
        </MDBox>
      ) : (
        <MDBox py={5} mt={30} display="flex" justifyContent="center" alignItems="center">
          <CircularProgress color="info" />
        </MDBox>
      )}
      <Personaldetails
        openPersonal={openEdit}
        setOpenPersonal={setOpenEdit}
        title="Update Profile"
        pdata={profileData}
        setUpdate={setUpdate}
        roleOptions={roleOptions}
        profileFunctionOptions={profileFunctionOptions}
      />
      <FullScreenImageComponent
        fullScreenImage={fullScreenImage}
        handleCloseFullView={handleCloseFullView}
        src={fullScreenImage}
      />
    </MDBox>
  );

  useEffect(() => {
    if (id || userId) {
      getuserListByIdFunc();
    } else {
      fetchAdminDataFunc();
    }
    getAllCertificateTypeFunc();
  }, [id, userId, update]);

  return isUserShow ? (
    <DashboardLayout>
      <DashboardNavbar />
      {renderUserView()}
    </DashboardLayout>
  ) : (
    renderUserView()
  );
}

Overview.defaultProps = {
  isUserShow: true,
  userId: "",
};
Overview.propTypes = {
  isUserShow: PropTypes.bool,
  userId: PropTypes.string,
};
export default Overview;
