import React, { useEffect, useState } from "react";

// Components
import Author from "components/Table/Author";
import Status from "components/Table/Status";
import By from "components/Table/By";

// 3rd party libraries
import moment from "moment";

// Utils
import Constants, { defaultData } from "utils/Constants";

export default function ApproverOrderDetailsData(approverOrderList) {
  const [approverOrderrows, setApproverOrderRows] = useState([]);
  useEffect(() => {
    if (approverOrderList) {
      const tempRows = approverOrderList?.approverProjectdata?.map((element, index) => {
        const temp = {
          collapseBtn: (
            <Author
              name={{
                orderId: element?.[Constants.MONGOOSE_ID],
                orderEquipmentTypeData: element?.equipmentTypeData,
              }}
            />
          ),
          srNo: <Author name={index + 1} />,
          orderNo: <Author name={element?.orderNumber} />,
          orderBy: (
            <By
              name={`${
                element?.orderBy?.callingName
                  ? element?.orderBy?.callingName
                  : element?.orderBy?.firstName
              } ${element?.orderBy?.lastName}`}
              when={moment(element?.createdAt).format(defaultData.WEB_DATE_FORMAT)}
            />
          ),
          totalItems: <Author name={element?.totalApprovedItems} />,
          totalRequestedQuantity: <Author name={element?.totalRequestedQuantity} />,
          totalWMAppQuantity: <Author name={element?.totalApprovedQuantity} />,
          orderStatus: <Status title={`${element?.orderStatus.replace("-", " ")}`} />,
        };
        return temp;
      });
      setApproverOrderRows(tempRows);
    }
  }, [approverOrderList]);

  return {
    approverOrderColumns: [
      { Header: "", accessor: "collapseBtn", width: "5%" },
      { Header: "No.", accessor: "srNo", width: "5%" },
      { Header: "Order No.", accessor: "orderNo" },
      { Header: "Order By", accessor: "orderBy" },
      { Header: "Total Items", accessor: "totalItems", align: "center" },
      { Header: "Total Requested Quantity", accessor: "totalRequestedQuantity", align: "center" },
      { Header: "Total WM Approved Quantity", accessor: "totalWMAppQuantity", align: "center" },
      { Header: "Status", accessor: "orderStatus", align: "center" },
    ],
    approverOrderrows,
  };
}
