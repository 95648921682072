import { useEffect, useState } from "react";

// Material Components
import Author from "components/Table/Author";

import Constants, { defaultData, Icons } from "utils/Constants";
import MDBox from "components/MDBox";
import DynamicTypeData from "components/Table/DynamicTypeData";
import pxToRem from "assets/theme/functions/pxToRem";
import FontComponent from "components/Responsive/fonts";
import moment from "moment";
import MDTypography from "components/MDTypography";
import { Icon } from "@mui/material";

const determineAnswer = (answerType, answer) => {
  const answerText = typeof answer?.answer === "string" ? answer.answer : "";
  if (
    answerType?.parameterType?.uniqueKey === "dateTime" &&
    answer?.answer !== "" &&
    answer?.answer !== null
  ) {
    return moment.utc(answer?.answer).format(defaultData.WEB_24_HOURS_FORMAT);
  }
  if (answerType?.parameterType?.uniqueKey === "date") {
    return moment(answer?.answer).format(defaultData.WEB_DATE_FORMAT);
  }
  if (answerText.includes("##")) {
    const parts = answerText
      .split("##")
      .map((part) => part.trim())
      .filter((part) => part !== "");

    return parts.join(", ");
  }
  return answer?.answer;
};

export default function InActiveReportData(inActiveReportListing, reportId, handleOpenAssignModal) {
  const [inActiveReportRows, setInActiveReportRows] = useState([]);
  const fontSize = FontComponent({ sizes: {} });

  useEffect(() => {
    if (!inActiveReportListing) return;

    let counter = 0;
    const inActiveImageArray = [];

    const list = inActiveReportListing
      .flatMap((item) =>
        item?.answerTypes?.flatMap((answerType) => {
          const answerTitleIds = new Set(
            answerType.title.map((title) => title[Constants.MONGOOSE_ID])
          );

          return answerType?.userAnswers?.flatMap((userAnswer) => {
            const { answers: userAnswers, userReport } = userAnswer;

            // Filter inactive answers matching answerTitleIds
            const foundAnswers = userAnswers.filter(
              (answer) => answerTitleIds.has(answer.answerTitleId) && !answer.isActive
            );

            if (foundAnswers.length === 0) return null;

            const userName = reportId?.userReportStatus.find(
              (reportf) => reportf.userReportId === userReport
            )?.createdBy;

            return foundAnswers.map((foundAnswer) => {
              const correspondingTitle = answerType.title.find(
                (title) => title[Constants.MONGOOSE_ID] === foundAnswer.answerTitleId
              );

              const isImageType =
                answerType?.parameterType?.uniqueKey === "image" ||
                answerType?.parameterType?.uniqueKey === "signature";

              if (isImageType && foundAnswer?.answer) {
                inActiveImageArray.push(foundAnswer.answer);
              }

              counter += 1;

              return {
                no: <Author name={counter} />,
                type: <Author name={answerType?.parameterType?.uniqueKey || ""} />,
                submittedBy: (
                  <Author
                    name={`${
                      userName?.callingName ? userName?.callingName : userName?.firstName || ""
                    } ${userName?.lastName || ""}`}
                  />
                ),
                question: <Author name={correspondingTitle?.value || ""} />,
                answer: (
                  <MDBox sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                    {isImageType ? (
                      <MDBox sx={{ paddingBottom: pxToRem(8) }}>
                        <DynamicTypeData
                          type={answerType?.parameterType?.uniqueKey}
                          data={foundAnswer.answer}
                          isDownload
                          imageArray={inActiveImageArray}
                        />
                      </MDBox>
                    ) : (
                      <MDBox>
                        <MDTypography
                          variant="caption"
                          sx={{
                            overflowWrap: "break-word",
                            wordWrap: "break-word",
                            fontSize,
                          }}
                        >
                          {determineAnswer(answerType, foundAnswer)}
                        </MDTypography>
                      </MDBox>
                    )}
                  </MDBox>
                ),
                action: (
                  <Icon
                    sx={{ width: 30, height: 30 }}
                    onClick={() =>
                      handleOpenAssignModal(
                        answerType.title,
                        foundAnswer,
                        correspondingTitle?.value,
                        userAnswer[Constants.MONGOOSE_ID],
                        answerType?.questionId
                      )
                    }
                  >
                    {Icons.NEW}
                  </Icon>
                ),
              };
            });
          });
        })
      )
      .flat()
      .filter(Boolean);

    setInActiveReportRows(list);
  }, [inActiveReportListing]);

  return {
    inActiveReportCoumns: [
      { Header: "No", accessor: "no", align: "left", width: "5%" },
      { Header: "Type", accessor: "type", align: "left" },
      { Header: "Submitted By", accessor: "submittedBy", align: "left" },
      { Header: "Question", accessor: "question", align: "left" },
      { Header: "Answer", accessor: "answer", align: "left" },
      { Header: "Action", accessor: "action", align: "center", width: "10%" },
    ],

    inActiveReportRows,
  };
}
