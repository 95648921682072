import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// @mui material components
import { Card } from "@mui/material";

// Custom Components
import MDBox from "components/MDBox";
import SignaturePad from "components/SignaturePad";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import FontComponent from "components/Responsive/fonts";
import CardBreakPoint from "components/Responsive/BreakPoints";

// Raynard Example Components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import WebReportsTable from "examples/Tables/DataTable/WebReportsTable";
import PageTitle from "examples/NewDesign/PageTitle";
import ViewReportAnswer from "examples/Drawers/Report/ViewReportAnswer";

// Raynard Layouts components
import WebReportDetailsData from "layouts/report/data/webReportData";

// Constant
import Constants, { FeatureTags, PageTitles, ButtonTitles, defaultData } from "utils/Constants";

// Redux Component
import { useDispatch, useSelector } from "react-redux";
import { resetReportDetailsState } from "redux/Slice/Report";
import { getReportById, createWebReport } from "redux/Thunks/Report";
import { openSnackbar } from "redux/Slice/Notification";

// 3rd party Library
import { Feature } from "flagged";

function WebReport() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const customFontSize = FontComponent({ sizes: CardBreakPoint.baseTitleBreakPoint });
  const { reportDetails, reportDetailsLoading, reportId } = useSelector((state) => state.report);

  // State variables
  const [buttonLoading, setButtonLoading] = useState(false);
  const [inputObj, setInputObj] = useState({});
  const [reportSign, setReportSign] = useState(null);
  const [displayReportSignError, setDisplayReportSignError] = useState(false);

  // State variables for view report answer
  const [viewReportAnswerAnchor, setViewReportAnswerAnchor] = useState({ right: false });
  const [reportQuestionAnswer, setReportQuestionAnswer] = useState({
    question: "",
    questionNo: "",
    answer: [],
    supportedContent: [],
  });

  // Function to handle view report answer
  const handleView = async (reportAnswer, questionIndex) => {
    setViewReportAnswerAnchor({ right: true });
    setReportQuestionAnswer({
      question: reportAnswer?.title,
      questionNo: questionIndex,
      answer: reportAnswer?.answerTypes,
      supportedContent: reportAnswer?.supportedContent,
    });
  };

  // Function to create table data and update input object
  const { reportColumns, reportRows } = WebReportDetailsData(
    reportDetails?.[0]?.reportQuestions,
    handleView,
    inputObj,
    setInputObj
  );

  // Function to transform inputObj's raw data to desired format
  const updateInputObjPayload = (dataObj) => {
    const payloadArr = [];
    const dataObjArr = Object.entries(dataObj);

    dataObjArr?.forEach(([key, value]) => {
      const [questionId, answerTypeId, answerTitleId] = key.split("_");

      let question = payloadArr.find((q) => q.reportQuestion === questionId);
      if (!question) {
        question = { reportQuestion: questionId, reportQuestionAnswers: [] };
        payloadArr.push(question);
      }

      let answerType = question.reportQuestionAnswers.find((a) => a.id === answerTypeId);
      if (!answerType) {
        answerType = { id: answerTypeId, answers: [] };
        question.reportQuestionAnswers.push(answerType);
      }

      answerType.answers.push({ answerTitleId, answer: value });
    });

    return payloadArr;
  };

  // Function to create payload for web report
  const createPayloadForWebReport = () => {
    const payload = {};
    const reportDetailsData = reportDetails?.[0]?.[Constants.MONGOOSE_ID] || {};
    const { asset = [], location = {}, project = {}, report = {} } = reportDetailsData;

    payload.project = project?.[Constants.MONGOOSE_ID];
    payload.report = report?.[Constants.MONGOOSE_ID];
    payload.location = location?.[Constants.MONGOOSE_ID];
    payload.asset = asset.map((a) => ({ asset: a[Constants.MONGOOSE_ID] }));
    payload.questions = updateInputObjPayload(inputObj);
    payload.signature = reportSign?.url;
    payload.status = Constants.SUBMITTED;

    return payload;
  };

  // Function to submit web report
  const handleSubmitBtn = async () => {
    if (!reportSign) {
      setDisplayReportSignError(true);
      await dispatch(
        openSnackbar({
          message: Constants.SIGNATURE_REQUIRED,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
      return;
    }

    const payload = createPayloadForWebReport();

    setButtonLoading(true);

    const res = await dispatch(createWebReport(payload));
    if (res.payload.status === 200) {
      setInputObj({});
      setReportSign(null);
      await dispatch(
        openSnackbar({
          message: Constants.WEB_REPORT_CREATE_SUCCESS,
          notificationType: Constants.NOTIFICATION_SUCCESS,
        })
      );
      navigate(`/client/report/report-details/${params?.id}`, {
        state: { reportId, fetchData: true, type: "reportDetail" },
      });
    } else {
      await dispatch(
        openSnackbar({
          message: Constants.SOMETHING_WENT_WRONG,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
    }
    setButtonLoading(false);
  };

  useEffect(() => {
    if (params?.id) {
      (async () => {
        await dispatch(getReportById(params.id));
      })();
    } else {
      navigate("/client/report");
    }
    return dispatch(resetReportDetailsState());
  }, [params.id]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox display="flex" justifyContent="space-between">
        <PageTitle title={PageTitles.WEB_REPORT} />
      </MDBox>
      <Feature name={FeatureTags.SETUP_REPORT}>
        <MDBox>
          <Card id="delete-account" sx={{ mt: 2, px: 2 }}>
            <MDBox>
              <MDBox
                py={2}
                px={1}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" fontWeight="medium" sx={{ fontSize: customFontSize }}>
                  Active Question
                </MDTypography>
              </MDBox>
              <WebReportsTable
                table={{ columns: reportColumns, rows: reportRows }}
                loading={reportDetailsLoading}
                entriesPerPage={{ defaultValue: defaultData.PER_PAGE_4 }}
                showTotalEntries={false}
                isSorted={false}
                licenseRequired
              />

              <MDBox
                sx={{ display: "flex", justifyContent: "flex-end", gap: 2, margin: "20px 0px" }}
              >
                <SignaturePad
                  folderName="Report_Signature"
                  handleSignaturePad={(_, value) => setReportSign(value)}
                  displayError={displayReportSignError}
                />
                <MDButton
                  variant="contained"
                  color="info"
                  onClick={handleSubmitBtn}
                  disabled={buttonLoading}
                  style={{ textTransform: "none", boxShadow: "none", padding: "6px 50px" }}
                >
                  <span
                    style={{
                      fontSize: FontComponent({ sizes: {} }),
                    }}
                  >
                    {buttonLoading ? ButtonTitles.LOADING : ButtonTitles.SUBMIT_REPORT}
                  </span>
                </MDButton>
              </MDBox>
            </MDBox>
          </Card>
        </MDBox>
      </Feature>

      {/* View Report Answer Component */}
      <ViewReportAnswer
        viewReportAnswerAnchor={viewReportAnswerAnchor}
        setViewReportAnswerAnchor={setViewReportAnswerAnchor}
        reportAnswer={reportQuestionAnswer}
      />
    </DashboardLayout>
  );
}
export default WebReport;
