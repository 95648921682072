import React, { useState } from "react";

// MUI components
import { TextField } from "@mui/material";

// 3rd party libs
import PropTypes from "prop-types";

// Custom component
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import FontComponent from "components/Responsive/fonts";
import pxToRem from "assets/theme/functions/pxToRem";

function QuickJumperPagination({ totalPages, onPageChange }) {
  const [inputValue, setInputValue] = useState("");
  const fontSize = FontComponent({ sizes: {} });

  // Handle input change
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  // Function to handle page jump
  const jumpToPage = () => {
    const pageNumber = parseInt(inputValue, 10);

    // Only jump if the page number is valid
    if (!Number.isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
      // Create a synthetic event to mimic MUI Pagination
      const syntheticEvent = {
        target: {
          innerText: `${pageNumber}`, // Simulate the innerText of the Pagination item
        },
      };
      onPageChange(syntheticEvent, pageNumber); // Call the onPageChange prop function
    }
    setInputValue(""); // Reset input field
  };

  // Handle quick jump on Enter key press
  const handleJumpToPage = (event) => {
    if (event.key === "Enter") {
      jumpToPage(); // Call jumpToPage function
    }
  };

  return (
    <MDBox display="flex" alignItems="center" gap={1}>
      <MDTypography variant="body1" sx={{ color: "black", fontSize }}>
        Go to
      </MDTypography>
      <TextField
        variant="outlined"
        size="small"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleJumpToPage}
        onBlur={jumpToPage}
        type="number"
        inputProps={{
          min: 1,
          max: totalPages,
        }}
        sx={{
          width: pxToRem(60),
          fontSize: pxToRem(16),
          "& .MuiInputBase-input": {
            fontSize: "1rem",
            padding: "6px 10px",
            color: "#495057",
          },
          // Hide spinner controls for numeric input
          "& input[type='number']": {
            "-moz-appearance": "textfield", // Firefox
            "&::-webkit-outer-spin-button": {
              display: "none",
            },
            "&::-webkit-inner-spin-button": {
              display: "none",
            },
          },
        }}
      />
      <MDTypography variant="body1" sx={{ color: "black", fontSize }}>
        Page
      </MDTypography>
    </MDBox>
  );
}

export default QuickJumperPagination;

QuickJumperPagination.defaultProps = {
  totalPages: 0,
  onPageChange: () => {},
};

QuickJumperPagination.propTypes = {
  totalPages: PropTypes.number,
  onPageChange: PropTypes.func,
};
