import React, { useState } from "react";

// Common Components
import BasicModal from "examples/modal/BasicModal/BasicModal";

// Mui Components
import { Box, Checkbox, FormControlLabel } from "@mui/material";

// Redux
import { useDispatch } from "react-redux";
import { openSnackbar } from "redux/Slice/Notification";
import { exportMatrixExcelData } from "redux/Thunks/TrainingMatrix";

// Utils
import userDetails from "utils/Types/userDetails";
import { paramCreater } from "utils/methods/methods";
import Constants, { ButtonTitles, Common } from "utils/Constants";

// 3rd party libraries
import PropTypes from "prop-types";

function UserExcel({ openUserDetailModal, setOpenUserDetailModal, filters }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [selectedState, setSelectedState] = useState({});

  // Functions to check and uncheck user details
  const toggleChildCheckbox = (parentName, childValue) => {
    setSelectedState((prev) => {
      const updatedState = { ...prev };
      const parentState = { ...updatedState[parentName] };

      if (parentState[childValue]) {
        // Uncheck the child checkbox
        delete parentState[childValue];
      } else {
        // Check the child checkbox
        parentState[childValue] = true;
      }

      if (Object.keys(parentState).length === 0) {
        // Remove the parent if no children are selected
        delete updatedState[parentName];
      } else {
        updatedState[parentName] = parentState;
      }

      return updatedState;
    });
  };

  const toggleParentCheckbox = (parentName, children) => {
    setSelectedState((prev) => {
      const updatedState = { ...prev };

      const isAllSelected = children.every((child) => updatedState[parentName]?.[child.value]);

      if (isAllSelected) {
        // Uncheck all children
        delete updatedState[parentName];
      } else {
        // Check all children
        updatedState[parentName] = Object.fromEntries(children.map((child) => [child.value, true]));
      }

      return updatedState;
    });
  };

  // Function to close the user detail modal
  const handleCloseUserDetailModal = () => {
    setOpenUserDetailModal(false);
    setSelectedState({});
  };

  // Function to export matrix excel
  const exportMatrixExcel = async () => {
    const paramData = {
      project: filters[0].selectedValue,
      expire: filters[1].selectedValue,
      ...(filters[0].selectedValue !== Common.ALL && {
        user: filters[2].selectedValue === "" ? Common.ALL : filters[2].selectedValue,
        functions:
          filters[3].selectedValue &&
          (filters[3].selectedValue.length === filters[3].list.length ||
            filters[3].selectedValue.length === 0)
            ? Common.ALL
            : filters[3].selectedValue.join(","),
      }),
    };
    const body = {
      selectedState: {
        ...selectedState,
        timeZone: Common.CURRENT_TIME_ZONE,
      },
      param: paramCreater(paramData),
    };
    setLoading(true);
    const res = await dispatch(exportMatrixExcelData(body));
    setLoading(false);
    if (res.payload.status === 200) {
      const url = window.URL.createObjectURL(res.payload.data);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Training_Matrix.xlsx");
      document.body.appendChild(link);
      link.click();
      handleCloseUserDetailModal();
      link.remove();
      await dispatch(
        openSnackbar({
          message: Constants.EXPORT_EXCEL_SUCCESS,
          notificationType: Constants.NOTIFICATION_SUCCESS,
        })
      );
    } else {
      await dispatch(
        openSnackbar({
          message: Constants.SOMETHING_WENT_WRONG,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
      handleCloseUserDetailModal();
    }
  };

  return (
    <BasicModal
      title={Constants.USER_MODAL_DETAILS_TITLE}
      open={openUserDetailModal}
      handleClose={handleCloseUserDetailModal}
      actionButton={loading ? ButtonTitles.EXPORTING : ButtonTitles.DONE}
      handleAction={exportMatrixExcel}
      disabled={loading}
    >
      {userDetails.map(({ label, name, details }) => (
        <Box key={name} sx={{ marginBottom: 2 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={details.every((detail) => selectedState[name]?.[detail.value])}
                onChange={() => toggleParentCheckbox(name, details)}
              />
            }
            label={label}
          />
          <Box sx={{ paddingLeft: 4 }}>
            {details.map(({ title, value }) => (
              <FormControlLabel
                key={value}
                control={
                  <Checkbox
                    checked={!!selectedState[name]?.[value]}
                    onChange={() => toggleChildCheckbox(name, value)}
                  />
                }
                label={title}
              />
            ))}
          </Box>
        </Box>
      ))}
    </BasicModal>
  );
}

UserExcel.defaultProps = {
  openUserDetailModal: false,
  setOpenUserDetailModal: () => {},
  filters: {},
};
UserExcel.propTypes = {
  openUserDetailModal: PropTypes.bool,
  setOpenUserDetailModal: PropTypes.func,
  filters: PropTypes.arrayOf(PropTypes.object),
};

export default UserExcel;
