const feedbackRowData = {
  _id: "",
  type: "",
  subject: "",
  description: "",
  status: "",
  images: [],
  account: {
    _id: "",
    name: "",
  },
  createdBy: {
    _id: "",
    callingName: "",
    firstName: "",
    lastName: "",
  },
  updatedBy: null,
  createdAt: "",
  updatedAt: "",
};

export default feedbackRowData;
