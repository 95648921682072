import React, { useEffect, useState } from "react";

// Material Components
import MDBox from "components/MDBox";
import { Divider, IconButton } from "@mui/material";

// Table data
import TowerData from "examples/Drawers/Report/locationCalculation/data/locationData";

// Redux
import { getLocationProgress } from "redux/Thunks/FieldsData";
import { exportLocationProgressPdf } from "redux/Thunks/Filter";

// Common Components
import DataTable from "examples/Tables/DataTable/LocationCalculationTable";
import CustomDrawer from "components/Drawer/CustomDrawer";
import pxToRem from "assets/theme/functions/pxToRem";
import MDTypography from "components/MDTypography";
import BasicButton from "examples/NewDesign/CustomButton/BasicButton";
import FontComponent from "components/Responsive/fonts";
import { DrawerBreakPoint } from "components/Responsive/BreakPoints";

// Utils
import Constants, { defaultData, Icons, Colors, ButtonTitles } from "utils/Constants";
import { useDispatch } from "react-redux";

// 3rd party library
import PropTypes from "prop-types";

export default function LocationCalculation({
  viewLocationProgressAnchor,
  setViewLocationProgressAnchor,
  locationData,
  setLocationData,
}) {
  const [reportProgressData, setReportProgressData] = useState([]);
  const [loading, setLoading] = useState(Constants.PENDING);
  const [pdfLoading, setPdfLoading] = useState(false);
  const dispatch = useDispatch();
  const titleBreakPoint = FontComponent({ sizes: DrawerBreakPoint.baseTitleBreakPoint });

  const locationProgressData = async () => {
    if (locationData.id !== "") {
      const data2 = {
        id: locationData.id,
      };
      const res = await dispatch(getLocationProgress(data2));
      setLoading(Constants.FULFILLED);
      if (res.payload.status === 200) {
        setReportProgressData(res?.payload?.data?.data);
      }
    }
  };

  useEffect(() => {
    locationProgressData();
  }, [locationData.id]);

  const { columns, rows } = TowerData(reportProgressData?.reports);

  const handleCloseLocationProgressDrawer = () => {
    setViewLocationProgressAnchor({ right: false });
    setLocationData({ id: "", name: "" });
  };

  const handleExport = async (format) => {
    setPdfLoading(true);
    const currentDate = new Date();
    const filename = `Reynard_location_progress_${currentDate.getFullYear()}-${
      currentDate.getMonth() + 1
    }-${currentDate.getDate()}_${currentDate.getHours()}-${currentDate.getMinutes()}-${currentDate.getSeconds()}.${format}`;
    const res = await dispatch(exportLocationProgressPdf(locationData?.id));
    const url = window.URL.createObjectURL(res.payload);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
    setPdfLoading(false);
  };

  return (
    <CustomDrawer
      defaultAnchor={viewLocationProgressAnchor}
      width="90%"
      onDrawerClose={handleCloseLocationProgressDrawer}
    >
      <MDBox
        sx={{
          backgroundColor: Colors.PRIMARY,
          padding: pxToRem(15),
          scrollX: "auto",
          width: "100%",
        }}
        px={pxToRem(24)}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        height={pxToRem(74)}
      >
        <MDBox sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            mr={2}
            aria-label="fingerprint"
            color="info"
            onClick={handleCloseLocationProgressDrawer}
          >
            {Icons.CROSS}
          </IconButton>
          <MDTypography
            sx={{
              color: Colors.WHITE,
              fontFamily: "Inter",
              fontSize: titleBreakPoint,
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: pxToRem(28),
              textAlign: "center",
              textTransform: "none",
            }}
          >
            {`Location Progress (${locationData.name})`}
          </MDTypography>
        </MDBox>
        <MDBox sx={{ marginRight: pxToRem(20) }}>
          <BasicButton
            title={pdfLoading ? ButtonTitles.EXPORTING : ButtonTitles.EXPORT}
            icon={Icons.EXPORT}
            background={Colors.WHITE}
            border
            disabled={pdfLoading}
            color={Colors.PRIMARY}
            action={() => handleExport("pdf")}
          />
        </MDBox>
      </MDBox>
      <Divider />
      <MDBox sx={{ padding: pxToRem(30) }}>
        <DataTable
          table={{ columns, rows }}
          backgroundColor={Colors.LIGHT_GRAY}
          textColor={Colors.BLACK}
          isSorted={false}
          entriesPerPage={{ defaultValue: defaultData.PER_PAGE_4 }}
          showTotalEntries={false}
          noEndBorder
          loading={loading}
          towerProgress={
            reportProgressData?.totalCompletion > 0
              ? `${Math.floor((reportProgressData?.totalCompletion ?? 0) * 100) / 100}%`
              : ""
          }
        />
      </MDBox>
    </CustomDrawer>
  );
}

LocationCalculation.propTypes = {
  viewLocationProgressAnchor: PropTypes.objectOf(PropTypes.object),
  setViewLocationProgressAnchor: PropTypes.func.isRequired,
  locationData: PropTypes.objectOf(PropTypes.object),
  setLocationData: PropTypes.func.isRequired,
};

LocationCalculation.defaultProps = {
  viewLocationProgressAnchor: { right: false },
  locationData: {
    id: "",
    name: "",
  },
};
