import { useEffect, useState } from "react";

// react-router-dom components
import { useLocation, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import { Alert, Snackbar } from "@mui/material";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React context
import { useMaterialUIController, setLayout } from "context";

// Custom Components
import Timer from "examples/modal/Timer";
import FontComponent from "components/Responsive/fonts";
import AccessDenied from "components/AccessDenied/accessDenied";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { closeSnackBar } from "redux/Slice/Notification";
import configThunk from "redux/Thunks/Config";
import { resetAllFilters } from "redux/Slice/Filter";
import { resetStateThunk } from "redux/Thunks/Authentication";

// Utils
import Session from "utils/Sessions";
import Constants, { defaultData, ModalContent, Icons } from "utils/Constants";

// 3rd party
import jwtDecode from "jwt-decode";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

function DashboardLayout({ xPadding, children, module }) {
  const [logout, setLogout] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav } = controller;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const Notification = useSelector((state) => state.Notification);
  const { config, loading } = useSelector((state) => state.config);
  const [isRender, setIsRender] = useState(null);
  const dispatchAction = useDispatch();
  useEffect(() => {
    if (module !== Constants.DEFAULT) {
      const isPermission = config[0]?.screens?.find((screen) => screen.screenId === module)
        ?.agreement?.read;
      setIsRender(isPermission);
    }
    const previousRole = config?.[0]?.role;
    const currentRole = config?.[0]?.tokenRole;
    if (previousRole !== currentRole) setLogout(true);
  }, [config]);

  useEffect(() => {
    (async () => {
      setLayout(dispatch, "dashboard");
      const token = jwtDecode(Session.userToken);
      if (
        config?.length === 0 &&
        (token.role !== defaultData.SUPER_ADMIN_ROLE ||
          (token.role === defaultData.SUPER_ADMIN_ROLE && Session.isSuperAdminViewingAdminPanel))
      )
        await dispatchAction(configThunk());
    })();
  }, [pathname]);

  const handleSnackbarClose = () => {
    dispatchAction(closeSnackBar());
  };

  const handleLogout = async () => {
    Session.setClear();
    navigate("/authentication/sign-in", { replace: true });
    await dispatchAction(resetStateThunk());
    await dispatchAction(resetAllFilters());
  };
  const renderContent = () => {
    if (loading === Constants.PENDING) {
      // While data is loading, render a placeholder or spinner
      return (
        <MDBox
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {Icons.LOADING2}
        </MDBox>
      );
    }

    if (loading === Constants.FULFILLED2) {
      // Only evaluate isRender when loading is fulfilled
      if (isRender) {
        return children;
      }
      if (isRender === false) {
        return (
          <>
            <DashboardNavbar />
            <AccessDenied />
          </>
        );
      }
    }

    // Handle unexpected states, if necessary
    return null;
  };

  return (
    <MDBox
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        px: pxToRem(xPadding),
        position: "relative",
        background: "linear-gradient(0deg, #f6f7ff, #f6f7ff)",
        minHeight: "100vh",

        [breakpoints.up("xl")]: {
          marginLeft: miniSidenav ? pxToRem(65) : pxToRem(250),
          transition: transitions.create(["margin-left", "margin-right"], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}
    >
      {module === Constants.DEFAULT ? children : renderContent()}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={Notification.openSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={Notification.notificationType}
          sx={{
            width: "100%",
            backgroundColor: Notification.notificationType === "success" ? "green" : "error",
          }}
        >
          <MDTypography
            variant="body"
            color={Notification.notificationType === "success" ? "light" : "error"}
            sx={{ fontSize: FontComponent({ sizes: {} }) }}
          >
            {Notification.snackbarMessage}
          </MDTypography>
        </Alert>
      </Snackbar>

      {/* Logout user when role is changed */}
      <Timer open={logout} message={ModalContent.ON_ROLE_CHANGE} callback={handleLogout} />
    </MDBox>
  );
}

DashboardLayout.defaultProps = {
  xPadding: 30,
  // [default,allowed,notAllowed]
  // default props will render the children when prop is not specified or for static pages.
  // module true props will render the children when the user read agreement for the calling module.
  // module false props will render the access denied page.
  module: Constants.DEFAULT,
};

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
  xPadding: PropTypes.number,
  module: PropTypes.string,
};

export default DashboardLayout;
