import { useEffect, useState } from "react";

// Material Components
import MDBox from "components/MDBox";
import Author from "components/Table/Author";
import { IconButton, Switch } from "@mui/material";

// utils
import Constants, { Icons, defaultData } from "utils/Constants";

export default function ReportUploadDocumentData(
  reportDocumentData,
  handleDocumentEdit,
  handleOpenDocumentDelete,
  handleDocumentIsMerge
) {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (reportDocumentData) {
      const list = reportDocumentData?.map((item) => {
        const temp = {
          name: (
            <MDBox
              sx={{
                display: "inline-flex",
                alignItems: "center",
                gap: "5px",
                maxWidth: "100%",
              }}
            >
              <MDBox
                sx={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  maxWidth: "calc(100% - 40px)",
                }}
              >
                <Author name={item?.name} maxContent={defaultData.SMALL_CONTENT_LENGTH} />
              </MDBox>
              <IconButton
                aria-label="view report document"
                color="info"
                onClick={() => window.open(item?.document, "_blank")}
              >
                {Icons.VIEW}
              </IconButton>
            </MDBox>
          ),
          isMerge: <Switch checked={item?.isMerge} onChange={() => handleDocumentIsMerge(item)} />,
          action: (
            <MDBox>
              <IconButton
                aria-label="edit report document"
                color="info"
                onClick={() => handleDocumentEdit(item)}
              >
                {Icons.EDIT}
              </IconButton>
              <IconButton
                aria-label="delete report document"
                color="error"
                onClick={() => handleOpenDocumentDelete(item?.[Constants.MONGOOSE_ID] || "")}
              >
                {Icons.DELETE}
              </IconButton>
            </MDBox>
          ),
        };
        return temp;
      });
      setRows([...list]);
    }
  }, [reportDocumentData]);

  return {
    documentColumns: [
      { Header: "Document Name", accessor: "name", align: "left", width: "70%" },
      { Header: "Is Merge", accessor: "isMerge", align: "left", width: "20%" },
      { Header: "Action", accessor: "action", align: "left", width: "10%" },
    ],

    documentRows: rows,
  };
}
