import { useEffect, useState } from "react";

// Material Components
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  Slider,
  Switch,
  Tooltip,
} from "@mui/material";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

// Common Components
import MDTypography from "components/MDTypography";
import Author from "components/Table/Author";
import MDBox from "components/MDBox";
import FontComponent from "components/Responsive/fonts";
import FTextField from "components/Form/FTextField";
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox";
import DateTime from "components/DateTime/DateTime";
import ImageUpload from "components/ImageUpload/imageUpload";
import SignaturePad from "components/SignaturePad";
import { TitleBreakPoint } from "components/Responsive/BreakPoints";

// Redux
import { useDispatch } from "react-redux";
import { setQuestionTree } from "redux/Slice/Report";

// Utils
import Constants, { Icons, defaultData, Colors } from "utils/Constants";

// 3rd party library
import ReactDatePicker from "react-datepicker";
import moment from "moment";

class TreeNode {
  constructor(value) {
    this.value = value;
    this.children = [];
    this.expanded = true; // Default to expanded
  }
}

// Validation function for numeric input field
const numericValidation = (e) => {
  const invalidChars = ["e", "-", "+"];

  if (!/[0-9.]/.test(e.key) || invalidChars.includes(e.key)) {
    e.preventDefault();
  }
};

export default function WebReportDetailsData(report, handleView, inputObj, setInputObj) {
  const dispatch = useDispatch();
  const fontSize = FontComponent({ sizes: {} });
  const inputBreakPoint = FontComponent({ sizes: TitleBreakPoint.baseTitleBreakPoint });

  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(0);

  // Function to handle image upload
  const handleImageChange = (imageValues, keyName) => {
    setInputObj((prev) => ({ ...prev, [keyName]: imageValues?.[0]?.url || "" }));
  };

  // Function to delete the uploaded image
  const handleImageCancel = (keyName) => {
    setInputObj((prev) => ({ ...prev, [keyName]: "" }));
  };

  // Function to add or update signature
  const handleSignaturePad = (keyName, signatureValue) => {
    setInputObj((prev) => ({ ...prev, [keyName]: signatureValue?.url }));
  };

  // Function to get input types according to parameter type from the question array
  const getInputType = (ansObj, questionId) => {
    const type = ansObj?.parameterType?.uniqueKey;
    let checkboxValues = [];

    const handleCheckboxChange = (event) => {
      const { name, checked } = event.target;

      if (checked) {
        // Add the new checkbox value to the array if checked and not already present
        if (!checkboxValues.includes(name)) {
          checkboxValues.push(name);
        }
      } else {
        // Remove the checkbox value from the array if unchecked (case-insensitive)
        checkboxValues = checkboxValues.filter((value) => value !== name);
      }
      // Update state with the new checkbox values
      setInputObj((prev) => ({ ...prev, [questionId]: checkboxValues.join("##") }));
    };

    const handleDateChange = (key, value) => {
      setInputObj((prev) => ({ ...prev, [key]: value }));
      setCount((prev) => prev + 1);
    };

    switch (type) {
      case "shortAnswer":
        return (
          <FTextField
            id={questionId}
            name={questionId}
            type="text"
            placeholder={`Enter ${ansObj?.parameterType?.name} here`}
            value={inputObj?.[questionId] || ""}
            handleChange={(e) =>
              setInputObj((prev) => ({ ...prev, [questionId]: e.target.value?.trimStart() || "" }))
            }
            isMarginTopRequired={false}
          />
        );

      case "number":
        return (
          <FTextField
            id={questionId}
            name={questionId}
            type="number"
            placeholder={`Enter ${ansObj?.parameterType?.name} here`}
            value={inputObj?.[questionId] || ""}
            handleChange={(e) => setInputObj((prev) => ({ ...prev, [questionId]: e.target.value }))}
            onKeyPress={numericValidation}
            isMarginTopRequired={false}
          />
        );

      case "dropdown":
        return (
          <Select
            displayEmpty
            fullWidth
            defaultValue={inputObj?.[questionId] || ""}
            {...(inputObj?.[questionId] && { value: inputObj?.[questionId] })}
            onChange={(e) => setInputObj((prev) => ({ ...prev, [questionId]: e.target.value }))}
            sx={{
              height: 45,
              minWidth: "100%",
              "& .MuiInputBase-input": {
                fontSize: inputBreakPoint,
                fontWeight: 400,
                color: "#667085",
              },
              textTransform: "capitalize",
              backgroundColor: "black",
              paddingY: "0.65rem",
              paddingRight: "0.55rem",
              maxHeight: 100,
              cursor: "pointer",
            }}
          >
            <MenuItem value="" disabled>
              Select
            </MenuItem>
            {ansObj?.option?.map((item) => (
              <MenuItem
                sx={{
                  textTransform: "capitalize",
                  maxHeight: 400,
                  fontSize: inputBreakPoint,
                  fontWeight: 400,
                  marginTop: "4px",
                  color: "#667085",
                }}
                value={item?.title}
                key={item?.title}
              >
                {item && item?.title?.length > defaultData.SMALLER_CONTENT_LENGTH ? (
                  <Tooltip title={item?.title}>
                    <span>{`${item?.title.slice(0, defaultData.SMALLER_CONTENT_LENGTH)}...`}</span>
                  </Tooltip>
                ) : (
                  item?.title
                )}
              </MenuItem>
            ))}
          </Select>
        );

      case "checkbox":
        return (
          <MDBox display="flex" flexDirection="column" gap={2} flexWrap="wrap">
            {[...ansObj.option]?.map((item) => (
              <FormControlLabel
                name={questionId}
                label={item?.title}
                key={item?.title}
                control={
                  <Checkbox
                    name={item?.title}
                    onChange={handleCheckboxChange}
                    style={{
                      border: "2px solid #191A51",
                      borderRadius: "2px",
                      padding: "0",
                      height: "18px",
                      width: "18px",
                      opacity: 1,
                    }}
                    icon={
                      <CheckCircleOutlineIcon
                        style={{
                          border: "0px solid #191A51",
                          borderRadius: "0px",
                          padding: "0",
                          margin: 0,
                        }}
                      />
                    }
                    checkedIcon={
                      <CheckCircleIcon
                        style={{
                          border: "0px solid #191A51",
                          borderRadius: "0px",
                          padding: "0",
                          margin: 0,
                        }}
                      />
                    }
                  />
                }
              />
            ))}
          </MDBox>
        );

      case "boolean":
        return (
          <Switch
            checked={inputObj?.[questionId]}
            onChange={(e) => setInputObj((prev) => ({ ...prev, [questionId]: e.target.checked }))}
          />
        );

      case "range":
        return (
          <Slider
            id={questionId}
            name={questionId}
            aria-label="Default"
            valueLabelDisplay="auto"
            min={ansObj?.range?.min}
            max={ansObj?.range?.max}
            onChange={(e) => setInputObj((prev) => ({ ...prev, [questionId]: e.target.value }))}
          />
        );

      case "date":
        return (
          <ReactDatePicker
            selected={inputObj?.[questionId] && moment(inputObj?.[questionId]).toDate()}
            onChange={(date) =>
              handleDateChange(questionId, moment(date).format(defaultData.DATABSE_DATE_FORMAT))
            }
            customInput={<DateTime isMarginBottomRequired={false} item={{ hint: "Select date" }} />}
            placeholderText="mm/dd/yyyy"
            dateFormat={defaultData.REACTDATETIMEPICKER_DATE_FORMAT}
          />
        );

      case "dateTime":
        return (
          <ReactDatePicker
            selected={inputObj?.[questionId] && moment(inputObj?.[questionId]).toDate()}
            onChange={(date) =>
              handleDateChange(
                questionId,
                moment(date).format(defaultData.DATABASE_24_HOURS_FORMAT)
              )
            }
            customInput={
              <DateTime isMarginBottomRequired={false} item={{ hint: "Select date & time" }} />
            }
            placeholderText="mm/dd/yyyy"
            showTimeSelect
            dateFormat={defaultData.REACTDATETIMEPICKER_24_HOURS_FORMAT}
            timeFormat="HH:mm"
          />
        );

      case "image":
        return (
          <MDBox width="100%" sx={{ py: "8px" }}>
            <ImageUpload
              name="image"
              onImageUpload={(imageValues) => handleImageChange(imageValues, questionId)}
              onImageCancel={() => handleImageCancel(questionId)}
              type="WebReport"
              formats={["image/jpeg", "image/jpg", "image/png", "image/svg+xml"]}
              acceptType="image/*"
              maxImageCount={1}
              imageTypeError={Constants.IMAGE_FILE_TYPE_NOT_ALLOWED}
              sizeUpdate
              direction="row-reverse"
              isBottomMarginRequired={false}
            />
          </MDBox>
        );

      case "signature":
        return (
          <MDBox>
            <SignaturePad
              keyName={questionId}
              handleSignaturePad={handleSignaturePad}
              folderName="Report_Answers"
            />
          </MDBox>
        );

      default:
        return null;
    }
  };

  const findTotalAnswerCountInUserArray = (answerId, userArray) => {
    const totalAnswerCount = userArray.reduce((cnt, user) => {
      const answer = user.answers.find((ans) => ans.answerTitleId === answerId);
      return cnt + (answer ? 1 : 0);
    }, 0);

    return totalAnswerCount;
  };

  const getIconAtProgressByValue = (answerCount) => {
    switch (answerCount) {
      case 0: {
        return Icons.REJECTED;
      }
      case 1: {
        return Icons.APPROVED;
      }
      default: {
        return Icons.INFO_2;
      }
    }
  };

  useEffect(() => {
    if (report) {
      (async () => {
        const questionTree = new TreeNode("Question Tree");
        const sortedReport = [...report].sort((a, b) => a.sortOrder - b.sortOrder);

        // Map to keep track of usernames and their associated report IDs
        const list = sortedReport?.map((item, idx) => {
          // Getting all answerTypes title array length
          const activeAnswerTypesTitles = item.answerTypes.map((b) => ({
            ...b,
            title: b?.title?.filter((c) => c.isActive),
            userAnswers: b.userAnswers.map((usrAns) => ({
              ...usrAns,
              answers: usrAns.answers.filter((ans) => ans.isActive),
            })),
          }));
          // Getting all answerTypes title array length sum
          const titleLength = activeAnswerTypesTitles.reduce((total, b) => {
            const requiredCount = b?.title?.filter((c) => c.isRequired)?.length || 0;
            return total + requiredCount;
          }, 0);

          const isRequiredCountMap = new Map();

          // To create a unique set of answerTitleIds by matching answerTypes title array and
          // answersTypes userAnswers's answer array
          activeAnswerTypesTitles.forEach((element1) => {
            element1?.title?.forEach((elem) => {
              if (elem?.isRequired && !isRequiredCountMap.has(elem?.isRequired)) {
                const answerTitleArr = [];
                element1.userAnswers?.forEach((item21) => {
                  item21?.answers?.forEach((answer1) => {
                    if (answer1?.answerTitleId === elem?.[Constants.MONGOOSE_ID]) {
                      answerTitleArr.push(answer1?.answerTitleId);
                    }
                  });
                });
                if (answerTitleArr?.length > 0) {
                  isRequiredCountMap.set(elem?.isRequired, 1);
                }
              } else if (elem?.isRequired && isRequiredCountMap.has(elem?.isRequired)) {
                const answerTitleArr = [];
                element1.userAnswers?.forEach((item21) => {
                  item21?.answers?.forEach((answer1) => {
                    if (answer1?.answerTitleId === elem?.[Constants.MONGOOSE_ID]) {
                      answerTitleArr.push(answer1?.answerTitleId);
                    }
                  });
                });
                if (answerTitleArr?.length > 0) {
                  isRequiredCountMap.set(
                    elem?.isRequired,
                    isRequiredCountMap.get(elem?.isRequired) + 1
                  );
                }
              }
            });
          });

          // Questions's Answer title Row
          const questionObj = {
            id: item?.questionId,
            queNo: <Author name={`${idx + 1}`} />,
            type: <Author name="" />,
            questionAnswer: (
              <MDBox>
                <MDTypography
                  variant="caption"
                  sx={{
                    overflowWrap: "break-word",
                    wordWrap: "break-word",
                    fontSize,
                  }}
                >
                  {`Q${idx + 1}.  ${item.title}`}
                </MDTypography>
              </MDBox>
            ),
            progress: (
              <MDBox
                sx={{
                  display: "flex",
                  width: "100px",
                  justifyContent: "center",
                }}
              >
                <Author
                  style={{ color: Colors.PRIMARY, fontWeight: "700" }}
                  name={`${isRequiredCountMap.get(true) || 0} / ${titleLength}`}
                />
              </MDBox>
            ),
            action: activeAnswerTypesTitles.length > 0 && (
              <IconButton
                aria-label="view report detail"
                color="info"
                onClick={() => handleView(item, idx + 1)}
              >
                {Icons.VIEW}
              </IconButton>
            ),
            isQuestion: true,
            queId: idx + 1,
          };
          questionTree.children.push(new TreeNode(questionObj));

          if (activeAnswerTypesTitles.length > 0) {
            // Answer's Array to Iterate over title Array to get all required data
            activeAnswerTypesTitles.forEach((element, ansIndex) => {
              // Iterating over title array for each Question's Answer
              element.title.forEach((item2, answerIdx) => {
                const answerArrayLength = findTotalAnswerCountInUserArray(
                  item2?.[Constants.MONGOOSE_ID],
                  element?.userAnswers
                );

                const answerObj2 = {
                  id: item2?.[Constants.MONGOOSE_ID],
                  queNo: `${idx + 1}.${ansIndex + 1}.${answerIdx + 1}`,
                  type: <Author name={element?.parameterType?.uniqueKey} />,
                  isReuired: (
                    <MDBox
                      sx={{
                        display: "flex",
                        width: "100px",
                        justifyContent: "center",
                      }}
                    >
                      <CustomCheckbox disabled checked={item2?.isRequired} />
                    </MDBox>
                  ),
                  questionAnswer: (
                    <Author name={`${item2?.value} :`} style={{ color: "#7B809A" }} />
                  ),
                  answer: (
                    <MDBox
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                        width: "400px",
                        padding: "8px",
                      }}
                    >
                      {getInputType(
                        element,
                        `${item?.questionId}_${element?.[Constants.MONGOOSE_ID]}_${
                          element?.title?.[answerIdx]?.[Constants.MONGOOSE_ID]
                        }`
                      )}
                    </MDBox>
                  ),
                  progress: (
                    <MDBox
                      sx={{
                        display: "flex",
                        width: "100px",
                        justifyContent: "center",
                      }}
                    >
                      {getIconAtProgressByValue(answerArrayLength)}
                    </MDBox>
                  ),
                  action: "",
                  isQuestion: false,
                  queId: idx + 1,
                  ansId: "",
                  title: "",
                };

                questionTree.children[idx].children.push(new TreeNode(answerObj2));
              });
            });
          }

          const questionRow = {
            queNo: idx + 1,
            type: <Author name="" />,
            questionAnswer: item?.title,
            action: "",
          };
          return questionRow;
        });
        await dispatch(setQuestionTree(questionTree));
        setRows([...list]);
      })();
    }
  }, [report, count]);

  return {
    reportColumns: [
      { Header: "No.", accessor: "queNo", width: "2%" },
      { Header: "Type", accessor: "type", align: "left", width: "10%" },
      {
        Header: "Question",
        accessor: "questionAnswer",
        align: "left",
        width: "10%",
      },
      { Header: "Answer", accessor: "answer", align: "left", width: "30%" },
      { Header: "Is Required", accessor: "isReuired", align: "center", width: "2%" },
      { Header: "Progress", accessor: "progress", align: "center", width: "7%" },
      { Header: "Action", accessor: "action", align: "center", width: "7%" },
    ],
    reportRows: rows,
  };
}
