const toolBoxTalkDataFormat = {
  _id: "",
  createdAt: "",
  createdBy: {
    _id: "",
    callingName: "",
    firstName: "",
    lastName: "",
  },
  location: {
    _id: "",
    title: "",
  },
  memberSigned: 0,
  note: "",
  project: {
    _id: "",
    title: "",
  },
  team: {
    _id: "",
    teamsWfmName: "",
  },
  totalMembers: 0,
};

export default toolBoxTalkDataFormat;
