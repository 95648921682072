// License and Permission
const LicensePermission = {
  // License
  QHSE_MANAGEMENT: "QHSE Management",
  PROJECT_MANAGEMENT: "Project Management",
  EQUIPMENT_MANAGEMENT: "Equipment Management",
  WFM_WIZARD: "WFM Wizard",
  RESOURCE: "Resource",
  PERSONNEL_MANAGEMENT: "Personnel Management",

  // Permissions
  SAFE_CARD: "Safe",
  UNSAFE_CARD: "Unsafe",
  NCR_CARD: "NCR",
  INCIDENT_CARD: "Incident",
  SHIFT_DETAILS: "Shift Details",
  REPORT: "Report",
  DPR: "DPR",
  INVENTORY: "Inventory",
  WAREHOUSE: "Warehouse",
  ORDER_APPROVAL: "Order Approval",
  EQUIPMENT_REQUEST: "Request",
  USER_MANAGEMENT: "Personnel",
};

export default LicensePermission;
