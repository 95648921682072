const mongooseId = "_id";

const filtersModuleName = {
  // All Filters Module Name OR Titles
  PROJECT: "Project",
  TITLE: "Title",
  LOCATION: "Location",
  TEAM: "Team",
  STATUS: "Status",
  SEARCH: "Search",
  COUNTRY: "Country",
  PROFILE_FUNCTION: "Profile Function",

  // All Filter Modules Title Object
  REPORT_FILTERS_TITLE_OBJ: { [mongooseId]: "all", title: "All" },
  TOOLBOX_TALK_FILTERS_TITLE_OBJ: { [mongooseId]: "all", title: "All" },
  USER_MANAGEMENT_FILTERS_TITLE_OBJ: { [mongooseId]: "All", title: "All" },

  // All Filter Modules Selected Value And Error Messages
  ALL_IN_SMALL_CASE: "all",
  ALL_WITH_FIRST_LETTER_CAPITAL: "All",
  NO_DATA_FOUND: "No data found",
};

export default filtersModuleName;
