import React, { useEffect, useState } from "react";

// Common Components
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox";
import BasicModal from "examples/modal/BasicModal/BasicModal2";
import ImageUpload from "components/ImageUpload/imageUpload";
import CustomRadio from "components/CustomRadio/CustomRadio";
import DateTime from "components/DateTime/DateTime";
import pxToRem from "assets/theme/functions/pxToRem";
import FTextField from "components/Form/FTextField";
import FDropdown from "components/Dropdown/FDropdown";
import ReactDatePicker from "react-datepicker";
import MDBox from "components/MDBox";

// Material Components
import MDTypography from "components/MDTypography";

// Utils
import Constants, { ButtonTitles, ModalContent, defaultData } from "utils/Constants";

// Redux
import { updateReportAnswer } from "redux/Thunks/Report";
import { openSnackbar } from "redux/Slice/Notification";
import { updateReport } from "redux/Slice/Report";
import { useDispatch } from "react-redux";
import { FormControlLabel, Slider } from "@mui/material";
import FontComponent from "components/Responsive/fonts";
import { ModalBreakPoint, TitleBreakPoint } from "components/Responsive/BreakPoints";

// 3rd party library
import moment from "moment";
import PropTypes from "prop-types";

export default function ReportAnswerUpdate({ reportEdit, setReportEdit }) {
  const dispatch = useDispatch();
  const [reportAnswerBody, setReportAnswerBody] = useState("");
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const modalTitleFontSize = FontComponent({ sizes: ModalBreakPoint.baseTitleBreakPoint });

  const handleClose = () => {
    setReportEdit({ open: false, questionTitle: "", answerType: "", answerData: {} });
    setReportAnswerBody("");
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setReportAnswerBody(value);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    // Convert checkbox name to lowercase for comparison
    const lowercaseName = name ? name.toLowerCase() : "";

    // Get the current checkbox values from state or initialize as an empty string
    let newCheckbox = reportEdit?.answerData?.reportAnswer || "";
    let checkboxValues = newCheckbox.split("##");

    if (checked) {
      // Convert each item in checkboxValues to lowercase for comparison
      checkboxValues = checkboxValues.map((value) => value.toLowerCase());
      // Add the new checkbox value to the array if checked and not already present
      if (!checkboxValues.includes(lowercaseName)) {
        checkboxValues.push(lowercaseName);
      }
    } else {
      // Remove the checkbox value from the array if unchecked (case-insensitive)
      checkboxValues = checkboxValues.filter((value) => value !== lowercaseName);
    }

    // Join the array elements with "##" as the delimiter
    newCheckbox = checkboxValues.join("##");

    // Update state with the new checkbox values
    setReportEdit({
      ...reportEdit,
      answerData: {
        ...reportEdit.answerData,
        reportAnswer: newCheckbox,
      },
    });
    setReportAnswerBody(newCheckbox);
  };

  const handleDateChange = (value) => {
    setReportEdit({
      ...reportEdit,
      answerData: {
        ...reportEdit.answerData,
        reportAnswer: value,
      },
    });
  };

  const handleImageChange = (imageValues) => {
    setReportEdit({
      ...reportEdit,
      answerData: {
        ...reportEdit.answerData,
        reportAnswer: imageValues?.[0]?.url || "",
      },
    });
    setReportAnswerBody(imageValues?.[0]?.url || "");
  };

  const handleImageCancel = () => {
    setReportAnswerBody("");
  };

  useEffect(() => {
    if (reportEdit?.open && reportEdit?.answerType !== "image") {
      setReportAnswerBody(reportEdit?.answerData?.reportAnswer || "");
    } else {
      setReportAnswerBody("");
    }
  }, [reportEdit?.open]);

  useEffect(() => {
    const type = reportEdit?.answerType;
    let shouldDisableSubmit = false;

    switch (type) {
      case "shortAnswer":
        if (reportAnswerBody.trim() === "") {
          shouldDisableSubmit = true;
        }
        break;

      case "number":
      case "image":
        if (reportAnswerBody === "") {
          shouldDisableSubmit = true;
        }
        break;

      case "checkbox":
        if (reportEdit?.answerData?.reportAnswer === "") {
          shouldDisableSubmit = true;
        }
        break;

      default:
        shouldDisableSubmit = false;
    }

    setDisableSubmit(shouldDisableSubmit);
  }, [reportEdit, reportAnswerBody]);

  const handleAnswerUpdate = async () => {
    const body = {
      answerId: reportEdit?.answerData?.answerId,
      data: {
        answerTitleId: reportEdit?.answerData?.answerTitleId,
        answer:
          reportEdit?.answerType === "checkbox" ||
          reportEdit?.answerType === "date" ||
          reportEdit?.answerType === "dateTime"
            ? reportEdit?.answerData?.reportAnswer
            : reportAnswerBody,
      },
    };

    setButtonLoading(true);
    setDisableSubmit(true);

    const res = await dispatch(updateReportAnswer(body));

    setDisableSubmit(false);

    if (res.payload.status === 200) {
      await dispatch(updateReport(body));
      setReportEdit({ open: false, questionTitle: "", answerType: "", answerData: {} });
      setButtonLoading(false);
      setReportAnswerBody("");
      await dispatch(
        openSnackbar({
          message: Constants.REPORT_ANSWER_UPDATE_SUCCESS,
          notificationType: Constants.NOTIFICATION_SUCCESS,
        })
      );
    } else {
      await dispatch(
        openSnackbar({
          message: Constants.SOMETHING_WENT_WRONG,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
    }
  };

  const numericValidation = (e) => {
    const invalidChars = ["e", "-", "+"];

    if (!/[0-9.]/.test(e.key) || invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <BasicModal
      title={ModalContent.UPDATE_REPORT_ANSWER}
      open={reportEdit.open}
      handleAction={handleAnswerUpdate}
      handleClose={handleClose}
      disabled={disableSubmit}
      actionButton={buttonLoading === false ? ButtonTitles.SAVE : ButtonTitles.LOADING}
      py={0}
    >
      <MDBox mt={2}>
        <MDTypography
          sx={{
            fontWeight: 600,
            fontSize: modalTitleFontSize,
            lineHeight: pxToRem(16),
            height: pxToRem(20),
            color: "#475467",
          }}
        >
          Question
        </MDTypography>
        <MDBox
          sx={{
            paddingY: pxToRem(10),
          }}
        >
          <MDTypography
            sx={{
              fontWeight: 600,
              fontSize: FontComponent({ sizes: TitleBreakPoint.baseTitleBreakPoint }),
              color: "#191A51",
              height: pxToRem(24),
            }}
          >
            1. {reportEdit.questionTitle}
          </MDTypography>
        </MDBox>
        <MDBox
          sx={{
            marginTop: pxToRem(22),
          }}
        >
          <MDTypography
            sx={{
              fontWeight: 600,
              fontSize: modalTitleFontSize,
              lineHeight: pxToRem(16),
              height: pxToRem(20),
              color: "#475467",
            }}
          >
            Answer
          </MDTypography>
          <MDBox
            sx={{
              paddingX: pxToRem(24),
              paddingY: pxToRem(16),
            }}
          >
            <MDBox>
              <MDTypography
                sx={{
                  fontWeight: 600,
                  fontSize: FontComponent({ sizes: TitleBreakPoint.smallTitleBreakPoint1 }),
                  lineHeight: pxToRem(36),
                  height: pxToRem(24),
                  color: "#475467",
                }}
              >
                {`Answer by ${
                  reportEdit?.answerData?.userName?.callingName
                    ? reportEdit?.answerData?.userName?.callingName
                    : reportEdit?.answerData?.userName?.firstName
                } ${reportEdit?.answerData?.userName?.lastName}`}
              </MDTypography>

              <MDBox sx={{ paddingX: pxToRem(8) }}>
                <MDTypography
                  sx={{
                    marginTop: pxToRem(24),
                    fontWeight: 500,
                    fontSize: FontComponent({ sizes: TitleBreakPoint.baseTitleBreakPoint }),
                    color: "#475467",
                  }}
                >
                  {reportEdit?.answerData?.answerTitle}
                </MDTypography>
              </MDBox>

              {reportEdit?.answerType === "shortAnswer" ? (
                <FTextField
                  id="shortAnswer"
                  name="shortAnswer"
                  type="text"
                  value={reportAnswerBody}
                  handleChange={handleChange}
                  marginBottom={2}
                />
              ) : null}

              {reportEdit?.answerType === "dropdown" ? (
                <FDropdown
                  id="dropdown"
                  name="dropdown"
                  defaultValue={reportEdit?.answerData?.reportAnswer}
                  menu={reportEdit?.answerData?.reportAnswerData?.map((item) => item?.title)}
                  handleChange={(name, value, id) => handleChange({ target: { name, value, id } })}
                />
              ) : null}

              {reportEdit?.answerType === "number" ? (
                <FTextField
                  id="shortAnswer"
                  name="shortAnswer"
                  type="number"
                  value={reportAnswerBody}
                  handleChange={handleChange}
                  onKeyPress={(e) => numericValidation(e)}
                  marginBottom={2}
                />
              ) : null}

              <MDBox
                display="flex"
                justifyContent="space-between"
                flexWrap="wrap"
                width="400px"
                ml="20px"
                mt="5px"
              >
                {reportEdit?.answerType === "checkbox"
                  ? reportEdit?.answerData?.reportAnswerData?.map((check) => {
                      const cleanedReportAnswerArray =
                        reportEdit?.answerData?.reportAnswer.split("##");

                      return (
                        <FormControlLabel
                          name="isVisibleForIncidentCard"
                          label={check?.title}
                          control={
                            <CustomCheckbox
                              name={check.title}
                              checked={cleanedReportAnswerArray.some(
                                (answer) => answer.toLowerCase() === check?.title.toLowerCase()
                              )}
                              onChange={(event) => handleCheckboxChange(event)}
                            />
                          }
                        />
                      );
                    })
                  : null}
              </MDBox>

              {reportEdit?.answerType === "date" ? (
                <ReactDatePicker
                  selected={moment(reportEdit?.answerData?.reportAnswer).toDate()}
                  onChange={(date) =>
                    handleDateChange(moment(date).format(defaultData.DATABSE_DATE_FORMAT))
                  }
                  customInput={<DateTime item={{ hint: "select date" }} />}
                  placeholderText="mm/dd/yyyy"
                  dateFormat={defaultData.REACTDATETIMEPICKER_DATE_FORMAT}
                />
              ) : null}

              {reportEdit?.answerType === "dateTime" ? (
                <ReactDatePicker
                  selected={moment(reportEdit?.answerData?.reportAnswer).toDate()}
                  onChange={(date) =>
                    handleDateChange(moment(date).format(defaultData.DATABASE_24_HOURS_FORMAT))
                  }
                  customInput={<DateTime item={{ hint: "select date" }} />}
                  placeholderText="mm/dd/yyyy"
                  showTimeSelect
                  dateFormat={defaultData.REACTDATETIMEPICKER_24_HOURS_FORMAT}
                  timeFormat="HH:mm"
                />
              ) : null}

              {reportEdit?.answerType === "boolean" ? (
                <CustomRadio
                  name="boolean"
                  list={[
                    { label: "True", value: "true" },
                    { label: "False", value: "false" },
                  ]}
                  defaultValue={reportEdit?.answerData?.reportAnswer}
                  handleChange={handleChange}
                />
              ) : null}

              {reportEdit?.answerType === "range" ? (
                <Slider
                  id="range"
                  name="range"
                  aria-label="Default"
                  defaultValue={reportEdit?.answerData?.reportAnswer}
                  valueLabelDisplay="auto"
                  min={reportEdit?.answerData?.reportAnswerData?.min}
                  max={reportEdit?.answerData?.reportAnswerData?.max}
                  onChange={handleChange}
                />
              ) : null}

              {reportEdit?.answerType === "image" ? (
                <MDBox>
                  <ImageUpload
                    name="image"
                    onImageUpload={(imageValues) => handleImageChange(imageValues)}
                    onImageCancel={handleImageCancel}
                    data={[
                      reportEdit?.answerData?.reportAnswer && {
                        name: "",
                        url: reportEdit?.answerData?.reportAnswer,
                        size: 0,
                      },
                    ]}
                    type="Report_Answers"
                    formats={["image/jpeg", "image/jpg", "image/png", "image/svg+xml"]}
                    acceptType="image/*"
                    maxImageCount={1}
                    imageTypeError={Constants.IMAGE_FILE_TYPE_NOT_ALLOWED}
                    sizeUpdate
                  />
                </MDBox>
              ) : null}
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
    </BasicModal>
  );
}

ReportAnswerUpdate.propTypes = {
  reportEdit: PropTypes.objectOf(PropTypes.any).isRequired,
  setReportEdit: PropTypes.func.isRequired,
};
