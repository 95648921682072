import React, { useEffect, useState } from "react";

// Material Components
import {
  MenuItem,
  FormControl,
  Select,
  Grid,
  Card,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  Divider,
  List,
  ListItem,
  Box,
  ListItemButton,
  ListItemText,
  FormHelperText,
  Tooltip,
} from "@mui/material";

// Table
import DataTable from "examples/Tables/DataTable";
import DataTableColl from "examples/Tables/DataTable/GroupedRowTable";
import Asset from "layouts/wfmwizard/DailyActivity/setupProject/ProjectTableData/Asset";
import Scopes from "layouts/wfmwizard/DailyActivity/setupProject/ProjectTableData/Scopes";
import Teamdata from "layouts/wfmwizard/DailyActivity/setupProject/ProjectTableData/Teams";
import Members from "layouts/wfmwizard/DailyActivity/setupProject/ProjectTableData/Members";
import Projects from "layouts/wfmwizard/DailyActivity/setupProject/ProjectTableData/Project";
import Functions from "layouts/wfmwizard/DailyActivity/setupProject/ProjectTableData/Functions";
import Activities from "layouts/wfmwizard/DailyActivity/setupProject/ProjectTableData/Activities";
import Locationdata from "layouts/wfmwizard/DailyActivity/setupProject/ProjectTableData/Locationdata";
import ProjectString from "layouts/wfmwizard/DailyActivity/setupProject/ProjectTableData/ProjectStringData";
import ProjectDocumentData from "layouts/wfmwizard/DailyActivity/setupProject/ProjectTableData/ProjectDocumentData";
import ProjectCertificateData from "layouts/wfmwizard/DailyActivity/setupProject/ProjectTableData/ProjectCertificateData";

// Material Common Components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import FTextField from "components/Form/FTextField";
import PageTitle from "examples/NewDesign/PageTitle";
import FDropdown2 from "components/Dropdown/fDropdown2";
import SearchBar from "components/Search/SearchInTable";
import CustomButton from "examples/NewDesign/CustomButton";
import CustomRadio from "components/CustomRadio/CustomRadio";
import ImageUpload from "components/ImageUpload/imageUpload";
import NewProject from "examples/modal/NewProject/NewProject";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ViewDrawer from "examples/Drawers/ViewTableDrawer/index";
import DeleteModal from "examples/modal/deleteModal/deleteModal";
import BasicButton from "examples/NewDesign/CustomButton/BasicButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import FullScreenImageComponent from "components/ViewFullImage/ViewImage";

// Redux
import projectCreateThunk, {
  projectListThunk,
  locationCreateThunk,
  locationListByIdThunk,
  projectStringCreateThunk,
  projectStringListThunk,
  assetCreateThunk,
  assetListThunk,
  scopesCreateThunk,
  scopesThunk,
  activityCreateThunk,
  activityThunk,
  teamCreateThunk,
  memberCreateThunk,
  teamThunk,
  memberThunk,
  functionCreateThunk,
  functionListThunk,
  reportTypesList,
  projectsUpdateThunk,
  locationsUpdateThunk,
  projectStringsUpdateThunk,
  assetsUpdateThunk,
  scopesUpdateThunk,
  activitiesUpdateThunk,
  teamUpdateThunk,
  membersUpdateThunk,
  functionUpdateThunk,
  projectsDeleteThunk,
  locationsDeleteThunk,
  projectStringsDeleteThunk,
  assetsDeleteThunk,
  scopesDeleteThunk,
  activitiesDeleteThunk,
  teamsDeleteThunk,
  membersDeleteThunk,
  functionDeleteThunk,
  createCertificate,
  getProjectCertificate,
  deleteProjectCertificate,
  getFunctionProjectCertificate,
  createProjectDocument,
  projectDocumentList,
  projectDocumentUpdateThunk,
  projectDocumentDelete,
} from "redux/Thunks/FieldsData";
import { useDispatch } from "react-redux";
import configThunk from "redux/Thunks/Config";
import { useNavigate } from "react-router-dom";
import { openSnackbar } from "redux/Slice/Notification";
import UserListThunk from "redux/Thunks/UserManagement";
import getAllQuestions from "redux/Thunks/certificateQuestion";

// Utils
import Validators from "utils/Validations";
import pxToRem from "assets/theme/functions/pxToRem";
import Constants, { Icons, defaultData, Colors, ButtonTitles } from "utils/Constants";
import {
  generateCertificateList,
  paramCreater,
  numericFieldValidation,
  handleEmptyRequestBody,
} from "utils/methods/methods";

// Assets
import Pdf from "assets/images/pdf.svg";
import Frame from "assets/images/Frame.svg";

// 3rd party libraries
import moment from "moment";
import CustomAutoComeplete from "components/Dropdown/CustomAutoComeplete";

const cardList = [
  {
    cardTitle: "Location",
    content:
      "Create or Change Locations. A Location is a physical structure that is part of a string.",
    isVisible: true,
  },
  {
    cardTitle: "Project String",
    content: "Create or Change Strings. A String consists of one or more Locations and Cables.",
    isVisible: true,
  },
  {
    cardTitle: "Asset",
    content:
      "Create or Change Assets. An Asset connects locations, optionally as part of a string.",
    isVisible: true,
  },
  {
    cardTitle: "Team",
    content: "Create or Change Teams. A Team is assigned to a Project and consists of Members.",
    isVisible: true,
  },
  {
    cardTitle: "Scopes",
    content: "Create or Change Locations. A Scope consists of one or more activities.",
    isVisible: true,
  },
  {
    cardTitle: "Activity",
    content: "Create or Change Activities. A Activity is assigned to a Scope and Project.",
    isVisible: true,
  },
  {
    cardTitle: "Functions",
    content: "Add or Update Project Functions",
    isVisible: true,
  },
  {
    cardTitle: "Member",
    content: "Add or Update Members. A Project can have more than one Member.",
    isVisible: true,
  },
  {
    cardTitle: "Report Type",
    content: "Add or Update Report Type. A Report Type is assigned to a Project.",
    isVisible: true,
  },
  {
    cardTitle: "Project Certificate",
    content: "Add or Update Project Certificate.",
    isVisible: true,
  },
  {
    cardTitle: "Project Document",
    content: "Add or Update Project Documents.",
    isVisible: true,
  },
];

function SetupProject() {
  const [userList, setUserList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [lists, setLists] = useState({
    project: [],
    location: [],
    projectString: [],
    asset: [],
    scope: [],
    activity: [],
    team: [],
    member: [],
    function: [],
    reportType: [],
    certificateList: [],
    projectCertificateList: [],
    projectDocument: [],
  });
  const [dropdownData, setDropdownData] = useState({
    project: [],
    location: [],
    projectString: [],
    asset: [],
    scope: [],
    activity: [],
    team: [],
    filteredMembers: [],
    allMember: [],
    function: [],
    reportType: [],
  });
  const [loadingStatus, setLoadingStatus] = useState({
    project: Constants.PENDING,
    location: Constants.PENDING,
    projectString: Constants.PENDING,
    asset: Constants.PENDING,
    scope: Constants.PENDING,
    activity: Constants.PENDING,
    team: Constants.PENDING,
    member: Constants.PENDING,
    function: Constants.PENDING,
    certificateList: Constants.PENDING,
    projectCertificateList: Constants.PENDING,
    projectDocument: Constants.PENDING,
  });
  const [editLists, setEditLists] = useState({
    project: {},
    location: {},
    projectString: {},
    asset: {},
    scope: {},
    activity: {},
    team: {},
    member: {},
    function: {},
    reportType: {},
    projectDocument: {},
  });
  const [body, setBody] = useState({
    project: { status: "open" },
    location: {},
    projectString: {},
    asset: {},
    scope: {},
    activity: {},
    team: {},
    member: {},
    function: {},
    reportType: {},
    projectCertificate: {},
    projectDocument: {},
  });
  const [error, setError] = useState({
    project: {},
    location: {},
    projectString: {},
    asset: {},
    scope: {},
    activity: {},
    team: {},
    member: {},
    function: {},
    reportType: {},
    certificate: {},
    projectDocument: {},
  });
  const [searchTable, setSearchTable] = useState({
    project: "",
    location: "",
    projectString: "",
    asset: "",
    scope: "",
    activity: "",
    team: "",
    member: "",
    function: "",
    projectCertificate: "",
  });
  const [tablePagination, setTablePagination] = useState({
    page: 0,
    perPage: defaultData.PER_PAGE,
  });
  const [filters, setFilters] = useState([
    {
      inputLabel: "Search",
      list: [{ [Constants.MONGOOSE_ID]: "All", title: "All" }],
      selectedValue: "All",
      isLoading: false,
    },
  ]);
  const [deleteData, setDeleteData] = useState({ openDeleteModal: false, type: "", id: "" });
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const [openProjectList, setOpenProjectList] = useState(false);
  const [updatedDocument, setUpdatedDocument] = useState({});
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [modaltype, setModalType] = useState("New");
  const [loading, setLoading] = useState(false);
  const [sortState, setSortState] = useState({
    location: null,
    projectString: null,
    asset: null,
    scope: null,
    activity: null,
    team: null,
    member: null,
    function: null,
    projectCertificate: null,
  });
  const dispatch = useDispatch();
  const mongooseId = "_id";
  let debounceTimeout;
  const navigate = useNavigate();

  const [openNewModal, setOpenNewModal] = useState(false);
  const [openLocation, setOpenLocation] = useState(false);
  const [openString, setOpenString] = useState(false);
  const [openAsset, setOpenAsset] = useState(false);
  const [openTeam, setOpenTeam] = useState(false);
  const [openMember, setOpenMember] = useState(false);
  const [openScopes, setOpenScopes] = useState(false);
  const [openActivity, setOpenActivity] = useState(false);
  const [openProjectCertificate, setOpenProjectCertificate] = useState(false);
  const [openFunction, setOpenFunction] = useState(false);
  const [openProjectDocument, setOpenProjectDocument] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [spin, setSpin] = useState(false);
  const [fullScreenImage, setFullScreenImage] = useState(null);
  const [projectListedData, setProjectListedData] = useState([]);
  const [functionId, setFunctionId] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleProjectChange = (e) => setSelectedProjectId(e.target.value);

  const handleOpenProjectList = () => {
    setOpenProjectList({ right: true });
    handlePageChange(0);
  };

  const handleCloseProjectList = () => setOpenProjectList({ right: false });

  const handleError = (res, type) => {
    if (res.status === 400) {
      const temp = { ...error };
      switch (type) {
        case "Project":
          temp.project.title = Constants.PROJECT_DUPLICATE_ERROR;
          break;
        case "Location":
          temp.location.title = Constants.LOCATION_DUPLICATE_ERROR;
          break;
        case "ProjectString":
          temp.projectString.name = Constants.PROJECTSTRING_DUPLICATE_ERROR;
          break;
        case "Asset":
          temp.asset.cableName = Constants.ASSET_DUPLICATE_ERROR;
          break;
        case "Team":
          temp.team.teamsWfmName = Constants.TEAM_DUPLICATE_ERROR;
          break;
        case "Scope":
          temp.scope.name = Constants.SCOPE_DUPLICATE_ERROR;
          break;
        case "Activity":
          temp.activity.name = Constants.ACTIVITY_DUPLICATE_ERROR;
          break;
        case "Function":
          temp.function.functionName = Constants.FUNCTION_DUPLICATE_ERROR;
          break;
        default:
          break;
      }
      setError(temp);
    } else {
      dispatch(
        openSnackbar({
          message: Constants.SOMETHING_WENT_WRONG,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
    }
  };

  const handleOpenNewModal = (modal) => {
    if (modal === "Project") {
      setOpenNewModal(true);
    } else if (modal === "Location") {
      setOpenLocation(true);
    } else if (modal === "Project String") {
      setOpenString(true);
    } else if (modal === "Asset") {
      setOpenAsset(true);
    } else if (modal === "Scopes") {
      setOpenScopes(true);
    } else if (modal === "Activity") {
      setOpenActivity(true);
    } else if (modal === "Project Certificate") {
      setOpenProjectCertificate(true);
    } else if (modal === "Member") {
      setOpenMember(true);
    } else if (modal === "Team") {
      setOpenTeam(true);
    } else if (modal === "Functions") {
      setOpenFunction(true);
    } else if (modal === "Report Type") {
      navigate("/client/setting/setup-report", {
        state: { openNewReportType: true, projectId: selectedProjectId },
      });
    } else if (modal === "Project Document") {
      setOpenProjectDocument(true);
    }
  };

  const handleCloseNewModal = (modal) => {
    if (modal === "Project") {
      setOpenNewModal(false);
    } else if (modal === "Location") {
      setOpenLocation(false);
    } else if (modal === "ProjectString") {
      setOpenString(false);
    } else if (modal === "Asset") {
      setOpenAsset(false);
    } else if (modal === "Scope") {
      setOpenScopes(false);
    } else if (modal === "Activity") {
      setOpenActivity(false);
    } else if (modal === "Project Certificate") {
      setOpenProjectCertificate(false);
      setSelectedItems([]);
    } else if (modal === "Member") {
      setOpenMember(false);
    } else if (modal === "Team") {
      setOpenTeam(false);
    } else if (modal === "Function") {
      setOpenFunction(false);
      setSelectedItems([]);
    } else if (modal === "Project Document") {
      setOpenProjectDocument(false);
    }
    setModalType("New");
    const tempBody = { ...body };
    Object.keys(tempBody).forEach((key) => {
      tempBody[key] = {};
    });
    tempBody.project = { status: "open" };
    setBody(tempBody);
    setError(tempBody);
  };

  const handleCloseDeleteModal = () => setDeleteData({ openDeleteModal: false, type: "", id: "" });
  const handleDelete = (type, id, deletefunctionId) => {
    setDeleteData({ openDeleteModal: true, type, id });
    setFunctionId(deletefunctionId);
  };

  const handleOpenDeleteModal = async () => {
    const { type, id } = deleteData;
    if (type === "Project") {
      const res = await dispatch(projectsDeleteThunk(id));
      const newProjectList = await dispatch(projectListThunk());
      setLists({
        ...lists,
        project: newProjectList.payload.data,
      });
      await dispatch(
        openSnackbar({
          message: res.payload.message,
          notificationType: Constants.NOTIFICATION_SUCCESS,
        })
      );
    } else if (type === "Location") {
      const res = await dispatch(locationsDeleteThunk(id));
      const newLocationList = await dispatch(
        locationListByIdThunk({ id: selectedProjectId, name: searchTable.location })
      );
      setLists({
        ...lists,
        location: newLocationList.payload.data,
      });
      await dispatch(
        openSnackbar({
          message: res.payload.message,
          notificationType: Constants.NOTIFICATION_SUCCESS,
        })
      );
    } else if (type === "ProjectString") {
      const res = await dispatch(projectStringsDeleteThunk(id));
      const newProjectStringList = await dispatch(
        projectStringListThunk({ id: selectedProjectId, name: searchTable.projectString })
      );
      setLists({
        ...lists,
        projectString: newProjectStringList.payload.data,
      });
      await dispatch(
        openSnackbar({
          message: res.payload.message,
          notificationType: Constants.NOTIFICATION_SUCCESS,
        })
      );
    } else if (type === "Asset") {
      const res = await dispatch(assetsDeleteThunk(id));
      const newAssetList = await dispatch(
        assetListThunk({ id: selectedProjectId, name: searchTable.asset })
      );
      setLists({
        ...lists,
        asset: newAssetList.payload.data,
      });
      await dispatch(
        openSnackbar({
          message: res.payload.message,
          notificationType: Constants.NOTIFICATION_SUCCESS,
        })
      );
    } else if (type === "Scope") {
      const res = await dispatch(scopesDeleteThunk(id));
      const newScopeList = await dispatch(
        scopesThunk({ id: selectedProjectId, name: searchTable.scope })
      );
      setLists({
        ...lists,
        scope: newScopeList.payload.data.data,
      });
      await dispatch(
        openSnackbar({
          message: res.payload.message,
          notificationType: Constants.NOTIFICATION_SUCCESS,
        })
      );
    } else if (type === "Activity") {
      const res = await dispatch(activitiesDeleteThunk(id));
      const newActivityList = await dispatch(
        activityThunk({ id: selectedProjectId, name: searchTable.activity })
      );
      setLists({
        ...lists,
        activity: newActivityList.payload.data,
      });
      await dispatch(
        openSnackbar({
          message: res.payload.message,
          notificationType: Constants.NOTIFICATION_SUCCESS,
        })
      );
    } else if (type === "Team") {
      const res = await dispatch(teamsDeleteThunk(id));
      const newTeamList = await dispatch(
        teamThunk({ id: selectedProjectId, sortOrder: sortState.team, name: searchTable.team })
      );
      setLists({
        ...lists,
        team: newTeamList.payload.data,
      });
      await dispatch(
        openSnackbar({
          message: res.payload.message,
          notificationType: Constants.NOTIFICATION_SUCCESS,
        })
      );
    } else if (type === "Member") {
      const res = await dispatch(membersDeleteThunk(id));
      const updatedItem = res.payload.data;
      const updatedList = lists.member.filter(
        (item) => item[mongooseId] !== updatedItem[mongooseId]
      );
      setLists((prev) => ({
        ...prev,
        member: updatedList,
      }));
      await dispatch(
        openSnackbar({
          message: res.payload.message,
          notificationType: Constants.NOTIFICATION_SUCCESS,
        })
      );
      setRefresh(!refresh);
    } else if (type === "Project Certificate") {
      const paramObj = {
        project: selectedProjectId,
        function: functionId,
      };
      const paramData = paramCreater(paramObj);
      const res = await dispatch(deleteProjectCertificate({ id, paramData }));
      if (res.payload.status === 200) {
        await dispatch(
          openSnackbar({
            message: Constants.CERTIFICATE_REMOVED_SUCCESS,
            notificationType: Constants.NOTIFICATION_SUCCESS,
          })
        );
        const updatedItem = res.payload.data.data;
        const updatedList = projectListedData.filter(
          (item) => item[mongooseId] !== updatedItem[mongooseId]
        );
        const certificateList = generateCertificateList(updatedList);
        setProjectListedData(certificateList);
      } else if (res.payload.status === 400) {
        await dispatch(
          openSnackbar({
            message: Constants.CERTIFICATE_NOT_EXIST_ERROR,
            notificationType: Constants.NOTIFICATION_ERROR,
          })
        );
      } else {
        await dispatch(
          openSnackbar({
            message: Constants.SOMETHING_WENT_WRONG,
            notificationType: Constants.NOTIFICATION_ERROR,
          })
        );
      }
      setRefresh(!refresh);
    } else if (type === "Project Document") {
      const res = await dispatch(projectDocumentDelete(id));
      if (res.payload.status === 200) {
        await dispatch(
          openSnackbar({
            message: Constants.PROJECT_DOCUMENT_DELETE_SUCCESS,
            notificationType: Constants.NOTIFICATION_SUCCESS,
          })
        );
        const updatedItem = res.payload.data.data;
        const updatedList = lists.projectDocument.filter(
          (item) => item[mongooseId] !== updatedItem[mongooseId]
        );
        setLists((prev) => ({
          ...prev,
          projectDocument: updatedList,
        }));
        setRefresh(!refresh);
      } else {
        await dispatch(
          openSnackbar({
            message: Constants.SOMETHING_WENT_WRONG,
            notificationType: Constants.NOTIFICATION_ERROR,
          })
        );
      }
    } else {
      const res = await dispatch(functionDeleteThunk(id));
      const newFunctionList = await dispatch(
        functionListThunk({
          id: selectedProjectId,
          sortOrder: sortState.function,
          name: searchTable.function,
        })
      );
      setLists({
        ...lists,
        function: newFunctionList.payload.data,
      });
      await dispatch(
        openSnackbar({
          message: res.payload.message,
          notificationType: Constants.NOTIFICATION_SUCCESS,
        })
      );
      setRefresh(!refresh);
    }
    setTablePagination({ ...tablePagination, page: tablePagination.page + 1 });
    handleCloseDeleteModal();
    await dispatch(configThunk());
  };

  const handleImageFullView = (imageUrl) => {
    setFullScreenImage(imageUrl);
  };
  const handleCloseFullView = () => {
    setFullScreenImage(null);
  };

  const handleSort = async (thunkAction, listKey) => {
    let newSortState;

    if (!sortState[listKey]) {
      newSortState = "asc";
    } else if (sortState[listKey] === "asc") {
      newSortState = "desc";
    } else {
      newSortState = null;
    }

    setSortState({
      ...sortState,
      [listKey]: newSortState,
    });

    const searchTerm = filters[0].selectedValue === "All" ? "" : filters[0].selectedValue.trim();
    const data = {
      id: selectedProjectId,
      sortOrder: newSortState || "",
      name: searchTerm,
    };

    setLoadingStatus({
      ...loadingStatus,
      [listKey]: Constants.PENDING,
    });
    const res = await dispatch(thunkAction(data));
    setLoadingStatus({
      ...loadingStatus,
      [listKey]: Constants.FULFILLED,
    });
    setLists({
      ...lists,
      [listKey]: listKey === "scope" ? res.payload.data.data : res.payload.data,
    });
  };

  const { projectColumns, projectRows } = Projects(
    lists.project,
    handleOpenNewModal,
    setModalType,
    editLists,
    setEditLists,
    handleDelete
  );

  const { locationColumns, locationRows } = Locationdata(
    lists.location,
    handleOpenNewModal,
    setModalType,
    editLists,
    setEditLists,
    handleDelete,
    () => handleSort(locationListByIdThunk, "location"),
    sortState.location
  );

  const { ProjectStringColumns, ProjectStringRows } = ProjectString(
    lists.projectString,
    handleOpenNewModal,
    setModalType,
    editLists,
    setEditLists,
    handleDelete,
    () => handleSort(projectStringListThunk, "projectString"),
    sortState.projectString
  );

  const { AssetColumns, AssetRows } = Asset(
    lists.asset,
    handleOpenNewModal,
    setModalType,
    editLists,
    setEditLists,
    handleDelete,
    () => handleSort(assetListThunk, "asset"),
    sortState.asset
  );

  const { teamColumns, teamRows } = Teamdata(
    lists.team,
    handleOpenNewModal,
    setModalType,
    editLists,
    setEditLists,
    handleDelete,
    () => handleSort(teamThunk, "team"),
    sortState.team
  );

  const { membersColumns, membersRows } = Members(
    lists.member,
    handleOpenNewModal,
    setModalType,
    editLists,
    setEditLists,
    handleDelete,
    () => handleSort(memberThunk, "member"),
    sortState.member
  );

  const { ScopesColumns, ScopesRows } = Scopes(
    lists.scope,
    handleOpenNewModal,
    setModalType,
    editLists,
    setEditLists,
    handleDelete,
    () => handleSort(scopesThunk, "scope"),
    sortState.scope
  );

  const { ActivitiesColumns, ActivitiesRows } = Activities(
    lists.activity,
    handleOpenNewModal,
    setModalType,
    editLists,
    setEditLists,
    handleDelete,
    () => handleSort(activityThunk, "activity"),
    sortState.activity
  );

  const { ProjectDocumentColumns, ProjectDocumentRows } = ProjectDocumentData(
    lists.projectDocument,
    handleOpenNewModal,
    setModalType,
    editLists,
    setEditLists,
    handleDelete,
    handleImageFullView
  );

  useEffect(() => {
    const certificateList = generateCertificateList(lists.projectCertificateList);
    setProjectListedData(certificateList);
  }, [lists.projectCertificateList]);

  const { ProjectCertificateColumns, ProjectCertificateRows } = ProjectCertificateData(
    projectListedData,
    handleDelete
  );

  const { FunctionsColumns, FunctionsRows } = Functions(
    lists.function,
    handleOpenNewModal,
    setModalType,
    editLists,
    setEditLists,
    handleDelete,
    setSelectedItems,
    () => handleSort(functionListThunk, "function"),
    sortState.function
  );

  const useDrawerState = (initialState = false) => {
    const [state, setState] = useState({ right: initialState });
    const handleOpen = () => {
      setState({ right: true });
      handlePageChange(0);
    };
    const handleClose = () => {
      setState({ right: false });
      setFilters((prev) => [
        {
          ...prev[0],
          selectedValue: "All",
          list: [{ [Constants.MONGOOSE_ID]: "All", title: "All" }],
          isLoading: false,
        },
        ...prev.slice(1),
      ]);
      setSearchTable({
        project: "",
        location: "",
        projectString: "",
        asset: "",
        scope: "",
        activity: "",
        team: "",
        member: "",
        function: "",
        projectCertificate: "",
      });
      setRefresh(!refresh);
    };
    return [state, handleOpen, handleClose];
  };
  const [openLocationData, handleOpenLocationData, handleCloseLocationData] = useDrawerState();
  const [openProjectStringData, handleOpenProjectStringData, handleCloseProjectStringData] =
    useDrawerState();
  const [openAssetData, handleOpenAssetData, handleCloseAssetData] = useDrawerState();
  const [openTeamsData, handleOpenTeamsData, handleCloseTeamsData] = useDrawerState();
  const [openMemberData, handleOpenMemberData, handleCloseMemberData] = useDrawerState();
  const [openScopesData, handleOpenScopesData, handleCloseScopesData] = useDrawerState();
  const [openActivitiesData, handleOpenActivitiesData, handleCloseActivitiesData] =
    useDrawerState();
  const [
    openProjectCertificateData,
    handleOpenProjectCertificateData,
    handleCloseProjectCertificateData,
  ] = useDrawerState();
  const [openFunctionsData, handleOpenFunctionsData, handleCloseFunctionsData] = useDrawerState();
  const [openProjectDocumentData, handleOpenProjectDocumentData, handleCloseProjectDocumentData] =
    useDrawerState();

  const handleOpenProjectModal = (modal) => {
    if (modal === "Location") {
      handleOpenLocationData({ right: true });
    } else if (modal === "Project String") {
      handleOpenProjectStringData({ right: true });
    } else if (modal === "Asset") {
      handleOpenAssetData({ right: true });
    } else if (modal === "Team") {
      handleOpenTeamsData({ right: true });
    } else if (modal === "Member") {
      handleOpenMemberData({ right: true });
    } else if (modal === "Scopes") {
      handleOpenScopesData({ right: true });
    } else if (modal === "Activity") {
      handleOpenActivitiesData({ right: true });
    } else if (modal === "Project Certificate") {
      handleOpenProjectCertificateData({ right: true });
    } else if (modal === "Functions") {
      handleOpenFunctionsData({ right: true });
    } else if (modal === "Report Type") {
      navigate("/client/setting/setup-report", {
        state: { openNewReportType: false, projectId: selectedProjectId },
      });
    } else if (modal === "Project Document") {
      handleOpenProjectDocumentData({ right: true });
    }
  };

  useEffect(() => {
    (async () => {
      const res = await dispatch(projectListThunk());
      setLists({ ...lists, project: res.payload.data });
      setLoadingStatus({ ...loadingStatus, project: Constants.FULFILLED });
      const normalUser = await dispatch(UserListThunk());

      const users = normalUser.payload.data.data.usersData.filter(
        (user) =>
          user.role?.title !== defaultData.ADMIN_ROLE &&
          user.role?.title !== defaultData.SUPER_ADMIN_ROLE &&
          user?.isActive !== false
      );

      setUserList(users);
    })();
  }, [refresh]);

  useEffect(() => {
    (async () => {
      if (selectedProjectId !== "") {
        const paramData = {
          project: selectedProjectId,
        };
        Object.keys(paramData).forEach((key) => {
          if (paramData[key] === "") {
            delete paramData[key];
          }
        });
        const data = new URLSearchParams(paramData);
        const queryParams = {
          id: selectedProjectId,
        };
        const [
          locationRes,
          projectStringRes,
          assetRes,
          scopeRes,
          activityRes,
          teamRes,
          memberRes,
          functionRes,
          reportTypeRes,
          certificateRes,
          certificateListRes,
          projectDocumentRes,
        ] = await Promise.all([
          dispatch(
            locationListByIdThunk({
              ...queryParams,
              sortOrder: sortState.location,
              name: searchTable.location,
            })
          ),
          dispatch(
            projectStringListThunk({
              ...queryParams,
              sortOrder: sortState.projectString,
              name: searchTable.projectString,
            })
          ),
          dispatch(
            assetListThunk({
              ...queryParams,
              sortOrder: sortState.asset,
              name: searchTable.projectString,
            })
          ),
          dispatch(
            scopesThunk({ ...queryParams, sortOrder: sortState.scope, name: searchTable.scope })
          ),
          dispatch(
            activityThunk({
              ...queryParams,
              sortOrder: sortState.activity,
              name: searchTable.activity,
            })
          ),
          dispatch(
            teamThunk({ ...queryParams, sortOrder: sortState.team, name: searchTable.team })
          ),
          dispatch(
            memberThunk({ ...queryParams, sortOrder: sortState.member, name: searchTable.member })
          ),
          dispatch(
            functionListThunk({
              ...queryParams,
              sortOrder: sortState.function,
              name: searchTable.function,
            })
          ),
          dispatch(reportTypesList(data)),
          dispatch(getAllQuestions()),
          dispatch(getProjectCertificate({ ...queryParams, name: searchTable.projectCertificate })),
          dispatch(projectDocumentList(selectedProjectId)),
        ]);
        const dropdownFormat = {
          [Constants.MONGOOSE_ID]: "",
          title: "",
        };
        const tempScope = scopeRes.payload.data.data.map((item) => {
          const temp = { ...dropdownFormat };
          temp[Constants.MONGOOSE_ID] = item?.[Constants.MONGOOSE_ID];
          temp.title = item?.name;
          return temp;
        });
        const tempAllMembers = userList.map((item) => {
          const temp = { ...dropdownFormat };
          temp[Constants.MONGOOSE_ID] = item?.[Constants.MONGOOSE_ID];
          temp.title = `${item?.callingName ? item?.callingName : item?.firstName} ${
            item?.lastName
          }`;
          return temp;
        });

        const tempFilteredMembers = tempAllMembers.filter((item) => {
          const isAssigned = memberRes.payload.data.some(
            (user) =>
              user.user?.[Constants.MONGOOSE_ID] === item[Constants.MONGOOSE_ID] &&
              user.deletedAt === null
          );
          return !isAssigned;
        });

        const tempFunction = functionRes.payload.data.map((item) => {
          const temp = { ...dropdownFormat };
          temp[Constants.MONGOOSE_ID] = item?.[Constants.MONGOOSE_ID];
          temp.title = item.functionName;
          return temp;
        });
        const tempProjectString = projectStringRes.payload.data.map((item) => {
          const temp = { ...dropdownFormat };
          temp[Constants.MONGOOSE_ID] = item?.[Constants.MONGOOSE_ID];
          temp.title = item.name;
          return temp;
        });
        setDropdownData({
          ...dropdownData,
          function: tempFunction,
          filteredMembers: tempFilteredMembers,
          allMember: tempAllMembers,
          scope: tempScope,
          projectString: tempProjectString,
        });
        setLists({
          ...lists,
          location: locationRes.payload.data,
          projectString: projectStringRes.payload.data,
          asset: assetRes.payload.data,
          scope: scopeRes.payload.data.data,
          activity: activityRes.payload.data,
          team: teamRes.payload.data,
          member: memberRes.payload.data,
          function: functionRes.payload.data,
          reportType: reportTypeRes.payload.data,
          certificateList: certificateRes.payload.data.data,
          projectCertificateList: certificateListRes.payload.data.data,
          projectDocument: projectDocumentRes.payload.data.data,
        });
        setLoadingStatus({
          ...loadingStatus,
          location: Constants.FULFILLED,
          projectString: Constants.FULFILLED,
          asset: Constants.FULFILLED,
          scope: Constants.FULFILLED,
          activity: Constants.FULFILLED,
          team: Constants.FULFILLED,
          member: Constants.FULFILLED,
          function: Constants.FULFILLED,
          reportType: Constants.FULFILLED,
          certificateList: Constants.FULFILLED,
          projectCertificateList: Constants.FULFILLED,
          projectDocument: Constants.FULFILLED,
        });
      }
    })();
  }, [selectedProjectId, refresh]);

  useEffect(() => {
    setBody((prevBody) => ({
      ...prevBody,
      projectCertificate: {
        certificates: selectedItems,
        project: selectedProjectId,
        function: prevBody?.projectCertificate?.function,
      },
    }));
  }, [selectedItems]);

  useEffect(() => {
    const updatedBody = { ...body };

    if (Object.keys(editLists.project).length !== 0 && editLists.project.constructor === Object) {
      updatedBody.project = {
        title: editLists.project.title,
        projectNumber: editLists.project.projectNumber,
        client: editLists.project.client,
        status: editLists.project.status,
      };
    }
    if (Object.keys(editLists.location).length !== 0 && editLists.location.constructor === Object) {
      updatedBody.location = {
        title: editLists.location.title,
        latitude: editLists.location.latitude,
        longitude: editLists.location.longitude,
      };
    }
    if (
      Object.keys(editLists.projectString).length !== 0 &&
      editLists.projectString.constructor === Object
    ) {
      updatedBody.projectString = {
        name: editLists.projectString.name,
        fromLocation: editLists.projectString?.fromLocation[Constants.MONGOOSE_ID],
        toLocation: editLists.projectString.toLocation[Constants.MONGOOSE_ID],
      };
    }
    if (Object.keys(editLists.asset).length !== 0 && editLists.asset.constructor === Object) {
      updatedBody.asset = {
        cableName: editLists?.asset?.cableName,
        fromLocation: editLists?.asset?.fromLocation?.[Constants.MONGOOSE_ID],
        toLocation: editLists?.asset?.toLocation?.[Constants.MONGOOSE_ID],
        manufacturer: editLists?.asset?.manufacturer,
        typeMm2: editLists?.asset?.typeMm2,
        string: editLists?.asset?.string?.[Constants.MONGOOSE_ID],
      };
    }
    if (Object.keys(editLists.scope).length !== 0 && editLists.scope.constructor === Object) {
      updatedBody.scope = {
        name: editLists.scope.name,
        sortOrder: editLists.scope.sortOrder,
      };
    }
    if (Object.keys(editLists.activity).length !== 0 && editLists.activity.constructor === Object) {
      updatedBody.activity = {
        name: editLists?.activity?.name,
        scopeId: editLists?.activity?.scopeId?.[Constants.MONGOOSE_ID],
        weight: editLists?.activity?.weight.toString(),
      };
    }
    if (Object.keys(editLists.team).length !== 0 && editLists.team.constructor === Object) {
      updatedBody.team = {
        teamsWfmName: editLists.team.teamsWfmName,
      };
    }
    if (Object.keys(editLists.member).length !== 0 && editLists.member.constructor === Object) {
      updatedBody.member = {
        user: editLists?.member?.user?.[mongooseId],
        function: editLists?.member?.function?.[mongooseId],
      };
    }
    if (Object.keys(editLists.function).length !== 0 && editLists.function.constructor === Object) {
      updatedBody.function = {
        functionName: editLists.function.functionName,
        sortOrder: editLists.function.sortOrder,
      };
    }
    if (
      Object.keys(editLists.projectDocument).length !== 0 &&
      editLists.projectDocument.constructor === Object
    ) {
      updatedBody.projectDocument = {
        title: editLists.projectDocument.title,
        documentNumber: editLists.projectDocument.documentNumber,
        type: editLists.projectDocument.type,
        document: editLists.projectDocument.document,
      };
    }

    setBody(updatedBody);
  }, [editLists]);

  const groupData = projectListedData?.reduce((acc, item) => {
    const key = item?.function?.functionName;
    const updatedAcc = { ...acc };

    if (!updatedAcc[key]) {
      updatedAcc[key] = [];
    }
    updatedAcc[key].push(item);
    return updatedAcc;
  }, {});

  const handleChange = async (bodyName, e) => {
    if (e.target) {
      const { name, value } = e.target;
      setBody((prevBody) => ({
        ...prevBody,
        [bodyName]: {
          ...prevBody[bodyName],
          [name]: value,
          project: selectedProjectId,
        },
      }));
      if (bodyName === "projectCertificate") {
        setSpin(true);
        const res = await dispatch(getFunctionProjectCertificate({ selectedProjectId, value }));
        setSelectedItems(
          Array.from(
            res?.payload?.data.data.length === 0 ? [] : res?.payload?.data?.data[0].certificates,
            (item) => item[mongooseId]
          )
        );
        setSpin(false);
      }
    }
  };

  const handleMemberChange = (bodyName, e) => {
    const { name, value } = e.target;
    const options =
      modaltype === "Update" && openMember ? dropdownData.allMember : dropdownData.filteredMembers;
    if (options.some((option) => option[Constants.MONGOOSE_ID] === value)) {
      setBody((prevBody) => ({
        ...prevBody,
        [bodyName]: {
          ...prevBody[bodyName],
          [name]: value,
          project: selectedProjectId,
        },
      }));
    }
  };

  const handleCheckboxChange = (bodyName, e, type) => {
    setBody({
      ...body,
      [bodyName]: {
        ...body[bodyName],
        [e.target.name]: e.target.checked,
        ...(type === "New" && { project: selectedProjectId }),
      },
    });
  };

  const handleImageChange = (bodyName, imageValues, name) => {
    const imageInfoArray = {
      url: imageValues[0].url,
      name: imageValues[0].name,
    };
    setBody((prevBody) => ({
      ...prevBody,
      [bodyName]: {
        ...prevBody[bodyName],
        [name]: imageInfoArray,
        project: selectedProjectId,
      },
    }));
    setUpdatedDocument(imageInfoArray);
  };

  const handleImageCancel = (fieldName, updatedImageUrl, bodyName) => {
    const newImageUrlArray = updatedImageUrl;

    const filteredImageUrlArray = newImageUrlArray.filter((img) => img.url !== "Frame");

    setBody((prevBody) => ({
      ...prevBody,
      [bodyName]: {
        ...prevBody[bodyName],
        [fieldName]: filteredImageUrlArray,
      },
    }));
  };

  const validation = (type) => {
    const {
      project,
      location,
      projectString,
      asset,
      team,
      scope,
      activity,
      member,
      function: bodyFunction,
      projectCertificate,
      projectDocument,
    } = body;
    const tempError = {};

    const validateField = (fieldName, value, validatorType) => {
      const fieldValue = Validators.validate(
        validatorType,
        value || "",
        null,
        null,
        null,
        fieldName || ""
      );
      if (fieldValue !== "") tempError[fieldName] = fieldValue;
    };

    switch (type) {
      case "Project":
        validateField("title", project?.title, "basic");
        validateField("projectNumber", project?.projectNumber, "basic2");
        validateField("client", project?.client, "basic");
        validateField("status", project?.status, "basic");
        break;
      case "Location":
        validateField("title", location?.title, "basic");
        validateField("latitude", location?.latitude, "latlong");
        validateField("longitude", location?.longitude, "latlong");
        break;
      case "ProjectString": {
        const { name, fromLocation, toLocation } = projectString || {};
        validateField("name", name, "basic");
        validateField("fromLocation", fromLocation, "basic2");
        validateField("toLocation", toLocation, "basic2");
        break;
      }
      case "Asset": {
        const {
          cableName,
          fromLocation: assetFromLocation,
          toLocation: assetToLocation,
        } = asset || {};
        validateField("cableName", cableName, "basic2");
        validateField("fromLocation", assetFromLocation, "basic2");
        validateField("toLocation", assetToLocation, "basic2");
        break;
      }
      case "Team":
        validateField("teamsWfmName", team?.teamsWfmName, "basic");
        break;
      case "Scope": {
        const { name: scopeName, sortOrder } = scope || {};
        validateField("name", scopeName, "basic");
        validateField("sortOrder", sortOrder?.toString(), "basic2");
        break;
      }
      case "Activity": {
        const { name: activityName, scopeId, weight } = activity || {};
        validateField("name", activityName, "basic");
        validateField("scopeId", scopeId, "basic2");
        validateField("weight", weight, "basic2");
        if (weight < 0) tempError.weight = Constants.INVALID_VALUE;
        break;
      }
      case "Member":
        validateField("user", member?.user, "basic2");
        break;
      case "Function":
        validateField("functionName", bodyFunction?.functionName, "basic");
        validateField("sortOrder", bodyFunction?.sortOrder?.toString(), "basic2");
        break;
      case "Project Certificate":
        if (selectedItems.length === 0) tempError.certificates = Constants.REQUIRED;
        validateField("function", projectCertificate?.function, "basic2");
        break;
      case "Project Document": {
        validateField("title", projectDocument?.title, "basic");
        validateField("documentNumber", projectDocument?.documentNumber, "basic2");
        validateField("type", projectDocument?.type, "basic2");
        if (modaltype === "New") {
          validateField("document", projectDocument?.document?.toString(), "basic");
        }
        break;
      }
      default:
        break;
    }

    const isValid = Object.keys(tempError).length === 0;
    setError({
      ...error,
      [type.substring(0, 1).toLowerCase() + type.substring(1)]: tempError,
    });
    return isValid;
  };

  const handleReset = () => {
    const tempBody = { ...body };
    Object.keys(tempBody).forEach((key) => {
      tempBody[key] = {};
    });
    tempBody.project = { status: "open" };
    setBody(tempBody);
    setError(tempBody);
  };

  const handleCreate = async (type) => {
    setLoading(true);
    let isVAlidOnServer = true;
    const isValid = validation(type);
    if (type === "Project" && isValid) {
      setDisableSubmit(true);
      const res = await dispatch(projectCreateThunk(body.project));
      setDisableSubmit(false);
      if (res.payload.status === 200) {
        setOpenNewModal(false);
        await dispatch(
          openSnackbar({
            message: Constants.PROJECT_CREATE_SUCCESS,
            notificationType: Constants.NOTIFICATION_SUCCESS,
          })
        );
        const newProjectList = await dispatch(projectListThunk());
        setLists({
          ...lists,
          project: newProjectList.payload.data,
        });
      } else {
        handleError(res.payload, type);
        setDisableSubmit(false);
        isVAlidOnServer = false;
      }
    } else if (type === "Location" && isValid) {
      setDisableSubmit(true);
      const res = await dispatch(locationCreateThunk(body.location));
      setDisableSubmit(false);
      if (res.payload.status === 200) {
        setOpenLocation(false);
        await dispatch(
          openSnackbar({
            message: Constants.LOCATION_CREATE_SUCCESS,
            notificationType: Constants.NOTIFICATION_SUCCESS,
          })
        );
        const newLocationList = await dispatch(locationListByIdThunk(selectedProjectId));

        setLists({
          ...lists,
          location: newLocationList.payload.data,
        });
      } else {
        handleError(res.payload, type);
        setDisableSubmit(false);
        isVAlidOnServer = false;
      }
    } else if (type === "ProjectString" && isValid) {
      setDisableSubmit(true);
      const res = await dispatch(projectStringCreateThunk(body.projectString));
      setDisableSubmit(false);
      if (res.payload.status === 200) {
        setOpenString(false);
        await dispatch(
          openSnackbar({
            message: Constants.PROJECTSTRING_CREATE_SUCCESS,
            notificationType: Constants.NOTIFICATION_SUCCESS,
          })
        );
        const newProjectStringList = await dispatch(projectStringListThunk(selectedProjectId));
        setLists({
          ...lists,
          projectString: newProjectStringList.payload.data,
        });
        setRefresh(!refresh);
      } else {
        handleError(res.payload, type);
        setDisableSubmit(false);
        isVAlidOnServer = false;
      }
    } else if (type === "Asset" && isValid) {
      setDisableSubmit(true);
      body.asset = handleEmptyRequestBody(body);
      const res = await dispatch(assetCreateThunk(body.asset));
      setDisableSubmit(false);
      if (res.payload.status === 200) {
        setOpenAsset(false);
        handleReset();
        await dispatch(
          openSnackbar({
            message: Constants.ASSET_CREATE_SUCCESS,
            notificationType: Constants.NOTIFICATION_SUCCESS,
          })
        );
        const newAssetList = await dispatch(assetListThunk(selectedProjectId));
        setLists({
          ...lists,
          asset: newAssetList.payload.data,
        });
      } else {
        handleError(res.payload, type);
        setDisableSubmit(false);
        isVAlidOnServer = false;
      }
    } else if (type === "Scope" && isValid) {
      setDisableSubmit(true);
      const res = await dispatch(scopesCreateThunk(body.scope));
      setDisableSubmit(false);
      if (res.payload.status === 200) {
        setOpenScopes(false);
        await dispatch(
          openSnackbar({
            message: Constants.SCOPE_CREATE_SUCCESS,
            notificationType: Constants.NOTIFICATION_SUCCESS,
          })
        );
        const newScopeList = await dispatch(scopesThunk(selectedProjectId));
        setLists({
          ...lists,
          scope: newScopeList.payload.data.data,
        });
        setRefresh(!refresh);
      } else {
        handleError(res.payload, type);
        setDisableSubmit(false);
        isVAlidOnServer = false;
      }
    } else if (type === "Activity" && isValid) {
      setDisableSubmit(true);
      const res = await dispatch(activityCreateThunk(body.activity));
      setDisableSubmit(false);
      if (res.payload.status === 200) {
        setOpenActivity(false);
        await dispatch(
          openSnackbar({
            message: Constants.ACTIVITY_CREATE_SUCCESS,
            notificationType: Constants.NOTIFICATION_SUCCESS,
          })
        );
        const newActivityList = await dispatch(activityThunk(selectedProjectId));
        setLists({
          ...lists,
          activity: newActivityList.payload.data,
        });
      } else {
        handleError(res.payload, type);
        setDisableSubmit(false);
        isVAlidOnServer = false;
      }
    } else if (type === "Team" && isValid) {
      setDisableSubmit(true);
      const res = await dispatch(teamCreateThunk(body.team));
      setDisableSubmit(false);
      if (res.payload.status === 200) {
        setOpenTeam(false);
        await dispatch(
          openSnackbar({
            message: Constants.TEAM_CREATE_SUCCESS,
            notificationType: Constants.NOTIFICATION_SUCCESS,
          })
        );
        const newTeamList = await dispatch(teamThunk(selectedProjectId));
        setLists({
          ...lists,
          team: newTeamList.payload.data,
        });
      } else {
        handleError(res.payload, type);
        setDisableSubmit(false);
        isVAlidOnServer = false;
      }
    } else if (type === "Member" && isValid) {
      setDisableSubmit(true);
      const res = await dispatch(memberCreateThunk(body.member));
      if (res.payload.status === 200) {
        setDisableSubmit(false);
        setOpenMember(false);
        await dispatch(
          openSnackbar({
            message: res.payload.data.message,
            notificationType: Constants.NOTIFICATION_SUCCESS,
          })
        );
        setRefresh(!refresh);
      } else if (res.payload.status === 400) {
        const tempError = {};
        tempError.user = res.payload.data.message;
        setError({ ...error, member: tempError });
        setDisableSubmit(false);
        isVAlidOnServer = false;
      }
    } else if (type === "Function" && isValid) {
      setDisableSubmit(true);
      const res = await dispatch(functionCreateThunk(body.function));
      setDisableSubmit(false);
      if (res.payload.status === 200) {
        await dispatch(
          openSnackbar({
            message: Constants.FUNCTION_CREATE_SUCCESS,
            notificationType: Constants.NOTIFICATION_SUCCESS,
          })
        );
        setOpenFunction(false);
        setSelectedItems([]);
        setRefresh(!refresh);
      } else {
        handleError(res.payload, type);
        setDisableSubmit(false);
        isVAlidOnServer = false;
      }
    } else if (type === "Project Certificate" && isValid) {
      setDisableSubmit(true);
      const res = await dispatch(
        createCertificate({
          project: body.projectCertificate.project,
          certificates: body.projectCertificate.certificates,
          functionId: body.projectCertificate.function,
        })
      );
      setDisableSubmit(false);
      if (res.payload.status === 200) {
        await dispatch(
          openSnackbar({
            message: Constants.CERTIFICATE_ADDED_SUCCESS,
            notificationType: Constants.NOTIFICATION_SUCCESS,
          })
        );
        handleCloseNewModal(type);
      } else {
        await dispatch(
          openSnackbar({
            message: Constants.SOMETHING_WENT_WRONG,
            notificationType: Constants.NOTIFICATION_ERROR,
          })
        );
      }
      setSelectedItems([]);
      setRefresh(!refresh);
    } else if (type === "Project Document" && isValid) {
      setDisableSubmit(true);
      const trimmedTitle = body.projectDocument.title.trim();
      const trimmedNumber = body.projectDocument.documentNumber.trim();
      const res = await dispatch(
        createProjectDocument({
          ...body.projectDocument,
          title: trimmedTitle,
          documentNumber: trimmedNumber,
        })
      );
      setDisableSubmit(false);
      if (res.payload.status === 200) {
        await dispatch(
          openSnackbar({
            message: Constants.PROJECT_DOCUMENT_CREATE_SUCCESS,
            notificationType: Constants.NOTIFICATION_SUCCESS,
          })
        );
        setOpenProjectDocument(false);
        setSelectedItems([]);
        setRefresh(!refresh);
        setUpdatedDocument({});
      } else {
        handleError(res.payload, type);
        setDisableSubmit(false);
        isVAlidOnServer = false;
      }
    }
    setLoading(false);
    setModalType("New");
    if (isVAlidOnServer && isValid) {
      handleReset();
      await dispatch(configThunk());
    }
  };
  const handleUpdate = async (type) => {
    setLoading(true);
    let isVAlidOnServer = true;
    const isValid = validation(type);
    if (type === "Project" && isValid) {
      const res = await dispatch(
        projectsUpdateThunk({ body: body.project, id: editLists.project[mongooseId] })
      );
      if (res.payload.status === 200) {
        setOpenNewModal(false);
        await dispatch(
          openSnackbar({
            message: Constants.PROJECT_UPDATE_SUCCESS,
            notificationType: Constants.NOTIFICATION_SUCCESS,
          })
        );
        const updatedItem = res.payload.data.data;
        const updatedList = lists.project.map((item) => {
          if (item[mongooseId] === updatedItem[mongooseId]) {
            return updatedItem;
          }
          return item;
        });
        setLists((prev) => ({
          ...prev,
          project: updatedList,
        }));
        setRefresh(!refresh);
        setBody((prevBody) => ({
          ...prevBody,
          project: { status: "open" },
        }));
      } else {
        handleError(res.payload, type);
        setDisableSubmit(false);
        isVAlidOnServer = false;
      }
    } else if (type === "Location" && isValid) {
      const res = await dispatch(
        locationsUpdateThunk({
          body: { ...body.location, project: selectedProjectId },
          id: editLists.location[mongooseId],
        })
      );
      if (res.payload.status === 200) {
        setOpenLocation(false);
        await dispatch(
          openSnackbar({
            message: Constants.LOCATION_UPDATE_SUCCESS,
            notificationType: Constants.NOTIFICATION_SUCCESS,
          })
        );
        const updatedItem = res.payload.data.data;
        const updatedList = lists.location.map((item) => {
          if (item[mongooseId] === updatedItem[mongooseId]) {
            return updatedItem;
          }
          return item;
        });

        setLists((prev) => ({
          ...prev,
          location: updatedList,
        }));
        setRefresh(!refresh);
        setBody((prevBody) => ({
          ...prevBody,
          location: {},
        }));
      } else {
        handleError(res.payload, type);
        setDisableSubmit(false);
        isVAlidOnServer = false;
      }
    } else if (type === "ProjectString" && isValid) {
      const res = await dispatch(
        projectStringsUpdateThunk({
          body: body.projectString,
          id: editLists.projectString[mongooseId],
        })
      );
      if (res.payload.status === 200) {
        setOpenString(false);
        await dispatch(
          openSnackbar({
            message: Constants.PROJECTSTRING_UPDATE_SUCCESS,
            notificationType: Constants.NOTIFICATION_SUCCESS,
          })
        );
        const updatedItem = res.payload.data.data;
        const updatedList = lists.projectString.map((item) => {
          if (item[mongooseId] === updatedItem[mongooseId]) {
            return updatedItem;
          }
          return item;
        });
        setLists((prev) => ({
          ...prev,
          projectString: updatedList,
        }));
        setRefresh(!refresh);
        setBody((prevBody) => ({
          ...prevBody,
          projectString: {},
        }));
      } else {
        handleError(res.payload, type);
        setDisableSubmit(false);
        isVAlidOnServer = false;
      }
    } else if (type === "Asset" && isValid) {
      body.asset = handleEmptyRequestBody(body);
      const res = await dispatch(
        assetsUpdateThunk({ body: body.asset, id: editLists.asset[mongooseId] })
      );
      if (res.payload.status === 200) {
        setOpenAsset(false);
        await dispatch(
          openSnackbar({
            message: Constants.ASSET_UPDATE_SUCCESS,
            notificationType: Constants.NOTIFICATION_SUCCESS,
          })
        );
        const updatedItem = res.payload.data.data;
        const updatedList = lists.asset.map((item) => {
          if (item[mongooseId] === updatedItem[mongooseId]) {
            return updatedItem;
          }
          return item;
        });
        setLists((prev) => ({
          ...prev,
          asset: updatedList,
        }));
        setRefresh(!refresh);
        setBody((prevBody) => ({
          ...prevBody,
          asset: {},
        }));
      } else {
        handleError(res.payload, type);
        setDisableSubmit(false);
        isVAlidOnServer = false;
      }
    } else if (type === "Scope" && isValid) {
      const res = await dispatch(
        scopesUpdateThunk({ body: body.scope, id: editLists.scope[mongooseId] })
      );
      if (res.payload.status === 200) {
        setOpenScopes(false);
        await dispatch(
          openSnackbar({
            message: Constants.SCOPE_UPDATE_SUCCESS,
            notificationType: Constants.NOTIFICATION_SUCCESS,
          })
        );
        const updatedItem = res.payload.data.data;
        const updatedList = lists.scope.map((item) => {
          if (item[mongooseId] === updatedItem[mongooseId]) {
            return updatedItem;
          }
          return item;
        });
        setLists((prev) => ({
          ...prev,
          scope: updatedList,
        }));
        setRefresh(!refresh);
        setBody((prevBody) => ({
          ...prevBody,
          scope: {},
        }));
      } else {
        handleError(res.payload, type);
        setDisableSubmit(false);
        isVAlidOnServer = false;
      }
    } else if (type === "Activity" && isValid) {
      const res = await dispatch(
        activitiesUpdateThunk({ body: body.activity, id: editLists.activity[mongooseId] })
      );
      if (res.payload.status === 200) {
        setOpenActivity(false);
        await dispatch(
          openSnackbar({
            message: Constants.ACTIVITY_UPDATE_SUCCESS2,
            notificationType: Constants.NOTIFICATION_SUCCESS,
          })
        );
        const updatedItem = res.payload.data.data;
        const updatedList = lists.activity.map((item) => {
          if (item[mongooseId] === updatedItem[mongooseId]) {
            return updatedItem;
          }
          return item;
        });
        setLists((prev) => ({
          ...prev,
          activity: updatedList,
        }));
        setRefresh(!refresh);
        setBody((prevBody) => ({
          ...prevBody,
          activity: {},
        }));
      } else {
        handleError(res.payload, type);
        setDisableSubmit(false);
        isVAlidOnServer = false;
      }
    } else if (type === "Team" && isValid) {
      const res = await dispatch(
        teamUpdateThunk({ body: body.team, id: editLists.team[mongooseId] })
      );
      if (res.payload.status === 200) {
        setOpenTeam(false);
        await dispatch(
          openSnackbar({
            message: Constants.TEAM_UPDATE_SUCCESS,
            notificationType: Constants.NOTIFICATION_SUCCESS,
          })
        );
        const updatedItem = res.payload.data.data;
        const updatedList = lists.team.map((item) => {
          if (item[mongooseId] === updatedItem[mongooseId]) {
            return updatedItem;
          }
          return item;
        });
        setLists((prev) => ({
          ...prev,
          team: updatedList,
        }));
        setRefresh(!refresh);
        setBody((prevBody) => ({
          ...prevBody,
          team: {},
        }));
      } else {
        handleError(res.payload, type);
        setDisableSubmit(false);
        isVAlidOnServer = false;
      }
    } else if (type === "Member" && isValid) {
      const res = await dispatch(
        membersUpdateThunk({ body: body.member, id: editLists.member[mongooseId] })
      );
      if (res.payload.status === 200) {
        setOpenMember(false);
        await dispatch(
          openSnackbar({
            message: res.payload.data.message,
            notificationType: Constants.NOTIFICATION_SUCCESS,
          })
        );
        const updatedItem = res.payload.data.data;
        const updatedList = lists.member.map((item) => {
          if (item[mongooseId] === updatedItem[mongooseId]) {
            return updatedItem;
          }
          return item;
        });
        setLists((prev) => ({
          ...prev,
          member: updatedList,
        }));
        setRefresh(!refresh);
        setBody((prevBody) => ({
          ...prevBody,
          member: {},
        }));
      } else if (res.payload.status === 400) {
        const tempError = {};
        tempError.user = res.payload.data.message;
        setError({ ...error, member: tempError });
        setDisableSubmit(false);
        isVAlidOnServer = false;
      }
    } else if (type === "Function" && isValid) {
      const res = await dispatch(
        functionUpdateThunk({
          body: { ...body.function, project: selectedProjectId },
          id: editLists.function[mongooseId],
        })
      );
      if (res.payload.status === 200) {
        setOpenFunction(false);
        await dispatch(
          openSnackbar({
            message: Constants.FUNCTION_UPDATE_SUCCESS,
            notificationType: Constants.NOTIFICATION_SUCCESS,
          })
        );
        const updatedItem = res.payload.data.data;
        const updatedList = lists.function.map((item) => {
          if (item[mongooseId] === updatedItem[mongooseId]) {
            return updatedItem;
          }
          return item;
        });
        setLists((prev) => ({
          ...prev,
          function: updatedList,
        }));
        setRefresh(!refresh);
        setBody((prevBody) => ({
          ...prevBody,
          function: {},
        }));
      } else {
        handleError(res.payload, type);
        setDisableSubmit(false);
        isVAlidOnServer = false;
      }
    } else if (type === "Project Document" && isValid) {
      const { document, ...projectDocument } = body.projectDocument;
      const trimmedTitle = projectDocument.title.trim();
      const trimmedNumber = projectDocument.documentNumber.trim();
      setDisableSubmit(true);
      const res = await dispatch(
        projectDocumentUpdateThunk({
          body: {
            ...projectDocument,
            title: trimmedTitle,
            documentNumber: trimmedNumber,
            ...(updatedDocument?.url?.length > 0 && { document: updatedDocument }),
          },
          id: editLists.projectDocument[mongooseId],
        })
      );
      setDisableSubmit(false);
      if (res.payload.status === 200) {
        setOpenProjectDocument(false);
        await dispatch(
          openSnackbar({
            message: Constants.PROJECT_DOCUMENT_UPDATE_SUCCESS,
            notificationType: Constants.NOTIFICATION_SUCCESS,
          })
        );
        const updatedItem = res.payload.data.data;
        const updatedList = lists.projectDocument.map((item) => {
          if (item[mongooseId] === updatedItem[mongooseId]) {
            return updatedItem;
          }
          return item;
        });
        setLists((prev) => ({
          ...prev,
          projectDocument: updatedList,
        }));
        setRefresh(!refresh);
        setBody((prevBody) => ({
          ...prevBody,
          projectDocument: {},
        }));
        setUpdatedDocument({});
      } else {
        handleError(res.payload, type);
        setDisableSubmit(false);
        isVAlidOnServer = false;
      }
    }
    setLoading(false);
    if (isVAlidOnServer && isValid) {
      setModalType("New");
      await dispatch(configThunk());
    }
  };

  const handleClick = (item) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(selectedItems.filter((selectedItem) => selectedItem !== item));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const handleSearch = async (
    apiThunk,
    propertyKey,
    resType,
    updateList,
    searchValue = filters
  ) => {
    setFilters((prev) => {
      const temp = [...prev];
      temp[0].isLoading = true;
      return temp;
    });

    setTablePagination({ ...tablePagination, page: 0 });

    const searchTerm =
      searchValue[0].selectedValue === "All" ? "" : searchValue[0].selectedValue.trim();
    setSearchTable({
      ...searchTable,
      [updateList]: searchTerm,
    });
    const queryParams = {
      id: selectedProjectId,
      name: searchTerm,
      sortOrder: sortState[updateList],
    };
    const res = await dispatch(apiThunk(queryParams));
    const temp = [...filters];

    const filtered = resType ? res.payload.data : res.payload.data.data;
    const typeName = filtered.map((item) => {
      const keys = propertyKey.split(".");

      const title =
        propertyKey === "fullName"
          ? `${item.user.callingName ? item.user.callingName : item.user.firstName} ${
              item.user.lastName
            }`
          : keys.reduce((obj, key) => (obj ? obj[key] : undefined), item);
      return {
        [Constants.MONGOOSE_ID]: item[Constants.MONGOOSE_ID],
        title,
      };
    });
    temp[0].list = [{ [Constants.MONGOOSE_ID]: "all", title: "All" }, ...typeName];
    temp[0].isLoading = false;
    setFilters(temp);
    setLists({
      ...lists,
      [updateList]: filtered,
    });
    if (propertyKey === "projectCertificate") {
      const certificateList = generateCertificateList(filtered);
      setProjectListedData(certificateList);
    }
  };

  const debounce =
    (func, delay) =>
    (...args) => {
      const context = this;
      clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(() => func.apply(context, args), delay);
    };

  const createDebouncedHandleSearch = (apiThunk, propertyKey, resType, updateList) =>
    debounce((e) => {
      const temp = [...filters];
      const searchTerm = e.target.value.trim();

      if (searchTerm.length < 3) {
        temp[0].selectedValue = searchTerm;
        setFilters(temp);
        return;
      }
      temp[0].selectedValue = searchTerm;
      handleSearch(apiThunk, propertyKey, resType, updateList, temp);
    }, 300);

  const debouncedHandleSearchLocation = createDebouncedHandleSearch(
    locationListByIdThunk,
    "title",
    true,
    "location"
  );
  const debouncedHandleSearchString = createDebouncedHandleSearch(
    projectStringListThunk,
    "name",
    true,
    "projectString"
  );
  const debouncedHandleSearchAsset = createDebouncedHandleSearch(
    assetListThunk,
    "cableName",
    true,
    "asset"
  );
  const debouncedHandleSearchTeam = createDebouncedHandleSearch(
    teamThunk,
    "teamsWfmName",
    true,
    "team"
  );
  const debouncedHandleSearchScope = createDebouncedHandleSearch(
    scopesThunk,
    "name",
    false,
    "scope"
  );
  const debouncedHandleSearchActivity = createDebouncedHandleSearch(
    activityThunk,
    "name",
    true,
    "activity"
  );
  const debouncedHandleSearchFunction = createDebouncedHandleSearch(
    functionListThunk,
    "functionName",
    true,
    "function"
  );
  const debouncedHandleSearchMember = createDebouncedHandleSearch(
    memberThunk,
    "fullName",
    true,
    "member"
  );
  const debouncedHandleSearchCertificate = createDebouncedHandleSearch(
    getProjectCertificate,
    "projectCertificate",
    false,
    "projectCertificate"
  );
  return (
    <DashboardLayout>
      <DashboardNavbar />

      <ViewDrawer
        title="Projects"
        openDrawer={openProjectList}
        closeDrawer={handleCloseProjectList}
      >
        <DataTable
          table={{
            columns: projectColumns,
            rows: projectRows,
          }}
          backgroundColor={Colors.LIGHT_GRAY} // Specify the background color here
          textColor={Colors.BLACK}
          isSorted={false}
          entriesPerPage={{ defaultValue: defaultData.PER_PAGE }}
          showTotalEntries={false}
          pagination={{ variant: "gradient", color: "info" }}
          loading={loadingStatus.project}
          currentPage={currentPage}
          handleCurrentPage={handlePageChange}
        />
      </ViewDrawer>

      <NewProject
        title={modaltype === "New" ? "New Project" : "Edit Project"}
        openNewProject={openNewModal}
        handleCloseNewProject={() => handleCloseNewModal("Project")}
        handleSave={() => (modaltype === "New" ? handleCreate("Project") : handleUpdate("Project"))}
        actionButton={loading ? ButtonTitles.LOADING : ButtonTitles.SUBMIT}
        disabled={disableSubmit}
      >
        <MDTypography
          variant="caption"
          mt={2}
          mb={1}
          sx={{ fontSize: pxToRem(14), fontWeight: 500, color: "#344054" }}
        >
          Project name*
        </MDTypography>
        <MDInput
          sx={{
            marginTop: 0,
            marginBottom: 2,
            "& input": {
              fontSize: "16px",
              color: "#667085",
            },
          }}
          name="title"
          placeholder="Project name"
          margin="normal"
          error={Boolean(error.project.title)}
          helperText={error.project.title}
          FormHelperTextProps={{
            sx: { marginLeft: 0, color: "#FF2E2E" },
          }}
          defaultValue={modaltype === "New" ? null : editLists.project?.title}
          onChange={(e) => handleChange("project", e, modaltype)}
        />
        <FTextField
          label="Project Number*"
          placeholder="Project Number*"
          id="projectNumber"
          name="projectNumber"
          type="text"
          error={Boolean(error.project.projectNumber)}
          helperText={error.project.projectNumber}
          value={body.project?.projectNumber}
          handleChange={(e) => handleChange("project", e, modaltype)}
          marginBottom={2}
        />
        <FTextField
          label="Client*"
          placeholder="Client*"
          id="client"
          name="client"
          type="text"
          error={Boolean(error.project.client)}
          helperText={error.project.client}
          value={body.project?.client}
          handleChange={(e) => handleChange("project", e, modaltype)}
          marginBottom={2}
        />
        <CustomRadio
          label="Status*"
          name="status"
          list={[
            { label: "Open", value: "open" },
            { label: "Completed", value: "completed" },
            { label: "Closed", value: "closed" },
          ]}
          defaultValue
          value={body?.project?.status}
          handleChange={(e) => handleChange("project", e, modaltype)}
        />
      </NewProject>

      <NewProject
        title={modaltype === "New" ? "New Location" : "Edit Location"}
        openNewProject={openLocation}
        handleCloseNewProject={() => handleCloseNewModal("Location")}
        handleSave={() =>
          modaltype === "New" ? handleCreate("Location") : handleUpdate("Location")
        }
        actionButton={loading ? ButtonTitles.LOADING : ButtonTitles.SUBMIT}
        disabled={disableSubmit}
      >
        <MDTypography
          variant="caption"
          mt={2}
          mb={1}
          sx={{ fontSize: pxToRem(14), fontWeight: 500, color: "#344054" }}
        >
          Name*
        </MDTypography>
        <MDInput
          sx={{
            marginTop: 0,
            marginBottom: 2,
            "& input": {
              fontSize: "16px",
              color: "#667085",
            },
          }}
          name="title"
          placeholder="Name"
          margin="normal"
          error={Boolean(error.location.title)}
          helperText={error.location.title}
          FormHelperTextProps={{
            sx: { marginLeft: 0, color: "#FF2E2E" },
          }}
          defaultValue={modaltype === "New" ? null : editLists.location?.title}
          onChange={(e) => handleChange("location", e, modaltype)}
        />
        <MDTypography
          variant="caption"
          mt={2}
          mb={1}
          sx={{ fontSize: pxToRem(14), fontWeight: 500, color: "#344054" }}
        >
          Latitude
        </MDTypography>
        <MDInput
          sx={{
            marginTop: 0,
            marginBottom: 2,
            "& input": {
              fontSize: "16px",
              color: "#667085",
            },
          }}
          name="latitude"
          placeholder="Latitude"
          margin="normal"
          type="number"
          error={Boolean(error.location.latitude)}
          helperText={error.location.latitude}
          FormHelperTextProps={{
            sx: { marginLeft: 0, color: "#FF2E2E" },
          }}
          defaultValue={modaltype === "New" ? null : editLists.location?.latitude}
          onChange={(e) => handleChange("location", e, modaltype)}
        />
        <MDTypography
          variant="caption"
          mt={2}
          mb={1}
          sx={{ fontSize: pxToRem(14), fontWeight: 500, color: "#344054" }}
        >
          Longitude
        </MDTypography>
        <MDInput
          sx={{
            marginTop: 0,
            marginBottom: 2,
            "& input": {
              fontSize: "16px",
              color: "#667085",
            },
          }}
          name="longitude"
          placeholder="Longitude"
          margin="normal"
          type="number"
          error={Boolean(error.location.longitude)}
          helperText={error.location.longitude}
          FormHelperTextProps={{
            sx: { marginLeft: 0, color: "#FF2E2E" },
          }}
          defaultValue={modaltype === "New" ? null : editLists.location?.longitude}
          onChange={(e) => handleChange("location", e, modaltype)}
        />
      </NewProject>

      <NewProject
        title={modaltype === "New" ? "New String" : "Edit String"}
        openNewProject={openString}
        handleCloseNewProject={() => handleCloseNewModal("ProjectString")}
        handleSave={() =>
          modaltype === "New" ? handleCreate("ProjectString") : handleUpdate("ProjectString")
        }
        actionButton={loading ? ButtonTitles.LOADING : ButtonTitles.SUBMIT}
        disabled={disableSubmit}
      >
        <Typography mt={0} align="center">
          {lists?.location.length === 0 ? Constants.PROJECT_STRING_WARNING : ""}
        </Typography>
        <MDTypography
          variant="caption"
          mt={2}
          mb={1}
          sx={{ fontSize: pxToRem(14), fontWeight: 500, color: "#344054" }}
        >
          Name*
        </MDTypography>
        <MDInput
          sx={{
            marginTop: 0,
            marginBottom: 2,
            "& input": {
              fontSize: "16px",
              color: "#667085",
            },
          }}
          name="name"
          placeholder="Name"
          margin="normal"
          error={Boolean(error.projectString.name)}
          helperText={error.projectString.name}
          FormHelperTextProps={{
            sx: { marginLeft: 0, color: "#FF2E2E" },
          }}
          defaultValue={modaltype === "New" ? null : editLists.projectString?.name}
          onChange={(e) => handleChange("projectString", e, modaltype)}
        />
        <FDropdown2
          label="From*"
          id="from"
          name="fromLocation"
          value={
            body?.projectString?.fromLocation || body?.projectString?.fromLocation?.[mongooseId]
          }
          options={lists?.location || []}
          error={error.projectString.fromLocation}
          helperText={error.projectString.fromLocation}
          handleChange={(e) => handleChange("projectString", e)}
          marginBottom={2}
        />
        <FDropdown2
          label="To*"
          id="to"
          name="toLocation"
          value={body?.projectString?.toLocation || body?.projectString?.toLocation?.[mongooseId]}
          options={lists?.location || []}
          error={error.projectString.toLocation}
          helperText={error.projectString.toLocation}
          handleChange={(e) => handleChange("projectString", e)}
          marginBottom={2}
        />
      </NewProject>

      <NewProject
        title={modaltype === "New" ? "New Asset" : "Edit Asset"}
        openNewProject={openAsset}
        handleCloseNewProject={() => handleCloseNewModal("Asset")}
        handleSave={() => (modaltype === "New" ? handleCreate("Asset") : handleUpdate("Asset"))}
        actionButton={loading ? ButtonTitles.LOADING : ButtonTitles.SUBMIT}
        disabled={disableSubmit}
      >
        <Typography mt={0} align="center">
          {lists?.location.length === 0 && lists?.projectString.length === 0
            ? Constants.ASSET_WARNING
            : ""}
        </Typography>
        <MDTypography
          variant="caption"
          mt={2}
          mb={1}
          sx={{ fontSize: pxToRem(14), fontWeight: 500, color: "#344054" }}
        >
          Asset Name*
        </MDTypography>
        <MDInput
          sx={{
            marginTop: 0,
            marginBottom: 2,
            "& input": {
              fontSize: "16px",
              color: "#667085",
            },
          }}
          name="cableName"
          placeholder="Asset Name"
          margin="normal"
          error={Boolean(error.asset.cableName)}
          helperText={error.asset.cableName}
          FormHelperTextProps={{
            sx: { marginLeft: 0, color: "#FF2E2E" },
          }}
          defaultValue={modaltype === "New" ? null : editLists.asset?.cableName}
          onChange={(e) => handleChange("asset", e, modaltype)}
          modaltype
        />
        <Grid item xs={12} md={12}>
          <CustomAutoComeplete
            label="From*"
            id="from"
            name="fromLocation"
            hint="Enter From Location*"
            handleChange={(e) => handleChange("asset", e)}
            menu={lists.location}
            error={error.asset.fromLocation}
            helperText={error.asset.fromLocation}
            getOptionLabel={(option) => option.title || ""}
            value={{
              title: lists.location.find(
                (location) => location[Constants.MONGOOSE_ID] === body?.asset?.fromLocation
              )?.title,
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <CustomAutoComeplete
            label="To*"
            id="to"
            name="toLocation"
            hint="Enter To Location*"
            handleChange={(e) => handleChange("asset", e)}
            menu={lists.location}
            error={error.asset.toLocation}
            helperText={error.asset.toLocation}
            getOptionLabel={(option) => option.title || ""}
            value={{
              title: lists.location.find(
                (location) => location[Constants.MONGOOSE_ID] === body?.asset?.toLocation
              )?.title,
            }}
          />
        </Grid>
        <MDTypography
          variant="caption"
          mt={2}
          mb={1}
          sx={{ fontSize: pxToRem(14), fontWeight: 500, color: "#344054" }}
        >
          Manufacturer
        </MDTypography>
        <MDInput
          sx={{
            marginTop: 0,
            marginBottom: 2,
            "& input": {
              fontSize: "16px",
              color: "#667085",
            },
          }}
          name="manufacturer"
          placeholder="Manufacturer"
          margin="normal"
          error={Boolean(error.asset.manufacturer)}
          helperText={error.asset.manufacturer}
          FormHelperTextProps={{
            sx: { marginLeft: 0, color: "#FF2E2E" },
          }}
          defaultValue={modaltype === "New" ? null : editLists.asset?.manufacturer}
          onChange={(e) => handleChange("asset", e, modaltype)}
          modaltype
        />
        <MDTypography
          variant="caption"
          mt={2}
          mb={1}
          sx={{ fontSize: pxToRem(14), fontWeight: 500, color: "#344054" }}
        >
          Type mm2
        </MDTypography>
        <MDInput
          sx={{
            marginTop: 0,
            marginBottom: 2,
            "& input": {
              fontSize: "16px",
              color: "#667085",
            },
          }}
          name="typeMm2"
          placeholder="Type mm2"
          margin="normal"
          error={Boolean(error.asset.typeMm2)}
          helperText={error.asset.typeMm2}
          FormHelperTextProps={{
            sx: { marginLeft: 0, color: "#FF2E2E" },
          }}
          defaultValue={modaltype === "New" ? null : editLists.asset?.typeMm2}
          onChange={(e) => handleChange("asset", e, modaltype)}
          modaltype
        />
        <Grid item xs={12} md={12}>
          <CustomAutoComeplete
            label="Project String"
            id="string"
            name="string"
            hint="Enter Project String*"
            handleChange={(e) => handleChange("asset", e)}
            menu={dropdownData?.projectString}
            error={error.asset.string}
            helperText={error.asset.string}
            getOptionLabel={(option) => option.title || ""}
            value={{
              title: dropdownData?.projectString.find(
                (projectString) => projectString[Constants.MONGOOSE_ID] === body?.asset?.string
              )?.title,
            }}
          />
        </Grid>
      </NewProject>

      <NewProject
        title={modaltype === "New" ? "New Scope" : "Edit Scope"}
        openNewProject={openScopes}
        handleCloseNewProject={() => handleCloseNewModal("Scope")}
        handleSave={() => (modaltype === "New" ? handleCreate("Scope") : handleUpdate("Scope"))}
        actionButton={loading ? ButtonTitles.LOADING : ButtonTitles.SUBMIT}
        disabled={disableSubmit}
      >
        <MDTypography
          variant="caption"
          mt={2}
          mb={1}
          sx={{ fontSize: pxToRem(14), fontWeight: 500, color: "#344054" }}
        >
          Scope Name*
        </MDTypography>
        <MDInput
          sx={{
            marginTop: 0,
            marginBottom: 2,
            "& input": {
              fontSize: "16px",
              color: "#667085",
            },
          }}
          name="name"
          placeholder="Scope Name*"
          margin="normal"
          error={Boolean(error.scope.name)}
          helperText={error.scope.name}
          defaultValue={modaltype === "New" ? null : editLists.scope?.name}
          FormHelperTextProps={{
            sx: { marginLeft: 0, color: "#FF2E2E" },
          }}
          onChange={(e) => handleChange("scope", e, modaltype)}
          modaltype
        />
        <MDTypography
          variant="caption"
          mt={2}
          mb={1}
          sx={{ fontSize: pxToRem(14), fontWeight: 500, color: "#344054" }}
        >
          Sort Order*
        </MDTypography>
        <MDInput
          sx={{
            marginTop: 0,
            marginBottom: 2,
            "& input": {
              fontSize: "16px",
              color: "#667085",
            },
          }}
          name="sortOrder"
          placeholder="Sort Order*"
          margin="normal"
          error={Boolean(error.scope.sortOrder)}
          helperText={error.scope.sortOrder}
          FormHelperTextProps={{
            sx: { marginLeft: 0, color: "#FF2E2E" },
          }}
          defaultValue={modaltype === "New" ? null : editLists.scope?.sortOrder}
          onChange={(e) => handleChange("scope", e, modaltype)}
          onKeyDown={(e) => numericFieldValidation(e)}
          modaltype
        />
      </NewProject>

      <NewProject
        title={modaltype === "New" ? "New Team" : "Edit Team"}
        openNewProject={openTeam}
        handleCloseNewProject={() => handleCloseNewModal("Team")}
        handleSave={() => (modaltype === "New" ? handleCreate("Team") : handleUpdate("Team"))}
        actionButton={loading ? ButtonTitles.LOADING : ButtonTitles.SUBMIT}
        disabled={disableSubmit}
      >
        <MDTypography
          variant="caption"
          mt={2}
          mb={1}
          sx={{ fontSize: pxToRem(14), fontWeight: 500, color: "#344054" }}
        >
          Teams WFM Name*
        </MDTypography>
        <MDInput
          sx={{
            marginTop: 0,
            marginBottom: 2,
            "& input": {
              fontSize: "16px",
              color: "#667085",
            },
          }}
          name="teamsWfmName"
          placeholder="Teams WFM Name"
          margin="normal"
          error={Boolean(error.team.teamsWfmName)}
          helperText={error.team.teamsWfmName}
          FormHelperTextProps={{
            sx: { marginLeft: 0, color: "#FF2E2E" },
          }}
          defaultValue={modaltype === "New" ? null : editLists.team?.teamsWfmName}
          onChange={(e) => handleChange("team", e, modaltype)}
          modaltype
        />
      </NewProject>

      <NewProject
        title={modaltype === "New" ? "New Member" : "Edit Member"}
        openNewProject={openMember}
        handleCloseNewProject={() => handleCloseNewModal("Member")}
        handleSave={() => (modaltype === "New" ? handleCreate("Member") : handleUpdate("Member"))}
        actionButton={loading ? ButtonTitles.LOADING : ButtonTitles.SUBMIT}
        disabled={disableSubmit}
      >
        <Typography mt={0} align="center">
          {dropdownData.allMember.length === 0 ? Constants.MEMBER_WARNING : ""}
        </Typography>

        <MDBox>
          <CustomAutoComeplete
            label="Select Member*"
            id="member"
            name="user"
            hint="Enter Member Name*"
            handleChange={(e) => handleMemberChange("member", e)}
            menu={
              modaltype === "Update" && openMember
                ? dropdownData.allMember
                : dropdownData.filteredMembers
            }
            error={error.member.user}
            helperText={error.member.user}
            getOptionLabel={(option) => option.title || ""}
            value={
              (modaltype === "Update" && openMember
                ? dropdownData.allMember
                : dropdownData.filteredMembers
              ).find((role) => role[Constants.MONGOOSE_ID] === body.member.user) || null
            }
          />
        </MDBox>
        <FDropdown2
          label="Select Function"
          id="select-function"
          name="function"
          value={body?.member?.function || body?.member?.function?.[mongooseId]}
          options={dropdownData.function || []}
          error={error.member.function}
          helperText={error.member.function}
          handleChange={(e) => handleChange("member", e)}
          marginBottom={2}
        />
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={
                  modaltype === "Update" && openMember ? editLists?.member?.isApprover : false
                }
                name="isApprover"
                checked={body?.member?.isApprover}
                onChange={(e) => handleCheckboxChange("member", e, modaltype)}
              />
            }
            label="Equipment Approver"
            variant="body1"
            sx={{ fontWeight: 500 }}
          />
        </FormGroup>
      </NewProject>

      <NewProject
        title={modaltype === "New" ? "New Activity" : "Edit Activity"}
        openNewProject={openActivity}
        handleCloseNewProject={() => handleCloseNewModal("Activity")}
        handleSave={() =>
          modaltype === "New" ? handleCreate("Activity") : handleUpdate("Activity")
        }
        actionButton={loading ? ButtonTitles.LOADING : ButtonTitles.SUBMIT}
        disabled={disableSubmit}
      >
        <Typography mt={0} align="center">
          {lists.scope.length === 0 ? Constants.ACTIVITY_WARNING : ""}
        </Typography>
        <MDTypography
          variant="caption"
          mt={2}
          mb={1}
          sx={{ fontSize: pxToRem(14), fontWeight: 500, color: "#344054" }}
        >
          Activity*
        </MDTypography>
        <MDInput
          sx={{
            marginTop: 0,
            marginBottom: 2,
            "& input": {
              fontSize: "16px",
              color: "#667085",
            },
          }}
          name="name"
          placeholder="Activity*"
          margin="normal"
          error={Boolean(error.activity.name)}
          helperText={error.activity.name}
          FormHelperTextProps={{
            sx: { marginLeft: 0, color: "#FF2E2E" },
          }}
          defaultValue={modaltype === "Update" && openActivity ? editLists.activity?.name : null}
          onChange={(e) => handleChange("activity", e, modaltype)}
        />

        <FDropdown2
          label="Scope Id*"
          id="scopeId"
          name="scopeId"
          value={body?.activity?.scopeId || body.activity?.scopeId?.[mongooseId]}
          displayProperty="name"
          options={dropdownData?.scope || []}
          error={error.activity.scopeId}
          helperText={error.activity.scopeId}
          handleChange={(e) => handleChange("activity", e)}
          marginBottom={2}
        />
        <MDTypography
          variant="caption"
          mt={2}
          mb={1}
          sx={{ fontSize: pxToRem(14), fontWeight: 500, color: "#344054" }}
        >
          Weight*
        </MDTypography>
        <MDInput
          sx={{
            marginTop: 0,
            marginBottom: 2,
            "& input": {
              fontSize: "16px",
              color: "#667085",
            },
          }}
          name="weight"
          placeholder="Weight*"
          margin="normal"
          type="number"
          error={Boolean(error.activity.weight)}
          helperText={error.activity.weight}
          FormHelperTextProps={{
            sx: { marginLeft: 0, color: "#FF2E2E" },
          }}
          defaultValue={modaltype === "Update" && openActivity ? editLists.activity?.weight : null}
          onChange={(e) => handleChange("activity", e, modaltype)}
        />
      </NewProject>

      <NewProject
        title={modaltype === "New" ? "New Project Certificate" : "Edit Project Certificate"}
        openNewProject={openProjectCertificate}
        handleCloseNewProject={() => handleCloseNewModal("Project Certificate")}
        handleSave={() => handleCreate("Project Certificate")}
        actionButton={loading ? ButtonTitles.LOADING : ButtonTitles.SUBMIT}
        disabled={disableSubmit || !lists.certificateList}
      >
        <Typography mt={0} mb={2} align="center">
          {lists.function.length === 0 ? Constants.FUNCTION_WARNING : ""}
        </Typography>
        <FDropdown2
          label="Select Function*"
          id="select-function"
          name="function"
          value={
            body?.projectCertificate?.function || body?.projectCertificate?.function?.[mongooseId]
          }
          options={dropdownData.function || []}
          error={error["project Certificate"]?.function}
          helperText={error["project Certificate"]?.function}
          handleChange={(e) => handleChange("projectCertificate", e)}
          marginBottom={2}
        />
        {body?.projectCertificate?.function && (
          <MDBox mb={spin ? 15 : 0}>
            <MDTypography
              variant="caption"
              mb={1}
              sx={{ fontSize: pxToRem(14), fontWeight: 500, color: "#344054" }}
            >
              Choose Certificate Category*
            </MDTypography>
            {spin ? (
              <MDBox
                sx={{
                  position: "absolute",
                  top: "70%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                {Icons.LOADING2}
              </MDBox>
            ) : (
              <Box
                sx={{
                  width: "auto",
                  maxWidth: "700px",
                  overflowY: "auto",
                  maxHeight: "205px",
                  "::-webkit-scrollbar": { width: "5px" },
                  "::-webkit-scrollbar-track ": {
                    background: "#f1f1f1",
                  },
                  "::-webkit-scrollbar-thumb ": {
                    background: "#888",
                  },
                  "::-webkit-scrollbar-thumb:hover": {
                    background: "#555",
                  },
                  scrollbarWidth: "5px",
                  marginBottom: "20px",
                }}
              >
                <Box role="group" aria-labelledby="topping">
                  {lists.certificateList && lists.certificateList.length > 0 ? (
                    <List
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "0px",
                        padding: "0",
                        listStyleType: "none",
                        "& .MuiListItemButton-root": { "&:hover": { backgroundColor: "inherit" } },
                      }}
                    >
                      {lists?.certificateList?.map((item) => (
                        <ListItem
                          key={item[Constants.MONGOOSE_ID]}
                          disablePadding
                          sx={{
                            width: `fit-content`,
                            "& .MuiListItemButton-root": {
                              "&:hover": { backgroundColor: "inherit" },
                            },
                          }}
                        >
                          <Box
                            component={ListItem}
                            disablePadding
                            sx={{
                              width: `fit-content`,
                            }}
                          >
                            <ListItemButton
                              name="certificates"
                              onClick={(e) => {
                                handleClick(item[Constants.MONGOOSE_ID]);
                                handleChange("function", e, "certificates");
                              }}
                              sx={{
                                fontSize: "14px",
                                width: "auto",
                                padding: "10px",
                                borderRadius: "24px",
                              }}
                            >
                              <ListItemText
                                name="certificates"
                                onChange={(e) => handleChange("function", e, selectedItems)}
                              >
                                <MDTypography
                                  sx={{
                                    backgroundColor: selectedItems.includes(
                                      item[Constants.MONGOOSE_ID]
                                    )
                                      ? Colors.PRIMARY
                                      : Colors.WHITE,
                                    color: selectedItems.includes(item[Constants.MONGOOSE_ID])
                                      ? "#fff"
                                      : Colors.PRIMARY,
                                    borderRadius: "24px",
                                    border: "1px solid #191A51",
                                    textAlign: "center",
                                    padding: "5px 15px",
                                    fontSize: "16px",
                                  }}
                                >
                                  <Checkbox
                                    id={item.id}
                                    checked={selectedItems.includes(item)}
                                    disabled={selectedItems[0] === item}
                                    sx={{ display: "none" }}
                                    onChange={handleChange}
                                  />
                                  {item?.name}
                                </MDTypography>
                              </ListItemText>
                            </ListItemButton>
                          </Box>
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    <MDTypography
                      sx={{
                        color: "#667085",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      No Certificate found
                    </MDTypography>
                  )}
                </Box>
                <FormHelperText sx={{ color: "#FF2E2E" }}>
                  {error["project Certificate"]?.certificates}
                </FormHelperText>
              </Box>
            )}
          </MDBox>
        )}
      </NewProject>

      <NewProject
        title={modaltype === "New" ? "New Function" : "Edit Function"}
        openNewProject={openFunction}
        handleCloseNewProject={() => handleCloseNewModal("Function")}
        handleSave={() =>
          modaltype === "New" ? handleCreate("Function") : handleUpdate("Function")
        }
        actionButton={loading ? ButtonTitles.LOADING : ButtonTitles.SUBMIT}
        disabled={disableSubmit}
      >
        <MDTypography
          variant="caption"
          mt={2}
          mb={1}
          sx={{ fontSize: pxToRem(14), fontWeight: 500, color: "#344054" }}
        >
          Project Function Name*
        </MDTypography>
        <MDInput
          sx={{
            marginTop: 0,
            marginBottom: 2,
            "& input": {
              fontSize: "16px",
              color: "#667085",
            },
          }}
          name="functionName"
          placeholder="Project Function Name*"
          margin="normal"
          error={Boolean(error.function.functionName)}
          helperText={error.function.functionName}
          FormHelperTextProps={{
            sx: { marginLeft: 0, color: "#FF2E2E" },
          }}
          defaultValue={
            modaltype === "Update" && openFunction ? editLists.function?.functionName : null
          }
          onChange={(e) => handleChange("function", e, modaltype)}
        />
        <FTextField
          label="Sort Order*"
          placeholder="Sort Order*"
          id="sortOrder"
          name="sortOrder"
          type="number"
          error={Boolean(error.function.sortOrder)}
          helperText={error.function.sortOrder}
          value={body.function.sortOrder}
          handleChange={(e) => handleChange("function", e, modaltype)}
          onKeyPress={(e) => numericFieldValidation(e)}
          marginBottom={2}
        />
      </NewProject>

      <NewProject
        title={modaltype === "New" ? "New Project Document" : "Edit Project Document"}
        openNewProject={openProjectDocument}
        handleCloseNewProject={() => handleCloseNewModal("Project Document")}
        handleSave={() =>
          modaltype === "New" ? handleCreate("Project Document") : handleUpdate("Project Document")
        }
        actionButton={loading ? ButtonTitles.LOADING : ButtonTitles.SUBMIT}
        disabled={disableSubmit}
      >
        <Grid container spacing={2}>
          <Grid item xs={modaltype === "New" ? 12 : 6}>
            <FTextField
              label="Document Name*"
              placeholder="Document Name*"
              id="title"
              name="title"
              type="text"
              error={Boolean(error["project Document"]?.title)}
              helperText={error["project Document"]?.title}
              value={body.projectDocument?.title}
              handleChange={(e) => handleChange("projectDocument", e, modaltype)}
            />
          </Grid>
          <Grid item xs={6}>
            <MDBox mt={modaltype === "New" && 2}>
              <FTextField
                label="Document Number*"
                placeholder="Document Number*"
                id="documentNumber"
                name="documentNumber"
                type="text"
                error={Boolean(error["project Document"]?.documentNumber)}
                helperText={error["project Document"]?.documentNumber}
                value={body.projectDocument?.documentNumber}
                handleChange={(e) => handleChange("projectDocument", e, modaltype)}
              />
            </MDBox>
          </Grid>
          <Grid item xs={6}>
            <FDropdown2
              label="Document Type*"
              id="type"
              name="type"
              value={body?.projectDocument?.type}
              options={[
                { title: "Procedure", [Constants.MONGOOSE_ID]: "procedure" },
                {
                  title: "Management of Change",
                  [Constants.MONGOOSE_ID]: "management_of_change",
                },
                { title: "Safety Update", [Constants.MONGOOSE_ID]: "safety_update" },
                { title: "Quality Update", [Constants.MONGOOSE_ID]: "quality_update" },
              ]}
              error={error["project Document"]?.type}
              helperText={error["project Document"]?.type}
              handleChange={(e) => handleChange("projectDocument", e)}
              marginBottom={2}
            />
          </Grid>

          <Grid item xs={modaltype === "New" ? 12 : 6}>
            <MDBox mt={modaltype === "Update" && 1}>
              <ImageUpload
                label={modaltype !== "New" ? "Update Document" : "Upload Document*"}
                name="document"
                onImageUpload={(imageValues) =>
                  handleImageChange("projectDocument", imageValues, "document")
                }
                onImageCancel={(updatedImageUrl) =>
                  handleImageCancel("document", updatedImageUrl, "projectDocument")
                }
                error={modaltype === "New" && error["project Document"]?.document}
                helperText={modaltype === "New" && error["project Document"]?.document}
                type="Project_Documents"
                formats={[
                  "image/jpeg",
                  "image/jpg",
                  "image/png",
                  "image/svg+xml",
                  "application/pdf",
                ]}
                acceptType=""
                maxImageCount={1}
                imageTypeError={Constants.EQUIPMENT_FILE_TYPE_NOT_ALLOWED}
                sizeUpdate
              />
            </MDBox>
          </Grid>
          <MDBox
            maxHeight="120px"
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            width="100%"
            sx={{
              overflowY: "auto",
              "::-webkit-scrollbar": { width: "5px" },
              "::-webkit-scrollbar-track ": {
                background: "#f1f1f1",
              },
              "::-webkit-scrollbar-thumb ": {
                background: "#888",
              },
              "::-webkit-scrollbar-thumb:hover": {
                background: "#555",
              },
              scrollbarWidth: "5px",
            }}
          >
            {modaltype === "Update" &&
              body?.projectDocument?.document &&
              body?.projectDocument?.document?.length > 0 &&
              body?.projectDocument?.document
                ?.sort((a, b) => b.isActive - a.isActive)
                ?.map((item) => (
                  <Grid item xs={6} key={item?.id}>
                    <MDBox
                      display="flex"
                      border="1px solid #E0E6F5"
                      borderRadius="8px"
                      p={1}
                      ml={1}
                      mt={1}
                      sx={{
                        cursor: "pointer",
                        backgroundColor: item?.isActive ? "white" : "#f5f5f5",
                        opacity: item?.isActive ? 1 : 0.6,
                      }}
                      onClick={() => handleImageFullView(item?.url)}
                    >
                      <img
                        src={item?.name && item?.name.includes(".pdf") ? Pdf : item?.url || Frame}
                        alt="Preview"
                        height="60px"
                        width="60px"
                        style={{
                          border: "1px solid #D0D5DD",
                          borderRadius: "8px",
                          marginTop: "5px",
                          marginLeft: "4px",
                          filter: item?.isActive ? "none" : "grayscale(100%)",
                        }}
                      />

                      <MDBox ml={2} mt={0}>
                        {item?.name && item?.name?.length > defaultData.MEDIUM_CONTENT_LENGTH ? (
                          <MDTypography
                            display="block"
                            variant="caption"
                            sx={{ textTransform: "capitalize" }}
                          >
                            <Tooltip title={item?.name}>
                              <span>{`${item?.name.slice(
                                0,
                                defaultData.MEDIUM_CONTENT_LENGTH
                              )}...`}</span>
                            </Tooltip>
                          </MDTypography>
                        ) : (
                          <MDTypography
                            display="block"
                            variant="caption"
                            sx={{ textTransform: "capitalize" }}
                          >
                            {item?.name}
                          </MDTypography>
                        )}
                        {item?.date && (
                          <MDTypography display="block" variant="caption" color="text">
                            {`Upload Date: ${moment(item?.date).format(
                              defaultData.WEB_DATE_FORMAT
                            )}`}
                          </MDTypography>
                        )}
                        {item?.version && (
                          <MDTypography display="block" variant="caption" color="text">
                            {`Version: ${item?.version}`}
                          </MDTypography>
                        )}
                      </MDBox>
                    </MDBox>
                  </Grid>
                ))}
          </MDBox>
        </Grid>
      </NewProject>

      <ViewDrawer
        title="Location"
        openDrawer={openLocationData}
        closeDrawer={handleCloseLocationData}
      >
        <MDBox mb={2} mt={-3}>
          <SearchBar
            freeSolos
            options={filters[0]?.list.map((val) => val.title) || []}
            filters={filters}
            placeholder="Search"
            value={filters[0].selectedValue}
            debouncedHandleSearch={debouncedHandleSearchLocation}
            handleFilterChange={(e, value) =>
              debouncedHandleSearchLocation({
                target: { name: filters[0]?.inputLabel, value },
              })
            }
            isLoading={filters[0].isLoading}
          />
        </MDBox>
        <DataTable
          table={{
            columns: locationColumns,
            rows: locationRows,
          }}
          backgroundColor={Colors.LIGHT_GRAY} // Specify the background color here
          textColor={Colors.BLACK}
          isSorted={false}
          entriesPerPage={{ defaultValue: defaultData.PER_PAGE }}
          showTotalEntries={false}
          pagination={{ variant: "gradient", color: "info" }}
          loading={loadingStatus.location}
          currentPage={currentPage}
          handleCurrentPage={handlePageChange}
        />
      </ViewDrawer>

      <ViewDrawer
        title="Project String"
        openDrawer={openProjectStringData}
        closeDrawer={handleCloseProjectStringData}
      >
        <MDBox mb={2} mt={-3}>
          <SearchBar
            freeSolos
            options={filters[0]?.list.map((val) => val.title) || []}
            filters={filters}
            placeholder="Search"
            value={filters[0].selectedValue}
            debouncedHandleSearch={debouncedHandleSearchString}
            handleFilterChange={(e, value) =>
              debouncedHandleSearchString({
                target: { name: filters[0]?.inputLabel, value },
              })
            }
            isLoading={filters[0].isLoading}
          />
        </MDBox>
        <DataTable
          table={{
            columns: ProjectStringColumns,
            rows: ProjectStringRows,
          }}
          backgroundColor={Colors.LIGHT_GRAY} // Specify the background color here
          textColor={Colors.BLACK}
          isSorted={false}
          entriesPerPage={{ defaultValue: defaultData.PER_PAGE }}
          showTotalEntries={false}
          pagination={{ variant: "gradient", color: "info" }}
          loading={loadingStatus.projectString}
          currentPage={currentPage}
          handleCurrentPage={handlePageChange}
        />
      </ViewDrawer>

      <ViewDrawer
        title="Asset"
        openDrawer={openAssetData}
        closeDrawer={handleCloseAssetData}
        drawerWidth="1000px"
      >
        <MDBox mb={2} mt={-3}>
          <SearchBar
            freeSolos
            options={filters[0]?.list.map((val) => val.title) || []}
            filters={filters}
            placeholder="Search"
            value={filters[0].selectedValue}
            debouncedHandleSearch={debouncedHandleSearchAsset}
            handleFilterChange={(e, value) =>
              debouncedHandleSearchAsset({
                target: { name: filters[0]?.inputLabel, value },
              })
            }
            isLoading={filters[0].isLoading}
          />
        </MDBox>
        <DataTable
          table={{
            columns: AssetColumns,
            rows: AssetRows,
          }}
          backgroundColor={Colors.LIGHT_GRAY} // Specify the background color here
          textColor={Colors.BLACK}
          isSorted={false}
          entriesPerPage={{ defaultValue: defaultData.PER_PAGE }}
          showTotalEntries={false}
          pagination={{ variant: "gradient", color: "info" }}
          loading={loadingStatus.asset}
          currentPage={currentPage}
          handleCurrentPage={handlePageChange}
        />
      </ViewDrawer>

      <ViewDrawer title="Teams" openDrawer={openTeamsData} closeDrawer={handleCloseTeamsData}>
        <MDBox mb={2} mt={-3}>
          <SearchBar
            freeSolos
            options={filters[0]?.list.map((val) => val.title) || []}
            filters={filters}
            placeholder="Search"
            value={filters[0].selectedValue}
            debouncedHandleSearch={debouncedHandleSearchTeam}
            handleFilterChange={(e, value) =>
              debouncedHandleSearchTeam({
                target: { name: filters[0]?.inputLabel, value },
              })
            }
            isLoading={filters[0].isLoading}
          />
        </MDBox>
        <DataTable
          table={{
            columns: teamColumns,
            rows: teamRows,
          }}
          backgroundColor={Colors.LIGHT_GRAY} // Specify the background color here
          textColor={Colors.BLACK}
          isSorted={false}
          entriesPerPage={{ defaultValue: defaultData.PER_PAGE }}
          showTotalEntries={false}
          pagination={{ variant: "gradient", color: "info" }}
          loading={loadingStatus.team}
          currentPage={currentPage}
          handleCurrentPage={handlePageChange}
        />
      </ViewDrawer>

      <ViewDrawer title="Members" openDrawer={openMemberData} closeDrawer={handleCloseMemberData}>
        <MDBox mb={2} mt={-3}>
          <SearchBar
            freeSolos
            options={filters[0]?.list.map((val) => val.title) || []}
            filters={filters}
            placeholder="Search"
            value={filters[0].selectedValue}
            debouncedHandleSearch={debouncedHandleSearchMember}
            handleFilterChange={(e, value) =>
              debouncedHandleSearchMember({
                target: { name: filters[0]?.inputLabel, value },
              })
            }
            isLoading={filters[0].isLoading}
          />
        </MDBox>
        <DataTable
          table={{
            columns: membersColumns,
            rows: membersRows,
          }}
          backgroundColor={Colors.LIGHT_GRAY} // Specify the background color here
          textColor={Colors.BLACK}
          isSorted={false}
          entriesPerPage={{ defaultValue: defaultData.PER_PAGE }}
          showTotalEntries={false}
          pagination={{ variant: "gradient", color: "info" }}
          loading={loadingStatus.member}
          currentPage={currentPage}
          handleCurrentPage={handlePageChange}
        />
      </ViewDrawer>

      <ViewDrawer title="Scopes" openDrawer={openScopesData} closeDrawer={handleCloseScopesData}>
        <MDBox mb={2} mt={-3}>
          <SearchBar
            freeSolos
            options={filters[0]?.list.map((val) => val.title) || []}
            filters={filters}
            placeholder="Search"
            value={filters[0].selectedValue}
            debouncedHandleSearch={debouncedHandleSearchScope}
            handleFilterChange={(e, value) =>
              debouncedHandleSearchScope({
                target: { name: filters[0]?.inputLabel, value },
              })
            }
            isLoading={filters[0].isLoading}
          />
        </MDBox>
        <DataTable
          table={{
            columns: ScopesColumns,
            rows: ScopesRows,
          }}
          backgroundColor={Colors.LIGHT_GRAY} // Specify the background color here
          textColor={Colors.BLACK}
          isSorted={false}
          entriesPerPage={{ defaultValue: defaultData.PER_PAGE }}
          showTotalEntries={false}
          pagination={{ variant: "gradient", color: "info" }}
          loading={loadingStatus.scope}
          currentPage={currentPage}
          handleCurrentPage={handlePageChange}
        />
      </ViewDrawer>

      <ViewDrawer
        title="Activity"
        openDrawer={openActivitiesData}
        closeDrawer={handleCloseActivitiesData}
      >
        <MDBox mb={2} mt={-3}>
          <SearchBar
            freeSolos
            options={filters[0]?.list.map((val) => val.title) || []}
            filters={filters}
            placeholder="Search"
            value={filters[0].selectedValue}
            debouncedHandleSearch={debouncedHandleSearchActivity}
            handleFilterChange={(e, value) =>
              debouncedHandleSearchActivity({
                target: { name: filters[0]?.inputLabel, value },
              })
            }
            isLoading={filters[0].isLoading}
          />
        </MDBox>
        <DataTable
          table={{
            columns: ActivitiesColumns,
            rows: ActivitiesRows,
          }}
          backgroundColor={Colors.LIGHT_GRAY} // Specify the background color here
          textColor={Colors.BLACK}
          isSorted={false}
          entriesPerPage={{ defaultValue: defaultData.PER_PAGE }}
          showTotalEntries={false}
          pagination={{ variant: "gradient", color: "info" }}
          loading={loadingStatus.activity}
          currentPage={currentPage}
          handleCurrentPage={handlePageChange}
        />
      </ViewDrawer>

      <ViewDrawer
        title="Functions"
        openDrawer={openFunctionsData}
        closeDrawer={handleCloseFunctionsData}
      >
        <MDBox mb={2} mt={-3}>
          <SearchBar
            freeSolos
            options={filters[0]?.list.map((val) => val.title) || []}
            filters={filters}
            placeholder="Search"
            value={filters[0].selectedValue}
            debouncedHandleSearch={debouncedHandleSearchFunction}
            handleFilterChange={(e, value) =>
              debouncedHandleSearchFunction({
                target: { name: filters[0]?.inputLabel, value },
              })
            }
            isLoading={filters[0].isLoading}
          />
        </MDBox>
        <DataTable
          table={{
            columns: FunctionsColumns,
            rows: FunctionsRows,
          }}
          backgroundColor={Colors.LIGHT_GRAY} // Specify the background color here
          textColor={Colors.BLACK}
          isSorted={false}
          entriesPerPage={{ defaultValue: defaultData.PER_PAGE }}
          showTotalEntries={false}
          pagination={{ variant: "gradient", color: "info" }}
          loading={loadingStatus.function}
          currentPage={currentPage}
          handleCurrentPage={handlePageChange}
        />
      </ViewDrawer>

      <ViewDrawer
        title="Project Certificate"
        openDrawer={openProjectCertificateData}
        closeDrawer={handleCloseProjectCertificateData}
        drawerWidth="697px"
      >
        <MDBox mb={2} mt={-3}>
          <SearchBar
            freeSolos
            options={filters[0]?.list.map((val) => val.title) || []}
            filters={filters}
            placeholder="Search"
            value={filters[0].selectedValue}
            debouncedHandleSearch={debouncedHandleSearchCertificate}
            handleFilterChange={(e, value) =>
              debouncedHandleSearchCertificate({
                target: { name: filters[0]?.inputLabel, value },
              })
            }
            isLoading={filters[0].isLoading}
          />
        </MDBox>
        <DataTableColl
          table={{
            columns: ProjectCertificateColumns,
            rows: ProjectCertificateRows,
          }}
          backgroundColor={Colors.LIGHT_GRAY} // Specify the background color here
          textColor={Colors.BLACK}
          isSorted={false}
          entriesPerPage={false}
          showTotalEntries={false}
          pagination={{ variant: "gradient", color: "info" }}
          loading={loadingStatus.certificateList}
          groupedData={groupData}
          keyField="function"
          keyFieldHeader="Function"
          currentPage={currentPage}
          handleCurrentPage={handlePageChange}
        />
      </ViewDrawer>

      <ViewDrawer
        title="Project Document"
        openDrawer={openProjectDocumentData}
        closeDrawer={handleCloseProjectDocumentData}
        drawerWidth="85%"
      >
        <DataTable
          table={{
            columns: ProjectDocumentColumns,
            rows: ProjectDocumentRows,
          }}
          backgroundColor={Colors.LIGHT_GRAY} // Specify the background color here
          textColor={Colors.BLACK}
          isSorted={false}
          entriesPerPage={{ defaultValue: defaultData.PER_PAGE }}
          showTotalEntries={false}
          pagination={{ variant: "gradient", color: "info" }}
          loading={loadingStatus.projectDocument}
          currentPage={currentPage}
          handleCurrentPage={handlePageChange}
        />
      </ViewDrawer>

      <MDBox display="flex" justifyContent="space-between" alignItems="center">
        <PageTitle title="Project" />
      </MDBox>
      <Divider sx={{ marginTop: pxToRem(22) }} />
      <MDBox display="flex" justifyContent="space-between" alignItems="center" mt={2}>
        <MDBox display="flex wrap" flexWrap="wrap" justifyContent="start" mx={0}>
          <FormControl sx={{ my: 1, minWidth: 250 }}>
            <MDTypography
              variant="caption"
              mb={1}
              sx={{ fontSize: pxToRem(14), fontWeight: 600, color: "#344054" }}
            >
              Select Project
            </MDTypography>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              displayEmpty
              placeholder="selected project"
              sx={{
                height: 40,
                paddingX: "0.65rem",
                fontSize: pxToRem(16),
                fontWeight: 400,
                backgroundColor: "#fff",
              }}
              IconComponent={Icons.DROPDOWN}
              value={selectedProjectId || ""}
              onChange={handleProjectChange}
              MenuProps={{
                PaperProps: {
                  sx: {
                    marginTop: "10px",
                    marginLeft: "-10px",
                    maxHeight: 300,
                    overflowY: "auto",
                  },
                },
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
              }}
            >
              <MenuItem disabled value="">
                Select Project
              </MenuItem>
              {lists.project.map((item) => (
                <MenuItem
                  value={item[mongooseId]}
                  key={item[mongooseId]}
                  sx={{ fontSize: pxToRem(16), fontWeight: 400, color: "#344054" }}
                >
                  {item && item?.title?.length > defaultData.MEDIUM_CONTENT_LENGTH ? (
                    <Tooltip title={item.title}>
                      <span>{`${item.title.slice(0, defaultData.MEDIUM_CONTENT_LENGTH)}...`}</span>
                    </Tooltip>
                  ) : (
                    item.title
                  )}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </MDBox>
        <MDBox>
          <CustomButton
            title="Project List"
            background={Colors.PRIMARY}
            icon="calendar_view_day"
            color={Colors.WHITE}
            openModal={handleOpenProjectList}
          />

          <CustomButton
            title="New Project"
            icon="add_circle_outline"
            background={Colors.PRIMARY}
            color={Colors.WHITE}
            openModal={() => handleOpenNewModal("Project")}
          />
        </MDBox>
      </MDBox>
      <MDBox py={3}>
        <Grid container spacing={3} sx={{ display: "flex" }}>
          {cardList
            .filter((item) => item.isVisible)
            .map((val) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={4}
                key={val.cardTitle}
                sx={{ display: "flex", flex: "1", minWidth: 0 }}
              >
                <MDBox mb={1.5} sx={{ width: "100%" }}>
                  <Card
                    style={{
                      boxShadow: "none",
                      border: "1px solid #E0E6F5",
                      minHeight: "200px",
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <MDBox sx={{ flex: "1 1 auto" }}>
                      <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
                        <MDBox textAlign="right" lineHeight={1.25}>
                          <MDTypography variant="h4" fontWeight="bold" color="#191A51">
                            {val.cardTitle}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                      <MDBox mt={2} ml={1} sx={{ flex: "1 1 auto" }}>
                        <MDTypography fontWeight="light" color="#475467" variant="body2" m={1}>
                          {val.content}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <MDBox display="flex" justifyContent="flex-end" pt={1} px={1} pb={2}>
                      <BasicButton
                        title={ButtonTitles.VIEW_list}
                        background={Colors.WHITE}
                        border
                        borderColor={selectedProjectId === "" ? "#BCBDD1" : Colors.PRIMARY}
                        icon={Icons.VIEW}
                        color={selectedProjectId === "" ? "#BCBDD1" : Colors.PRIMARY}
                        disabled={selectedProjectId === ""}
                        fontWeight="bold"
                        action={() => handleOpenProjectModal(val.cardTitle)}
                      />
                      <BasicButton
                        title={ButtonTitles.ADD}
                        border
                        borderColor={selectedProjectId === "" ? "#BCBDD1" : Colors.PRIMARY}
                        icon={Icons.ADD}
                        color={selectedProjectId === "" ? "#BCBDD1" : Colors.PRIMARY}
                        disabled={selectedProjectId === ""}
                        fontWeight="bold"
                        action={() => handleOpenNewModal(val.cardTitle)}
                      />
                    </MDBox>
                  </Card>
                </MDBox>
              </Grid>
            ))}
        </Grid>
      </MDBox>
      <DeleteModal
        open={deleteData.openDeleteModal}
        title={`Delete ${deleteData.type.toLowerCase()}`}
        message="Are you sure you want to delete?"
        handleClose={handleCloseDeleteModal}
        handleDelete={handleOpenDeleteModal}
      />
      <FullScreenImageComponent
        fullScreenImage={fullScreenImage}
        handleCloseFullView={handleCloseFullView}
        src={fullScreenImage}
      />
    </DashboardLayout>
  );
}

export default SetupProject;
