// Common Components
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FTextField from "components/Form/FTextField";
import BasicModal from "examples/modal/BasicModal/BasicModal";
import DeleteModal from "examples/modal/deleteModal/deleteModal";
import FilterDropdown from "components/Dropdown/FilterDropdown";
import ConfirmationModal from "examples/modal/Confirmation/Confirmation";
import Validations from "utils/Validations/index";

// Material-UI Imports
import { Button, Divider, FormControlLabel, Radio, RadioGroup } from "@mui/material";

// Custom components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PageTitle from "examples/NewDesign/PageTitle";
import CustomButton from "examples/NewDesign/CustomButton";
import DataTable from "examples/Tables/DataTable";
import BasicButton from "examples/NewDesign/CustomButton/BasicButton";

// Redux
import getAllQuestions, {
  createCertificateQuestion,
  updateCertificateQuestion,
  deleteCertificateQuestion,
} from "redux/Thunks/certificateQuestion";
import { openSnackbar } from "redux/Slice/Notification";

// Other Imports
import Certificatemanagementdata from "layouts/wfmwizard/System/data/certificateQuestionsData";
import pxToRem from "assets/theme/functions/pxToRem";
import Constants, { Icons, ButtonTitles, ModalContent, Colors, defaultData } from "utils/Constants";

function CertificateManagement() {
  const dispatch = useDispatch();
  const [loadingStatus, setLoadingStatus] = useState(Constants.PENDING);
  const [loading, setLoading] = useState(false);
  const [next, setNext] = useState(0);
  const [questionsData, setQuestionsData] = useState([]);
  const [certificateQuestions, setCertificateQuestion] = useState({
    type: "new",
    openModal: false,
    list: [],
    body: {
      name: "",
      validityDate: false,
      sortOrder: "",
    },
    errors: {},
    openDeleteModal: false,
    openConfirmationModal: false,
    editDeleteId: "",
    confirmationTitle: "",
    confirmationMessage: "",
  });
  const [filters, setFilters] = useState([
    {
      inputLabel: "Certificate",
      list: [
        { [Constants.MONGOOSE_ID]: "All", title: "All" },
        { [Constants.MONGOOSE_ID]: "true", title: "Validity Date" },
        { [Constants.MONGOOSE_ID]: "false", title: "Without Validity Date" },
      ],
      selectedValue: "All",
    },
  ]);
  const [tablePagination, setTablePagination] = useState({
    page: 0,
    perPage: defaultData.DATE_ON_SINGLE_API_CALL,
  });
  const handleFilter = async (filterVale = filters) => {
    setTablePagination({ ...tablePagination, page: 0 });
    const paramData = {
      page: defaultData.PAGE,
      perPage: tablePagination.perPage,
      validityDate: filterVale[0].selectedValue.toLowerCase().replace(/ /g, "_"),
      sort: "asc",
    };
    Object.keys(paramData).forEach((key) => {
      if (paramData[key] === "") {
        delete paramData[key];
      }
    });
    const data = new URLSearchParams(paramData);
    const res = await dispatch(getAllQuestions(data));
    setLoadingStatus(Constants.FULFILLED);
    setQuestionsData(res.payload.data.data);
  };
  const handleResetFilter = () => {
    setFilters((prev) => [{ ...prev[0], selectedValue: "All" }, ...prev.slice(1)]);
    handleFilter([{ ...filters[0], selectedValue: "All" }, ...filters.slice(1)]);
  };
  const handleTablePagination = async () => {
    const data = new URLSearchParams({
      page: next + 1,
      perPage: tablePagination.perPage,
    });
    const res = await dispatch(getAllQuestions(data));
    if (res.payload.status === 200) {
      setQuestionsData([...questionsData, ...res.payload.data.data]);
      setNext(res.payload.data.data.length > 0 ? next + 1 : next);
    }
  };

  const handleFilterChange = (e) => {
    const temp = [...filters];
    const { value } = e.target;
    const index = filters.findIndex((filter) => filter.inputLabel === e.target.name);
    temp[index].selectedValue = value;
    setFilters(temp);
    handleFilter(temp);
  };
  useEffect(() => {
    (async () => {
      handleFilter();
    })();
  }, []);
  const handleCloseCertificatequestions = () =>
    setCertificateQuestion((prev) => ({
      ...prev,
      type: "new",
      openModal: false,
      body: {
        name: "",
        validityDate: false,
        sortOrder: "",
      },
      errors: {},
    }));
  const handleCloseDeleteCertificateQuestionModal = () =>
    setCertificateQuestion((prev) => ({ ...prev, openDeleteModal: false }));
  const handleCloseConfirmationlModal = () =>
    setCertificateQuestion((prev) => ({ ...prev, openConfirmationModal: false }));
  const handleEdit = (data) => {
    setCertificateQuestion((prev) => ({
      ...prev,
      type: "update",
      openModal: true,
      body: { ...data.body },
      editDeleteId: data.id,
    }));
  };
  const handleDelete = (id) => {
    setCertificateQuestion((prev) => ({ ...prev, openDeleteModal: true, editDeleteId: id }));
  };
  const handlePublishChange = (event, data) => {
    const eventChecked = event.target.checked;
    const modalData = {
      openConfirmationModal: true,
      editDeleteId: data[Constants.MONGOOSE_ID],
      body: { validityDate: eventChecked },
      confirmationTitle: ModalContent.CERTIFICATE_QUESTION_CONFIRMATION_TITLE,
      confirmationMessage: eventChecked
        ? ModalContent.CERTIFICATE_CONFIRMATION_MESSAGE
        : ModalContent.CERTIFICATE_CONFIRMATION_REMOVE_MESSAGE,
    };
    setCertificateQuestion((prev) => ({
      ...prev,
      ...modalData,
    }));
  };
  const handlecertificateQuestionChange = (e) => {
    const { name, value } = e.target;
    setCertificateQuestion((prev) => ({ ...prev, body: { ...prev.body, [name]: value } }));
  };

  const certificateQuestionValidation = () => {
    const { body } = certificateQuestions;
    const errors = {};
    const questionError = Validations.validate("basic", body?.name);
    const sortOrderError = Validations.validate("number", body?.sortOrder);
    if (questionError !== "") {
      errors.name = questionError;
    }
    if (sortOrderError !== "") {
      errors.sortOrder = sortOrderError;
    }
    return errors;
  };
  const handleCreateCertificateQuestion = async () => {
    setLoading(true);
    const errors = certificateQuestionValidation();
    if (Object.keys(errors).length === 0) {
      const { body } = certificateQuestions;
      const res = await dispatch(createCertificateQuestion(body));
      if (res.payload.status === 200) {
        handleFilter();
        dispatch(
          openSnackbar({
            message: Constants.CERTIFICATE_CREATE_SUCCESS,
            notificationType: Constants.NOTIFICATION_SUCCESS,
          })
        );
        setCertificateQuestion((prev) => ({
          ...prev,
          openModal: false,
          errors: {},
          body: {
            name: "",
            validityDate: false,
            sortOrder: "",
          },
        }));
      } else if (res.payload.status === 400) {
        const temp = { ...certificateQuestions.errors };
        temp.name = res.payload.data.message;
        setCertificateQuestion((prev) => ({ ...prev, errors: temp }));
      } else {
        dispatch(
          openSnackbar({
            message: Constants.SOMETHING_WENT_WRONG,
            notificationType: Constants.NOTIFICATION_ERROR,
          })
        );
      }
    } else {
      setCertificateQuestion((prev) => ({ ...prev, errors }));
    }
    setLoading(false);
  };
  const handleUpdateCertificateQuestion = async () => {
    setLoading(true);
    const errors = certificateQuestionValidation();
    if (Object.keys(errors).length === 0) {
      const { body, editDeleteId } = certificateQuestions;
      const data = { body, editDeleteId };
      const res = await dispatch(updateCertificateQuestion(data));
      if (res.payload.status === 200) {
        handleFilter();
        dispatch(
          openSnackbar({
            message: Constants.CERTIFICATE_UPDATE_SUCCESS,
            notificationType: Constants.NOTIFICATION_SUCCESS,
          })
        );
        setCertificateQuestion((prev) => ({
          ...prev,
          openModal: false,
          errors: {},
          body: {},
          editDeleteId: "",
          type: "new",
        }));
      } else if (res.payload.status === 400) {
        const temp = { ...certificateQuestions.errors };
        temp.name = res.payload.data.message;
        setCertificateQuestion((prev) => ({ ...prev, errors: temp }));
      } else {
        dispatch(
          openSnackbar({
            message: Constants.SOMETHING_WENT_WRONG,
            notificationType: Constants.NOTIFICATION_ERROR,
          })
        );
      }
    } else {
      setCertificateQuestion((prev) => ({ ...prev, errors }));
    }
    setLoading(false);
  };
  const handleDeleteCertificateQuestion = async () => {
    const { editDeleteId } = certificateQuestions;
    const res = await dispatch(deleteCertificateQuestion(editDeleteId));
    if (res.payload.status === 200) {
      handleFilter();
      await dispatch(
        openSnackbar({
          message: Constants.CERTIFICATE_DELETE_SUCCESS,
          notificationType: Constants.NOTIFICATION_SUCCESS,
        })
      );
    } else if (res.payload.status === 400) {
      dispatch(
        openSnackbar({
          message: Constants.CERTIFICATE_DELETE_ERROR,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
    } else {
      dispatch(
        openSnackbar({
          message: Constants.SOMETHING_WENT_WRONG,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
    }
    setCertificateQuestion((prev) => ({ ...prev, openDeleteModal: false, editDeleteId: "" }));
  };
  const handlePermissionQuestion = async () => {
    const { body, editDeleteId } = certificateQuestions;
    const res = await dispatch(updateCertificateQuestion({ body, editDeleteId }));
    if (res.payload.status === 200) {
      handleFilter();
      await dispatch(
        openSnackbar({
          message: Constants.CERTIFICATE_CONFIRMATION_SUCCESS,
          notificationType: Constants.NOTIFICATION_SUCCESS,
        })
      );
    } else if (res.payload.status === 400) {
      dispatch(
        openSnackbar({
          message: Constants.CERTIFICATE_UPDATE_ERROR,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
    } else {
      dispatch(
        openSnackbar({
          message: Constants.SOMETHING_WENT_WRONG,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
    }
    setCertificateQuestion((prev) => ({
      ...prev,
      openConfirmationModal: false,
      errors: {},
      body: {},
      editDeleteId: "",
      type: "new",
    }));
  };
  const { columns, rows } = Certificatemanagementdata(
    questionsData,
    handleEdit,
    handleDelete,
    handlePublishChange
  );
  const handleReload = async () => {
    setLoadingStatus(Constants.PENDING);
    handleFilter();
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox display="flex" justifyContent="space-between" alignItems="center" fullWidth>
        <PageTitle title="Certificate Management" />
        <MDBox display="flex" flexDirection="row">
          <CustomButton
            title="Certificate"
            icon="add_circle_outline"
            background="#191A51"
            color="#ffffff"
            openModal={() => setCertificateQuestion((prev) => ({ ...prev, openModal: true }))}
          />
          <Divider
            orientation="vertical"
            sx={{
              backgroundColor: "var(--gray-300, #D0D5DD)",
              height: "auto",
              marginLeft: pxToRem(16),
              marginRight: 0,
            }}
          />
          <BasicButton
            icon={Icons.RELOAD}
            background={Colors.WHITE}
            border
            color={Colors.BLACK}
            action={handleReload}
          />
        </MDBox>
      </MDBox>
      <Divider sx={{ marginTop: 2 }} />
      <MDBox display="flex" alignItems="center">
        <MDBox
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
          alignItems="start"
          sx={{ flexDirection: "row", mr: 2 }}
        >
          {filters?.map((val) => (
            <FilterDropdown
              key={val.inputLabel}
              label={val.inputLabel}
              name={val.inputLabel}
              defaultValue={val?.selectedValue}
              value={val?.selectedValue}
              handleChange={handleFilterChange}
              menu={val.list}
            />
          ))}
          <Button
            sx={{
              mt: 5.5,
              mr: 1,
              backgroundColor: "#fff",
              "&:hover": {
                backgroundColor: "#fff",
              },
              fontSize: pxToRem(14),
              textTransform: "capitalize",
            }}
            variant="outlined"
            color="info"
            startIcon={Icons.RESET_FILTER}
            onClick={handleResetFilter}
          >
            {ButtonTitles.RESET_FILTER}
          </Button>
        </MDBox>
      </MDBox>
      <MDBox mt={3} mb={3}>
        <DataTable
          table={{ columns, rows }}
          isSorted={false}
          entriesPerPage={{ defaultValue: defaultData.PER_PAGE }}
          showTotalEntries={false}
          pagination={{ variant: "gradient", color: "info" }}
          loading={loadingStatus}
          currentPage={tablePagination.page}
          handleTablePagination={handleTablePagination}
          handleCurrentPage={(page) => setTablePagination({ ...tablePagination, page })}
        />
      </MDBox>
      {/* Create and Update Medical Question modal */}
      <BasicModal
        open={certificateQuestions.openModal}
        handleClose={handleCloseCertificatequestions}
        title={
          certificateQuestions.type === "new"
            ? ModalContent.NEW_Certificate_QUESTION_TYPE_TITLE
            : ModalContent.UPDATE_Certificate_QUESTION_TITLE
        }
        actionButton={
          (certificateQuestions.type === "new" && !loading && ButtonTitles.SUBMIT) ||
          (certificateQuestions.type === "new" && loading && ButtonTitles.SUBMIT_LOADING) ||
          (certificateQuestions.type === "update" && !loading && ButtonTitles.UPDATE) ||
          (certificateQuestions.type === "update" && loading && ButtonTitles.UPDATE_LOADING)
        }
        handleAction={
          certificateQuestions.type === "new"
            ? handleCreateCertificateQuestion
            : handleUpdateCertificateQuestion
        }
      >
        <FTextField
          label="Certificate Name*"
          placeholder="Certificate Name*"
          id="name"
          name="name"
          type="text"
          error={Boolean(certificateQuestions.errors?.name)}
          helperText={certificateQuestions.errors?.name}
          value={certificateQuestions.body?.name}
          handleChange={handlecertificateQuestionChange}
          marginBottom={2}
        />
        <FTextField
          label="Sort Order*"
          placeholder="Sort Order*"
          id="sortOrder"
          name="sortOrder"
          type="number"
          error={Boolean(certificateQuestions.errors?.sortOrder)}
          helperText={certificateQuestions.errors?.sortOrder}
          value={certificateQuestions.body?.sortOrder}
          handleChange={handlecertificateQuestionChange}
          marginBottom={2}
        />
        {certificateQuestions.type === "new" ? (
          <MDBox>
            <MDTypography
              variant="caption"
              mb={1}
              mt={2}
              sx={{ fontSize: pxToRem(14), fontWeight: 500, color: "#344054" }}
            >
              You want to add validity date?
            </MDTypography>
            <RadioGroup
              name="validityDate"
              value={certificateQuestions.body.validityDate}
              onChange={handlecertificateQuestionChange}
              sx={{ display: "flex", flexDirection: "row" }}
            >
              <FormControlLabel label="Yes" value="true" control={<Radio />} />
              <FormControlLabel
                sx={{ marginLeft: "8px" }}
                label="No"
                value="false"
                control={<Radio />}
              />
            </RadioGroup>
          </MDBox>
        ) : null}
      </BasicModal>
      {/* Delete Modal for Medical Questions */}
      <DeleteModal
        open={certificateQuestions.openDeleteModal}
        title={ModalContent.CERTIFICATE_QUESTION_DELETE_TITLE}
        message={ModalContent.CERTIFICATE_QUESTION_DELETE_MESSAGE}
        handleClose={handleCloseDeleteCertificateQuestionModal}
        handleDelete={handleDeleteCertificateQuestion}
      />

      {/* Confirmation Modal */}
      <ConfirmationModal
        open={certificateQuestions.openConfirmationModal}
        title={certificateQuestions.confirmationTitle}
        message={certificateQuestions.confirmationMessage}
        handleClose={handleCloseConfirmationlModal}
        handleAction={handlePermissionQuestion}
      />
    </DashboardLayout>
  );
}
export default CertificateManagement;
